<div class="contaniner">
    <div class="row titleContainer">
        <!-- <div class="col-sm-1 col-md-1 col-lg-1 containerTop" style="display: flex;">

            <div (click)="onNoClick()" style="cursor: pointer;">
                <i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i>
            </div>
        </div> -->
        <div class="col-sm-3 col-md-3 col-lg-3 containerTop" style="padding-left: 8px;display:flex">
            <p class="name_lab">Lab Reports</p>

            <img style="width: 2.5rem;margin-left: 00.5rem;" *ngIf="pacExists" src="assets/brain_tick.svg">

            <img style="width: 2.5rem;margin-left: 00.5rem;cursor: pointer;" (click)="pacs()" *ngIf="!pacExists" src="assets/brain_close.svg">

            <p *ngIf="pacExists&&dicomCount>0" style="align-self: center;margin-left:0.5rem">{{dicomCount}} <span *ngIf="dicomCount==1">study</span><span *ngIf="dicomCount>1">studies</span> available</p>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 containerTop text-center">
            <button type="button" [class.filter_highlight]="filter_type==1" (click)="setFilterType(1)"
                class="btn btn-outline-info1 ml-3">ALL</button>
            <button type="button" [class.filter_highlight]="filter_type==2" (click)="setFilterType(2)"
                class="btn btn-outline-info1 ml-3">UNSEEN</button>
            <button type="button" [class.filter_highlight]="filter_type==3" (click)="setFilterType(3)"
                class="btn btn-outline-info1 ml-3">ABNORMAL</button>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3" style="display: flex;">
            <button type="button" style="border: 1px solid;border-radius: 0.5rem;" class="btn btn-outline-info2"
                (click)="openUpload()">Upload
                Reports</button>

                <img class="mute icon_menu"  style="margin-left: auto;cursor: pointer;width: 1.5rem;"  src="assets/significant labs.svg" (click)="labNotes()"  data-toggle="tooltip" data-placement="bottom" title="Lab Notes">


                <img (click)="close()" style="margin-left: 0.5rem;cursor: pointer;width: 1.5rem;" src="assets/close-copy.svg">

        </div>

    </div>

    <div class="row" style="max-height: 30rem;overflow: auto;">
        <p *ngIf="labArray.length == 0" class="text-center" style="width: 100%;font-size: 1rem;margin-top: 1rem;">No
            Records Found </p>
         <table class="table labtable" style="padding-top: 10px;"  *ngIf="labArray.length > 0">
            <tbody style="width: 100%;">
                <tr style="background-color: #E5F0FF;text-align: center;width: 100%;">
                    <td style="font-weight: 500;border-right: 2px solid #c8e2ff;" class="border_top">DATE &
                        TIME
                    </td>
                    <td style="font-weight: 500;border-right:2px solid #c8e2ff;width:20%" class="border_top">TESTS
                    </td>

                    <td style="font-weight: 500;border-right:2px solid #c8e2ff;" class="border_top">IMPRESSION
                    </td>

                    <td style="font-weight: 500;border-right:2px solid #c8e2ff;" class="border_top">COMMENTS
                    </td>

                    <td style="font-weight: 500;border-right:2px solid #c8e2ff;" class="border_top">TITLE
                    </td>

                    <td style="font-weight: 500;border-right:2px solid #c8e2ff;" class="border_top">FILE NAME
                    </td>

                    <td style="font-weight: 500;border-right:2px solid #c8e2ff;" class="border_top">TEST TAKEN DATE
                    </td>

                    <td style="font-weight: 500;border-right:2px solid #c8e2ff;" class="border_top">EDIT
                    </td>

                    <td style="font-weight: 500;border-right:2px solid #c8e2ff;" class="border_top">FILE TYPE
                    </td>

                    <td style="font-weight: 500;border-right:2px solid #c8e2ff;" class="border_top">ATTACHMENTS
                    </td>
                </tr>

                <tr *ngFor="let l of labArray; let i=index" style="width: 100%;" class="attachmentRow">


                    <td [attr.rowspan]="l.count" *ngIf="i==0||(l.date!=labArray[i-1].date)"
                        style="border-right: 2px solid #c8e2ff;border-bottom: 2px solid #c8e2ff;">

                        {{l.date | date:'dd
                        MMM yyyy'}}
                    </td>

                    <td [attr.rowspan]="l.count" *ngIf="i==0||(l.test_names!=labArray[i-1].test_names)" style="border-right: 2px solid #c8e2ff;border-bottom: 2px solid #c8e2ff;">
                        <p style="word-break: break-all;">{{l.test_names}}</p>
                    </td>

                    <td style="border-right: 2px solid #c8e2ff;border-bottom: 2px solid #c8e2ff;">
                        {{l.description}}
                    </td>

                    <td style="border-right: 2px solid #c8e2ff;border-bottom: 2px solid #c8e2ff;">
                        {{l.comments}}

                    </td>

                    <td style="border-right: 2px solid #c8e2ff;border-bottom: 2px solid #c8e2ff;">
                        {{l.title}}

                    </td>

                    <td style="border-right: 2px solid #c8e2ff;border-bottom: 2px solid #c8e2ff;">
                        {{l.file_name}}

                    </td>

                    <td style="border-right: 2px solid #c8e2ff;border-bottom: 2px solid #c8e2ff;">
                        {{l.test_taken_on | date:'dd
                        MMM yyyy'}}

                    </td>

                    <td style="border-right: 2px solid #c8e2ff;border-bottom: 2px solid #c8e2ff;">
                        <div style="display: flex;justify-content: center;">
                            <img style="cursor:pointer" (click)="edit1(l.lab_id,l.title?[l.title]:l.test_names,l.date,l.lab_id?2:1,l.lab_test,l.test_names, l.category, l.description, l.comments,l.file_name,l.test_taken_on)" src="assets/edit.svg">
                        </div>

                    </td>

                    <td style="border-right: 2px solid #c8e2ff;border-bottom: 2px solid #c8e2ff;">
                        <div *ngFor="let a of l.lab_attachments" style="margin-bottom:00.5rem;height:2rem;display: flex" [class.dicom_height]="a.file_type == 'dicom'">
                            <p style="align-self: center;">{{a.file_type}}</p>
                            <img *ngIf="a.other_format_files==true" style="width: 1.5rem;margin-left: 00.5rem;" src="assets/attach_dicom1.svg">
                    
                        </div>
                        
                    </td>

                    <td style="border-right: 2px solid #c8e2ff;border-bottom: 2px solid #c8e2ff;">
                        <div *ngFor="let a of l.lab_attachments" style="margin-bottom:00.5rem;height:2rem" [class.dicom_height]="a.file_type == 'dicom'">
                            <div style="display: flex;justify-content:space-between">
                                <img *ngIf="a.file_type != 'dicom' " (click)="replace(l.lab_id,l.title?[l.title]:l.test_names,l.date,l.lab_id?2:1,l.lab_test, l, l,  l.category,l.file_name,l.test_taken_on,a.id)" style="cursor:pointer;width: 30%;" src="assets/replace.svg">
                                <img *ngIf="a.file_type != 'dicom' " (click)="view(a.attachment,a.file_type,l.lab_id, l.category)" style="cursor:pointer;width: 30%;" src="assets/view.svg">
                                <img *ngIf="a.file_type == 'dicom' " (click)="view(a.attachment,a.file_type,l.lab_id, l.category)" style="cursor:pointer;width: 3rem;height: 3rem;" [src]="host+'/helyxonapi/cloud/dcm/'+ a.study_id + '.png'">

                                <img (click)="delete(a.id)" style="cursor:pointer;width: 30%;" src="assets/delete_lab.svg">

                            </div>
                        </div>
                        
                        <img style="cursor:pointer;width: 30%;" (click)="openAttachment2(l,l.lab_id,2,l.title)" src="assets/add_lab.svg">

                        <img style="cursor:pointer;width: 30%;margin-left: 6%;" (click)="getPacsAttachment(l,l.lab_id,2,l.title)" *ngIf="pacExists" src="assets/brain_view.svg">

                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</div>