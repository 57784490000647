<app-navbar class="mb-2"></app-navbar>

<div class="row" style="padding-bottom: 2rem;">
    <div class="col-lg-3 col-md-3">

        <div
            style="width: 20%;height:25rem;border:1px solid #FFF;margin-left: 10%;position: fixed;bottom: 40px;margin-left:2.5%;">
            <p style="text-align:center;font-size:1.2rem;height: 2rem;background-color: #FFF;color: #000;">Chat</p>
            <div class="conversation" style="width: 100%;height: 23rem;">
                <div class="conversation-container" style="width: 100%;height: 23rem;" #scrollMe>
                    <!-- <div *ngFor="let message of chathistoryList">
                        <div class="message " [class.sent]="message.source=='doctor'" [class.received]="message.source=='nurse'">
                            <p style="color:#000">{{message.notes}}</p>
                            <p style="font-size: 0.7rem;color:#000">- {{message.source}}</p>
                        </div>
                    </div> -->
                    <div *ngFor="let chat of chatArray">
                        <p class="message1 log">{{chat.date}}</p>
                        <div *ngFor="let message of chat.texts">
                        <div class="message " [class.sent]="message.source=='doctor'" [class.received]="message.source=='nurse'">
                            <p style="color:#000">{{message.notes}}</p>
                            <p style="font-size: 0.7rem;color:#000">{{message.time}}</p>
                            <p style="font-size: 0.7rem;color:#000">- {{message.source}}</p>

                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="col-lg-6 col-md-6">
        <p style="font-size: 1.2rem;text-align:center;display:flex;justify-content: center;">Clinical Pathway Days <img (click)="trends()"
                style="width: 1.5rem;margin-left: 5rem;cursor: pointer;" src="assets/trends.png"></p>

        <div style="margin-top:1rem">
            <p style="font-size: 1rem;text-align: center;">{{patient.patient_name}} / {{patient.gender}} / {{y}}Y {{m}}M
                / {{patient.patient_id}}</p>
        </div>

        <div style="display: flex;justify-content: center;color:#FFF;font-size:1rem;margin-top: 1rem;">
            <table>
                <tr>
                    <td>Treating Doctor</td>
                    <td> &nbsp;&nbsp;: </td>
                    <td>&nbsp;&nbsp;{{followup_data.treating_doctor}}</td>
                </tr>

                <tr>
                    <td>Surgery Doctor</td>
                    <td> &nbsp;&nbsp;: </td>
                    <td> &nbsp;&nbsp;{{followup_data.surgeon}}</td>

                </tr>

                <tr>
                    <td>Discharged on</td>
                    <td> &nbsp;&nbsp;: </td>
                    <td> &nbsp;&nbsp;{{followup_data.discharge_date}}</td>

                </tr>

                <tr>
                    <td>Surgery for</td>
                    <td> &nbsp;&nbsp;: </td>
                    <td> &nbsp;&nbsp;{{followup_data.surgery_name}}</td>

                </tr>
            </table>
        </div>

        <div style="color:#FFF;font-size:1rem;margin-top: 1rem;">
            <p style="text-align: center;">FollowUp Days</p>
            <div *ngFor="let f of follow_data;let i=index"
                style="display:flex;justify-content:center;margin-top: 0.5rem;cursor:pointer" (click)="navigate(f,i)">
                <button class="btn followBtn" [class.green]="f.data" style="pointer-events: none;"
                    [class.disabed]="!f.data">Day {{i+1}}</button>
                <img [class.visible]="f.notes&&f.notes!=''" style="margin-left:0.5rem;width: 1.5rem;visibility: hidden;"
                    src="assets/comment.svg">
            </div>

        </div>


    </div>

    <div class="col-lg-3 col-md-3">

    </div>
</div>