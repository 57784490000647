import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { PubNubAngular } from 'pubnub-angular2';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-chart1',
    templateUrl: './chart1.component.html',
    styleUrls: ['./chart1.component.scss'],
    providers: [PubNubAngular]

})
export class Chart1Component implements OnInit {

    pubnub: PubNubAngular;
    options;
    num=500
    number = 0;
    sub;
    code;
    mrn;
    data=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    points=[];
    audio;
    high;
    low;
 //   high=Number(sessionStorage.getItem("v1"))
 //   low=Number(sessionStorage.getItem("v2"))

    constructor(pubnub: PubNubAngular,private toastr: ToastrService) {
        this.pubnub = pubnub;
     }


    ngOnInit(): void {
        this.code=sessionStorage.getItem("code").toLowerCase();
        this.mrn=sessionStorage.getItem("mrn");
        console.log("code","mrn  chart 1", this.code , this.mrn)
        this.high=Number(sessionStorage.getItem("v1"));
        this.low=Number(sessionStorage.getItem("v2"))
        var that=this;
        var selectedChannel = 'get_message_' + this.code.toLowerCase();
        var hubChannel = 'receive_hub_data_' + this.code.toLowerCase();
        var refresh_channel = "refresh_hub_" + this.code.toLowerCase();
        var receivehub = "receive_hub_detail_"+this.code.toLowerCase();
        var disconnectedhub = "status_"+this.mrn.toLowerCase()+"_"+this.code.toLowerCase();
        
        this.pubnub.init({
            publishKey: 'demo',
            subscribeKey: 'demo',
            ssl: false
        });

        this.pubnub.subscribe({
            channels: [selectedChannel, hubChannel, refresh_channel , receivehub, disconnectedhub],
            withPresence: true,
            triggerEvents: ['message', 'presence', 'status']
        });

        this.pubnub.getStatus([selectedChannel, hubChannel, refresh_channel , receivehub, disconnectedhub], (msg) => {
          //  console.log(msg);
        });

        this.pubnub.getPresence([selectedChannel, hubChannel, refresh_channel , receivehub, disconnectedhub], (msg) => {
          //  console.log(msg);
        });

        this.options = {
            chart: {
                type: 'spline',
                // animation: {
                //     duration: 1000,
                //     easing: 'easeOutBounce'
                // },
             //   animation:false,
            //    animation: {
            //     duration: 500
            //   },
                // animation: Highcharts.svg, // don't animate in old IE
                marginRight: 10,
                backgroundColor: '#000000',
                
                events: {
                    load: function () {
                        
                        that.pubnub.getMessage([hubChannel], (msg) => {
                            
                        var series = this.series[0];
                        var x = (new Date()).getTime(), // current time
                            y = Math.random();
                        if(msg.message.patientID==that.mrn&&msg.message.type==0)
                        {
                            if(msg.message.bpm)
                            {
                                
                                that.number=msg.message.bpm;
                                console.log(that.number);
                                
                                series.data[0].remove(true, false);

                               series.addPoint([x, Number(msg.message.bpm)], true, false, 
                               
                           //    { duration: 200 }
                               
                               );

                            }

                        }
                        });  
                        
                        that.pubnub.getMessage([disconnectedhub], (msg) => {
                            console.log("reset here****");
                            console.log(msg);
                            if(msg.message.patientID==that.mrn&&msg.message.status=='disconnected')
                            {
                                that.toastr.error("Device Disconnected");
                                var series = this.series[0];
                                
                                for (var k = 0; k <20; k++){
                                    var x = (new Date()).getTime();
                                    series.data[0].remove(false, false);
                                    series.addPoint([x + k * 1000, Number(that.low)], true, false);
                                }
                                that.number = 0;
                                this.audio = new Audio("assets/beep.mp3");
                                this.audio.load();
                                this.audio.play();
                            }
                        });
                    }
                    
                }
            },
    
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
              //      lineWidth: 1,

                    color: '#00FF00'
                },
            },
    
            time: {
                useUTC: false
            },
    
            title: {
                text: ''
            },
    
            accessibility: {
                announceNewData: {
                    enabled: true,
                    minAnnounceInterval: 15000,
                    announcementFormatter: function (allSeries, newSeries, newPoint) {
                        if (newPoint) {
                            return 'New point added. Value: ' + newPoint.y;
                        }
                        return false;
                    }
                }
            },
    
            xAxis: {
                type: 'datetime',
                tickPixelInterval: 150,
                visible: false,
                
            },
    
            yAxis: {

                labels: {
                    style: {
                        color: '#00FF00'
                    }
                },
            

                gridLineWidth: 0.25,
                gridLineColor: '#00FF00',
              //  opacity:'0.5',
                title: {
                    useHTML: true,
                    text: 'bpm',
                    rotation: 0,
                    style: {
                        color: '#00FF00',
                        fontFamily: 'Montserrat'

                    }
                },
                plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                }],
                visible: true,
                tickPixelInterval: 10,
                 min:that.low,
                 max:that.high
            },
    
            tooltip: {
                headerFormat: '<b>{series.name}</b><br/>',
                pointFormat: '{point.y:.2f}',
                enabled: true,
                style:{
                    fontSize:'1.5vw'
                }
            },
    
            legend: {
                enabled: false
            },

    
            credits: {
                enabled: false
            },
    
            series: [{
                name: '',
                enableMouseTracking: true,
    
                data: (function () {
                    var data = [],
                        time = (new Date()).getTime(),
                        i;
    
                        for (i = -19; i <= 0; i++) {
                            data.push({
                            x: time + i * 1000,
                            y: that.low
                        });
                    }
                 
                    return data;
                }())
            }]
        }

        Highcharts.chart('container1', this.options);
    }


    ngOnDestroy() {
        var selectedChannel = 'get_message_' + this.code.toLowerCase();
        var hubChannel = 'receive_hub_data_' + this.code.toLowerCase();
        var refresh_channel = "refresh_hub_" + this.code.toLowerCase();
        var receivehub = "receive_hub_detail_"+this.code.toLowerCase();
        var disconnectedhub = "status_"+this.mrn.toLowerCase()+"_"+this.code.toLowerCase();
        this.pubnub.unsubscribe({
            channels: [selectedChannel, hubChannel, refresh_channel , receivehub,disconnectedhub]
        });
    }


}
