<div class="row">
    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
        <div class="bracket"></div> 
        <figure class="highcharts-figure">
            <div id="container4"></div>
        </figure>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
        <!-- <div class="row1">
            <p class="resp">&nbsp;Resp</p>
        </div>

        <div class="row1">
            <div style="width: 80%;">
                <p class="text2">&nbsp;{{number}}</p>

            </div>
            <div class="brac">
        
            </div>
            <div style="width: 80%;">

                
                <div class="row1 maxmin">
                    <p>&nbsp;{{high}}</p>
                </div>

                <div class="row1 maxmin">
                    <p style="align-self: flex-end;">&nbsp;{{low}}</p>
                </div>
            </div>
        </div> -->
        <p class="hr">&nbsp;Resp</p>
        <div style="display: flex;flex-direction: row;height: 75%;padding-bottom: 10px;">

        <div class="ecg_no">
            <!-- <p class="ecg">&nbsp;Heart Rate</p> -->
            <p class="number">&nbsp;{{number}}</p>
        </div>
        <div class="brac">
        
        </div>
        <div class="texts">
            <div class="row1">
                <p>&nbsp;{{high}}</p>
            </div>
            <div class="row1">
                <p style="align-self: flex-end;">&nbsp;{{low}}</p>
            </div>
        </div>
    </div>
</div>