<div class="row">
    <div class="col-lg-2"></div>
    <div class="col-lg-8">
        <select class="form-control" [(ngModel)]="selectdoctor">
            <option selected disabled value="select">Select Doctor</option>
            <option *ngFor="let doc of data.doctorlist" value="{{doc.dr_id}}" >{{doc.dr_name}}</option>
        </select>
    </div>
    <div class="col-lg-2"></div> 
</div>
<br>
<span>
    <button class="btn btn-info btn-pressure btn-sensitive buttons" (click)="adddoctrue()">Yes</button>
    <button class="btn btn-danger buttons" (click)="adddocfalse()">No</button>
</span>


