<div>
    <button _ngcontent-rqf-c101="" type="button" aria-label="Close" class="close"><span _ngcontent-rqf-c101="" aria-hidden="true"  (click)="closechart()">×</span></button>
</div>
<div style="overflow-y: auto;padding: 5px;height: 90%;">
    
    <div style="display: flex">
        <div [class.inactive]="block==2"  [class.active]="block==1" (click)="setBlock(1)" style="width: 10%;font-size: 1rem;padding: 0.5rem;">
            <p class="text-center" >mmol</p>
        </div>
    
        <div [class.inactive]="block==1"  [class.active]="block==2" (click)="setBlock(2)" style="width: 10%;font-size: 1rem;padding: 0.5rem;">
            <p class="text-center">mgdl</p>
        </div>
    </div>

    <br>
    <div id="glucometer"></div>
    <br>
    <div id="bp"></div> 
    <div style="text-align: center;">
        <span>{{bpdata}}</span>
    </div>  



</div>