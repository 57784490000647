<app-navbar class="mb-2"></app-navbar>

<!-- <div class="row markerrow">
  <div class="markermapcontent">
    <div>
      
   </div>
  </div>
</div> -->

<div class="media-container" style="margin-top: 10px;">
  <div id="local-media"></div> 
  <div id="remote-media"></div>
  <div id="myvideo"></div>
</div>


<div class="container-fluid">
  <div class="row">
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 p-2" *ngFor="let data of patient_List;">
      <div class="cardbody" [ngClass]="{'panel-border-danger':data.alert_user_state == 1 , 'panel-border-danger1':data.alert_user_state == 0}">
        <div class="card-header">
          <div class="row">
            <div class="col-7 pl-3 cardname"><h3 class="cardname" data-toggle="tooltip" [title]=data.patient_name >{{data.patient_name}}</h3></div>
            <!-- <div class="col-1 carddiv text-center"><img src="assets/ivital/siren.svg"  alt="ivital" height="20" width="20"></div> -->
           <div class="col-1 carddiv text-center"><img src="assets/ivital/sensor/6.png"  alt="ivital" height="18" width="18" data-toggle="tooltip" title="Cloud Monitoring Enabled" *ngIf="data.patient_id===data.patient_id && data.force_cloud === '1'"></div>
           <div class="col-1 carddiv text-center">
               <i class="fas fa-tint" [ngStyle]="{'color': data.device_color_code  }"  data-toggle="tooltip" title="Oxy2" alt="ivital" height="30" width="30"></i>
               <!-- <img src="assets/oxygen.svg" [ngStyle]="{'background-color': data.hub_color }"  data-toggle="tooltip" title="Oxy meter" alt="ivital" height="20" width="20" (click)="cardsetting(data)"> -->
           </div>
           <div class="col-1 carddiv text-center">
               <i class="fas fa-network-wired" [ngStyle]="{'color': data.hub_color }" data-toggle="tooltip" title="Blufi" height="30" width="30"></i>
           </div>
           <div class="col-1 carddiv text-center"><img src="assets/ivital/setting.png" data-toggle="tooltip" title="Card settings"  alt="ivital" height="20" width="20" (click)="cardsetting(data)"></div>
          </div>
          <div class="row">
            <div class="col-lg-9 col-md-9 col-sm-9 pl-3"><p>{{ data.sex === "0" ? 'M' : 'F' }}/ {{data.dob*1000 | date:'dd-MMM-yyyy'}} / {{ data.patient_id }} / {{data.uhid}}</p></div>
            <div class="col-lg-3 col-md-3 col-sm-3 p-0" ><p>{{data.hub_name}}</p></div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-2 pull-right"><img src="assets/ivital/temperature.png"  alt="ivital" height="35" width="35" (click)="temp(data)"></div>
            <div class="col-4 cardtext_left">{{data.temp}}</div>
            <div class="col-4 cardtext_right"><h3 class="righttext">{{data.bpm}}</h3></div>
            <div class="col-2 p-0 pull-left"><img src="assets/ivital/hr_ppm.png"  alt="ivital" height="35" width="35" (click)="temp(data)"></div>
          </div>
          <div class="row">
            <div class="col-2 mt-3 pull-right"><img src="assets/ivital/spo2.png"  alt="ivital" height="35" width="35" (click)="temp(data)"></div>
            <div class="col-4 mt-3 cardtext_left">{{data.o2}}</div>
            <div class="col-4 mt-3 cardtext_right"><h3 class="righttext"></h3></div>
            <div class="col-2 mt-3 p-0 pull-left"><img src="assets/ivital/heartbeat.png"  alt="ivital" height="35" width="35" (click)="temp(data)"></div>
          </div>
          <div class="row">
            <div class="col-2 mt-3 pull-right"><img src="assets/ivital/rr_rpm.png"  alt="ivital" height="35" width="35" (click)="temp(data)"></div>
            <div class="col-4 mt-3 cardtext_left">{{data.rr}}</div>
            <div class="col-4 mt-3 cardtext_rightrox"><h3 class="righttext">{{data.rox}}</h3></div>
            <div class="col-2 mt-3 p-0 pull-left rox">ROX</div>
          </div>
          <div class="row">
            <div class="col-2 mt-3 pull-right pi">P.I</div>
            <div class="col-4 mt-3 cardtext_left">{{data.pi}}</div>
            <div class="col-3 mt-3"></div>
            <div class="col-2 mt-3 p-0 pull-left timestamp">{{data.timeStamp*1000 | date:'HH:mm:ss'}}</div>
            <!-- <img src="assets/ivital/sensor/6.png"  alt="ivital" height="35" width="35"> -->
            <div class="col-6">
              <img *ngIf="data.discharge_id" style="width: 1.5rem;margin-top: 0.5rem;" src="assets/followup.png">
            </div>
            <div class="col-6" style="width:100%;display: flex;justify-content: space-evenly;"> 
               <img  *ngIf="data.is_bp == 1" src="assets/ivital/bcg.svg" height="27" width="27" style="display: flex;justify-content: space-evenly;margin:4px;" (click)="temp(data)">
               <img  *ngIf="data.is_bp == null" src="assets/black.png" height="27" width="27" style="display: flex;justify-content: space-evenly;margin:4px;">
               <img  *ngIf="data.is_bp == 0" src="assets/black.png" height="27" width="27" style="display: flex;justify-content: space-evenly;margin:4px;">
               
               <img  *ngIf="data.is_gulco == 1" src="assets/ivital/gluco.svg" height="27" width="27" style="display: flex;justify-content: space-evenly;margin:4px;" (click)="temp(data)">
               <img  *ngIf="data.is_gulco == null" src="assets/black.png" height="27" width="27" style="display: flex;justify-content: space-evenly;margin:4px;">
               <img  *ngIf="data.is_gulco == 0" src="assets/black.png" height="27" width="27" style="display: flex;justify-content: space-evenly;margin:4px;">
               
               <img  *ngIf="data.is_ecg == 1" src="assets/ivital/ecg.svg" height="27" width="27" style="display: flex;justify-content: space-evenly;margin:4px;" (click)="temp(data)">
               <img  *ngIf="data.is_ecg == null" src="assets/black.png" height="27" width="27" style="display: flex;justify-content: space-evenly;margin:4px;">
               <img  *ngIf="data.is_ecg == 0" src="assets/black.png" height="27" width="27" style="display: flex;justify-content: space-evenly;margin:4px;">
              
              </div>

          </div>
         
        </div>
      </div>
    </div>
    
    <div>
    <div class="peerlocation" *ngIf="peertopeer_list">
      <button type="btn" class="peertopeernotify" (click)="peertopeerlist()"><mat-icon style="margin-top: 2px;position: absolute;margin-left: 3px;" [matBadge]= 'peercount' matBadgeColor="warn"></mat-icon><img src="/assets/peertopeeerlogo.svg" height="25"/></button>
    </div>
     
     <div class="peerlocation" *ngIf="!peertopeer_list">
      <button type="btn" class="peertopeernotify1" (click)="peertopeerlist()"><mat-icon style="margin-top: 2px;position: absolute;margin-left: 3px;" [matBadge]= 'peercount' matBadgeColor="warn"></mat-icon><img src="/assets/peertopeeerlogo.svg" height="25"/></button>
     </div>
    </div>
   

     <div class="markerlocation" *ngIf="!showmarkermap">
      <button type="btn" class="markernotify" (click)="maptoggler()"><img src="/assets/gps.svg" height="25"/></button>
     </div>

     <div class="markerlocation" *ngIf="showmarkermap">
      <button type="btn" class="markernotify1" (click)="maptoggler()"><img src="/assets/gps.svg" height="25"/></button>
     </div>

    <div class="notification"  *ngIf="!hiderightbar">    
      <button type="btn" class="notify" (click)="rightbarshow()"><mat-icon class="bbadge" [matBadge]= 'appointmentcount + alertcount' matBadgeColor="warn"></mat-icon><i class="far fa-bell bell"></i></button>
    </div>

    <div class="notification"  *ngIf="hiderightbar">
      <button type="btn" class="notify1" (click)="rightbarshow()"><img src="assets/ivital/close.svg" height="15" width="15"/></button>
    </div>
    <!--Floating video-->
    <!-- <div cdkDrag class="floatingVideo" ng-show="callfloatingVideosrc==true" style="padding:20px;border:1px solid red;position:fixed;top:245px;left:20px;width:300px;height: 300px;">
      <iframe [src]="src" width="100%" height="100%"></iframe>
    </div> -->

  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div class="row" *ngIf="hiderightbar">
  <div class="col-lg-4 col-md-8 col-sm-8">
    <div class="card w-50 shadow-lg mb-2 bg-white rounded hotline-launcher">
      <div class="card-body rightbarbody">
    
      <div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 pl-4 pr-4" style="overflow-y: scroll;" *ngIf="epicarebook">
            <br>
            <div class="shadow-sm p-3  bg-body rounded" *ngFor="let epicare of epicarebooking">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3">
                  <div class="user-pic"><img class="img-responsive img-rounded" src="https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png" alt="User picture"> </div>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-9">
                  <h4>{{epicare.name_given}} -   {{epicare.mr_number}}</h4>
                  <span><span>{{epicare.type}}</span>&nbsp;&nbsp;
                  <i class="fa fa-circle ng-scope" *ngIf="epicare.type == 'video_call' && (epicare.allow_status == 0 && (epicare.participant_connect_status == null || epicare.participant_connect_status == 0))" style="font-size: 10px;color:red"></i>
                  <i class="fa fa-circle ng-scope" *ngIf="epicare.type == 'video_call' && (epicare.allow_status == 1 && (epicare.participant_connect_status == null || epicare.participant_connect_status == 0))" style="font-size: 10px;color:orange"></i>
                  <i class="fa fa-circle ng-scope" *ngIf="epicare.type == 'video_call' && (epicare.participant_connect_status == 1)" style="font-size: 10px;color: green"></i>
                  <i class="fa fa-circle ng-scope" *ngIf="epicare.type == 'video_call' && (epicare.status == 'closed')" style="font-size: 10px;color: blue"></i>
              
                  </span><br>
                  <span class="mb-2">
                    <img src="assets/ivital/direct.svg" height="17" width="17"  (click)="notify_Token(epicare)" class="mr-2" data-toggle="tooltip" title="Send Notification"/>
                    <img src="assets/ivital/phone-call.svg" height="17" width="17" (click)="bookVoice(epicare)" class="mr-2" data-toggle="tooltip" title="Join video call"/>
                    <span  *ngIf="epicare.type == 'video_call' && (epicare.participant_connect_status == 1)  && (epicare.status != 'closed')">
                      <img src="assets/ivital/merge-call.svg" height="17" width="17" (click)="doctorJoinOnClick(epicare)" class="mr-2" data-toggle="tooltip" title="Merge Calls"/>
                    </span>
                    <img src="assets/ivital/endvideocall.svg" height="22" width="22" (click)="endconsultation(epicare)" class="mr-2" data-toggle="tooltip" title="End Consultation"/>
                  </span>
                </div>
              </div>
            </div>
            <br><br><br><br>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 pl-4 pr-4" style="overflow-y: scroll;" *ngIf="alertsbook">
            <br>
            <div class="shadow-sm p-3  bg-body rounded" *ngFor="let data of emergencyalerts">
              <div class="row">
                
                <div class="col-lg-9 col-md-9 col-sm-9">
                    <h5>{{data.msg}}</h5>
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2">
                  <button class="btn btn-primary" (click)="update_snooze(data)">Ok</button>
                </div>
              </div>
            </div>
            <br><br><br><br>
          </div>
        </div>
      </div>

      <div class="cardfooter">
        <div class="row pb-2">
          <div class="col-lg-6 col-md-6 col-sm-6 text-center" (click)="bookedappointment()" style="border-right: 2px solid grey;">
            <img src="assets/ivital/health-check.svg" height="20" width="20"/>
            <h4 class="footcolor"><span class="badge bg-danger ml-1">{{appointmentcount}}</span>&nbsp;&nbsp;Book Appointment</h4>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 text-center" (click)="bookedalerts()">
            <img src="assets/ivital/danger.png" height="20" width="20"/>
            <h4 class="footcolor"><span class="badge bg-danger ml-1">{{alertcount}}</span>&nbsp;&nbsp;Alerts</h4>
          </div>
        </div>
      </div>
      
      </div>
    </div>
  </div>
</div>
