<app-navbar class="mb-2"></app-navbar>

<p style="font-size: 1.2rem;text-align: center;">{{patient.patient_name}} / {{patient.gender}} / {{y}}Y {{m}}M / {{patient.patient_id}} - Follow Up Day {{questionnaire_data.index}}</p>


<div style="display: flex;justify-content: center;margin-top: 1rem; border: 1px solid #FFF;padding: 00.75rem;margin-left:20%;margin-right: 20%;">
    <div>

        <p style="font-size: 1.2rem;text-align: center;margin-bottom:1rem !important;font-weight: 600;">Vitals</p>

        <!-- <p style="margin-top: 00.5rem;">ECG taken : <span *ngIf="questionnaire_data.ecg">Yes</span> <span *ngIf="!questionnaire_data.ecg">No</span></p>
        <p  style="margin-top: 00.5rem;">Temp : {{questionnaire_data.temp}} F</p>
        <p  style="margin-top: 00.5rem;">SpO2 : {{questionnaire_data.spo2}} %</p>
        <p  style="margin-top: 00.5rem;">RR : {{questionnaire_data.rpm}} F</p>
        <p  style="margin-top: 00.5rem;">Heart Rate : {{questionnaire_data.ppm}} F</p> -->

        <table style="color:#FFF;font-size:1rem">
            <tr>
                <td style="font-weight: 600;">ECG taken</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                <td><span *ngIf="questionnaire_data.ecg">Yes</span> <span
                        *ngIf="!questionnaire_data.ecg">No</span></td>
            
                        <td style="font-weight: 600;">Temp</td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                        <td>{{questionnaire_data.temp}} F</td>

                        <td style="font-weight: 600;">SpO2</td>
                        <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                        <td>{{questionnaire_data.spo2}} %</td>
            </tr>

            <tr>
                <td style="font-weight: 600;">RR</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                <td>{{questionnaire_data.rpm}} RPM</td>

                <td style="font-weight: 600;">Heart Rate</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                <td>{{questionnaire_data.ppm}} PPM</td>

                <td style="font-weight: 600;">Date</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                <td>{{date}}</td>
            </tr>

           
        </table>

        <!-- <div style="display: flex;justify-content: center;margin-top: 1rem;font-weight: 600;">
            <button class="btn" (click)="openComments()" style="background-color: #45c2c5;">Add/Updade Comments</button>
        </div> -->

    </div>
</div>

<div class="row" style="padding-bottom: 2rem;">
    <div class="col-lg-3 col-md-3">
    </div>

    <div class="col-lg-6 col-md-6">
       

        <div style="display: flex;justify-content: center;margin-top: 1rem;">
            <div>
                <div style="margin-bottom:0.5rem" *ngFor="let q of questionnaire_data.data;let i=index">
                    <p><span style="font-weight: 600;">{{q.que}} </span>: <span *ngIf="q.ans==1">Yes</span> <span *ngIf="q.ans==0">No</span></p>
                    <p><span style="font-weight: 600;">Notes </span>: {{q.notes}}</p>
                </div>
            </div>
        </div>

    </div>

    <div class="col-lg-3 col-md-3">

        <div class="adminActions">
            <input type="checkbox" name="adminToggle" class="adminToggle" />
            <a class="adminButton" href="#!">
                <img style="width:2rem" src="assets/comment.svg">
            </a>
            <div class="adminButtons">
                <a title="Add Company">
                    <div class="textbox">
                        <textarea [(ngModel)]="text"></textarea>
                        <button class="btn btn-primary" (click)="save()"> 
                            Send
                        </button>
                    </div>
                    <!-- <img src="assets/comment.svg"> -->
                </a>
        <!-- 		<a href="#" title="Edit Company"><i class="fa fa-pen"></i></a>
                <a href="#" title="Add User"><i class="fa fa-user-plus"></i></a>
                <a href="#" title="Edit User"><i class="fa fa-user-edit"></i></a> -->
            </div>
        </div>

    </div>
</div>