import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { PubNubAngular } from 'pubnub-angular2';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';



@Component({
    selector: 'app-chart3',
    templateUrl: './chart3.component.html',
    styleUrls: ['./chart3.component.scss'],
    providers: [PubNubAngular]

})
export class Chart3Component implements OnInit {
    pubnub: PubNubAngular;
    options;
    number = 0;
    sub;
    code;
    mrn;
    pi=0.0;
    low;
   // low=Number(sessionStorage.getItem("v3"))

    constructor(pubnub: PubNubAngular) {
        this.pubnub = pubnub;
        
    }


    ngOnInit(): void {

        for (var i = 1; i <= 15; i++) {
            (document.getElementById('e' + i) as HTMLElement).style.visibility = 'hidden';
        }

        this.code = sessionStorage.getItem("code").toLowerCase();
        this.mrn = sessionStorage.getItem("mrn");
        console.log("code","mrn  chart 3", this.code , this.mrn)
        this.low=Number(sessionStorage.getItem("v3"));
        //      alert(this.mrn)
        var that = this;

        var selectedChannel = 'get_message_' + this.code.toLowerCase();
        var hubChannel = 'receive_hub_data_' + this.code.toLowerCase();
        var refresh_channel = "refresh_hub_" + this.code.toLowerCase();
        var disconnectedhub = "status_"+this.mrn.toLowerCase()+"_"+this.code.toLowerCase();

        this.pubnub.init({
            publishKey: 'demo',
            subscribeKey: 'demo',
            ssl: false
        });

        this.pubnub.subscribe({
            channels: [selectedChannel, hubChannel, refresh_channel, disconnectedhub],
            withPresence: true,
            triggerEvents: ['message', 'presence', 'status']
        });

        this.pubnub.getStatus([selectedChannel, hubChannel, refresh_channel,disconnectedhub], (msg) => {
       //     console.log(msg);
        });

        this.pubnub.getPresence([selectedChannel, hubChannel, refresh_channel,disconnectedhub], (msg) => {
        //    console.log(msg);
        });

        this.options = {
            chart: {
                type: 'spline',
                // animation: Highcharts.svg, // don't animate in old IE
                marginRight: 10,
                backgroundColor: '#000000',
           //     animation:false,

                events: {
                    load: function () 
                    {

                        that.pubnub.getMessage([hubChannel], (msg) => {
                            var arr;

                            var series = this.series[0];
                            var x = (new Date()).getTime(), // current time
                                y = Math.random();
                            if (msg.message.patientID == that.mrn&&msg.message.type==0) 
                            {
                                // console.log(msg.message)

                                that.number = msg.message.o2;
                                that.pi=msg.message.pi;
                                series.data[0].remove(false, false);

                                series.addPoint([x, Number(msg.message.o2)], true, false);
                            }

                            if(msg.message.patientID==that.mrn&&msg.message.type==1)
                            {
                                arr=msg.message.bargraph.split(',');
                             //   console.log(arr)

                                for(var i=0;i<arr.length;i++)
                                {
                                    arr[i]=Number(arr[i])
                                }

                                for (var i = 1; i <= 15; i++) 
                                {
                                    (document.getElementById('e' + i) as HTMLElement).style.visibility = 'visible';
                                }

                                for(var i=0;i<arr.length;i++)
                                {
                                    for (var k = 1; k <= 15 - arr[i]; k++)
                                        (document.getElementById('e'+k) as HTMLElement).style.visibility = 'hidden';
                                }

                                // for (var i = 1; i <= 15 - msg.message.o2 / 10; i++)
                                //     (document.getElementById('e' + i) as HTMLElement).style.visibility = 'hidden';
                            }
                        });

                        that.pubnub.getMessage([disconnectedhub], (msg) => {
                            //console.log(msg);
                            if(msg.message.patientID==that.mrn&&msg.message.status=='disconnected')
                            {
                                that.number = 0;
                                var series = this.series[0];
                                
                                for (var k = 0; k <20; k++){
                                    var x = (new Date()).getTime();
                                    series.data[0].remove(false, false);
                                    series.addPoint([x + k * 1000, Number(that.low)], true, false);
                                }
                            }
                        });
                    }
                }
            },

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    color: '#87CEEB'
                },
            },

            time: {
                useUTC: false
            },

            title: {
                text: ''
            },

            accessibility: {
                announceNewData: {
                    enabled: true,
                    minAnnounceInterval: 15000,
                    announcementFormatter: function (allSeries, newSeries, newPoint) {
                        if (newPoint) {
                            return 'New point added. Value: ' + newPoint.y;
                        }
                        return false;
                    }
                }
            },

            xAxis: {
                type: 'datetime',
                tickPixelInterval: 150,
                visible: false,
            },

            yAxis: {

                labels: {
                    style: {
                        color: '#87CEEB'
                    }
                },

                gridLineWidth: 0.25  ,
                gridLineColor: '#87CEEB', 
                title: {
                 //   text: 'Percentage'
                    useHTML: true,
                    text: '% &nbsp; &nbsp;',
                    rotation: 0,
                    style: {
                        color: '#87CEEB',
                        fontFamily: 'Montserrat'
                    }
                },
                plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                }],
                visible: true,
                tickPixelInterval: 10,
                min:that.low,
                max:100,
                
            },

            tooltip: {
                headerFormat: '<b>{series.name}</b><br/>',
                pointFormat: '{point.y:.2f}',
                enabled: true,
                style:{
                    fontSize:'1.5vw'
                }
            },

            legend: {
                enabled: false
            },

            exporting: {
                enabled: false
            },

            credits: {
                enabled: false
            },

            series: [{
                name: '',
                enableMouseTracking: true,

                data: (function () {
                    //   alert("hi")
                    var data = [],
                        time = (new Date()).getTime(),
                        i;

                    for (i = -19; i <= 0; i += 1) {
                        data.push({
                            x: time + i * 1000,
                            y: that.low
                        });
                    }

                    return data;
                }())
            }]
        }

        Highcharts.chart('container3', this.options);

    }

    ngOnDestroy() {
        var selectedChannel = 'get_message_' + this.code.toLowerCase();
        var hubChannel = 'receive_hub_data_' + this.code.toLowerCase();
        var refresh_channel = "refresh_hub_" + this.code.toLowerCase();
        var receivehub = "receive_hub_detail_"+this.code.toLowerCase();
        this.pubnub.unsubscribe({
            channels: [selectedChannel, hubChannel, refresh_channel , receivehub],
        });
    }

}
