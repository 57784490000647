
<app-navbar class="mb-2"></app-navbar>
  <div class="row">
      <div class="col-sm-1 col-md-1 col-lg-1"></div>
      <div class="col-sm-10 col-md-10 col-lg-10">
        <table class="table">
            <thead class="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Alert name</th>
                <th scope="col">Alert sound</th>
              </tr>
            </thead>
            <tbody>
               <tr>
                   <td>1</td>
                   <td>Vitals Breach</td>
                   <td>
                    <select name="alertsound" class="form-control" [(ngModel)]="vitalbreach" (change)="audiochange($event)" width="50% !important">
                        <option selected disabled value="select">Select Alert Tone</option>
                        <option *ngFor="let audio of audios ; let i = index;" [value]="[audio.audio_id+audio.audio_url]">
                          Audio{{" "+audio.audio_id}}
                        </option>
                      </select>
                   </td>
               </tr>
               <tr>
                <td>2</td>
                <td>Snooze Alert</td>
                <td>
                    <select name="alertsound" class="form-control" [(ngModel)]="snoozealert" (change)="audiochangesnooze($event)" width="50% !important">
                        <option selected disabled value="select">Select Alert Tone</option>
                        <option *ngFor="let snooze of snoozeaudios ; let i = index;" [value]="[snooze.audio_id+snooze.audio_url]">
                          Audio{{" "+snooze.audio_id}}
                        </option>
                      </select>
                </td>
            </tr>
            <tr>
                <td>3</td>
                <td>Incoming Video Calls</td>
                <td>
                    <select name="alertsound" class="form-control" [(ngModel)]="IVC" (change)="audiochangeivc($event)" width="50% !important">
                        <option selected disabled value="select">Select Alert Tone</option>
                        <option *ngFor="let ivc of ivcs ; let i = index;" [value]="[ivc.audio_id+ivc.audio_url]">
                          Audio{{" "+ivc.audio_id}}
                        </option>
                      </select>
                </td>
            </tr>
            <tr>
                <td>4</td>
                <td>Outgoing Calls</td>
                <td>
                    <select name="alertsound" class="form-control" [(ngModel)]="oc" (change)="audiochangeoutcalls($event)" width="50% !important">
                        <option selected disabled value="select">Select Alert Tone</option>
                        <option *ngFor="let data of ocs ; let i = index;" [value]="[data.audio_id+data.audio_url]">
                          Audio{{" "+data.audio_id}}
                        </option>
                      </select>
                </td>
            </tr>
            <tr>
                <td>5</td>
                <td>Notification Alert</td>
                <td>
                    <select name="alertsound" class="form-control" [(ngModel)]="notification" (change)="audiochangencalls($event)" width="50% !important">
                        <option selected disabled value="select">Select Alert Tone</option>
                        <option *ngFor="let na of  notificationalert; let i = index;" [value]="[na.audio_id+na.audio_url]">
                          Audio{{" "+na.audio_id}}
                        </option>
                      </select>
                </td>
            </tr>
            </tbody>
          </table>
      </div>
      <div class="col-sm-1 col-md-1 col-lg-1"></div>
  </div>
  
  <!-- <div class="row mt-2 text-center">
      <div class="col-sm-4 col-md-4 col-lg-4"></div>
      <div class="col-sm-4 col-md-4 col-lg-4">
        <div>
            <h2 class="mt-2 mb-4">Select Alert Tone</h2>
        </div>
        <div>
            <mat-radio-group aria-label="Select an option">
                <mat-radio-button  value={{data.audio_id}} *ngFor="let data of audios" (change)="selectedtone($event)">
                    <label class="form-check-label" for="flexRadioDefault1">
                        <audio controls >
                            <source src={{data.audio_url}} type="audio/mpeg">
                        </audio>
                    </label>
                </mat-radio-button>
            </mat-radio-group>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4"></div>
  </div> -->