<app-navbar class="mb-2"></app-navbar>
<div>
    <h2 style="color: #fff;text-align:center">Notifications</h2>
</div>
<div class="container-fluid">
    <div class="table-responsive" style="height: 80vh;overflow:auto;">
        <table class="table">
            <thead>
                <tr style="text-align: center;" style="top: 0;position:sticky">
                    <th class="th_class">#</th>
                    <th class="th_class">Date</th>
                    <th class="th_class">Time</th>
                    <th class="th_class">Message</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let x of notification_response; let i = index;">
                    <td class="td_class">{{i+1}}</td>
                    <td class="td_class">{{x.timestamp *1000 | date: 'dd-MMM-yyyy' }}</td>
                    <td class="td_class">{{x.timestamp *1000 | date: 'h:mm:ss a' }}</td>
                    <td class="td_class">{{x.message}}</td>
                </tr>
            </tbody>
            <tbody>
                <tr style="text-align: center;"  *ngIf="notification_response == ''">
                    <td colspan="5" style="border: 1px solid white;color:white;">No Records Found</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>