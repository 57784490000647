import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { PubNubAngular } from 'pubnub-angular2';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { TransitionCheckState } from '@angular/material/checkbox';
import { heLocale } from 'ngx-bootstrap/chronos';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'app-chart5',
    templateUrl: './chart5.component.html',
    styleUrls: ['./chart5.component.scss'],
    providers: [PubNubAngular]

})
export class Chart5Component implements OnInit {

    pubnub: PubNubAngular;
    options;
    number = 0;
    sub;
    code;
    mrn;
    t1:any='0.0';
    t_off:any='0.0';
    high;
    low;
    urlPoints;
    gettemp;
  //  high=Number(sessionStorage.getItem("v6"))
  //  low=Number(sessionStorage.getItem("v7"))

    constructor(pubnub: PubNubAngular , private appService: AppService) {
        this.urlPoints = AppConfig.settings.EndPoints;
        this.pubnub = pubnub;

        var gettempParams = {
            code :sessionStorage.getItem("code").toUpperCase()
          } 
      
          this.appService.getData(this.urlPoints.gettempunit, gettempParams).subscribe((response: any) => {
            if(response.settings.success == 1){
              this.gettemp = response.settings.temp_unit;
            }
          });
     }
     
    ngOnInit(): void {
        this.code=sessionStorage.getItem("code").toLowerCase();
        this.mrn=sessionStorage.getItem("mrn");
        this.low=Number(sessionStorage.getItem("v7"));
        this.high=Number(sessionStorage.getItem("v6"));
        var that=this;

        var selectedChannel = 'get_message_' + this.code.toLowerCase();
        var hubChannel = 'receive_hub_data_' + this.code.toLowerCase();
        var refresh_channel = "refresh_hub_" + this.code.toLowerCase();
        var disconnectedhub = "status_"+this.mrn.toLowerCase()+"_"+this.code.toLowerCase();

        this.pubnub.init({
            publishKey: 'demo',
            subscribeKey: 'demo',
            ssl: false
        });

        this.pubnub.subscribe({
            channels: [selectedChannel, hubChannel, refresh_channel,disconnectedhub],
            withPresence: true,
            triggerEvents: ['message', 'presence', 'status']
        });

        this.pubnub.getStatus([selectedChannel, hubChannel, refresh_channel,disconnectedhub], (msg) => {
         //   console.log(msg);
        });

        this.pubnub.getPresence([selectedChannel, hubChannel, refresh_channel,disconnectedhub], (msg) => {
         //   console.log(msg);
        });

        this.options = {
            chart: {
                type: 'spline',
 
                marginRight: 10,
                backgroundColor: '#000000',

                events: {
                    load: function () {
                        
                        that.pubnub.getMessage([hubChannel], (msg) => {
                         
                            var series = this.series[0];
                            var x = (new Date()).getTime() 
                            var y = Math.random();
                            if (msg.message.patientID == that.mrn&&msg.message.type==0) 
                            {
                                // console.log(msg.message.patientID , that.mrn ,msg.message.type)
                              if(that.gettemp == 'c' && msg.message.temp != ''){
                               
                                 that.t1=msg.message.temp + "°C";
                              }else if(that.gettemp == 'f' && msg.message.tempf != ''){
                                
                                 that.t1=msg.message.tempf + "°F";
                                 
                                //  this.low = (Number(sessionStorage.getItem("v7")) * (9/5)) + 32;
                                //  this.high = (Number(sessionStorage.getItem("v6")) * (9/5)) + 32;
                            

                              }
                            that.t_off=msg.message.tempoffset;
                            series.data[0].remove(false, false);
                            series.addPoint([x, Number(msg.message.temp)], true, false);

                            }
                        });   

                        that.pubnub.getMessage([disconnectedhub], (msg) => {
                            //console.log(msg);
                            if(msg.message.patientID==that.mrn&&msg.message.status=='disconnected')
                            {
                                that.t1 = "0.0";
                                that.t_off = "0.0";
                                var series = this.series[0];
                                
                                for (var k = 0; k <20; k++){
                                    var x = (new Date()).getTime();
                                    series.data[0].remove(false, false);
                                    series.addPoint([x + k * 1000, Number(that.low)], true, false);
                                }
                            }
                        });
                                         
                    }
                }
            },
    
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    color: '#FFFF'
                },
            },
    
            time: {
                useUTC: false
            },
    
            title: {
                text: ''
            },
    
            accessibility: {
                announceNewData: {
                    enabled: true,
                    minAnnounceInterval: 15000,
                    announcementFormatter: function (allSeries, newSeries, newPoint) {
                        if (newPoint) {
                            return 'New point added. Value: ' + newPoint.y;
                        }
                        return false;
                    }
                }
            },
    
            xAxis: {
                type: 'datetime',
                tickPixelInterval: 150,
                visible: false,
                
            },
    
            yAxis: {
                gridLineWidth: 0.25  ,
                gridLineColor: '#FFFF',                
                minorGridLineWidth: 0,
                title: {
                    useHTML: true,
                    text: '&#8451;&nbsp;',
                    rotation: 0,
                    style: {
                        color: '#FFFF',
                        fontFamily: 'Montserrat'
                    }
                    // style: {
                    //     color: '#000000'
                    // }
                },
                plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                }],
                visible: true,
                tickPixelInterval: 20,
                min:that.low,
                max:that.high,
                labels: {
                    style: {
                        color: '#FFFF'
                    }
                },
            },
    
            tooltip: {
                headerFormat: '<b>{series.name}</b><br/>',
                pointFormat: '{point.y:.2f}',
                enabled: true,
                style:{
                    fontSize:'1.5vw'
                }
            },
    
            legend: {
                
                enabled: false
            },
    
            exporting: {
                enabled: false
            },
    
            credits: {
                enabled: false
            },
    
            series: [{
                name: '',
                enableMouseTracking: true,
    
                data: (function () {
                 //   alert("hi")
                    var data = [],
                        time = (new Date()).getTime(),
                        i;
    
                    for (i = -19; i <= 0; i += 1) {
                        data.push({
                            x: time + i * 1000,
                            y: that.low
                        });
                    }


                   // data=[{x:'1',y:'98'},{x:'2',y:'100'}]
//                    console.log(data)

                 //   data=  []
                 
                    return data;
                }())
            }]
        }

        Highcharts.chart('container5', this.options);
    }
  
    ngOnDestroy() {
        var selectedChannel = 'get_message_' + this.code.toLowerCase();
        var hubChannel = 'receive_hub_data_' + this.code.toLowerCase();
        var refresh_channel = "refresh_hub_" + this.code.toLowerCase();
        var receivehub = "receive_hub_detail_"+this.code.toLowerCase();
        this.pubnub.unsubscribe({
            channels: [selectedChannel, hubChannel, refresh_channel , receivehub],
        });
    }
}
