import { Component, Input, OnInit , Type} from '@angular/core';
import { PubNubAngular } from 'pubnub-angular2';
import { AppConfig } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { computeDecimalDigest } from '@angular/compiler/src/i18n/digest';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Moment } from 'moment';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import * as lib from 'countries-and-timezones';
import { NgxSpinnerService } from "ngx-spinner";
// import { settings } from 'cluster';
// import { DateTime, Settings, Info, Zone } from 'luxon';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  urlPoints;
  timezoneForm;
  gettempParams;
  gettemp;
  tempunit;
  gettimezoneParams;
  getsettingsparams;
  code = sessionStorage.getItem("code");
  timezones:any = [];
  zone;
  zones;
  time_zone ;
  hospital;
  hospital_create ;
  hospital_createenable = false;
  doctor_name
  
  date: string;
  // public a: DateTime;


  token = localStorage.getItem("authentication");
  

  constructor(private fb: FormBuilder , private appService: AppService, private spinner: NgxSpinnerService ,  pubnub: PubNubAngular, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router , private toastr: ToastrService) { 
    this.urlPoints = AppConfig.settings.EndPoints;
    this.doctor_name = sessionStorage.getItem("code");

    this.gettempParams = {
      'code': this.code.toUpperCase(),
    }
    this.gettempvalue();

    this.gettimezoneParams = {
      'code': this.code.toUpperCase(),
    }
    this.gettimezone()

    this.getsettingsparams ={
      'code': this.code.toUpperCase(),
    }
    this.getsettings()

    var hospitalparams = {
      code : this.code
    }

    this.gethospital(this.urlPoints.gethospitals ,  hospitalparams)
   
  }

  ngOnInit(): void {
    this.zone = lib.getAllTimezones();
    this.zones = Object.entries(this.zone)
    for(var i=0 ; i< this.zones.length ; i++){
      this.timezones.push(this.zones[i][0] + "    -    " + this.zones[i][1].utcOffsetStr + "   -   " + this.zones[i][1].utcOffset)
    }
  }

  getaccount(){
    
    var hospitalparams = {
      code : this.code
    }

    this.gethospital_acc(this.urlPoints.gethospitals ,  hospitalparams)
  }
  
  gethospital(url , params){
    this.appService.getData( url ,params).subscribe((response: any) => {
      if( response.settings.success == 1){
         this.hospital =  response.settings.profile
         console.log("this.hospital", this.hospital);
      }else if(response.settings.success == 0){
        this.hospital_createenable = true
      }
    });
  }


  gethospital_acc(url , params){
    this.appService.getData( url ,params).subscribe((response: any) => {
      console.log("response gethospital_acc", response);
      if( response.settings.success == 1){
         this.hospital =  response.settings.profile
         console.log("this.hospital", this.hospital);
         this.router.navigate(["/reset"],{ queryParams: {}})
      }else if(response.settings.success == 0){
        this.hospital_createenable = true
        this.router.navigate(["/createaccount"],{ queryParams: {}})
      }
    });
  }
 
  gettempvalue(){
    this.appService.getData(this.urlPoints.gettempunit, this.gettempParams).subscribe((response: any) => {
      if(response.settings.success == 1){
        this.gettemp = response.settings.temp_unit;
        console.log("this.gettemp", this.gettemp)
        if(this.gettemp == 'c'){
           this.tempunit = true
        }else{
          this.tempunit = false
        }
      }
    });
  }

  temp_unit(){
    this.spinner.show()
    this.tempunit = !this.tempunit
   if(this.tempunit == true){
     var tempparms={
        "code": this.code,
        "unit":"c"
     }
     this.updatetemp(this.urlPoints.updatetempunit ,  tempparms , this.token )
   }else{
    console.log("F")
    this.spinner.show()
    var tempparms={
      "code": this.code,
      "unit":"f"
   }
   this.updatetemp(this.urlPoints.updatetempunit ,  tempparms , this.token)

   }
   }

   updatetemp(url ,params , token){
    this.appService.postData(url ,params , token).subscribe((response: any) => {
      this.spinner.hide()
     if(response.settings.success == 1){
      this.toastr.success(response.settings.message)
     }else{
      this.toastr.error(response.settings.message);
     }
   });
  }

  posttimezone(url ,params , token){
    this.appService.postData(url ,params , token).subscribe((response: any) => {
      this.spinner.hide()
      if(response.settings.success == 1 && response.settings.message == "updated time zone"){
        this.toastr.success(response.settings.message);
      }else{
        console.log(response);
        this.toastr.error(response.settings.timezone);
      }
   });
  }

  gettimezone(){
    this.appService.getData(this.urlPoints.gettimezone, this.gettimezoneParams).subscribe((response: any) => {
      this.time_zone = response.settings.timezone_name;
    });
  }

  getsettings(){
    this.appService.getData(this.urlPoints.getsettings, this.getsettingsparams).subscribe((response: any) => {
      console.log("get settings", response)
    });
  }

 
  timeZoneChanged(e){
    console.log("this.timezone", e.target.value);
    this.spinner.show()
    for(var i=0 ; i< this.zones.length;i++){
      //  console.log("this.zones", this.zones[i][1].name);
       if( this.zones[i][1].name == e.target.value){
         console.log("here is the outcome", this.zones[i])
         var posttimeParams = {
          "code": this.code,
          "timezone":this.zones[i][1].utcOffset * 60,
          "timezone_name":this.zones[i][1].name
        }
         console.log("posttimeParams ", posttimeParams );
        this.posttimezone(this.urlPoints.updatetimezone , posttimeParams , this.token)
       } 
    }
  }


}
