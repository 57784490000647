<app-navbar class="mb-2"></app-navbar>
<form [formGroup]="resetpasswordForm" (submit)="reset_form()">
<div class="row mt-5">
    <div class="col-lg-5"></div>
    <div class="col-lg-2 text-center">
        <div><h2>Reset Password</h2></div>
    <div class="mt-3">
        <input type="text" class="form-control" placeholder="Username" formControlName="Name" pattern="[a-zA-Z '']*">        
    </div>
    <div class="error" *ngIf="(resetpasswordForm.controls.Name.touched||resetpasswordForm.controls.Name.dirty )&&resetpasswordForm.controls.Name.errors?.required">
        Name required
    </div>
    <div class="error" *ngIf="(resetpasswordForm.controls.Name.touched||resetpasswordForm.controls.Name.dirty )&&resetpasswordForm.controls.Name.errors?.minlength">
        Minimum 3 characters required
    </div>
    <div class="error" *ngIf="(resetpasswordForm.controls.Name.touched||resetpasswordForm.controls.Name.dirty )&&resetpasswordForm.controls.Name.errors?.pattern">
        Only letters are allowed
    </div>
    <div  class="mt-3">
        <input [type]="password" id="password" class="form-control" placeholder="Old Password" formControlName="password1" onpaste="return false;" ondrop="return false;" autocomplete="off" >
        <span class="hideshow"><i class="fa fa-eye" aria-hidden="true" *ngIf="!show" (click)="onClick()"></i><i class="fa fa-eye-slash" aria-hidden="true" *ngIf="show" (click)="onClick()"></i></span>
    </div>
    <div class="error" *ngIf="(resetpasswordForm.controls.password1.touched||resetpasswordForm.controls.password1.dirty )&&resetpasswordForm.controls.password1.errors?.required">
        Password required
    </div>
    <div  class="mt-3">
        <input [type]="password" class="form-control" placeholder="New Password" formControlName="password2" maxlength="15" minlength="8" onpaste="return false;" ondrop="return false;" autocomplete="off" >
    </div>
    <div class="error" *ngIf="(resetpasswordForm.controls.password2.touched||resetpasswordForm.controls.password2.dirty )&&resetpasswordForm.controls.password2.errors?.required">
        New Password required
    </div>
    <div class="error" *ngIf="(resetpasswordForm.controls.password2.touched||resetpasswordForm.controls.password2.dirty )&&resetpasswordForm.controls.password2.errors?.minlength">
        Minimum 8 characters required
    </div>
    
    <div  class="mt-3">
        <input [type]="password" class="form-control" placeholder="Confirm New Password" formControlName="password3" maxlength="15" minlength="8" onpaste="return false;" ondrop="return false;" autocomplete="off" >
    </div>
    <div class="error" *ngIf="(resetpasswordForm.controls.password3.touched||resetpasswordForm.controls.password3.dirty )&&resetpasswordForm.controls.password3.errors?.required">
       Confirm New Password required
    </div>
    <div class="error" *ngIf="(resetpasswordForm.controls.password3.touched||resetpasswordForm.controls.password3.dirty )&&resetpasswordForm.controls.password3.errors?.minlength">
        Minimum 8 characters required
    </div>
    <div  class="mt-4">
        <button mat-raised-button type="submit">SUBMIT</button>
    </div>
    <div class="mt-3">
        
        <span><i class="fas fa-arrow-left leftarraow" ></i>&nbsp;&nbsp;<a class="underlineHover1 texta"  href="#" [routerLink]="['/security']">Login</a> </span>
    </div>
  
    </div>
    <div class="col-lg-5"></div>
</div>
</form>