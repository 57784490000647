<app-navbar class="mb-2"></app-navbar>

<div class="row">
    <div class="col-lg-12">
        <div class="text-center" style="color: #fff;">
           <span><span>{{patient_mr}}</span>&nbsp;/&nbsp;<span>{{pname}}</span>&nbsp;/&nbsp;<span>{{dob | date:'dd-MMM-yyyy'}}</span></span>
        </div>
        <div class="row">
            <div class="col-sm-1 col-md-1 col-lg-1"></div>
            <div class="col-sm-10 col-md-10 col-lg-10">
                <div class="float-right">
                    <div class="btn-group" mdbDropdown style="margin-top: -20px;">
                       <button mdbDropdownToggle type="button" mdbBtn color="white" class="dropdown-toggle waves-light" mdbWavesEffect>
                         {{dateselect}}
                       </button>
                       <div class="dropdown-menu dropdown-primary">
                           <option class="dropdown-item" style="color: black !important;" *ngFor="let data of datearrray" (click)="selecteddate(data)">{{data}}</option>
                       </div>
                    </div>
                </div>

            </div>
            <div class="col-sm-1 col-md-1 col-lg-1"></div>
        </div>
       
        <div class="scroll p-4 mt-1 text-center">
            <table class="table tablefix">
                <thead>
                    <tr>
                       <th colspan="1">#</th>
                       <th colspan="1">Date</th>
                       <th colspan="1">Time</th>
                       <th colspan="1">O2 ( % )</th>
                       <th colspan="1">HR ( PPM )</th>
                       <th colspan="1">PI ( % )</th>
                       <th colspan="1">RR ( RPM )</th>
                       <th colspan="3" class="pl-1 pr-0 pt-0 pb-0">Temperature 
                        <tr colspan="3" class="p-1 m-0">
                            <td colspan="1" class="pl-1 pr-1 pt-1 pb-1 temp">&nbsp;&nbsp;Temp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                            <td colspan="2" class="pl-1 pr-1 pt-1 pb-1 tempoffset">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Offset&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        </tr>
                       </th>
                       <th colspan="1">Hub Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of getallvitalsdata;let i = index;">
                       <td colspan="1">{{i+1}}</td>
                       <td colspan="1">{{data.timeStamp * 1000 | date:'dd-MMM-yyyy'}}</td>
                       <td colspan="1">{{data.timeStamp * 1000 | date:'HH:mm:ss a'}}</td>
                       <td colspan="1">{{data.o2}}</td>
                       <td colspan="1">{{data.bpm}}</td>
                       <td colspan="1">{{data.pi}}</td>
                       <td colspan="1">{{data.rr}}</td>
                       <td colspan="1">{{data.temp}}</td>
                       <td colspan="2">{{data.tempoffset}}</td>
                       <td colspan="1">{{data.hub_name}}</td>
                       
                    </tr>
                </tbody>
                <tbody>
                    <tr style="text-align: center;" *ngIf="getallvitalsdata == ''">
                        <td colspan="11" style="border: 1px solid white;color:white;">No Records Found</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
