<app-navbar class="mb-2"></app-navbar>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white"></p>
</ngx-spinner>

<div class="row">
    <div class="col-lg-12 timercount">{{count}}</div>
</div>

<div class="row">
    <div class="col-12 pl-5 pr-5 pb-5 pt-3">
        <label style="color: white;font-size: 18px;">Add Patient</label>
        <form [formGroup]="patientForm" (submit)="save_patientForm()">
        <div class="row mb-0">
            <div class="col-sm-12 col-md-3 col-lg-3 pl-1 pr-1 mb-2"><input type="text" class="form-control" id="inlineFormInput" placeholder="Name" formControlName="patientName"  
                pattern="[a-zA-Z ]*" maxlength="20" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"><br>
                <div class="error" style="color: red !important;"
                *ngIf="(patientForm.controls.patientName.touched||patientForm.controls.patientName.dirty)&&patientForm.controls.patientName.errors?.required">
                First Name required</div>
            <div class="error"  style="color: red !important;"
                *ngIf="(patientForm.controls.patientName.touched||patientForm.controls.patientName.dirty)&&patientForm.controls.patientName.errors?.minlength">
                Minimum 3 characters required</div>
            <div class="error"  style="color: red !important;"
                *ngIf="(patientForm.controls.patientName.touched||patientForm.controls.patientName.dirty)&&patientForm.controls.patientName.errors?.pattern">
                Only letters are allowed</div>
            
            </div>
            <div class="col-sm-12 col-md-3 col-lg-2 pl-1 pr-1 mb-2">
                <input  class="form-control" placeholder="Date of Birth"  bsDatepicker [maxDate]="maxDate" formControlName="DOB" [bsConfig]="{ showWeekNumbers:false }" >
            </div>
            <div class="col-sm-12 col-md-2 col-lg-1  pl-1 pr-1 mb-2">
                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-light" style="width:4vw;">
                        <input type="radio" name="gender" value="male" formControlName="gender"> M
                    </label>
                    <label class="btn btn-light" style="width:4vw;">
                        <input type="radio" name="gender" value="female" formControlName="gender"> F
                    </label>
                </div>
                &nbsp;&nbsp;&nbsp;
            </div>
            <!-- <div class="col-sm-12 col-md-2 col-lg-2 pl-1 pr-1 mb-2">
                <select class="form-control" formControlName="doctor">
                    <option selected disabled value="select">Select Doctor</option>
                    <option *ngFor="let doc of doctor_List" value="{{doc.dr_id}}">{{doc.dr_name}}</option>
                </select>
            </div> -->
            <div class="col-sm-12 col-md-2 col-lg-2 pl-2 ml-2 mb-2">
                <input class="form-control mb-2" type="text" ng2TelInput
                     (countryChange)="onCountryChange($event)"  placeholder="phonenumber" [ng2TelInputOptions]="{initialCountry: 'in'}" formControlName="phonenumber" maxlength="10" pattern="[0-9]*"/>
                     
                     <div class="error"
                  *ngIf="(patientForm.controls.phonenumber.touched||patientForm.controls.phonenumber.dirty)&&patientForm.controls.phonenumber.errors?.required" style="color: red;">
                  <span style="color: red;">Phone Number required</span></div>
                  <div class="error"
                  *ngIf="(patientForm.controls.phonenumber.touched||patientForm.controls.phonenumber.dirty)&&patientForm.controls.phonenumber.errors?.pattern" style="color: red;">
                  <span style="color: red;">Only numbers are allowed</span>
                 </div>
            </div>
            
            
            <!-- <div class="col-sm-12 col-md-2 col-lg-2 pl-1 pr-1 mb-2"><input type="text" class="form-control" id="inlineFormInput" placeholder="Patient id" formControlName="patientid"></div>
            <div class="col-sm-12 col-md-3 col-lg-2 pl-1 pr-1 mb-2"><input type="text" class="form-control" id="inlineFormInput" placeholder="Bed No" formControlName="bedno"></div> -->
            <div class="col-sm-12 col-md-2 col-lg-2 mb-2" style="text-align: center;">
                <button mat-raised-button type="submit">Register</button>
            </div>
            <div class="col-sm-12 col-md-2 col-lg-1 mb-2" style="text-align: center;">
                <button mat-raised-button type="button" (click)="cancel_patientform()">Cancel</button>
            </div>

        </div>
        </form>
        <div class="row mt-4">
            <div class="col-md-6 searchbar">
                <label style="color: white;font-size: 18px;margin-left: 14px;">Search</label><br/>
                <div id="custom-search-input">
                    <div class="input-group col-md-12">
                        <input type="text" class="form-control input-lg" placeholder="Name / MR Number " id="inputsearch" [(ngModel)]="searchfilter" (input)="search_filter()"/>
                        <span class="input-group-btn">
                            <button class="btn btn-info btn-lg" type="button">
                                <i class="fas fa-search"></i>
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" >
            <div class="col-md-11"></div>
            <div class="col-md-1">
                <div style="text-align: right;">
                    <input type="radio" id="age1" name="age" value="30" (click)="addpatient('2')" checked="true">
                    <label for="age1" style="color: white !important;">All</label>&nbsp;&nbsp;
                    <input type="radio" id="age1" name="age" value="30" (click)="addpatient('3')">
                    <label for="age1" style="color: white !important;">Live</label> 
                 </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5">

            </div>
            <div class="col-md-7">
                <div style="text-align: center !important;justify-content:center !important">
                    <nav aria-label="...">
                       <ul class="pagination pagination-sm" >
                         <span style="color: #fff;margin-top:3px;"> Pages &nbsp;&nbsp;</span> <li class="page-item" [class.pagess]="mypage == i"  id="pages{{i}}" style="border: 1px solid white;cursor:pointer" *ngFor="let num of totalpagebuttons ;let i = index"  (click)="callpagination(num , i)"><a class="page-link" id="pagenum">{{num}}</a></li>
                       </ul>
                    </nav>
                </div>
            </div>
            
        </div>

       <div class="row text-center">
        <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
       </div>
        <div class="row mt-4">
            <div class="scroll">
                <table class="table tablefix">
                    <thead>
                        <tr>
                            <!-- <th colspan="1">#</th> -->
                            <th colspan="2" (click)="sort('patient_name')">Patient Name&nbsp; <i class="fa fa-sort"></i></th>
                            <th colspan="1" (click)="sort('patient_id')">Patient Id&nbsp; <i class="fa fa-sort"></i></th>
                            <th colspan="1">UHID</th>
                            <th colspan="1" (click)="sort('sex')">Gender&nbsp; <i class="fa fa-sort"></i></th>
                            <th colspan="1">DOB (dd-mm-yyyy)</th>
                            <th colspan="1">Used Today</th>
                            <th colspan="1">Rank</th>
                            
                            <!-- <th colspan="1">Age( years )</th> -->
                            <th colspan="1">Live</th>
                            <th colspan="1">Cloud Monitoring</th>
                            <th colspan="1">Doctor</th>
                            <th colspan="1">Community</th>
                            <th colspan="1">Delete Patient</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- | filter:searchfilter;let i = index; -->
                        <!-- | paginate: { itemsPerPage: 10, currentPage: p } -->
                        <!-- | orderBy: order : false : false -->
                        <tr *ngFor="let item of addpatient_List | orderBy: orderHeader : isDescOrder : true">
                            <!-- <th colspan="1">{{i + 1}}</th> -->
                            <th colspan="2" class="text-left">{{item.patient_name}}</th>
                            <td colspan="1">{{item.patient_id}}</td>
                            <td colspan="1">{{item.uhid}}</td>
                            <td colspan="1">{{item.sex ==='0' ? 'MALE' : 'FEMALE'}}</td>
                            <td colspan="1">{{item.dob * 1000 | date:'dd/MM/yyyy'}}</td>
                            <td colspan="1"><i style="color: green !important;" class="fa fa-check" aria-hidden="true" *ngIf="item.is_data_exists_today == 1"></i></td>
                            <td colspan="1"><i style="color: green !important;" class="fa fa-check" aria-hidden="true" *ngIf="item.rank == 1"></i></td>
                            <td colspan="1" class="text-center">
                                <div class="form-check">
                                    <input class="form-check-input flexCheckDefault" type="checkbox" value="true" [checked]="item.dstate == 1" (click)="islive(item)">
                                </div>
                            </td>
                            <td colspan="1" class="text-center">
                                <div class="form-check">
                                    <input class="form-check-input flexCheckDefault" type="checkbox" value="" [checked]="item.force_cloud == 1" (click)="cloudmonitoring(item)">
                                </div>
                            </td>
                            <td colspan="1" class="text-center">
                                <div class="form-check">
                                    <input class="form-check-input flexCheckDefault" type="checkbox" value="" [checked]="item.doctor_id != null && item.doctor_id != ''" (click)="addenabledoctor(item)">
                                </div>
                            </td>
                            <td colspan="1" class="text-center">
                                <div class="form-check">
                                    <input class="form-check-input flexCheckDefault" type="checkbox" value="" [checked]="item.is_community == '1'" (click)="addcommunity(item)">
                                </div>
                            </td>
                            <td colspan="1"><i class="far fa-trash-alt" id="delete" (click)="deletepatient(item)"></i></td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>




