import { Component, OnInit , Type} from '@angular/core';
import { PubNubAngular } from 'pubnub-angular2';
import { AppConfig } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { computeDecimalDigest } from '@angular/compiler/src/i18n/digest';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  urlPoints;
  notification_response:any = [];
  constructor(private fb: FormBuilder , private appService: AppService, pubnub: PubNubAngular, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router , private toastr: ToastrService) { 
    this.urlPoints = AppConfig.settings.EndPoints 
    var params = {
      "code":  sessionStorage.getItem("code"),
      "date": moment(new Date()).format("DD-MM-YYYY")
    }
    this.appService.getData(this.urlPoints.alertnotification  ,params ).subscribe((response: any) => {
       var mydata = response.data
       this.notification_response = mydata.reverse()
    });
  }

  ngOnInit(): void {
  }

}
