// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ENVNAME: 'test',
  // tslint:disable-next-line: no-string-literal
  HOST: 'https://ivital.co',
  epic:"https://test.epicare.net:3000",
  epic_dev:"https://epicare.net:3000", 
  epic1:"https://test.epicare.net",
  epic_dev1:"https://epicare.net",
  imgurl: '/helyxonapi/cloud/image/image.php',
  pdfurl: '/helyxonapi/cloud/pdf/pdf.php'
  // tslint:disable-next-line: no-string-literal
 // PORT: ':3000'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
