<app-navbar class="mb-2"></app-navbar>

<div  style="padding-bottom: 2rem;">
    <table style="margin-top: 1rem;width: 80%;margin-left: 10%;">
        <tr>
            <td>Symptoms</td>
            <td>Day 1</td>
            <td>Day 2</td>
            <td>Day 3</td>
            <td>Day 4</td>
            <td>Day 5</td>
            <td>Day 6</td>
            <td>Day 7</td>
        </tr>

        <tr *ngFor="let q of questions_array;let i=index">
            <td>{{q.que}}</td>
            <td [class.green]="questionnaire_data[0].data&&questionnaire_data[0].data[i].ans==1"
                [class.red]="questionnaire_data[0].data&&questionnaire_data[0].data[i].ans==0"></td>
            <td [class.green]="questionnaire_data[1].data&&questionnaire_data[1].data[i].ans==1"
                [class.red]="questionnaire_data[1].data&&questionnaire_data[1].data[i].ans==0"></td>
            <td [class.green]="questionnaire_data[2].data&&questionnaire_data[2].data[i].ans==1"
                [class.red]="questionnaire_data[2].data&&questionnaire_data[2].data[i].ans==0"></td>
            <td [class.green]="questionnaire_data[3].data&&questionnaire_data[3].data[i].ans==1"
                [class.red]="questionnaire_data[3].data&&questionnaire_data[3].data[i].ans==0"></td>
            <td [class.green]="questionnaire_data[4].data&&questionnaire_data[4].data[i].ans==1"
                [class.red]="questionnaire_data[4].data&&questionnaire_data[4].data[i].ans==0"></td>
            <td [class.green]="questionnaire_data[5].data&&questionnaire_data[5].data[i].ans==1"
                [class.red]="questionnaire_data[5].data&&questionnaire_data[5].data[i].ans==0"></td>
            <td [class.green]="questionnaire_data[6].data&&questionnaire_data[6].data[i].ans==1"
                [class.red]="questionnaire_data[6].data&&questionnaire_data[6].data[i].ans==0"></td>

        </tr>

    </table>

    <div style="display: flex;justify-content: center;margin-top: 1rem;">
        <div style="display:flex">
            <div style="width: 1rem;height: 1rem;" class="green"></div>
            <p style="margin-left:0.5rem">Yes</p>
            <div style="width: 1rem;height: 1rem;margin-left:0.5rem" class="red"></div>
            <p style="margin-left:0.5rem">No</p>
        </div>
    </div>

    <p style="text-align: center;margin-bottom: 0.5rem;margin-top: 0.5rem;">SpO2 in %</p>

    <div style="display: flex;justify-content: center;margin-top: 1rem;">
        <app-spo2graph [questionnaire_data]="questionnaire_data"></app-spo2graph>
    </div>

    <p style="text-align: center;margin-bottom: 0.5rem;margin-top: 1rem;">RR in Respiratory Per Minute</p>
    
    <div style="display: flex;justify-content: center;margin-top: 1rem;">
        <app-rrgraph [questionnaire_data]="questionnaire_data"></app-rrgraph>
    </div>

    <p style="text-align: center;margin-bottom: 0.5rem;margin-top: 1rem;">Temp in F</p>

    
    <div style="display: flex;justify-content: center;margin-top: 1rem;">
        <app-tempgraph [questionnaire_data]="questionnaire_data"></app-tempgraph>
    </div>
</div>