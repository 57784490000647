
<app-navbar class="mb-2"></app-navbar>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="row mt-5">
    <div class="col-12">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css" />
<div class="container">
    <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-12">
      <div class="card-counter cardcolor" href="#" [routerLink]="['/adddoctor']">
        <h4>Doctor</h4>
        <div class="text-center image_top"><img src="assets/ivital/doctor.svg"  alt="ivital" height="90" width="90"></div>
        <div class="text-center">
           <h3>Add Doctor</h3>
           <!-- <p class="card_p">BDS., MDS., Pediatric and Preventive Dental Surgeon</p> -->
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12">
      <div class="card-counter cardcolor" (click)="getaccount()">
        <h4>Security</h4>
        <div class="text-center image_top"><img src="assets/ivital/shield.svg"  alt="ivital" height="90" width="90"></div>
        <div class="text-center">
           <h3>Username & Password</h3>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="card-counter cardcolor">
          <h4>Time Zone</h4>
          <div class="text-center image_top">
            <img src="assets/ivital/Time.svg"  alt="ivital" height="90" width="90">
            <p>A-Z time zones available</p>
          </div>
      
          <div class="text-center mt-1">
          <select name="timeZone" [(ngModel)]="time_zone" (change)="timeZoneChanged($event)"  class="form-control">
            <option selected disabled value="select">Select TimeZone</option>
            <option *ngFor="let tz of zones ; let i = index;" [value]="tz[0]">
              {{ tz[0] +" - " + tz[1].utcOffsetStr}}
            </option>
          </select>
          </div>  
        </div>
    </div>

    <div class="col-lg-4  col-md-6 col-sm-12">
        <div class="card-counter cardcolor">
          <h4>Temperature</h4>
          <div class="row image_top">
            <div class="col-6"><img src="assets/ivital/nursing.svg"  alt="ivital" height="90" width="90"></div>
            <div class="col-6"><h1 class="mt-3">{{tempunit ? "37°C" : "98.6°F"}}</h1></div>
            <div class="row text-center mt-4">
              <div class="col-5"></div>
              <div class="col-5">
                <div class="can-toggle demo-rebrand-1">
                  <input id="d" type="checkbox" [checked]="tempunit" (change)="temp_unit()">
                  <label for="d">
                    <div class="can-toggle__switch" data-checked="℃" data-unchecked="°F" value="temp" ></div>
                  </label>
                </div>
              </div>
              <div class="col-2"></div>
            </div>
           
          </div>
         
        </div>
    </div>

    <div class="col-lg-4  col-md-6 col-sm-12">
      <div class="card-counter cardcolor"  href="#" [routerLink]="['/hospitals']">
        <h4>Hospital</h4>
        <div class="text-center image_top"><i class="fas fa-hospital"></i></div>
        <div class="text-center p-3 mt-2">
          <h3 *ngIf="hospital">{{hospital.hospital_name}}</h3>
          <!-- <button class="btn btn-primary">Create Hospital</button> -->
        </div>
      </div>
    </div>

    <div class="col-lg-4  col-md-6 col-sm-12">
      <div class="card-counter cardcolor" href="#" [routerLink]="['/alerts']">
        <h4>Alert</h4>
        <div class="text-center image_top"><img src="assets/ivital/stopalaram.svg"  alt="ivital" height="90" width="90"></div>
        <div class="text-center p-3 mt-2">
          <h3>Alert Settings</h3>
        </div>
      </div>
    </div>

    
  </div>
</div>

    </div>
</div>