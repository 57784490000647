<div class="row">
    
    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
        <div class="bracket"></div> 
        <figure class="highcharts-figure">
            <div id="container1"></div>
        </figure>
    </div>
   
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
        <p class="hr">&nbsp;Heart Rate</p>
        <div style="display: flex;flex-direction: row;">

        <div class="ecg_no">
            <!-- <p class="ecg">&nbsp;Heart Rate</p> -->
            <p class="number">&nbsp;{{number}}</p>
        </div>
        <div class="brac">
        
        </div>
        <div class="texts">
            <div class="row1">
                <p>&nbsp;{{high}}</p>
            </div>
            <div class="row1">
                <p style="align-self: flex-end;">&nbsp;{{low}}</p>
            </div>
        </div>
    </div>

    </div>
</div>