import { Component, Input, OnInit , Type} from '@angular/core';
import { PubNubAngular } from 'pubnub-angular2';
import { AppConfig } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { computeDecimalDigest } from '@angular/compiler/src/i18n/digest';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-vitaltable',
  templateUrl: './vitaltable.component.html',
  styleUrls: ['./vitaltable.component.scss']
})
export class VitaltableComponent implements OnInit {
  urlPoints;
  getallvitals_data;
  getallvitalsdata;
  getallvitalsParams;
  code = sessionStorage.getItem("code");

  patient_mr = sessionStorage.getItem("mrn");
  pname;
  dob;
  selectdate;
  datearrray:any = [];

  dateselect = "Select Date"
 

  constructor(private spinner: NgxSpinnerService  , private fb: FormBuilder , private appService: AppService, pubnub: PubNubAngular, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router) {
    this.urlPoints = AppConfig.settings.EndPoints;

    this.pname = sessionStorage.getItem("pname");
    var timedob:any = sessionStorage.getItem("dob"); 
    this.dob =  moment.unix( timedob).format("DD/MM/YYYY");

    var params = {
      "pid": sessionStorage.getItem("mrn"),
      "code": sessionStorage.getItem("code").toUpperCase(),
      "dtype": 0,
      "ddate":0,
      "mode":0
    }
    this.getdropdowndates(params)

   }

  ngOnInit(): void {
  }

  selecteddate(data){
    console.log("selecteddate", data);
    this.dateselect = data;
    this.getallvitalsParams = {
      'code': sessionStorage.getItem("code").toUpperCase(),
      'patientID': sessionStorage.getItem("mrn"),
      'from_date': data,
      "to_date": data
    }
    this.getallvitals();
    this.spinner.show()
  }

  getallvitals(){
    this.appService.getData(this.urlPoints.getallvitalsdata, this.getallvitalsParams).subscribe((response: any) => {
      this.getallvitals_data = "";
      this.spinner.hide();
      this.getallvitals_data = response.data[0].data;
      this.getallvitalsdata = this.getallvitals_data.reverse();
      console.log(" this.getallvitalsdata",  this.getallvitalsdata);
    });
  }

  getdropdowndates(params){
    this.appService.getData(this.urlPoints.dropdowndates, params).subscribe((response: any) => {
      console.log("dropdowndates", response);
      for(var i=0;i<response.ivital[0].response.length;i++){
        console.log(response.ivital[0].response[i]);
        this.datearrray.push(response.ivital[0].response[i].date);
        console.log(this.datearrray);
      }
    });
  }

}
