import { Component, OnInit , Type} from '@angular/core';
import { PubNubAngular } from 'pubnub-angular2';
import { AppConfig } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { computeDecimalDigest } from '@angular/compiler/src/i18n/digest';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-adddoctor',
  templateUrl: './adddoctor.component.html',
  styleUrls: ['./adddoctor.component.scss']
})
export class AdddoctorComponent implements OnInit {
  adddoctorForm
  urlPoints;
  countrycode;
  samedoctor;

  code = sessionStorage.getItem("code");


  constructor(private toastr: ToastrService ,private fb: FormBuilder , private appService: AppService, pubnub: PubNubAngular, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router ) { 
    this.urlPoints = AppConfig.settings.EndPoints;
    this.adddoctorForm = this.fb.group({
      username: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      phonenumber: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      Emailaddress: ["", [Validators.required,Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
    })
    
  }

  ngOnInit(): void {
   
}

save_adddoctorForm(){
  
    if(this.adddoctorForm.valid){
      var data = this.adddoctorForm.value;
      var adddoctorParams = {
        mode: 1,
        drid: 'dr' + Math.round(new Date().getTime() / 1000),
        drname: data.username,
        dremail: data.Emailaddress,
        drmobile: data.phonenumber,
        "code" : this.code
       }
      //  console.log('daa', adddoctorParams);
      this.verrifyusernamepasswordParams( this.urlPoints.adddoctor , adddoctorParams);

     }
  }

  verrifyusernamepasswordParams(url ,params ){
    // console.log('response first' , url ,params )
    this.appService.getData(url ,params ).subscribe((response: any) => {
      // console.log("response 61", response);
       if(response.doctor[0].success == 2){
          this.samedoctor = response.doctor[0].message;
          this.toastr.success(response.doctor[0].message)
       }else if(response.doctor[0].success == 1){
        this.toastr.success(response.doctor[0].message)
        // this.samedoctor = "";
       }
    });
  }

  onCountryChange(event){
    // console.log(event.dialCode);
    // console.log(event.name);
    // console.log(event.iso2);

    this.countrycode = event.dialCode;
    
}
  

}
