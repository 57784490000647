import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Chart1Component } from './chart1/chart1.component';
import { Chart2Component } from './chart2/chart2.component';
import { ChartsComponent } from './charts/charts.component';


const routes: Routes = [
  {
    path: '',
    component: ChartsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChartsRoutingModule { }
