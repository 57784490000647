<nav class="navbar navbar-expand-lg navbar-light nav-style mb-3">
    <a class="navbar-brand" (click)="navigate('home')"><img src="assets/ivital/head.png" alt="ivital" height="25"
            width="90"></a>
    <button class="navbar-toggler nav-style-btn" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <ul class="navbar-nav ml-auto">

            <li class="nav-item">
                <a class="nav-link navbar-images"><img src="assets/followup.png" alt="ivital" height="30" width="30"
                        data-toggle="tooltip" title="Follow Up" (click)="navigate('follow')"></a>
            </li>

            <li class="nav-item">
                <a class="nav-link navbar-images"><img src="assets/ivital/monitor.svg" alt="ivital" height="30"
                        width="30" data-toggle="tooltip" title="Spot Check" (click)="viewcharts()"></a>
            </li>
            <li class="nav-item">
                <a class="nav-link navbar-images"><img src="assets/lab_report.svg" alt="ivital" height="27" width="27"
                        data-toggle="tooltip" title="Lab Reports" (click)="viewreport()"></a>
            </li>
            <li class="nav-item">
                <a class="nav-link navbar-images" (click)="callpeertopeer()"><img src="assets/ivital/video-call.svg"
                        alt="ivital" height="30" width="30" data-toggle="tooltip" title="P2P video call"></a>
            </li>
            <!-- <li class="nav-item">
            <a class="nav-link navbar-images" (click)="peertopeercall()"><img src="assets/ivital/video-call.svg"  alt="ivital" height="30" width="30" data-toggle="tooltip" title="P2P video call"></a>
        </li> -->
            <li class="nav-item">
                <a class="nav-link navbar-images" (click)="openMultiline()"><img
                        src="assets/ivital/chart.svg" alt="ivital" height="30" data-toggle="tooltip"
                        title="Multiline Chart" width="30"></a>
            </li>

            <li class="nav-item">
                <a class="nav-link navbar-images" (click)="openData()"><img
                        src="assets/table.svg" alt="ivital" height="30" data-toggle="tooltip"
                        title="Data" width="30"></a>
            </li>

            <li class="nav-item">
                <a class="nav-link navbar-images" (click)="navigate('home')"><img src="assets/ivital/home.png"
                        alt="ivital" height="30" width="30" data-toggle="tooltip" title="Home"></a>
            </li>
            <li class="nav-item">
                <a class="nav-link navbar-images" (click)="navigate('security')"><img src="assets/ivital/add.png"
                        alt="ivital" height="30" width="30" data-toggle="tooltip" title="Add Patient"></a>
            </li>
            <li>
                <div class="btn-group">
                    <a class="nav-link navbar-images dropdown-toggle" data-toggle="dropdown" role="button"
                        aria-haspopup="true" aria-expanded="false"><img src="assets/ivital/user.svg" alt="ivital"
                            height="30" width="30" data-toggle="tooltip" title="Profile"></a>
                    <div class="dropdown-menu dropdown-menu-right profiledrop">
                        <!-- <button class="dropdown-item" type="button" href="#" [routerLink]="['/login']"><span><img src="assets/ivital/user-2.svg"  alt="ivital" height="18" width="18">&nbsp;&nbsp;<a class="navprofile">Profile</a></span></button> -->
                        <button class="dropdown-item" type="button" (click)="navigate('settings')"><span><img
                                    src="assets/ivital/settings.svg" alt="ivital" height="18" width="18">&nbsp;&nbsp;<a
                                    class="navprofile">Settings</a></span></button>
                        <button class="dropdown-item" type="button" (click)="navigate('notification')"><span><img
                                    src="assets/ivital/bell_.svg" alt="ivital" height="18" width="18">&nbsp;&nbsp;<a
                                    class="navprofile">Notification</a></span></button>
                        <!-- <button class="dropdown-item" type="button" href="#" [routerLink]="['/']"><span><img src="assets/ivital/logout.svg"  alt="ivital" height="18" width="18">&nbsp;&nbsp;<a class="navprofile">Logout</a></span></button> -->
                        <button class="dropdown-item" type="button" (click)="logout()"><span><img
                                    src="assets/ivital/logout.svg" alt="ivital" height="18" width="18">&nbsp;&nbsp;<a
                                    class="navprofile">Logout</a></span></button>

                    </div>
                </div>
            </li>

        </ul>
    </div>
</nav>

<!-- <div class="row" style="height: 60%;width:60%;padding:5px;margin-left: 10px;">
    <iframe frameborder="0" id="peervideo" tabindex="1" [src]="peertopeervideocall" allow="camera *;microphone *"></iframe>
</div> -->

<div #resizeBox cdkDrag id="floatingVideocontainer" style="cursor: default;" [style.width.px]="width" 
    *ngIf="floatingvideoscreen">
    <div style="display:flex">
        <span #dragHandleCorner class="dragHandle corner" cdkDrag
            (cdkDragMoved)="dragMove(dragHandleCorner, $event)"></span>
        <span #dragHandleRight cdkDragLockAxis="x" class="dragHandle right" cdkDrag
            (cdkDragMoved)="dragMove(dragHandleRight, $event)"></span>
        <span #dragHandleBottom cdkDragLockAxis="y" class="dragHandle bottom" cdkDrag
            (cdkDragMoved)="dragMove(dragHandleBottom, $event)"></span>

            <p cdkDragHandle style="color: #38989B;padding-left: 0.5rem;cursor: all-scroll;background-color: #FFF;padding: 0.25rem;width: fit-content;margin-left: 20px;margin-top: 5px;"><img style="margin-right: 0.5rem;" src="assets/move.svg">Click here to drag</p>

        <span style="float:right;margin-top: 5px;cursor: pointer;margin-left:auto"><span><img src="assets/ivital/peer/maxmize.svg" height="20"
                    width="20" (click)="maxi('max')" *ngIf="showmax" /></span><span><img
                    src="assets/ivital/peer/minimize.svg" height="20" width="20" (click)="maxi('min')"
                    *ngIf="showmin" /></span>&nbsp;&nbsp;
        </span>
    </div>

    <div>
        <p (click)="removepeer()" style="color: #FFF;padding-left: 0.5rem;background-color: #e22525;padding: 0.25rem;cursor: pointer;text-align: center;margin-left: 20px;margin-right: 20px;">End Call and Exit</p>
    </div>

     <div>
    </div> 
    <div class="row" style="height: 25rem;width:100%;z-index:500;">
        <iframe style="width:100%;padding: 3px 0px 30px 15px !important;" frameborder="0" id="peervideo" tabindex="1"
            [src]="peertopeervideocall" allow="camera *;microphone *;display-capture"></iframe>
    </div>
</div>


<div class="row">
    <div *ngIf="!full">

        <!-- <app-peertopeer *ngIf="video"></app-peertopeer>  -->
        <!-- <div class="media-container" style="margin-top: 10px;">
            <div id="local-media">
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="cameraonoff"><i class="fas fa-video-slash"></i></span>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="cameraonoff"><i class="fas fa-video"></i></span>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="microphoneon_off" ><i class="fas fa-microphone "></i></span>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="microphoneonoff"><i class="fas fa-microphone-slash"></i></span>
                &nbsp;&nbsp;&nbsp;&nbsp;<img src="assets/ivital/endvideocall.svg" height="40" width="40"/>
            </div> 
            <div id="remote-media"></div>
            <div id="myvideo"></div>
        </div> -->
    </div>
</div>

<body id="body" class="half">
    <div id="row" class="row">
        <!-- <div id="charts" class="col-lg-9" *ngIf="!patient">
            <div style="text-align: center !important;">
                <h2  style="color: rgba(245, 81, 52, 0.952) ;">Please Enable Live Monitoring</h2>
            </div>
        </div> -->
        <div id="charts" class="col-lg-9" *ngIf="patient">
            <div class="row1 row header">
                <div class="col-lg-6 row1" style="display: flex;">
                    <!-- <img class="pic" src="assets/pic.svg"> -->
                    <!-- <p style="text-align: left;" class="name" >{{patient.patient_name}}</p> -->
                    <p class="age">{{patient.patient_name}} / {{patient.gender}} / {{y}}Y {{m}}M /
                        {{patient.patient_id}} </p>
                </div>
                <div class="col-lg-6 row1">
                    <p class="bed"></p>
                    <p class="acu">ADU</p>
                    <div class="datetime">
                        <p>12:41:33 PM</p>
                        <p>21 - 1 - 2021</p>
                    </div>
                    <img class="pic1" id="icon" (click)="fullscreen()" src="assets/full-size.svg">
                    <!-- <img style="cursor: pointer;float: right;margin-top: 5px;" src="assets/stethoscope.svg" (click)="ausculation(patient.patient_id)" height="35" width="35">&nbsp;&nbsp; -->
                    <p *ngIf="informstatus" style="font-size: 10px;border:1px solid;padding:5px;text-align:center;height: 60px;
                    box-shadow: 0px 0px 3px;margin-top: 6px;">Please wait for upto a minute after disconnect of device
                        for values to be reset
                        <span style="cursor:pointer;float: right;margin-top: 5px;" (click)="informclose()"><i
                                class='fa'>&#xf00d;</i></span>
                    </p>
                    <!-- <img style="cursor: pointer;float: right;margin-top: 5px;" src="assets/stethoscope.svg"  height="20" width="20" data-toggle="tooltip" title="*Please wait for upto a minutes after disconnect of device for values to be reset"> -->
                </div>
            </div>

            <app-chart2></app-chart2>
            <app-chart1></app-chart1>
            <app-chart3></app-chart3>
            <app-chart4></app-chart4>
            <app-chart5></app-chart5>

        </div>


        <div *ngIf="!full" class="col-lg-3">
            <div class="map">
                <iframe [src]="src" loading="lazy"></iframe>
            </div>
            <div>
                <div class="gluco container">
                    <div class="row text-center">
                        <div class="col-md-7 col-sm-12" style="border-right: 2px solid rgb(231, 224, 224);">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 text-center zero">
                                    <h4 class="zero sys" style="font-family: Montserrat">Systolic</h4>
                                    <h2 class="zero sysdata">{{systolicdata}}</h2>
                                    <p class="zero" style="font-weight: 400;font-family: Montserrat">(mmHg)</p>
                                </div>
                                <div class="col-md-6 col-sm-6 zero text-center">
                                    <h4 class="zero dia" style="font-family: Montserrat">Diastolic</h4>
                                    <h2 class="zero diadata">{{diastolicdata}}</h2>
                                    <p class="zero" style="font-weight: 400;font-family: Montserrat">(mmHg)</p>
                                </div>
                            </div>
                            <p class="zeros" style="font-size: 13px;font-weight: 400;font-family: Montserrat">
                                {{bp_date}}</p>
                        </div>
                        <div class="col-md-5 col-sm-12">
                            <div class="row text-center">
                                <div class="col-md-12 col-sm-12 zero">
                                    <h4 class="zero glu" style="font-family: Montserrat">Glucose</h4>
                                    <h2 class="zero mmol_data">{{glucometer_mgol}} <sub
                                            style="font-size: 13px;font-weight: 400;font-family: Montserrat">(mg/dl)</sub>
                                    </h2>
                                </div>
                            </div>
                            <p class="zero" style="font-size: 13px;font-weight: 400; font-family: Montserrat">
                                {{gluco_date }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="container" class="container">
                <div class="icons">
                    <div class="row1 profile">
                        <img class="pic" src="assets/pic.svg">
                        <div class="texts">
                            <p style="font-size: 20px;" class="name">{{patient.patient_name}}</p>
                            <p id="p2">{{patient.gender}} / {{y}}Y {{m}}M / {{patient.patient_id}}</p>
                        </div>
                        <img class="settings" src="assets/settings.svg">
                    </div>
                    <div class="icons1">
                        <div class="row1">
                            <div class="left">
                                <img src="/assets/ivital/temperature.png" height="35" width="35">
                            </div>
                            <div id="no1" class="left_no">
                                <p *ngIf="data">{{temperature}}</p>
                            </div>

                            <div id="no2" class="right_no">
                                <p *ngIf="data">{{vitals.bpm}} bpm</p>
                            </div>

                            <div class="right">
                                <img src="/assets/ivital/hr_ppm.png" height="35" width="35">
                            </div>
                        </div>
                        <div class="row1">
                            <div class="left">
                                <img src="/assets/ivital/spo2.png" height="35" width="35">
                            </div>

                            <div id="no3" class="left_no">
                                <p *ngIf="data">{{vitals.o2}}%</p>
                            </div>

                            <div id="no4" class="right_no">
                                <p *ngIf="data"></p>
                            </div>

                            <div class="right">
                                <img src="/assets/ivital/heartbeat.png" height="35" width="35">
                            </div>
                        </div>
                        <div class="row1">
                            <div class="left">
                                <img src="/assets/ivital/rr_rpm.png" height="35" width="35">
                            </div>

                            <div id="no5" class="left_no">
                                <p *ngIf="data">{{vitals.rr}} rpm</p>
                            </div>

                            <div id="no6" class="right_no">
                                <p *ngIf="data">{{rox}}</p>
                            </div>

                            <div class="right">
                                <p>ROX</p>
                            </div>
                        </div>
                        <div class="row1">
                            <div class="left">
                                <p>P.I</p>
                            </div>

                            <div id="no7" class="left_no">
                                <p *ngIf="data">{{vitals.pi}}</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row1 bg">
                    <p (click)="viewall()" id="t1">View All</p>
                    <p (click)="prev()" id="t2">
                        < Prev</p>
                            <p (click)="next()" id="t3">Next ></p>
                </div>
            </div>
            <div *ngIf="list" id="container1">
                <div></div>
                <div *ngFor="let patient1 of patientList;let i as index"
                    [class.highlighted]="patient1.patient_id === mrn">
                    <div (click)="detailView(i)" class="row1 profile listitem">
                        <img class="pic" src="assets/pic.svg">
                        <div class="texts">
                            <p>{{patient1.patient_name}}</p>
                            <p id="p2">{{patient1.gender}} / {{patient1.y}}Y {{patient1.m}}M / {{patient1.patient_id}}
                            </p>
                        </div>
                        <img class="settings" src="assets/settings.svg">
                    </div>
                </div>
            </div>
            <div class="row2 bg1" *ngIf="list">
                <p (click)="toggle()" id="cancel">Cancel</p>
            </div>
        </div>
    </div>

</body>

<br><br><br><br>