<div class="row mb-5">
    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
        <div class="bracket"></div> 
        <figure class="highcharts-figure">
            <div id="container5"></div>
        </figure>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
        <div class="row1 row2">
            <span class="temp">&nbsp;Temp.</span>
            <!-- <span class="c">&nbsp;Offset Temp.</span> -->
        </div>
        <div>
            <div class="row1">
                <!-- <span>T1</span> -->
                <span class="big" style="font-size: 20px;">&nbsp;{{t1}}<span style="font-size: 8px !important;vertical-align: top;"></span></span>

                <div class="brac">
        
                </div>

                <div style="display: flex;flex-direction: column;">
                    <div class="row1 maxmin">
                        <!-- <p>Max.</p> -->
                        <p>&nbsp;{{high}}</p>
                    </div>

                    <div class="row1 maxmin">
                        <!-- <p>Min.</p> -->
                        <p style="align-self: flex-end;">&nbsp;{{low}}</p>
                    </div>
                </div>
                <!-- <span class="big1">&nbsp;{{t_off}}
                </span> -->
            </div>
            <!-- <div class="row1">
                <span class="t2">T2</span>
                <span class="big t2">37.7</span>
                <span class="big">{{t_off}}</span>
            </div> -->
        </div>
        <div class="row1">
            <span class="c">&nbsp;Offset Temp : {{t_off}}</span>
            <span class="c">&nbsp;Location: Oral</span>

        </div>

        <!-- <div class="row1">
        </div> -->
    </div>
</div>