import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChartsRoutingModule } from './charts-routing.module';
import { Chart1Component } from './chart1/chart1.component';
import { Chart2Component } from './chart2/chart2.component';
import { Chart3Component } from './chart3/chart3.component';
import { Chart4Component } from './chart4/chart4.component';
import { Chart5Component } from './chart5/chart5.component';
import { ChartsComponent, DialogOverviewExampleviewreport, Dialog_attachment,Dialog_attachment2, Dialog_pac_attachments } from './charts/charts.component';
import { AgmCoreModule } from '@agm/core';
import { SafePipeModule } from 'safe-pipe';
import { PeertopeerComponent } from './peertopeer/peertopeer.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {InfiniteScrollModule} from 'ngx-infinite-scroll'

@NgModule({
  declarations: [Chart1Component, Chart2Component, Chart3Component, Chart4Component, Chart5Component, ChartsComponent, PeertopeerComponent,DialogOverviewExampleviewreport,Dialog_attachment,Dialog_attachment2,Dialog_pac_attachments],
  imports: [
    CommonModule,
    ChartsRoutingModule,
    AgmCoreModule,
    SafePipeModule,
    DragDropModule,
    InfiniteScrollModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ChartsModule { }
