<body>
    <!--Floating video-->
    <div #resizeBox cdkDrag id="floatingVideocontainer"
    [style.width.px]="width"    
    [style.height.px]="height">
        <span #dragHandleCorner  class="dragHandle corner" cdkDrag (cdkDragMoved)="dragMove(dragHandleCorner, $event)"></span>
        <span #dragHandleRight cdkDragLockAxis="x" class="dragHandle right" cdkDrag (cdkDragMoved)="dragMove(dragHandleRight, $event)"></span>
        <span #dragHandleBottom cdkDragLockAxis="y" class="dragHandle bottom" cdkDrag (cdkDragMoved)="dragMove(dragHandleBottom, $event)"></span>
        <span class="full">
    <div class="row">
       <div class="col-sm-6 col-md-6 col-lg-6">
          <!-- <button class="btn btn-primary joinp2p_btn" id="join-button" (click)="onJoinClick()">Join P2P Room</button> -->
       </div>
       <div class="col-sm-6 col-md-6 col-lg-6 video_btn" *ngIf="phone_ring_endcall">
           <!-- <div class="row">
            <div class="col-sm-4 col-md-4 col-lg-4"><button class="btn btn-danger"><img src="assets/phone_cut.svg" (click)="disconnect_call();disconnect()" height="15" width="15" /></button></div>
            <div class="col-sm-4 col-md-4 col-lg-4"><button class="btn btn-primary" *ngIf="micon"><img src="assets/microphone_off.svg" (click)="mic_on()" height="15" width="15" /></button><button class="btn btn-primary" *ngIf="micoff"><img src="assets/microphone_on.svg" (click)="mic_off()" height="15" width="15" /></button></div>
            <div class="col-sm-4 col-md-4 col-lg-4"><button class="btn btn-info" *ngIf="cam_on"><img src="assets/video_on.svg"  (click)="camera_on()" height="15" width="15" /></button><button class="btn btn-info" *ngIf="cam_off"><img src="assets/video-camera.svg" (click)="camera_off()" height="15" width="15" /></button></div>
            </div> -->
            <div class="btn-group" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-danger"><img src="assets/phone_cut.svg" (click)="disconnect()" height="15" width="15" /></button>
                <button class="btn btn-primary" *ngIf="micon"><img src="assets/microphone_off.svg" (click)="mic_on()" height="15" width="15" /></button><button class="btn btn-primary" *ngIf="micoff"><img src="assets/microphone_on.svg" (click)="mic_off()" height="15" width="15" /></button>
                <button class="btn btn-info" *ngIf="cam_on"><img src="assets/video_on.svg"  (click)="camera_on()" height="15" width="15" /></button><button class="btn btn-info" *ngIf="cam_off"><img src="assets/video-camera.svg" (click)="camera_off()" height="15" width="15" /></button>
              </div>
       </div>
    </div>

<div class="media-container" style="margin-top: 10px;">
    <div id="local-media-container"></div> 
    <div id="remote-media-container"></div>
    <div id="myvideo"></div>
</div>
</span>
    </div>
</body>
<!-- <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="phone_ring" *ngIf="phone_ring">
            <div class="phonering-alo-phone phonering-alo-green phonering-alo-show" id="phonering-alo-phoneIcon">
                <div class="phonering-alo-ph-circle"></div>
                 <div class="phonering-alo-ph-circle-fill"></div>
                <a  class="pps-btn-img" >
                 <div class="phonering-alo-ph-img-circle"></div>
                 </a>
            </div>
        </div>
    </div>
</div> -->


