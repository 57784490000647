import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  SystemJsNgModuleLoader,
  APP_INITIALIZER,
} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PubNubAngular } from 'pubnub-angular2';
import { AppConfig } from './app.config';
import { constantsApp, initializeApp } from './app.initializer';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatInputModule} from '@angular/material/input'
import {MatNativeDateModule} from '@angular/material/core'
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';

import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip'
import { ToastrModule } from 'ngx-toastr';

import { DialogOverviewExamplelogout, NavbarComponent } from './navbar/navbar.component';
import { SettingsComponent } from './settings/settings.component';
import { DialogOverviewExampleDialog_cardsettings, DialogOverviewExamplegeomap, DialogOverviewExamplepeertopeer, HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';


import { AddpatientComponent, DialogOverviewExampleDialog_community, DialogOverviewExampleDialog_doctor } from './addpatient/addpatient.component';
import { NotificationComponent } from './notification/notification.component';
import { SecurityComponent } from './security/security.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FooterComponent } from './footer/footer.component';
import { VitaltableComponent } from './vitaltable/vitaltable.component';
import { DialogOverviewExampleDialogpdf, MultilinechartComponent } from './multilinechart/multilinechart.component';
import { CreateaccountComponent } from './createaccount/createaccount.component'
import {Ng2TelInputModule} from 'ng2-tel-input';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { FormsModule } from '@angular/forms';
import {MatBadgeModule} from '@angular/material/badge';
import { AdddoctorComponent } from './adddoctor/adddoctor.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {MatCardModule} from '@angular/material/card';
import { NgxSpinnerModule } from "ngx-spinner";
import { AlertsComponent } from './alerts/alerts.component';
import {MatRadioModule} from '@angular/material/radio';
import { HospitalsComponent } from './hospitals/hospitals.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AgeModule } from 'age-pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgxPaginationModule } from 'ngx-pagination'
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { OrderModule } from 'ngx-order-pipe';
import { DialogOverviewExampleDataTable, DialogOverviewExamplelabnotes, DialogOverviewExampleuploadreport, DialogOverviewExampleviewchart, DialogOverviewExampleviewreport, Dialog_addpacs, Dialog_attachment_edit, Dialog_multiline, Dialog_upload } from './charts/charts/charts.component';
import { FollowupComponent } from './followup/followup.component';
import { ReportsComponent } from './reports/reports.component';
import { PaginateComponent } from './paginate/paginate.component';
import { Dialog_comments, QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { QuestionnaireChartsComponent } from './questionnaire-charts/questionnaire-charts.component';
import { Spo2graphComponent } from './spo2graph/spo2graph.component';
import { RrgraphComponent } from './rrgraph/rrgraph.component';
import { TempgraphComponent } from './tempgraph/tempgraph.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll'



@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SettingsComponent,
    HomeComponent,
    LoginComponent,
    AddpatientComponent,
    NotificationComponent,
    SecurityComponent,
    FooterComponent,
    VitaltableComponent,
    MultilinechartComponent,
    CreateaccountComponent,
    ResetpasswordComponent,
    ReportsComponent,
    AdddoctorComponent,
    DialogOverviewExampleDialog_doctor,
    DialogOverviewExamplelogout,
    DialogOverviewExamplepeertopeer,
    DialogOverviewExamplegeomap,
    AlertsComponent,
    HospitalsComponent,
    PaginateComponent,
    DialogOverviewExampleDialog_cardsettings,
    DialogOverviewExampleDialog_community,
    DialogOverviewExampleDialogpdf,
    DialogOverviewExampleuploadreport,
    // DialogOverviewExampleviewreport,
    DialogOverviewExamplelabnotes,
    Dialog_attachment_edit,
    DialogOverviewExampleviewchart,
    FollowupComponent,
    QuestionnaireComponent,
    QuestionnaireChartsComponent,
    Spo2graphComponent,
    RrgraphComponent,
    TempgraphComponent,
    Dialog_comments,
    Dialog_multiline,
    Dialog_addpacs,
    Dialog_upload,
    DialogOverviewExampleDataTable
  ],
  imports: [
    NgxDaterangepickerMd.forRoot(),
    MDBBootstrapModule.forRoot(),
    FormsModule ,
    AgeModule,
    IntlModule,
    LabelModule,
    DateInputsModule,
    BrowserModule,
    MatBadgeModule,
    AppRoutingModule,
    HttpClientModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatExpansionModule,
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatButtonModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    Ng2SearchPipeModule,
    MatCardModule,
    NgxSpinnerModule,
    MatRadioModule,
    MatTableModule,
    MatTooltipModule,
    MatSlideToggleModule,
    DragDropModule,
    AgeModule,
    NgxPaginationModule,
    OrderModule ,
    BsDatepickerModule.forRoot(),
    InfiniteScrollModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      progressBar:true,
      progressAnimation: 'increasing',
      preventDuplicates : true
    }),
    Ng2TelInputModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAFgM81Qz-SwfTzUsr4F51AgDj0HdN88CQ'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    PubNubAngular,
    { provide: Window, useValue: window },
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: constantsApp,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    // tslint:disable-next-line: deprecation
    SystemJsNgModuleLoader,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
  ],
  bootstrap: [AppComponent],entryComponents: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],})
export class AppModule { }
