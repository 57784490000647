<link href="//netdna.bootstrapcdn.com/twitter-bootstrap/2.3.2/css/bootstrap-combined.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//netdna.bootstrapcdn.com/twitter-bootstrap/2.3.2/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>
<!------ Include the above in your HEAD tag ---------->




<nav class="navbar navbar-expand-lg nav-style mb-3">
  <div class="container-fluid-nav text-center" height="25">
        <h3>{{carddata.patientdata.patient_id +" / "+ carddata.patientdata.patient_name +" / "}}{{ carddata.patientdata.sex === '0' ? 'MALE' : 'FEMALE' + " / "}}</h3>
    </div>
</nav>

<form  [formGroup]="card_settingForm" (submit)="save_cardsettingForm()">
<div class="well">
    <table class="table">
      <thead>
        <tr>
          <th>Units</th>
          <th>Temperature ( °C )</th>
          <th>Heart rate ( PPM )</th>
          <th>SPO2 ( % )</th>
          <th>Respiratory Rate ( RPM )</th>
          <th>ROX ( % )</th>
        </tr>
      </thead>
      <tbody>
        <tr>
            <td>Threshold High</td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="th_temp"/></td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="th_heart"/></td>
            <td></td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="th_resp"/></td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="th_rox"/></td>
        </tr>
        <tr>
            <td>Threshold Low</td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="tl_temp"/></td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="tl_heart"/></td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="tl_spo2"/></td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="tl_resp"/></td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="tl_rox"/></td>
           
        </tr>
        <tr>
            <td>Alarm Escalation</td>
            <td>
                <label class="switch">
                    <input type="checkbox" value="" formControlName="ae_temp">
                    <span class="slider round"></span>
                </label>
            </td>
            <td>
                <label class="switch">
                    <input type="checkbox" value="" formControlName="ae_heart">
                    <span class="slider round"></span>
                </label>
            </td>
            <td>
                <label class="switch">
                    <input type="checkbox" value="" formControlName="ae_spo2">
                    <span class="slider round"></span>
                </label>
            </td>
            <td>
                <label class="switch">
                    <input type="checkbox" value="" formControlName="ae_resp">
                    <span class="slider round"></span>
                </label>
            </td>
            <td class="text-center">
                <label class="switch">
                    <input type="checkbox" value="" formControlName="ae_rox">
                    <span class="slider round"></span>
                </label>
            </td>
     
        </tr>
        <tr>
            <td>Alarm Delay (Seconds)</td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="aes_temp"/></td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="aes_heart"/></td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="aes_spo2"/></td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="aes_resp"/></td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="aes_rox"/></td>
       
        </tr>
        <tr>
            <td>Snooze Interval(Seconds)</td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="si_temp"/></td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="si_heart"/></td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="si_spo2"/></td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="si_resp"/></td>
            <td><input type="text" class="form-control" height="60" width="15" formControlName="si_rox"/></td>
           
        </tr>
        <tr>
            <td>Snooze</td>
            <td>
                <label class="switch">
                    <input type="checkbox" value="" formControlName="s_temp">
                    <span class="slider round"></span>
                </label>
            </td>
            <td>
                <label class="switch">
                    <input type="checkbox"value="" formControlName="s_heart">
                    <span class="slider round"></span>
                </label>
            </td>
            <td>
                <label class="switch">
                    <input type="checkbox" value="" formControlName="s_spo2">
                    <span class="slider round"></span>
                </label>
            </td>
            <td>
                <label class="switch">
                    <input type="checkbox" value=""  formControlName="s_resp">
                    <span class="slider round"></span>
                </label>
            </td>
            <td>
                <label class="switch">
                    <input type="checkbox"value="" formControlName="s_rox">
                    <span class="slider round"></span>
                </label>
            </td>
            
        </tr>
        <tr>
            <td>Temperature Offset Value</td>
            <td>
                <!-- <input type="text" class="form-control" height="60" width="15" formControlName="tof_temp"/> -->
                <select  class="form-control" formControlName="tof_temp">
                    <option *ngFor="let data of temperature">{{data}}</option>
                </select>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td><span style="color: #45C2C5;float:left;">Fio2 %</span><input type="text" class="form-control" height="60" width="15" formControlName="tof_rox"/></td>
        </tr>
      </tbody>
    </table>
</div>
<span class="float-left">
    <span class="mr-3"><img src="assets/ivital/ivrs.svg"  class="mr-2" alt="ivital" height="25" width="25"><input type="checkbox" value="" formControlName="ivrs"></span><span><img src="assets/ivital/email.svg"  class="mr-2" alt="ivital" height="25" width="25"><input type="checkbox" value=""  formControlName="sms"></span>
</span>

<span class="float-right">
    <button class="btn mr-1 btn-info btn-pressure btn-sensitive buttons" type="submit">Save</button>
    <button class="btn mr-1 btn-warning buttons" (click)="cardreset()" type="button">Reset</button>
    <button class="btn mr-1 btn-danger buttons" (click)="cardfalse()" type="button">Cancel</button>
</span>

</form>

