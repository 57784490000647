import {Component, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { AfterViewInit, ElementRef, NgZone, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';

import { connect,createLocalVideoTrack,RemoteAudioTrack, RemoteParticipant,RemoteTrack,RemoteVideoTrack,Room } from 'twilio-video';

import { Nullable } from './types';

@Component({
  selector: 'app-peertopeer',
  templateUrl: './peertopeer.component.html',
  styleUrls: ['./peertopeer.component.scss']
})
export class PeertopeerComponent implements OnInit {

  @ViewChild('resizeBox') resizeBox: ElementRef;
  @ViewChild('dragHandleCorner') dragHandleCorner: ElementRef;
  @ViewChild('dragHandleRight') dragHandleRight: ElementRef;
  @ViewChild('dragHandleBottom') dragHandleBottom: ElementRef;

  joinButton;
  phone_ring;
  soundPlayer;
  localVideoTrack;
  phone_ring_endcall;
  remoteMediaContainer;
  localMediaContainer;
  access_token;
  room_name;
  room;
  participant;
  src;
  endcall = false;
  cam_on = false;
  cam_off= true;
  micon = false;
  micoff = true;
  // closepeertopeer = true;
 code = sessionStorage.getItem("code");
  width = '430';
  height = '430';
  urlPoints;
  audio;
  
  constructor(private appService: AppService,private dom: DomSanitizer , private ngZone: NgZone, private router: Router,private elementRef: ElementRef ) {
    this.urlPoints = AppConfig.settings.EndPoints;
    var audio_forallparams = {
      "code": this.code.toUpperCase()
    }

    this.appService.getData(this.urlPoints.getalert, audio_forallparams).subscribe((response: any) => {
       console.log("response 350", response);
       if(response.settings.alerts[3].alert_name == "Outgoing Calls"){
        var audiostring = response.settings.alerts[3].audio_url
        console.log("audiostring", audiostring);
        this.audio = new Audio();
        this.audio.src = audiostring;
        this.audio.load();
        this.audio.play();
      }
      });
   }

  ngOnInit(): void { 
    // this.joinButton = document.querySelector('#join-button') as HTMLButtonElement;
    this.localMediaContainer = document.querySelector('#local-media-container') as HTMLDivElement;
    this.remoteMediaContainer = document.querySelector('#remote-media-container') as HTMLDivElement;
    // this.soundPlayer = new Audio("assets/phone_ring.mp3");

    this.onJoinClick();
  }

//  async playsound(){
//     this.soundPlayer.play();   
//  }


 async onJoinClick() {
  if(localStorage.getItem('accesstoken') != undefined && localStorage.getItem('room_name') != undefined){
    this.localVideoTrack = await createLocalVideoTrack({ width: 100 });
    this.localMediaContainer.appendChild(this.localVideoTrack.attach());
    // this.onJoinClick()  
  } 
  
  this.phone_ring = true;
  this.phone_ring_endcall = true;
  this.access_token = localStorage.getItem('accesstoken');
  this.room_name = localStorage.getItem('room_name');

  this.room = await connect( this.access_token , {
      name: this.room_name,
      audio: false,
      video: { height: 300, frameRate: 10, width: 300 }
  });
 
  console.log(' this.room', this.room);
  this.room.participants.forEach(
      participant => this.manageTracksForRemoteParticipant(participant)
  );

  this.room.on('participantConnected', (participant: RemoteParticipant) =>{
    var that = this
    this.phone_ring = false;
    this.audio.pause();
    this.audio.currentTime = 0;
    that.manageTracksForRemoteParticipant(participant);
  });
   
  this.room.on('participantDisconnected', (participant: RemoteParticipant) =>{
    document.getElementById(participant.sid)?.remove();
    this.disconnect();
  });
  window.onbeforeunload = () => this.room.disconnect();
 
}

mic_on(){
  this.room.localParticipant.audioTracks.forEach(audioTrack => {
    audioTrack.track.enable();
    console.log(audioTrack.track.enable());
    this.micon = false;
    this.micoff = true;
});
}
mic_off(){
  this.room.localParticipant.audioTracks.forEach(audioTrack => {
    audioTrack.track.disable();
    console.log(audioTrack.track.disable());
    this.micon = true;
    this.micoff = false;
});
}
camera_off(){
  this.room.localParticipant.videoTracks.forEach(videoTrack => {
    console.log("camera off")
    videoTrack.track.disable();
    console.log(videoTrack.track.disable());
    this.cam_on = true;
    this.cam_off= false;
  });
}
camera_on(){
  console.log("camera ON")
   this.room.localParticipant.videoTracks.forEach(videoTrack => {
     videoTrack.track.enable();
      console.log(videoTrack.track.enable());
      this.cam_on = false;
      this.cam_off= true;
   });
}


disconnect(){
  this.audio.pause();
  this.audio.currentTime = 0; 
  this.endcall = false;
  this.phone_ring = false;
  this.phone_ring_endcall = false;
  this.localMediaContainer.removeChild(this.localMediaContainer.childNodes[0]);
  var element = document.getElementById("floatingVideocontainer") as HTMLImageElement
  element.remove()
  console.log('tracks', this.localVideoTrack._trackSender.track.stop() )
  this.room.disconnect(); 
  window.onbeforeunload = () => this.room.disconnect();
  this.room.detach().forEach(element => element.remove());
 
}

manageTracksForRemoteParticipant(participant: RemoteParticipant) {
  // Handle tracks that this participant has already published.
  this.attachAttachableTracksForRemoteParticipant(participant);

  // Handles tracks that this participant eventually publishes.
  participant.on('trackSubscribed', (track: RemoteTrack) =>{
     this.endcall = true;
    if (!(this.trackExistsAndIsAttachable(track))){
      return;
    }
   this.attachTrack(track);
  
  });

  participant.on('trackUnsubscribed', (track: RemoteTrack) =>{
    if (this.trackExistsAndIsAttachable(track))
    track.detach().forEach(element => element.remove());
  });

}

// disconnecttracks(){
//  alert();
// }

attachAttachableTracksForRemoteParticipant(participant: RemoteParticipant) {
  console.log('participant 88', participant);
  participant.tracks.forEach(publication => {

      if (!publication.isSubscribed){
        return;
      }
      if (!this.trackExistsAndIsAttachable(publication.track)){
        return;
      }

      this.attachTrack(publication.track);
  });
}

trackExistsAndIsAttachable(track?: Nullable<RemoteTrack>): track is RemoteAudioTrack | RemoteVideoTrack {
  
  return !!track && (
      (track as RemoteAudioTrack).attach !== undefined ||
      (track as RemoteVideoTrack).attach !== undefined
  );
}


attachTrack(track: RemoteAudioTrack | RemoteVideoTrack) {
  this.remoteMediaContainer.appendChild(track.attach()); 
  this.remoteMediaContainer.addClass(
    this.elementRef.nativeElement,'video'); 
}

get resizeBoxElement(): HTMLElement {
  return this.resizeBox.nativeElement;
}

get dragHandleCornerElement(): HTMLElement {
  return this.dragHandleCorner.nativeElement;
}

get dragHandleRightElement(): HTMLElement {
  return this.dragHandleRight.nativeElement;
}

get dragHandleBottomElement(): HTMLElement {
  return this.dragHandleBottom.nativeElement;
}

ngAfterViewInit() {
  this.setAllHandleTransform();
}

setAllHandleTransform() {
  const rect = this.resizeBoxElement.getBoundingClientRect();
  this.setHandleTransform(this.dragHandleCornerElement, rect, 'both');
  this.setHandleTransform(this.dragHandleRightElement, rect, 'x');
  this.setHandleTransform(this.dragHandleBottomElement, rect, 'y');
}

setHandleTransform(
  dragHandle: HTMLElement,
  targetRect: ClientRect | DOMRect,
  position: 'x' | 'y' | 'both'
) {
  const dragRect = dragHandle.getBoundingClientRect();
  const translateX = targetRect.width - dragRect.width;
  const translateY = targetRect.height - dragRect.height;

  if (position === 'x') {
    dragHandle.style.transform = `translate(${translateX}px, 0)`;
  }

  if (position === 'y') {
    dragHandle.style.transform = `translate(0, ${translateY}px)`;
  }

  if (position === 'both') {
    dragHandle.style.transform = `translate(${translateX}px, ${translateY}px)`;
  }
}

dragMove(dragHandle: HTMLElement, $event: CdkDragMove<any>) {
  this.ngZone.runOutsideAngular(() => {
    this.resize(dragHandle, this.resizeBoxElement);
  });
}

resize(dragHandle: HTMLElement, target: HTMLElement) {
  const dragRect = dragHandle.getBoundingClientRect();
  const targetRect = target.getBoundingClientRect();

  const width = dragRect.left - targetRect.left + dragRect.width;
  const height = dragRect.top - targetRect.top + dragRect.height;

  target.style.width = width + 'px';
  target.style.height = height + 'px';

  this.setAllHandleTransform();
}




}
