
import { Component, Input, OnInit , Type, ViewChild} from '@angular/core';
import { PubNubAngular } from 'pubnub-angular2';
import { AppConfig } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { computeDecimalDigest } from '@angular/compiler/src/i18n/digest';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Moment } from 'moment';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import * as lib from 'countries-and-timezones';
import { NgxSpinnerService } from "ngx-spinner";
import * as html2pdf from 'html2pdf.js';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit { 
  
  urlPoints;
  code = sessionStorage.getItem("code");
  token = localStorage.getItem("authentication");
  getreportData;
  todayDate:any;
  startdateInFormat = '-';
enddateInFormat = '-';
selected: any;
alwaysShowCalendars: boolean;
clr = 0;
  totalpagebuttons:any = [];
  pagelist = [];
  count;
  pageOfItems:any;
 pagination = true;
 sendstartDate;
 sendendDate;

 @ViewChild(MatPaginator) paginator: MatPaginator;

  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  
  

  constructor(private fb: FormBuilder , private appService: AppService, private spinner: NgxSpinnerService ,  pubnub: PubNubAngular, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router , private toastr: ToastrService) { 
    this.urlPoints = AppConfig.settings.EndPoints;
  }

  ngOnInit(): void {
    let date = new Date();
    let momDate = moment(date);
    this.todayDate = momDate.format('DD/MM/YYYY');
    let sendDate = momDate.format('DD-MM-YYYY')
    this.getreport(sendDate,sendDate);
  }

  getreport(startDate,endDate){
    var report_params ={
      code:'iogc',
      from_date: startDate,
      to_date: endDate
    }
    this.getnotificationReport(this.urlPoints.getnotificationreport, report_params);

  }

  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') )
  }

  rangeClicked(range) {
    //console.log('[rangeClicked] range is : ', range);
  }

  datesUpdated(range) {
   //console.log('[datesUpdated] range is : ', range);
   
   const startDate = moment(this.selected.startDate.toDate());
   const endDate = moment(this.selected.endDate.toDate());
   this.startdateInFormat = startDate.format('DD/MM/YYYY');
   this.enddateInFormat = endDate.format('DD/MM/YYYY');
        console.log(this.startdateInFormat);
        console.log(this.enddateInFormat);
   this.sendstartDate = startDate.format('DD-MM-YYYY');
   this.sendendDate = endDate.format('DD-MM-YYYY');
   this. getreport(this.sendstartDate,this.sendendDate);

  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
    var report_params ={
      code:'iogc',
      from_date: this.sendstartDate,
      to_date: this.sendendDate
    }
    this.getnotificationReport(this.urlPoints.getnotificationreport, report_params)
  }

  getnotificationReport(url ,params){
    this.appService.getData(url, params).subscribe((response: any) => {
      if(response){
        this.getreportData =  response.data;
        
        for(var i=0;i< this.getreportData.length;i++){
          let timechange = moment(this.getreportData[i].time).local().format('DD-MM-YYYY ddd HH:mm:ss');
          this.getreportData[i].time = timechange;
        }
      }

    });
  }

  
downloadPDF(){
  this.clr = 1;
  var element = document.getElementById('mytable');
var opt = {
 margin: 0,
 filename: 'AlertNotificationReport.pdf',
 image: { type: 'jpeg', quality: 0.98 },
 html2canvas: { scale: 2 },
 jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' }
};
// New Promise-based usage:
  html2pdf().from(element).set(opt).save();
}

downloadXLS(){
  var blob = new Blob([document.getElementById('mytable').innerHTML], {
    type: "application/vnd.ms-excel;charset=utf-8"
  })
  saveAs(blob, "AlertNotificationReport.xls");
}




}
