import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-rrgraph',
  templateUrl: './rrgraph.component.html',
  styleUrls: ['./rrgraph.component.scss']
})
export class RrgraphComponent implements OnInit {
  @Input() questionnaire_data
  points=[];
  options;
  
  constructor() { }

  ngOnInit(): void {
    console.log(this.questionnaire_data)

    var that = this

    for(var i=0;i<this.questionnaire_data.length;i++)
    {
      if(this.questionnaire_data[i].data)
      {
        this.points.push(
          Number(this.questionnaire_data[i].rpm)
          // [Number(this.questionnaire_data[i].spo2),'Day '+(i+1)]
          // {
          //   value:this.questionnaire_data[i].spo2,
          //   day:'Day '+(i+1)
          // }
        )

        
      }
      else
      {
        this.points.push(
          null
          // [null,'Day '+(i+1)]

          // {
          //   value:null,
          //   day:'Day '+(i+1)
          // }
        )
      }
    }

    console.log(this.points)

    this.options = {
      chart: {
          type: 'line',
          
     //     marginRight: 10,
          backgroundColor: '#FFF',
          
          events: {
              load: function () {
              }
              
          }
      },

      plotOptions: {
          series: {
              marker: {
                  enabled: false
              },
        //      lineWidth: 1,

              // color: '#00FF00'
          },
      },

      time: {
          useUTC: false
      },

      title: {
          text: ''
      },

      accessibility: {
          announceNewData: {
              enabled: true,
              minAnnounceInterval: 15000,
              announcementFormatter: function (allSeries, newSeries, newPoint) {
                  if (newPoint) {
                      return 'New point added. Value: ' + newPoint.y;
                  }
                  return false;
              }
          }
      },

      xAxis: {
        categories: ["Day 1", "Day 2", "Day 3","Day 4","Day 5","Day 6","Day 7"],
        tickInterval: 1,
        labels: {
            enabled: true
            }
          // type: 'datetime',
          // tickPixelInterval: 150,
          // visible: false,
        //   labels: {
        //     enabled: true,
        //     formatter: function() { return that.seriesData[0];},
        // }
      },

      yAxis: {

        //   labels: {
        //       style: {
        //           color: '#00FF00'
        //       }
        //   },
      

        //   gridLineWidth: 0.25,
        //   gridLineColor: '#00FF00',
        // //  opacity:'0.5',
        //   title: {
        //       useHTML: true,
        //       text: 'bpm',
        //       rotation: 0,
        //       style: {
        //           color: '#00FF00',
        //           fontFamily: 'Montserrat'

        //       }
        //   },
        //   plotLines: [{
        //       value: 0,
        //       width: 1,
        //       color: '#808080'
        //   }],
        //   visible: true,
        //   tickPixelInterval: 10,
        //    min:that.low,
        //    max:that.high
      },

      tooltip: {
          // headerFormat: '<b>{series.name}</b><br/>',
          // pointFormat: '{point.y:.2f}',
          // enabled: true,
          // style:{
          //     fontSize:'1.5vw'
          // }
      },

      legend: {
          // enabled: false
      },


      credits: {
          enabled: false
      },

      series: [{
          // name: '',
          // enableMouseTracking: true,
          type: "line",
          marker: {
            enabled: true,
            radius: 5
        },
          data: that.points
      }]

    }

    Highcharts.chart('container_rr', this.options);

  }

}
