<app-navbar class="mb-2"></app-navbar>
<form [formGroup]="createForm" (submit)="create_form()">
<div class="row mt-5">
    <div class="col-lg-5"></div>
    <div class="col-lg-2 text-center">
        <div><h2>Create Account</h2></div>
    <div class="mt-3"> 
        <input type="text" class="form-control" placeholder="Username" formControlName="username" pattern="[a-zA-Z '']*" maxlength="30">
    </div>
    <div class="error" *ngIf="(createForm.controls.username.touched||createForm.controls.username.dirty )&&createForm.controls.username.errors?.required">
        Name required
    </div>
    <div class="error" *ngIf="(createForm.controls.username.touched||createForm.controls.username.dirty )&&createForm.controls.username.errors?.minlength">
        Minimum 3 characters required
    </div>
    <div class="error" *ngIf="(createForm.controls.username.touched||createForm.controls.username.dirty )&&createForm.controls.username.errors?.pattern">
        Only letters are allowed
    </div>
    <div class="mt-3">
    <input class="form-control lastnamefilter" type="text" ng2TelInput
    (countryChange)="onCountryChange($event)"  placeholder="phonenumber" [ng2TelInputOptions]="{initialCountry: 'in'}" formControlName="phonenumber" maxlength="10" pattern="[0-9]*" />
    </div>
    <div class="error"
      *ngIf="(createForm.controls.phonenumber.touched||createForm.controls.phonenumber.dirty)&&createForm.controls.phonenumber.errors?.required">
      Phone Number required</div>
      <div class="error"
      *ngIf="(createForm.controls.phonenumber.touched||createForm.controls.phonenumber.dirty)&&createForm.controls.phonenumber.errors?.pattern">
      Only numbers are allowed
     </div>

    <div  class="mt-3">
        <input type="email" class="form-control lastnamefilter" placeholder="Email Id" formControlName="Emailaddress">
    </div>
    <div class="error" *ngIf="(createForm.controls.Emailaddress.touched||createForm.controls.Emailaddress.dirty )&&createForm.controls.Emailaddress.errors?.required">
        Email required
    </div>
    <div class="error" *ngIf="(createForm.controls.Emailaddress.touched||createForm.controls.Emailaddress.dirty )&&createForm.controls.Emailaddress.errors?.pattern">
        Invalid Email
    </div>
    <div  class="mt-3">
        <input [type]="password" class="form-control" placeholder="Password" formControlName="password" maxlength="15" minlength="8" onpaste="return false;" ondrop="return false;" autocomplete="off" >
        <span class="hideshow"><i class="fa fa-eye" aria-hidden="true" *ngIf="!show" (click)="onClick()"></i><i class="fa fa-eye-slash" aria-hidden="true" *ngIf="show" (click)="onClick()"></i></span>
    </div>
    <div class="error" *ngIf="(createForm.controls.password.touched||createForm.controls.password.dirty )&&createForm.controls.password.errors?.required">
        Password required
    </div>
    <div class="error" *ngIf="(createForm.controls.password.touched||createForm.controls.password.dirty )&&createForm.controls.password.errors?.minlength">
        Minimum 8 characters required
    </div>
    <div  class="mt-4">
        <button mat-raised-button>SIGN IN</button>
    </div>
    <div class="mt-3">
        
        <span><i class="fas fa-arrow-left leftarraow" ></i>&nbsp;&nbsp;<a class="underlineHover1 texta"  href="#" [routerLink]="['/security']">Login</a>
        </span>
    
        <span class="mt-4">&nbsp;&nbsp;<a class="underlineHover1 texta"  href="#" [routerLink]="['/reset']">Reset password</a>
        </span>
    </div>
  
    </div>
    <div class="col-lg-5"></div>
</div>
</form>