import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AppConfig } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {Md5} from 'ts-md5/dist/md5';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  urlPoints;
  resetpasswordForm;
 
  password = "password";
  show;
  code = sessionStorage.getItem("code");
  token = localStorage.getItem("authentication");

  constructor(private fb: FormBuilder , private appService: AppService,private route: ActivatedRoute, private router: Router , private toastr: ToastrService) { 
    this.urlPoints = AppConfig.settings.EndPoints;

    this.resetpasswordForm = this.fb.group({
      Name: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      password1: ["", [Validators.required,Validators.maxLength(15)]],
      password2: ["", [Validators.required, Validators.minLength(8),Validators.maxLength(15)]],
      password3: ["", [Validators.required, Validators.minLength(8),Validators.maxLength(15)]]

    }, )

    console.log("reset password", this.resetpasswordForm );
  }

  ngOnInit(): void {
  }

  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }


  // pass1() {
  //   if (this.password_1 === 'password_1;') {
  //     this.passwordtext1 = 'text';
  //     this.passwordshow_1 = true;
  //   } else {
  //     this.passwordtext1 = 'password';
  //     this.passwordshow_1= false;
  //   }
  // }

  reset_form(){
    
    if(this.resetpasswordForm.valid){
      var data = this.resetpasswordForm.value;
      if(data.password2 == data.password3){
        const md5 = new Md5();
        var data1 = md5.appendStr(data.password1).end()
        const md6 = new Md5();
        var data2 = md6.appendStr(data.password2).end()
        var upadteusernamepasspasrams = {
          "mode": 3,
          "uname" : data.Name,
          "upswd" : data2,
          "opswd" : data1,
          "code" : this.code
        }
        this.updateusernamepassword( this.urlPoints.verifyadminpassword , upadteusernamepasspasrams , this.token);
      }else{
          this.toastr.error("New Password and Confirm New Password Does not Match? Retry")
      }
     
     
   
    }
  }

  updateusernamepassword(url ,params , token){
    this.appService.postData(url ,params , token).subscribe((response: any) => {
      if(response.settings.success == 1 && response.settings.message == "updated successfully"){
        console.log("response.settings.success",response.settings.message)
        this.toastr.success("Password " +response.settings.message);
        this.router.navigate(["/security"],{ queryParams: {}})
      }else{
        this.toastr.error(response.settings.message)
      }
    });
  }
    
}
