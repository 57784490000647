<nav class="navbar navbar-expand-lg navbar-light nav-style mb-3">
    <a class="navbar-brand" href="#" [routerLink]="['/home']"><img src="assets/ivital/head.png"  alt="ivital" height="25" width="90"></a>
    <button class="navbar-toggler nav-style-btn" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
    <ul class="navbar-nav ml-auto">
        <!-- <li class="nav-item">
            <a class="nav-link navbar-images" href="#" [routerLink]="['/multiline']"><img src="assets/ivital/chart.svg"  alt="ivital" height="30" width="30"></a>
        </li> -->
        <!-- <li class="nav-item">
            <a class="nav-link navbar-images"href="#" [routerLink]="['/charts']"><img src="assets/ivital/video-call.svg"  alt="ivital" height="30" width="30" data-toggle="tooltip" title="P2P video call"></a>
        </li> -->
        <li class="nav-item">
            <a class="nav-link navbar-images" href="#" [routerLink]="['/home']"><img src="assets/ivital/home.png"  alt="ivital" height="30" width="30" data-toggle="tooltip" title="Home"></a>
        </li>
        <li>
            <div class="btn-group">
                <a class="nav-link navbar-images dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><img src="assets/ivital/report.svg"  alt="ivital" height="30" width="30" data-toggle="tooltip" title="Reports"></a>
                <div  class="dropdown-menu dropdown-menu-right profiledrop">
                  <button class="dropdown-item" type="button" href="#" [routerLink]="['/reports']"><span><img src="assets/ivital/bell_.svg"  alt="ivital" height="18" width="18">&nbsp;&nbsp;<a class="navprofile">AlertNotificationsReport</a></span></button>
                </div>
            </div>
        </li>
        <li class="nav-item">
            <a class="nav-link navbar-images" href="#" [routerLink]="['/security']"><img src="assets/ivital/add.png"  alt="ivital" height="30" width="30" data-toggle="tooltip" title="Add Patient"></a>
        </li>
        <li>
            <div class="btn-group">
                <a class="nav-link navbar-images dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><img src="assets/ivital/user.svg"  alt="ivital" height="30" width="30" data-toggle="tooltip" title="Profile"></a>
                <div  class="dropdown-menu dropdown-menu-right profiledrop">
                  <!-- <button class="dropdown-item" type="button" href="#" [routerLink]="['/login']"><span><img src="assets/ivital/user-2.svg"  alt="ivital" height="18" width="18">&nbsp;&nbsp;<a class="navprofile">Profile</a></span></button> -->
                  <button class="dropdown-item" type="button" href="#" [routerLink]="['/settings']"><span><img src="assets/ivital/settings.svg"  alt="ivital" height="18" width="18">&nbsp;&nbsp;<a class="navprofile">Settings</a></span></button>
                  <button class="dropdown-item" type="button" href="#" [routerLink]="['/notification']"><span><img src="assets/ivital/bell_.svg"  alt="ivital" height="18" width="18">&nbsp;&nbsp;<a class="navprofile">Notification</a></span></button>
                  <!-- <button class="dropdown-item" type="button" href="#" [routerLink]="['/']"><span><img src="assets/ivital/logout.svg"  alt="ivital" height="18" width="18">&nbsp;&nbsp;<a class="navprofile">Logout</a></span></button> -->
                  <button class="dropdown-item" type="button" (click)="logout()"><span><img src="assets/ivital/logout.svg"  alt="ivital" height="18" width="18">&nbsp;&nbsp;<a class="navprofile">Logout</a></span></button>
                  
                </div>
              </div>
        </li>
        
    </ul>
    </div>
</nav>