<div>
    <button _ngcontent-rqf-c101="" type="button" aria-label="Close" class="close"><span _ngcontent-rqf-c101="" aria-hidden="true"  (click)="closechart()">×</span></button>
</div>

<div class="row">
    
    <div style="margin-left:5px"><h3>Auscultation</h3></div>
    
    <div style="margin-top: 50px;font-weight: 600;">Examination : <span style="font-weight:800">{{getExamination}}</span></div>
    
    <div style="margin-top: 30px;margin-left: 25px;">
        <audio controls autoplay src="{{getaudiosrc}}">
            <!-- <source src="https://ivital.co/cloud/files/aus/helyxon_ivital_steth__30-03-202216_56_25-filtered.wav" > -->
        </audio>
    </div>
    
</div>