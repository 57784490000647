<div class="colortop"><br>
<div class="row">
  <div class="col-md-12 pl-5 pr-5">
    <span class="save">Add Lab Reports</span> <span style="float: right;"><span class="save" (click)="save()">Save</span>&nbsp;&nbsp; <span class="save" (click)="closereport()">Cancel</span></span>
  </div>
</div>

<div class="row">
    <div class="col-md-12" style="height: 600px;overflow-y: auto !important">

<div class="row">
    <div class="col-md-6 text-center pl-5 pr-5 pt-3">
        <label class="type1">
            <img src="assets/ivital/image.svg" height="25" wwidth="25"/><br>
            <p>Upload Image</p>
            <input type="file" id="files" multiple accept="image/*" style="display: none;" (change)="GetFile($event)">
        </label>
        <br>
        <div>
            <img [src]="imgenvironment+'/helyxonapi/cloud/image/upload/'+ imagepreview " height="60" width="60" *ngIf="imagepreview"/>
           &nbsp;<i class="fa fa-times-circle" *ngIf="imagepreview" style="position: absolute;" (click)="removeimg()"></i>
        </div>
    </div> 
    <div class="col-md-6 text-center pl-5 pr-5 pt-3">
        <label class="type2">
            <img src="assets/ivital/documentupload.svg" height="25" wwidth="25"/><br>
            <p>Upload Document</p>
            <input type="file" id="files" multiple accept="application/pdf,application/vnd.ms-excel" style="display: none;" (change)="GetFile($event)">
        </label>
    </div>
</div>
<div class="row">
    <div class="col-md-12 pl-5 pr-5">
        <div class="row image_size" *ngFor="let item of imgURL; let i= index;" style="margin-bottom:0.5rem">
            <div style="width: 30%;">
                <div class="text-center" style="position: relative;">
                    <img *ngIf="item?.type != 'pdf'" src="" imgPreview [src]="item.url" class="image" width="50" height="50">
                    <img *ngIf="item?.type === 'pdf'" imgPreview src="assets/pdf.png" class="image" width="50" height="50">
                </div>
            </div>
            <div style="width: 50%;">
                {{ item?.name }}
            </div>
            <div style="width: 20%;">
                <i class="fa fa-times-circle" (click)="removePicture(item, i);"></i>
            </div>
        </div>
    </div>
</div>


<div class="row">
<div class="col-md-12 pl-5 pr-5">
    <input (input)="search($event)">
        <div class="cc_items2" style="height: 5rem;max-height: 5rem;" infiniteScroll  (scrolled)="onScroll4()">
        <div #inv_item style="font-size: 0.75rem;height: fit-content;"
            class="cc_item chip chip-md success-color white-text  example z-depth-2 mr-1"
            *ngFor="let inv of inv_copy1;let indexOfelement=index;let i as index"
            tabindex="1" (click)="update_inv(indexOfelement,i)"
            (keydown.enter)="update_inv(indexOfelement,i)">
            {{inv.name}}
        </div>
    <p *ngIf="investigations.length==0">No investigations found.</p>
    </div>
</div>
</div>

<div class="row">
    <div class="col-md-12 pl-5 pr-5">
        <p style="font-weight: 600;">Title</p>
        <p style="cursor: pointer;"><span *ngFor="let inv_s of inv_selected; let i=index;" (click)='click4(i)'
            (dblclick)='doubleClick4(i)'>{{inv_s.name}}<span
                *ngIf="inv_selected.length!=(i+1)">, </span></span></p>
    </div>
</div>

<div class="row">
    <div class="col-md-12 pl-5 pr-5">
        <input style="cursor:pointer" type="checkbox" id="abnormal" name="abnormal" [(ngModel)]="abnormal">
        <label for="abnormal">Abnormal</label>
    </div>
</div>

<div class="row">
    <div class="col-md-12 pl-5 pr-5">
        <p>Impression</p><br>
        <textarea maxlength="200" class="desc" [(ngModel)]="description"></textarea>
    </div>
</div>

<div class="row">
    <div class="col-md-12 pl-5 pr-5">
        <p>Comments</p><br>
        <textarea maxlength="200" class="desc" [(ngModel)]="comments"></textarea>
    </div>
</div>

</div>
</div>

</div>

