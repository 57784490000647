<div class="row">
    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
        <div class="bracket"></div> 
        <figure class="highcharts-figure">
            <div id="container2"></div>
        </figure>
    </div>
   
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
        <p class="plet">&nbsp;Plethysmograph</p>
        <div class="row1">
            <div class="texts">
                <span>NIBP</span>
                <span>15:15</span>
                <span>nvdKg</span>
            </div>
        </div>
        <div class="row1">
            <div class="numbers">
                <div id="big">{{number}}</div>
                <div id="small"></div>
            </div>
        </div>
        <!-- <div class="row1">
            <div>
                <span>PERSONAL</span>
            </div>
        </div> -->
    </div>
</div>