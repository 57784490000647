<div>
    <div style="display: flex;justify-content: space-between;">
        <i class="fa fa-close" style="font-size:24px;visibility:hidden"></i>
        <p style="font-size:1.25rem;color:#FFF">Data Table</p>
        <img style="width:1.25rem;cursor: pointer;" (click)="close()" src="assets/close.svg">

    </div>

</div>

<div style="display: flex;justify-content: center;margin-top: 00.5rem;">
    <!-- <mat-form-field appearance="fill">
        <mat-label>Favorite food</mat-label>
        <mat-select>
          <mat-option *ngFor="let food of foods" [value]="food.value">
            {{food.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->


</div>

<div style="display: flex;justify-content: center;margin-top: 00.5rem;">

    <select style="padding: 0.5rem;" (change)="onSelectChange($event)" [(ngModel)]="dropDate"
        *ngIf="dropDownArray.length>0">
        <option *ngFor="let d of dropDownArray" [value]="d.date" style="padding: 00.5rem;">{{d.date1}}</option>
    </select>

    <p style="align-self: center;font-size: 1.1rem;margin-left: 0.5rem">From : {{startDate | date:'dd-MMM-yyyy' }}</p>
    <img style="margin-left: 0.5rem;width: 1.1rem;cursor:pointer" src="assets/calendar.svg"
        (click)="picker.open()">


    <mat-form-field style="width:0px !important;visibility:hidden;">
        <input matInput [matDatepicker]="picker" [(ngModel)]="startDate" (dateChange)="onDateChange($event)"
            [max]="maxDate">
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <p style="align-self: center;margin-left: 0.5rem;font-size: 1.1rem;">To : {{endDate | date:'dd-MMM-yyyy' }}</p>
    <img style="margin-left: 0.5rem;width: 1.1rem;cursor: pointer;" src="assets/calendar.svg"
        (click)="picker2.open()">


    <mat-form-field style="width:0px !important;visibility:hidden;">
        <input matInput [matDatepicker]="picker2" [(ngModel)]="endDate" (dateChange)="onDateChange($event)"
            [max]="maxDate">
        <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>

    <img style="width: 1.75rem;margin-left: 3rem;cursor:pointer;height: 1.75rem;align-self: center" (click)="export()" src="assets/export.png">
</div>

<div style="height: 50vh;overflow: auto;margin-top: 0.5rem;">
    <table  style="width: 100%;" id="dataTable">
        <tr>
            <th rowspan="2">#</th>
            <th rowspan="2">Date</th>
            <th rowspan="2">Time</th>
            <th rowspan="2">O2 (%)</th>
            <th rowspan="2">HR (PPM)</th>
            <th rowspan="2">PI (%)</th>
            <th rowspan="2">RR (RPM)</th>
            <th colspan="2">
                Temperature (&#8451;)
                <!-- <div></div>
                <div style="display: flex;">
                    <div style="width: 50%; border-top: 2px solid white;border-right: 2px solid white;">
                        Temp
                    </div>

                    <div style="width: 50%;border-top: 2px solid white;">
                        Temp Offset
                    </div>
                </div> -->
            </th>
            <th rowspan="2">Hub Name</th>

        </tr>

        <tr>
            <th>Temp</th>
            <th>Temp Offset</th>
        </tr>

        <tr *ngFor="let t of tableData;let i = index">
            <td>
                {{i+1}}
            </td>

            <td>
                {{t.timeStamp*1000 | date:'dd-MM-yyyy'}}
            </td>

            <td>
                {{t.timeStamp*1000 | date:'hh:mm:ss a'}}
            </td>

            <td>
                {{t.o2}}
            </td>

            <td>
                {{t.bpm}}
            </td>

            <td>
                {{t.pi}}
            </td>

            <td>
                {{t.rr}}
            </td>

            <td>
                {{t.temp}}

                <!-- <div style="display: flex;">
                    <div style="width: 50%;border-right: 2px solid white;">
                    </div>

                    <div style="width: 50%;">
                        {{t.tempoffset}}
                    </div>
                </div> -->
            </td>

            <td>
                {{t.tempoffset}}

            </td>

            <td>
                {{t.hub_name}}
            </td>

        </tr>
    </table>
</div>