<app-navbar class="mb-2"></app-navbar>
<div class="row">
    <div class="col-sm-5 col-md-5 col-lg-5"></div>
    <div class="col-sm-2 col-md-2 col-lg-2" *ngIf="!hospital_create">
        <form [formGroup]="hospitalForm" (submit)="save_hospitalForm()">
        <div class="text-center">
            <h2 style="color: #ffff;">Create Hospital</h2>
        </div>
        <input class="form-control mb-2" type="text" placeholder="Enter Hospital Name" formControlName="hospitalname" pattern="[a-zA-Z '']*" maxlength="30">
        <div class="error" *ngIf="(hospitalForm.controls.hospitalname.touched||hospitalForm.controls.hospitalname.dirty )&&hospitalForm.controls.hospitalname.errors?.required">
            Name required
        </div>
        <div class="error" *ngIf="(hospitalForm.controls.hospitalname.touched||hospitalForm.controls.hospitalname.dirty )&&hospitalForm.controls.hospitalname.errors?.minlength">
            Minimum 3 characters required
        </div>
        <div class="error" *ngIf="(hospitalForm.controls.hospitalname.touched||hospitalForm.controls.hospitalname.dirty )&&hospitalForm.controls.hospitalname.errors?.pattern">
            Only letters are allowed
        </div>
        <input class="form-control mb-2" type="text" ng2TelInput
         (countryChange)="onCountryChange($event)"  placeholder="phonenumber" [ng2TelInputOptions]="{initialCountry: 'in'}" formControlName="phonenumber"  maxlength="10" pattern="[0-9]*"/>
         <div class="error"
      *ngIf="(hospitalForm.controls.phonenumber.touched||hospitalForm.controls.phonenumber.dirty)&&hospitalForm.controls.phonenumber.errors?.required">
      Phone Number required</div>
      <div class="error"
      *ngIf="(hospitalForm.controls.phonenumber.touched||hospitalForm.controls.phonenumber.dirty)&&hospitalForm.controls.phonenumber.errors?.pattern">
      Only numbers are allowed
     </div>
         <input class="form-control mb-2 mt-2" type="text" placeholder="address1" formControlName="address1">
         <div class="error" *ngIf="(hospitalForm.controls.address1.touched||hospitalForm.controls.address1.dirty )&&hospitalForm.controls.address1.errors?.required">
            address1 required
        </div>
         <input class="form-control mb-2" type="text" placeholder="address2" formControlName="address2">
         <div class="error" *ngIf="(hospitalForm.controls.address2.touched||hospitalForm.controls.address2.dirty )&&hospitalForm.controls.address2.errors?.required">
            address2 required
        </div>
         <input class="form-control mb-2" type="text" placeholder="city" formControlName="city">
         <div class="error" *ngIf="(hospitalForm.controls.city.touched||hospitalForm.controls.city.dirty )&&hospitalForm.controls.city.errors?.required">
            city required
        </div>
         <input class="form-control mb-2" type="text" placeholder="state" formControlName="state">
         <div class="error" *ngIf="(hospitalForm.controls.state.touched||hospitalForm.controls.state.dirty )&&hospitalForm.controls.state.errors?.required">
            state required
        </div>
         <input class="form-control mb-2" type="text" placeholder="country" formControlName="country">
         <div class="error" *ngIf="(hospitalForm.controls.country.touched||hospitalForm.controls.country.dirty )&&hospitalForm.controls.country.errors?.required">
            country required
        </div>
         <input class="form-control mb-2" type="number" placeholder="pincode" formControlName="pincode" onKeyPress="if(this.value.length==6) return false;">
         <div class="error" *ngIf="(hospitalForm.controls.pincode.touched||hospitalForm.controls.pincode.dirty )&&hospitalForm.controls.pincode.errors?.required">
            pincode required
        </div>
        <div class="error"
        *ngIf="(hospitalForm.controls.pincode.touched||hospitalForm.controls.pincode.dirty)&&hospitalForm.controls.pincode.errors?.pattern">
        Only numbers are allowed
       </div>
         <div class="text-center mt-3">
            <button class="btn btn-primary" type="submit">Create Hospital</button>
         </div>
        </form>
    </div>

    <div class="col-sm-2 col-md-2 col-lg-2" *ngIf="hospital_create">
        <form [formGroup]="updatehospitalForm" (submit)="update_hospitalForm()">
            <div class="text-center">
                <h2 style="color: #ffff;">Update Hospital</h2>
            </div>
        <input class="form-control mb-2" type="text" placeholder="Enter Hospital Name" formControlName="hospitalname" pattern="[a-zA-Z '']*" maxlength="30">
        <div class="error" *ngIf="(updatehospitalForm.controls.hospitalname.touched||updatehospitalForm.controls.hospitalname.dirty )&&updatehospitalForm.controls.hospitalname.errors?.required">
            Name required
        </div>
        <div class="error" *ngIf="(updatehospitalForm.controls.hospitalname.touched||updatehospitalForm.controls.hospitalname.dirty )&&updatehospitalForm.controls.hospitalname.errors?.minlength">
            Minimum 3 characters required
        </div>
        <div class="error" *ngIf="(updatehospitalForm.controls.hospitalname.touched||updatehospitalForm.controls.hospitalname.dirty )&&updatehospitalForm.controls.hospitalname.errors?.pattern">
            Only letters are allowed
        </div>

        <input class="form-control mb-2" type="text" ng2TelInput
         (countryChange)="onCountryChange($event)"  placeholder="phonenumber" [ng2TelInputOptions]="{initialCountry: 'in'}" formControlName="phonenumber" maxlength="10" pattern="[0-9]*"/>
         
         <div class="error"
      *ngIf="(updatehospitalForm.controls.phonenumber.touched||updatehospitalForm.controls.phonenumber.dirty)&&updatehospitalForm.controls.phonenumber.errors?.required">
      Phone Number required</div>
      <div class="error"
      *ngIf="(updatehospitalForm.controls.phonenumber.touched||updatehospitalForm.controls.phonenumber.dirty)&&updatehospitalForm.controls.phonenumber.errors?.pattern">
      Only numbers are allowed
     </div>
         
         <input class="form-control mb-2 mt-2" type="text" placeholder="address1" formControlName="address1">
         <div class="error" *ngIf="(updatehospitalForm.controls.address1.touched||updatehospitalForm.controls.address1.dirty )&&updatehospitalForm.controls.address1.errors?.required">
            country required
        </div>
         <input class="form-control mb-2" type="text" placeholder="address2" formControlName="address2">
         <div class="error" *ngIf="(updatehospitalForm.controls.address2.touched||updatehospitalForm.controls.address2.dirty )&&updatehospitalForm.controls.address2.errors?.required">
            country required
        </div>
         <input class="form-control mb-2" type="text" placeholder="city" formControlName="city">
         <div class="error" *ngIf="(updatehospitalForm.controls.city.touched||updatehospitalForm.controls.city.dirty )&&updatehospitalForm.controls.city.errors?.required">
            country required
        </div>
         <input class="form-control mb-2" type="text" placeholder="state" formControlName="state">
         <div class="error" *ngIf="(updatehospitalForm.controls.state.touched||updatehospitalForm.controls.state.dirty )&&updatehospitalForm.controls.state.errors?.required">
            state required
        </div>
         <input class="form-control mb-2" type="text" placeholder="country" formControlName="country">

         <div class="error" *ngIf="(updatehospitalForm.controls.country.touched||updatehospitalForm.controls.country.dirty )&&updatehospitalForm.controls.country.errors?.required">
            country required
        </div>

         <input class="form-control mb-2" type="number" placeholder="pincode" formControlName="pincode" onKeyPress="if(this.value.length==6) return false;">
         
         <div class="error" *ngIf="(updatehospitalForm.controls.pincode.touched||updatehospitalForm.controls.pincode.dirty )&&updatehospitalForm.controls.pincode.errors?.required">
            pincode required
        </div>
        <div class="error"
        *ngIf="(updatehospitalForm.controls.pincode.touched||updatehospitalForm.controls.pincode.dirty)&&updatehospitalForm.controls.pincode.errors?.pattern">
        Only numbers are allowed
       </div>

         <div class="text-center mt-3">
            <button class="btn btn-primary" type="submit">Update Hospital</button>
         </div>
        </form>
    </div>
    
    <div class="col-sm-5 col-md-5 col-lg-5"></div>
</div>
