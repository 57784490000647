<div class="wrapper fadeInDown">
    <div id="formContent">
      <!-- Tabs Titles -->
  
      <!-- Icon -->
      <div class="fadeIn first">
        <img src="assets/ivital/LOGO.svg" id="icon" alt="ivital logo" />
        <!-- <h1>Aditya News</h1> -->
      </div>
  
      <!-- Login Form -->
      <div class="text-center logintext">Please Enter CODE</div>
      <form [formGroup]="loginForm" (submit)="save_loginForm()">
        <input type="text" id="login" class="fadeIn second" name="login" placeholder="   CODE " formControlName="code">
        <input type="submit" class="fadeIn fourth" value="Submit">
      </form>
  
      <!-- Remind Passowrd -->
      <!-- <div id="formFooter">
        <a class="underlineHover" href="#">Go to the Site</a>
      </div> -->


    </div>
</div>