<app-navbar class="mb-2"></app-navbar>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white"></p>
</ngx-spinner>

<form [formGroup]="date_Form" (submit)="date__form()">
<div class="row daterow">
    <div class="col-sm-12 col-md-12 col-lg-12 datetime">
       <div class="row p-2">
           <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3 p-2" style="border-right:2px solid rgb(119, 119, 119)">
               <span style="font-size: 18px;font-weight: 600;">{{pname}}&nbsp; /</span><br>
               <span>{{mr}}&nbsp;/&nbsp;{{dob}}</span>
           </div>
           <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3 p-2" style="border-right:2px solid rgb(119, 119, 119)">

            <div class="example-wrapper text-center">
               From : <kendo-datetimepicker [format]="format1" [(value)]="value1" formControlName="Date1"></kendo-datetimepicker>
            </div>

           </div>
           <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3 p-2 text-center" style="border-right:2px solid rgb(119, 119, 119)">

            <div class="example-wrapper">
               To : <kendo-datetimepicker [format]="format2" [(value)]="value2" formControlName="Date2"></kendo-datetimepicker>
            </div>

           </div>
           <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3 p-2">
               <div class="row">
                   <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                       <div class="row" *ngIf="printdownload">
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center"><img src="assets/download.svg" height="40" width="40" (click)="previewmultiline()" data-toggle="tooltip" title="Template"/></div>
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center"><img src="assets/print.svg" height="40" width="40" (click)="printmultiline()" data-toggle="tooltip" title="Print"/></div>
                       </div>

                   </div>
                   <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <button  style="width:100%;" type="submit" class="btn btn-primary">Get Data</button>
                   </div>
               </div>
           
           </div>
       </div>
    </div>
</div>
</form>

<div class="row">
    <div class="col-lg-12 pl-5 pr-5 pb-5 pt-3">
        <div id="container"></div>
    </div>
</div>

<div class="m-5">
    <div style="display: flex">
        <div [class.inactive]="Block==2"  [class.active]="Block==1" (click)="set_Block(1)" style="width: 10%;font-size: 1rem;padding: 0.5rem;">
            <p class="text-center" >mmol</p>
        </div>
    
        <div [class.inactive]="Block==1"  [class.active]="Block==2" (click)="set_Block(2)" style="width: 10%;font-size: 1rem;padding: 0.5rem;">
            <p class="text-center">mgdl</p>
        </div>
    </div>
    <div class="p-1">
        <br>
        <div id="glucometer"></div>
        <br>
        <div id="bp"></div>
    </div>
    <div style="text-align: center;">
        <span style="color: white;">{{bpdata}}</span>
    </div>
</div>
<br><br><br>


<!-- <div class="row">
    <div class="col-lg-12 pl-5 pr-5 pb-5 pt-3" id="container1" hidden="true">
        <div id="oxygen"></div>
        <div id="pulse"></div>
        <div id="temp"></div>
        <div id="resp"></div>
    </div>
</div> -->




