<div style="padding: 00.5rem;">
<div style="display: flex;justify-content: space-between;">
    <i class="fa fa-close" style="font-size:24px;visibility:hidden"></i>
    <p  style="font-size:1rem">Add/Update Comments</p>
    <!-- <i class="fa fa-close" style="font-size:24px"></i> -->
    <img style="width:1rem;cursor: pointer;" (click)="close()" src="assets/close.svg">

</div>

<textarea [(ngModel)]="comments" style="height: 10rem;width: 100%;margin-top: 0.5rem;">

</textarea>

<div style="display: flex;justify-content: center;margin-top: 0.5rem;">
<button class="btn" (click)="save()" style="background-color: #45c2c5;color:#fff">Save</button>
</div>
</div>