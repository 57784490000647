import { Component, VERSION ,OnInit } from '@angular/core';
import { PubNubAngular } from 'pubnub-angular2';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject} from '@angular/core';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private fb: FormBuilder , private appService: AppService, pubnub: PubNubAngular, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router , public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  logout(){
    const dialogRef = this.dialog.open(DialogOverviewExamplelogout , {
      width: '30%',
      data: {
        mypdfdata : ''
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        if(result == 'yes'){
          this.router.navigate(["/"])
        }
      }
    });
  }

}




export interface DialogDatapdf {
  mypdfdata:any
}

@Component({
  selector: 'logout',
  templateUrl:'logout.html',
  styleUrls: ['logout.scss']
})

export class DialogOverviewExamplelogout {

  urlPoints;
 

  token = sessionStorage.getItem("access_token");
  code = sessionStorage.getItem("code");

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExamplelogout>,private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDatapdf) {
      this.urlPoints = AppConfig.settings.EndPoints;
     
    }
  onNoClick(): void {
    this.dialogRef.close();
  }

  logout(data){
      if(data == 'yes'){
        this.dialogRef.close(data);
      }else{
        this.dialogRef.close(data);
      }
  }

  closepop(){
    this.dialogRef.close();
  }

}

