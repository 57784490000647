import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { PubNubAngular } from 'pubnub-angular2';

@Component({
    selector: 'app-chart2',
    templateUrl: './chart2.component.html',
    styleUrls: ['./chart2.component.scss'],
    providers: [PubNubAngular]

})
export class Chart2Component implements OnInit {

    pubnub: PubNubAngular;
    options;
    number = 0;
    sub;
    code;
    mrn;
    pi = 0.0;
    data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    count = 5;
    num = 0;
    nums = [];
    points = [];
    index = 0;
    message = false;
    running = true;
    runmessage;

    temp=0;

    constructor(pubnub: PubNubAngular) {
        this.pubnub = pubnub;
    }

    ngOnInit(): void {
        this.code = sessionStorage.getItem("code").toLowerCase();
        this.mrn = sessionStorage.getItem("mrn");
        console.log("code","mrn  chart 2", this.code , this.mrn)
        var that = this;

        var selectedChannel = 'get_message_' + this.code.toLowerCase();
        var hubChannel = 'receive_hub_data_' + this.code.toLowerCase();
        var refresh_channel = "refresh_hub_" + this.code.toLowerCase();
        var receivehub = "receive_hub_detail_"+this.code.toLowerCase();
        var disconnectedhub = "status_"+this.mrn.toLowerCase()+"_"+this.code.toLowerCase();

        this.pubnub.init({
            publishKey: 'demo',
            subscribeKey: 'demo',
            ssl: false
        });

        this.pubnub.subscribe({
            channels: [selectedChannel, hubChannel, refresh_channel , receivehub, disconnectedhub],
            withPresence: true,
            triggerEvents: ['message', 'presence', 'status' , 'hub']
        });

        this.pubnub.getStatus([selectedChannel, hubChannel, refresh_channel,disconnectedhub], (msg) => {
            //  console.log(msg);
        });

        this.pubnub.getPresence([selectedChannel, hubChannel, refresh_channel,disconnectedhub], (msg) => {
            //   console.log(msg);
        });

        this.options = {
            chart: {
                type: 'spline',
                marginRight: 10,
                backgroundColor: '#000000',

                events: {
                    load: function () {

                        that.pubnub.getMessage([receivehub], (msg) => {
                            that.temp++;
                            if (msg.message.patientID == that.mrn && msg.message.type == 0) {
                            }
                            if (msg.message.patientID == that.mrn && msg.message.type == 1) {
                                var arr;
                                arr = msg.message.plethysmograph.split(',');

                                for (var i = 0; i < arr.length; i++) {
                                    arr[i] = Number(arr[i])
                                }

                                for (var i = 7; i < that.data.length; i++) {
                                    that.data[i - 7] = that.data[i];
                                }

                                for (var i = 13; i < that.data.length; i++) {
                                    that.data[i] = arr[i - 13];
                                }


                                that.points = that.points.concat(arr)

                                that.message = true;

                                if(that.temp==1)
                                that.runmessage = setInterval(function(){ 
                                     if(that.message&&that.index<that.points.length)
                                     {
                                     that1.series[0].data[(that.index+100)%200].update({
                                         y: Number(that.points[that.index]),
                                     }, true, true);
         
                                     that1.series[0].data[(that.index + 101) % 200].update({
                                         y: null,
                                     }, true, false);
                                     that.index++;
                                     }else{
                                        
                                        console.log(that1.series[0].data.length);
                                        that1.series[0].data[(that.index+100)%200].update({
                                         y: 40,
                                     }, true, true);
         
                                     that1.series[0].data[(that.index + 101) % 200].update({
                                         y: null,
                                     }, true, false);
                                     
                                     }
                                 }, 26);
                            }

                        });

                        that.pubnub.getMessage([disconnectedhub], (msg) => {
                            if(msg.message.patientID==that.mrn&&msg.message.status=='disconnected')
                             {
                                console.log("cleared graph")
                                clearInterval(that.runmessage);
                                that.temp = 0;
                                that.data = [];
                                that.points = [];
                                that.index = 0;
                                var series = this.series[0];
                                
                                for (var k = 0; k <200; k++){
                                    that1.series[0].data[(k)].update({
                                        y: 40,
                                    }, true, false);
                                }
                            }
                        });
                        var that1 = this;
                     //   that.temp = "hello";
                        
                       

                    }
                }
            },

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    color: '#FA0404'
                },
            },

            time: {
                useUTC: false
            },

            title: {
                text: ''
            },

            accessibility: {
                announceNewData: {
                    enabled: true,
                    minAnnounceInterval: 15000,
                    announcementFormatter: function (allSeries, newSeries, newPoint) {
                        if (newPoint) {
                            return 'New point added. Value: ' + newPoint.y;
                        }
                        return false;
                    }
                }
            },

            xAxis: {
                type: 'datetime',
                tickPixelInterval: 150,
                visible: false,
            },

            yAxis: {
                gridLineWidth: 0.5  ,
                gridLineColor: '#FA0404',                
                title: {
                    useHTML: true,
                    text: 'PPG',
                    rotation: 0,
                    style: {
                        color: '#FA0404',
                        fontFamily: 'Montserrat'
                    },

                },

                labels: {
                    style: {
                        color: '#FA0404'
                    }
                },

                plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                }],
                visible: true,
                max: 100,
                min: 10,
                tickPixelInterval: 1,
            },

            tooltip: {
                headerFormat: '<b>{series.name}</b><br/>',
                pointFormat: '{point.y:.2f}',
                enabled: true,
                style:{
                    fontSize:'1.5vw'
                }
            },

            legend: {
                enabled: false
            },

            exporting: {
                enabled: false
            },

            credits: {
                enabled: false
            },

            series: [{
                name: '',
                enableMouseTracking: true,

                data: (function () {

                    var data = [],
                        time = (new Date()).getTime(),
                        i;

                    for (i = 0; i < 200; i += 1) {
                        data.push({
                            x: i,
                            y: 10
                        });
                    }
                    return data;
                }())
            }]
        }

        Highcharts.chart('container2', this.options);
    }

    ngOnDestroy() {
      
        clearInterval(this.runmessage)
        var selectedChannel = 'get_message_' + this.code.toLowerCase();
        var hubChannel = 'receive_hub_data_' + this.code.toLowerCase();
        var refresh_channel = "refresh_hub_" + this.code.toLowerCase();
        var receivehub = "receive_hub_detail_"+this.code.toLowerCase();
        this.pubnub.unsubscribe({
            channels: [selectedChannel, hubChannel, refresh_channel , receivehub],
        });
    }

}
