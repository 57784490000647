import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from '../app.config';
import { AppService } from '../app.service';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {
  questionnaire_data;
  date;
  code;
  mrn;
  urlPoints;
  auth = localStorage.getItem("authentication")
  patient;
  y
  m
  text;

  constructor(private router: Router, private appService: AppService, private toastr: ToastrService, public dialog: MatDialog) {
    this.urlPoints = AppConfig.settings.EndPoints;

  }

  ngOnInit(): void {
    this.questionnaire_data = JSON.parse(sessionStorage.getItem('question_data'));
    console.log(this.questionnaire_data.date)

    var date_array = this.questionnaire_data.date.split('-')

    var date_string = date_array[2] + ' ' + date_array[1] + ' ' + date_array[0]

    this.date = moment(date_string).format('Do MMM , YYYY');
    this.mrn = sessionStorage.getItem("mrn");
    this.code = sessionStorage.getItem("code").toLowerCase();

    this.patient = JSON.parse(sessionStorage.getItem('patient_data'))
    this.y = sessionStorage.getItem('year')
    this.m = sessionStorage.getItem('month')
  }

  openComments() {

    // console.log(this.questionnaire_data)

    const dialogRef = this.dialog.open(Dialog_comments, {
      width: '600px',
      data: { comments: this.questionnaire_data.notes ? this.questionnaire_data.notes : '' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // alert(result)
        console.log(result.mode == 1)

        console.log('here')
        var params = {
          code: this.code,
          mrn: this.mrn,
          followup_date: this.questionnaire_data.date,
          notes: result.text
        }


        this.appService.postData(this.urlPoints.updateNotes, params, this.auth).subscribe((response: any) => {
          console.log(response)
          this.toastr.success('Notes saved')
          this.questionnaire_data.notes = result.text

          sessionStorage.setItem('question_data', JSON.stringify(this.questionnaire_data))

        });
      }
    });
  }

  save()
  {

    console.log('here')

    var params = {
      code: this.code,
      mrn: this.mrn,
      followup_date: this.questionnaire_data.date,
      notes: this.text
    }


    this.appService.postData(this.urlPoints.updateNotes, params, this.auth).subscribe((response: any) => {
      console.log(response)
      this.toastr.success('Comment sent!')
      this.questionnaire_data.notes = this.text

      sessionStorage.setItem('question_data', JSON.stringify(this.questionnaire_data))
      this.text = ''

    });
  }
}


@Component({
  selector: 'comments',
  templateUrl: 'comments.html',
  styleUrls: ['comments.scss']

})

export class Dialog_comments {
  comments;
  

  constructor(
    public dialogRef: MatDialogRef<Dialog_comments>, private appService: AppService, public dialog: MatDialog, private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any, public router: Router) {

  }
  ngOnInit(): void {
    this.comments = this.data.comments
  }

  save() {
    this.dialogRef.close(
      { text: this.comments, mode: 1 });

  }

  close() {

    this.dialogRef.close();
  }

}


