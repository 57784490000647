import { Component, OnInit, Inject, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as Highcharts from 'highcharts';
import { ActivatedRoute, Router } from '@angular/router';
import { PubNubAngular } from 'pubnub-angular2';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { TransitionCheckState } from '@angular/material/checkbox';
import { DomSanitizer } from '@angular/platform-browser';
import { getDate } from "@progress/kendo-date-math";
import domtoimage from 'dom-to-image';
import * as XLSX from "xlsx";


// import { CdkDragMove } from '@angular/cdk/drag-drop';
// import { AfterViewInit, ElementRef, NgZone, ViewChild } from '@angular/core';
import {
  connect,
  createLocalVideoTrack,
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteVideoTrack,
  Room
} from 'twilio-video';

import { Nullable } from './types';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, _countGroupLabelsBeforeOption } from '@angular/material/core';
import { JsonPipe } from '@angular/common';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from 'src/app/multilinechart/multilinechart.component';

declare var require: any;
const More = require('highcharts/highcharts-more');
More(Highcharts);

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
  providers: [PubNubAngular]
})
export class ChartsComponent implements OnInit {

  // @ViewChild('resizeBox') resizeBox: ElementRef;
  // @ViewChild('dragHandleCorner') dragHandleCorner: ElementRef;
  // @ViewChild('dragHandleRight') dragHandleRight: ElementRef;
  // @ViewChild('dragHandleBottom') dragHandleBottom: ElementRef;
  @ViewChild('resizeBox') resizeBox: ElementRef;
  @ViewChild('dragHandleCorner') dragHandleCorner: ElementRef;
  @ViewChild('dragHandleRight') dragHandleRight: ElementRef;
  @ViewChild('dragHandleBottom') dragHandleBottom: ElementRef;


  code: any;
  mrn: any;
  Account_id;
  MR;

  urlPoints;
  locParams;
  listParams;
  patientList;
  index;
  patient;
  y;
  m;
  lat: any;
  long: any
  src;
  full = false;
  pubnub: PubNubAngular;
  sub;
  latstr;
  locArray;
  lat_long;
  detail = true;
  list = false;
  data = false;
  temperature;
  vitals;
  rox;
  s_id;
  access_token;
  patientemail;
  account_id;
  room_name;
  url;
  video = false;
  gettemp;
  token = localStorage.getItem("authentication");
  Video;
  localroom;
  peertopeervideocall;

  width = '430';
  height = '430';

  max;
  min;

  showmax = true;
  showmin = false;

  options: any;
  options1: any;

  floatingvideoscreen = false;
  informstatus = true;

  connectpeer;
  room;
  accesstoken;

  bpivitaldata;
  glucoivitaldata;

  uploadedimg;
  files: any = [];

  audio;


  // epicaretoken = "b2F1dGhfdG9rZW49aWJrOEpYUXJUbVgzbk9ra2tIb3gmb2F1dGhfdG9rZW5fc2VjcmV0PU1RQnFOenJ3MGNZQzFPZ0huWmNmJmFjY291bnRfaWQ9cng0JTQwcnhjYXJlbmV0Lm9yZw=="
  epicaretoken = localStorage.getItem("authentication");


  systolicdata
  diastolicdata
  gluco_date
  bp_date
  glucometer_mmol
  glucometer_mgol
  auth = localStorage.getItem("authentication")

  constructor(private window: Window, private appService: AppService, pubnub: PubNubAngular, private ngZone: NgZone, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router, private toastr: ToastrService, public dialog: MatDialog) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.pubnub = pubnub;

    // https://ivital.co/graph/#/charts?code=code&mrn=MR61201

    this.sub = this.route.queryParams
      .subscribe(params => {
        console.log("params 90 90", params);
        sessionStorage.setItem("code", params.code);
        sessionStorage.setItem("mrn", params.mrn);

        this.code = sessionStorage.getItem("code").toLowerCase();
        this.mrn = sessionStorage.getItem("mrn");
        this.connectpeer = params.mode;
        this.accesstoken = params.accesstoken;
        this.room = params.room;
      });
    console.log("my code", this.code, this.mrn)

    sessionStorage.setItem('access_token', this.access_token);
    sessionStorage.setItem('room_name', this.room_name);

    if (this.connectpeer == 'connect') {
      this.floatingvideoscreen = true;
      this.peertopeervideocall = this.dom.bypassSecurityTrustResourceUrl('https://exp.epicare.net:8000/room/' + this.room + '/' + this.accesstoken);

    }



    this.pubnub1();
    this.pubnub2();


  }

  pubnub2() {
    var account_id = sessionStorage.getItem("acc_email")
    var channel = account_id.replace('@', '_').replace('.org', '');
    var uuid = account_id
    var doctor_id = sessionStorage.getItem("doc_id")
    this.pubnub.init({
      publishKey: 'pub-c-c3cecd91-62bc-4365-b2d1-a6333576f17f',
      subscribeKey: 'sub-c-60c79014-12d7-11e8-b32f-5ea260837941',
      uuid: uuid
    });

    this.pubnub.subscribe({
      channels: [channel, channel, 'get_banner_' + channel, 'videocall_' + channel + '_' + doctor_id, 'peer_room_' + account_id + '_' + doctor_id, 'peer_room_status_' + account_id + '_' + doctor_id, 'vital_criticalityscore_' + channel, 'voice_' + uuid + '_' + doctor_id, 'room_' + uuid + '_' + doctor_id],
      withPresence: true,
      triggerEvents: ['callback', 'message', 'presence']
    });

    this.pubnub.addListener({
      message: msg => {
        console.log("135", msg);
        if (msg) {
          console.log("Get message from Channel****", msg);
          var split_Channel_Name = msg.channel.split("_");
          console.log(split_Channel_Name[1])
          if (split_Channel_Name[1] == 'room') {
            console.log("***Room channel")
          }

        }


      }
    })

  }

  // callpeertopeerlist(){
  //   var type:any = sessionStorage.getItem("is_live")
  //   var peertopeerparams = {
  //     "account_email" : this.account_email,
  //     "code": this.code,
  //     "doctor_id": this.doctor_id
  //   }
  //   if(type == 0){
  //     this.appService.postecData( environment.epic+"/service/getpeerforaccesscode", peertopeerparams , this.epicaretoken).subscribe((response: any) => {
  //       if(response.success == 1){
  //         for(var i=0;i< response.details.length;i++){
  //            this.peerlist.push(response.details[i]);
  //         }
  //       }
  //       console.log("resonse peer", this.peerlist);
  //     });
  //   }else if (type == 1){
  //     this.appService.postecData( environment.epic_dev+"/service/getpeerforaccesscode", peertopeerparams , this.epicaretoken).subscribe((response: any) => {
  //       if(response.success == 1){
  //         for(var i=0;i< response.details.length;i++){
  //           this.peerlist.push(response.details[i]);
  //        }
  //      }
  //     });
  //   }

  // }

  pubnub1() {
    var that = this;
    var selectedChannel = 'get_message_' + this.code.toLowerCase();
    var hubChannel = 'receive_hub_data_' + this.code.toLowerCase();
    var refresh_channel = "refresh_hub_" + this.code.toLowerCase();
    var receivehub = "receive_hub_detail_" + this.code.toLowerCase();
    var disconnectedhub = "status_" + this.mrn.toLowerCase() + "_" + this.code.toLowerCase();

    this.pubnub.init({
      publishKey: 'demo',
      subscribeKey: 'demo',
      ssl: false
    });

    this.pubnub.subscribe({
      channels: [selectedChannel, hubChannel, refresh_channel, receivehub, disconnectedhub],
      withPresence: true,
      triggerEvents: ['message', 'presence', 'status', "hub"]
    });

    this.pubnub.getMessage([hubChannel], (msg) => {
      if (msg.message.patientID == that.mrn && msg.message.type == 0) {
        this.data = true;
        if (this.gettemp == 'c' && msg.message.temp != '') {
          this.temperature = msg.message.temp + "°C";
        } else if (this.gettemp == 'f' && msg.message.tempf != '') {
          this.temperature = msg.message.tempf + "°F";
        }
        that.vitals = msg.message;
        // console.log("mes", msg.message);
        if (that.vitals.rox != "") {
          that.rox = that.vitals.rox;
        }
      }
    });

    that.pubnub.getMessage([disconnectedhub], (msg) => {
      //console.log(msg);
      if (msg.message.patientID == that.mrn && msg.message.status == 'disconnected') {
        this.data = false;
        this.temperature = "";
        that.vitals = "";
        that.rox = "";
      }
    });

    this.pubnub.getStatus([selectedChannel, hubChannel, refresh_channel, receivehub, disconnectedhub], (msg) => {
      //  console.log(msg);
    });

    this.pubnub.getPresence([selectedChannel, hubChannel, refresh_channel, disconnectedhub], (msg) => {
      //  console.log(msg);
    });
  }

  ngOnInit(): void {

    var SearchDataparams = {
      'mr_number': sessionStorage.getItem("mr").toUpperCase(),
      "account_email": sessionStorage.getItem("acc_email")
    };

    console.log("279 SearchDataparams", SearchDataparams)
    var type: any = sessionStorage.getItem("is_live")
    if (type == 0) {
      this.appService.postecData(environment.epic + "/service/search", SearchDataparams, this.token).subscribe((response: any) => {
        console.log("285 res", response[0].email)
        this.glucodata(SearchDataparams, response[0].email)

      })

    } else if (type == 1) {
      this.appService.postecData(environment.epic_dev + "/service/search", SearchDataparams, this.token).subscribe((response: any) => {
        console.log("288 res", response[0].email)
        this.glucodata(SearchDataparams, response[0].email)

      })
    }

    var gettempParams = {
      code: this.code.toUpperCase()
    }

    this.appService.getData(this.urlPoints.gettempunit, gettempParams).subscribe((response: any) => {
      if (response.settings.success == 1) {
        this.gettemp = response.settings.temp_unit;
        console.log("this.gettemp", this.gettemp)
      }
    });

    this.locParams = {
      'code': this.code
    }

    this.listParams = {
      'mode': '1',
      'params': this.code,
      'dstate': '1'
    }
    this.getDetails();
    this.getLocation(this.mrn);
  }

  ausculation(getMR) {

    const dialogRef = this.dialog.open(DialogOverviewAusculation, {
      width: '25%',
      height: '28%',
      data: {
        account_email: sessionStorage.getItem("acc_email"),
        patient_email: getMR,
      }
    });
  }

  checkFollowUp() {

    var params = {
      code: this.code,
      mrn: this.mrn
    }

    console.log(params)

    this.appService.postData(this.urlPoints.getFollowUp, params, this.auth).subscribe((response: any) => {
      if (response.success == 2) {

        this.toastr.error('Patient has no follow up data. Please update in iVital+', '', {
          timeOut: 3000,
        }

        );
      }
      else if (response.success == 1) {
        console.log(this.patient)
        sessionStorage.setItem('followup_data', JSON.stringify(response.details))
        sessionStorage.setItem('patient_data', JSON.stringify(this.patient))
        sessionStorage.setItem('year', this.y)
        sessionStorage.setItem('month', this.m)

        this.router.navigate(["/followup"])

      }
    });


  }

  glucodata(param, email) {
    var type: any = sessionStorage.getItem("is_live")
    var auth = localStorage.getItem("authentication")

    var data = {
      "account_email": param.account_email,
      "patient_email": email
    }
    if (type == 0) {
      this.appService.getecData(environment.epic + "/service/getlatestvitalvalues", data, auth).subscribe((response: any) => {
        console.log('324', response);
        var datas = response[0];
        this.systolicdata = Math.trunc(datas.systolic);
        this.diastolicdata = Math.trunc(datas.diastolic);
        this.gluco_date = moment(datas.gluco_date).format('DD MMM YYYY HH:mm:ss')
        this.bp_date = moment(datas.bp_date).format('DD MMM YYYY HH:mm:ss')
        this.glucometer_mmol = datas.glucometer.mmol_value
        this.glucometer_mgol = datas.glucometer.mgol_value

        console.log(this.systolicdata, this.diastolicdata, this.gluco_date, this.bp_date, this.glucometer_mmol, this.glucometer_mgol)

      })

    } else if (type == 1) {
      this.appService.getecData(environment.epic_dev + "/service/getlatestvitalvalues", data, auth).subscribe((response: any) => {
        console.log('324', response);
        var datas = response[0];
        this.systolicdata = datas.systolic
        this.diastolicdata = datas.diastolic
        this.gluco_date = datas.gluco_date
        this.bp_date = datas.bp_date
        this.glucometer_mmol = datas.glucometer.mmol_value
        this.glucometer_mgol = datas.glucometer.mgol_value

        console.log(this.systolicdata, this.diastolicdata, this.gluco_date, this.bp_date, this.glucometer_mmol, this.glucometer_mgol)


      })
    }
  }

  getDetails() {
    this.appService.getData(this.urlPoints.ListData, this.listParams).subscribe((response: any) => {
      this.patientList = response.patient[0].response;
      this.getIndex(this.mrn);
    });

  }

  getLocation(mrn) {
    this.lat = undefined;
    this.long = undefined;
    this.locParams.pid = mrn;
    this.appService.getData(this.urlPoints.LocData, this.locParams).subscribe((response: any) => {
      this.locArray = response.ivital[0].response;
      if (this.locArray != undefined) {
        this.latstr = this.locArray[this.locArray.length - 1].location;

        var lat1 = this.latstr.slice(1, -1);

        this.lat_long = lat1.split(',')

        this.lat = Number(this.lat_long[0]);
        this.long = Number(this.lat_long[1]);
        console.log("at map1")
        this.src = "https://maps.google.com/maps?q=" + this.lat + "," + this.long + "&z=16&output=embed";
        this.src = this.dom.bypassSecurityTrustResourceUrl(this.src);
      }

      else {
        console.log("at map2")
        this.src = "https://maps.google.com/maps?q=" + "1" + "," + "1" + "&z=16&output=embed";
        this.src = this.dom.bypassSecurityTrustResourceUrl(this.src);
      }

    });
  }

  getIndex(mrn) {
    for (var i = 0; i < this.patientList.length; i++) {
      if (this.patientList[i].patient_id == mrn) {
        this.index = i;
      }
    }
    this.getPatient();



    //  alert(this.index)
  }

  getPatient() {

    if (this.index == 0) {

    }
    this.patient = this.patientList[this.index]
    console.log("patient", this.patient)
    sessionStorage.setItem("v1", this.patient.hr_thresh_high);
    sessionStorage.setItem("v2", this.patient.hr_thresh_low)
    sessionStorage.setItem("v3", this.patient.spo2_thresh_low)
    sessionStorage.setItem("v4", this.patient.rr_thresh_high)
    sessionStorage.setItem("v5", this.patient.rr_thresh_low)
    sessionStorage.setItem("v6", this.patient.thresh_high)
    sessionStorage.setItem("v7", this.patient.thresh_low)

    if (this.patientList[this.index].sex == "0") {
      this.patientList[this.index].gender = "M"
    }
    else {
      this.patientList[this.index].gender = "F"
    }

    var timeStamp = this.patientList[this.index].dob
    var timeStamp_ms = timeStamp * 1000;
    var date_ob = new Date(timeStamp_ms);
    var birth_year = date_ob.getFullYear();
    var birth_month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    var birth_day = ("0" + date_ob.getDate()).slice(-2);
    var birthDay = birth_month + "/" + birth_day + "/" + birth_year;
    var DOB = new Date(birthDay);
    var today = new Date();
    var age = today.getTime() - DOB.getTime();
    var elapsed = new Date(age);
    var year = elapsed.getFullYear() - 1970;
    var month = elapsed.getMonth();
    var day = elapsed.getDay();
    var ageTotal = " " + year + " Years " + month + " Months"
    this.m = month;
    this.y = year;
    this.patientList[this.index].m = month;
    this.patientList[this.index].y = year;

    this.getLocation(this.patientList[this.index].patient_id);
  }

  getMRN() {
    this.router.navigate(["/charts"], { queryParams: { code: this.code, mrn: this.patientList[this.index].patient_id } })
      .then(() => {
        window.location.reload();
      });
  }

  prev() {
    if (this.index != 0) {
      this.index--;
      this.getPatient();
      this.getMRN();
    }
  }

  next() {
    if (this.index != this.patientList.length) {
      this.index++;
      this.getPatient();
      this.getMRN();
    }
  }

  informclose() {
    this.informstatus = false;
  }

  openData() {
    const dialogRef = this.dialog.open(DialogOverviewExampleDataTable, {
      width: '90vw',
      panelClass: 'multiline',
      // height: '90vh',
      data: {
        account_email: sessionStorage.getItem("acc_email"),
        code: this.code,
        mrn: this.mrn
      }
    });
  }

  fullscreen() {
    var container = document.getElementById("charts") as HTMLElement
    var body = document.getElementById("body") as HTMLBodyElement;
    var row = document.getElementById("row") as HTMLElement;
    var icon = document.getElementById("icon") as HTMLImageElement

    if (!this.full) {
      this.full = true;
      icon.src = "assets/minimize1.svg"
      container.classList.remove("col-lg-9");

      container.classList.remove("col-md-9");

      container.classList.remove("col-sm-9");

      container.classList.remove("col-xs-9");

      container.style.width = "100vw"

      body.classList.remove("half");
      body.classList.add("full");

      row.style.marginLeft = "0";
      row.style.marginRight = "0";
    }
    else {

      icon.src = "assets/full-size.svg"


      this.full = false;

      container.classList.add("col-lg-9");

      container.classList.add("col-md-9");

      container.classList.add("col-sm-9");

      container.classList.add("col-xs-9");

      body.classList.remove("full");
      body.classList.add("half");

      row.style.marginLeft = "-15";
      row.style.marginRight = "-15";
    }
  }

  viewall() {

    for (var i = 0; i < this.patientList.length; i++) {
      if (this.patientList[i].sex == "0") {
        this.patientList[i].gender = "M"
      }
      else {
        this.patientList[i].gender = "F"
      }

      var timeStamp = this.patientList[i].dob
      var timeStamp_ms = timeStamp * 1000;
      var date_ob = new Date(timeStamp_ms);
      var birth_year = date_ob.getFullYear();
      var birth_month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
      var birth_day = ("0" + date_ob.getDate()).slice(-2);
      var birthDay = birth_month + "/" + birth_day + "/" + birth_year;
      var DOB = new Date(birthDay);
      var today = new Date();
      var age = today.getTime() - DOB.getTime();
      var elapsed = new Date(age);
      var year = elapsed.getFullYear() - 1970;
      var month = elapsed.getMonth();
      var day = elapsed.getDay();
      var ageTotal = " " + year + " Years " + month + " Months"
      this.m = month;
      this.y = year;
      this.patientList[i].m = month;
      this.patientList[i].y = year;
    }
    this.detail = false;
    this.list = true;
  }

  detailView(i) {
    this.detail = true;
    this.list = false;
    this.getIndex(i.patient_id);

    this.router.navigate(["/charts"], { queryParams: { code: this.code, mrn: this.patientList[this.index].patient_id } })
      .then(() => {
        window.location.reload();
      });
  }

  toggle() {
    this.detail = true;
    this.list = false;
  }

  navigate(i) {
    // alert(i)

    if (!this.floatingvideoscreen) {
      if (i == 'home') {
        this.router.navigate(["/home"])
      }

      if (i == 'follow') {
        // this.router.navigate(["/home"])
        this.checkFollowUp()
      }

      if (i == 'line') {

        this.router.navigate(["/multiline"])

      }//notification

      if (i == 'security') {

        this.router.navigate(["/security"])

      }


      if (i == 'settings') {

        this.router.navigate(["/settings"])

      }

      if (i == 'notification') {

        this.router.navigate(["/notification"])

      }
    }
    else {
      const dialogRef = this.dialog.open(DialogOverviewExampleconfirm, {
        width: '30%',
        data: {
          mypdfdata: ''
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          if (result == 'yes') {

            var params = {
              "account_email": sessionStorage.getItem("acc_email"),
              "patient_email": sessionStorage.getItem("patientemail"),
              "a_type": "instant",
              "doctor_id": "1",
              "code": this.code
            }


            var type: any = sessionStorage.getItem("is_live")

            if (type == 0) {
              this.appService.postecData(environment.epic + "/service/endvitalpeercall", params, this.token).subscribe((response: any) => {
                if (response.success == 1) {
                  this.toastr.success(response.message)
                }
              })
            } else if (type == 1) {
              this.appService.postecData(environment.epic_dev + "/service/endvitalpeercall", params, this.token).subscribe((response: any) => {
                if (response.success == 1) {
                  this.toastr.success(response.message)
                }
              })
            }

            if (i == 'home') {
              this.router.navigate(["/home"])
            }

            if (i == 'follow') {
              // this.router.navigate(["/home"])
              this.checkFollowUp()
            }

            if (i == 'line') {

              this.router.navigate(["/multiline"])

            }//notification

            if (i == 'security') {

              this.router.navigate(["/security"])

            }


            if (i == 'settings') {

              this.router.navigate(["/settings"])

            }

            if (i == 'notification') {

              this.router.navigate(["/notification"])

            }

            // this.router.navigate(["/"])
          }
        }
      });
    }
  }

  //new try

  // peer_to_peercall(){
  //   navigator.mediaDevices.getUserMedia({video: true, audio: true}).then( stream => {
  //     this.callpeertopeer();
  //   }).catch( err => {
  // console.log("u got an error:" + err)
  //   const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
  //     width: '40%',
  //     data: {
  //       camdata : err
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //      console.log('The dialog was closed');
  //    });
  //  });
  // }

  maxi(data) {
    console.log("data min max", data);
    if (data == 'max') {
      this.showmax = false;
      this.showmin = true;
      this.height = "700"
      this.width = "1200"
    } else if (data == 'min') {
      this.showmax = true;
      this.showmin = false;
      this.height = "430"
      this.width = "430"
    }
  }

  removepeer() {
    this.floatingvideoscreen = false;
    this.router.navigate(["/charts"], {
      queryParams: {
        code: this.code,
        mrn: this.mrn,
      }
    })


    var params = {
      "account_email": sessionStorage.getItem("acc_email"),
      "patient_email": sessionStorage.getItem("patientemail"),
      "a_type": "instant",
      "doctor_id": "1",
      "code": this.code
    }

    var type: any = sessionStorage.getItem("is_live")

    if (type == 0) {
      this.appService.postecData(environment.epic + "/service/endvitalpeercall", params, this.token).subscribe((response: any) => {
        if (response.success == 1) {
          this.toastr.success(response.message)
        }
      })
    } else if (type == 1) {
      this.appService.postecData(environment.epic_dev + "/service/endvitalpeercall", params, this.token).subscribe((response: any) => {
        if (response.success == 1) {
          this.toastr.success(response.message)
        }
      })
    }


  }

  openMultiline() {
    const dialogRef = this.dialog.open(Dialog_multiline, {
      width: '90vw',
      panelClass: 'multiline',
      // height: '90vh',
      data: {
        // account_email: sessionStorage.getItem("acc_email"),
        // patient_email: getMR,
      }
    });
  }



  callpeertopeer() {

    var type: any = sessionStorage.getItem("is_live")
    this.Account_id = sessionStorage.getItem("acc_email")
    this.MR = sessionStorage.getItem("mr");
    console.log("this.Account_id,this.MR", this.Account_id, this.MR);
    var SearchDataparams = {
      'mr_number': this.MR.toUpperCase(),
      "account_email": this.Account_id
    };
    console.log("paarms", SearchDataparams);
    if (type == 0) {
      this.appService.postecData(environment.epic + "/service/search", SearchDataparams, this.token).subscribe((response: any) => {
        console.log("P2P", response);

        if (response[0]) {
          this.patientemail = response[0].email;
          this.account_id = response[0].account_id;
          console.log(" this.patientemail", this.patientemail, "this.account_id", this.account_id);
          sessionStorage.setItem("patientemail", response[0].email)

          var audio_forallparams = {
            "code": this.code.toUpperCase()
          }
          this.appService.getData(this.urlPoints.getalert, audio_forallparams).subscribe((response: any) => {
            console.log("response 350", response);
            if (response.settings.alerts[3].alert_name == "Outgoing Calls") {
              var audiostring = response.settings.alerts[3].audio_url
              console.log("audiostring", audiostring);
              this.audio = new Audio();
              this.audio.src = audiostring;
              this.audio.load();
              this.audio.play();
            }
          });

          this.Videocalls(this.Account_id, this.account_id, this.patientemail);
        } else {
          this.toastr.error('Peer To Peer Video call is NOT availabe for this patient');
          console.log("peer 1")
        }
      });
    } else if (type == 1) {
      this.appService.postecData(environment.epic_dev + "/service/search", SearchDataparams, this.token).subscribe((response: any) => {
        console.log("P2P", response);
        if (response[0]) {
          this.patientemail = response[0].email;
          this.account_id = response[0].account_id;

          sessionStorage.setItem("patientemail", response[0].email)
          var audio_forallparams = {
            "code": this.code.toUpperCase()
          }
          this.appService.getData(this.urlPoints.getalert, audio_forallparams).subscribe((response: any) => {
            console.log("response 350", response);
            if (response.settings.alerts[3].alert_name == "Outgoing Calls") {
              var audiostring = response.settings.alerts[3].audio_url
              console.log("audiostring", audiostring);
              this.audio = new Audio();
              this.audio.src = audiostring;
              this.audio.load();
              this.audio.play();
            }
          });

          this.Videocalls(this.Account_id, this.account_id, this.patientemail);
        } else {
          this.toastr.error('Peer To Peer Video call is NOT availabe for this patient');
          console.log("peer 2")
        }
      });
    }
  }


  Videocalls(carenetid, a_id, patientemail) {

    var type: any = sessionStorage.getItem("is_live")
    var doctor_id = sessionStorage.getItem("doc_id");
    var code = sessionStorage.getItem("code")
    var ediocallparams = {
      "account_email": carenetid,
      "patient_email": patientemail,
      "a_type": "instant",
      "a_id": a_id,
      "doctor_id": doctor_id,
      "code": code,
      "mode": "peer"
    }
    if (type == 0) {
      console.log("468 params", ediocallparams);
      this.appService.postecData(environment.epic + "/service/createvitallinstantroom", ediocallparams, this.token).subscribe((response: any) => {
        console.log("460", response);
        this.audio.pause();
        this.audio.currentTime = 0;
        this.floatingvideoscreen = true;

        // this.video = true;
        console.log("response 471 471", response.room_name, " ", response.access_token);
        // window.open('https://exp.epicare.net:8000/room/'+response.room_name+'/'+response.access_token,'_parent');
        this.peertopeervideocall = this.dom.bypassSecurityTrustResourceUrl('https://exp.epicare.net:8000/room/' + response.room_name + '/' + response.access_token);

      });
    } else if (type == 1) {
      this.appService.postecData(environment.epic_dev + "/service/createvitallinstantroom", ediocallparams, this.token).subscribe((response: any) => {
        console.log("460", response);
        this.audio.pause();
        this.audio.currentTime = 0;
        this.floatingvideoscreen = true;

        // this.video = true;
        console.log("response 471 471", response.room_name, " ", response.access_token);
        // window.open('https://exp.epicare.net:8000/room/'+response.room_name+'/'+response.access_token,'_parent');
        this.peertopeervideocall = this.dom.bypassSecurityTrustResourceUrl('https://exp.epicare.net:8000/room/' + response.room_name + '/' + response.access_token);

        // console.log("387", response);
        // this.video = true;
        // localStorage.setItem("accesstoken" , response.access_token)
        // localStorage.setItem("room_name", response.room_name);
        // localStorage.setItem("s_id", response.s_id)
      });
    }

  }



  //end new try


  //peertopeer videocall
  peertopeercall() {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
      this.peertopeer();
    }).catch(err => {
      console.log("u got an error:" + err)
      const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
        width: '40%',
        data: {
          camdata: err
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    });

  }

  peertopeer() {
    var type: any = sessionStorage.getItem("is_live")
    this.Account_id = sessionStorage.getItem("acc_email")
    this.MR = sessionStorage.getItem("mr");
    console.log("this.Account_id,this.MR", this.Account_id, this.MR);
    var SearchDataparams = {
      'mr_number': this.MR.toUpperCase(),
      "account_email": this.Account_id
    };
    console.log("paarms", SearchDataparams);
    if (type == 0) {
      this.appService.postecData(environment.epic + "/service/search", SearchDataparams, this.token).subscribe((response: any) => {
        console.log("P2P", response);
        if (response[0]) {
          this.patientemail = response[0].email
          this.account_id = response[0].account_id

          console.log(" this.patientemail", this.patientemail, "this.account_id", this.account_id);
          this.Video_call(this.Account_id, this.account_id, this.patientemail);
        } else {
          this.toastr.error('Peer To Peer Video call is NOT availabe for this patient');
          console.log("peer 3")
        }
      });
    } else if (type == 1) {
      this.appService.postecData(environment.epic_dev + "/service/search", SearchDataparams, this.token).subscribe((response: any) => {
        console.log("P2P", response);
        if (response[0]) {
          this.patientemail = response[0].email
          this.account_id = response[0].account_id
          this.Video_call(this.Account_id, this.account_id, this.patientemail);
        } else {
          this.toastr.error('Peer To Peer Video call is NOT availabe for this patient');
          console.log("peer 4")
        }
      });
    }
  }

  Video_call(carenetid, a_id, patientemail) {
    var type: any = sessionStorage.getItem("is_live")
    var doctor_id = sessionStorage.getItem("doc_id");
    var code = sessionStorage.getItem("code")
    var ediocallparams = {
      "account_email": carenetid,
      "patient_email": patientemail,
      "a_type": "instant",
      "a_id": a_id,
      "doctor_id": doctor_id,
      "code": code
    }
    if (type == 0) {
      // this.appService.postecData( environment.epic+"/service/notifybyvoice", ediocallparams , this.token).subscribe((response: any) => {
      //   console.log("callby voice", response);
      // });
      this.appService.postecData(environment.epic + "/service/createvitallinstantroom", ediocallparams, this.token).subscribe((response: any) => {
        console.log("383", response);
        this.video = true;
        localStorage.setItem("accesstoken", response.access_token)
        localStorage.setItem("room_name", response.room_name);
        localStorage.setItem("s_id", response.s_id)
      });
    } else if (type == 1) {
      // this.appService.postecData( environment.epic_dev+"/service/notifybyvoice", ediocallparams , this.token).subscribe((response: any) => {
      //   console.log("callby voice", response);
      // });
      this.appService.postecData(environment.epic_dev + "/service/createvitallinstantroom", ediocallparams, this.token).subscribe((response: any) => {
        console.log("387", response);
        this.video = true;
        localStorage.setItem("accesstoken", response.access_token)
        localStorage.setItem("room_name", response.room_name);
        localStorage.setItem("s_id", response.s_id)
      });
    }

  }


  // uploadreport(){
  //   const dialogRef = this.dialog.open(DialogOverviewExampleuploadreport  , {
  //     width: '50%',
  //     data: {
  //       mypdfdata : ''
  //     }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     var type:any = sessionStorage.getItem("is_live")
  //     this.Account_id  = sessionStorage.getItem("acc_email");
  //     this.MR = sessionStorage.getItem("mr");

  //     if (result) {
  //      console.log("result 806", result)

  //      var SearchDataparams = {
  //            'mr_number': this.MR.toUpperCase(),
  //            "account_email": this.Account_id  
  //      };
  //      console.log("paarms", SearchDataparams);
  //      if(type == 0){
  //        this.appService.postecData( environment.epic+"/service/search", SearchDataparams , this.token).subscribe((response: any) => {

  //          if(response[0]){
  //             this.patientemail = response[0].email
  //             this.account_id = response[0].account_id

  //             for (var i = 0; i < result.fileList.length; i++) {
  //               console.log('starts with', result.fileList[i].type)

  //               this.appService.postimgData2('https://test.epicare.net', result.fileList[i].type.startsWith('image') ? this.urlPoints.imageUpload : this.urlPoints.pdfUpload, result.fileList[i], this.token, result.fileList[i].type.startsWith('image') ? 1 : 2).subscribe((response: any) => {
  //                 this.uploadedimg = response.body;

  //                 if (this.uploadedimg) {
  //                   if (this.uploadedimg.image)
  //                     this.files.push(this.uploadedimg.image[0].filename)
  //                   else
  //                     this.files.push(this.uploadedimg.pdf[0].filename)
  //                 }

  //                 if (this.files.length == result.fileList.length) {

  //                   var params = {
  //                     report: this.files,
  //                     description: result.description,
  //                     comments: result.comments,
  //                     lab_test: result.tests.split(','),
  //                     account_email: this.Account_id,
  //                     patient_email: this.patientemail,
  //                     title: result.title,
  //                     category: result.abnormal ? 'abnormal' : "seen"

  //                   }
  //                      console.log("params 847", params);
  //                   this.appService.postecData(environment.epic+'/service/createlabreport', params, this.token).subscribe((response: any) => {
  //                      alert('848');
  //                     // if (this.filter_type == 1) {
  //                     //   var params_labreports = {
  //                     //     account_email: this.account_id,
  //                     //     patient_email: this.patientemail,
  //                     //   }

  //                     //   this.labreports(this.urlPoints.labReports, params_labreports, this.token)
  //                     // }

  //                     // if (this.filter_type == 2) {
  //                     //   var params_labreports2 = {
  //                     //     account_email: this.account_id,
  //                     //     patient_email: this.patientemail,
  //                     //     category: 'Unseen'
  //                     //   }

  //                     //   this.labreports2(this.urlPoints.labReports, params_labreports2, this.token)
  //                     // }

  //                     // if (this.filter_type == 3) {
  //                     //   var params_labreports3 = {
  //                     //     account_email: this.account_id,
  //                     //     patient_email: this.patientemail,
  //                     //     category: 'abnormal'
  //                     //   }

  //                     //   this.labreports2(this.urlPoints.labReports, params_labreports3, this.token)
  //                     // }
  //                   });
  //                 }

  //               });
  //             }


  //          }
  //        });
  //      }else if (type == 1){
  //        this.appService.postecData( environment.epic_dev+"/service/search", SearchDataparams , this.token).subscribe((response: any) => {
  //          console.log("P2P", response);
  //          if(response[0]){
  //             this.patientemail = response[0].email
  //             this.account_id = response[0].account_id


  //          }
  //        });
  //      }

  //     }
  //   });
  // }

  viewcharts() {
    const dialogRef = this.dialog.open(DialogOverviewExampleviewchart, {
      width: '100%',
      height: '90%',
      data: {
        mypdfdata: ''
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      var type: any = sessionStorage.getItem("is_live")
      this.Account_id = sessionStorage.getItem("acc_email");
      this.MR = sessionStorage.getItem("mr");

      if (result) {



      }

    });
  }

  viewreport() {
    const dialogRef = this.dialog.open(DialogOverviewExampleviewreport, {
      width: '95vw',
      height: '80vh',
      // height: '80%',
      data: {
        mypdfdata: ''
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      var type: any = sessionStorage.getItem("is_live")
      this.Account_id = sessionStorage.getItem("acc_email");
      this.MR = sessionStorage.getItem("mr");

      if (result) {



      }

    });
  }


  logout() {
    const dialogRef = this.dialog.open(DialogOverviewExamplelogout, {
      width: '30%',
      data: {
        mypdfdata: ''
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result == 'yes') {

          var params = {
            "account_email": sessionStorage.getItem("acc_email"),
            "patient_email": sessionStorage.getItem("patientemail"),
            "a_type": "instant",
            "doctor_id": "1",
            "code": this.code
          }


          var type: any = sessionStorage.getItem("is_live")

          if (type == 0) {
            this.appService.postecData(environment.epic + "/service/endvitalpeercall", params, this.token).subscribe((response: any) => {
              if (response.success == 1) {
                this.toastr.success(response.message)
              }
            })
          } else if (type == 1) {
            this.appService.postecData(environment.epic_dev + "/service/endvitalpeercall", params, this.token).subscribe((response: any) => {
              if (response.success == 1) {
                this.toastr.success(response.message)
              }
            })
          }

          this.router.navigate(["/"])
        }
      }
    });
  }

  get resizeBoxElement(): HTMLElement {
    return this.resizeBox.nativeElement;
  }

  get dragHandleCornerElement(): HTMLElement {
    return this.dragHandleCorner.nativeElement;
  }

  get dragHandleRightElement(): HTMLElement {
    return this.dragHandleRight.nativeElement;
  }

  get dragHandleBottomElement(): HTMLElement {
    return this.dragHandleBottom.nativeElement;
  }

  ngAfterViewInit() {
    // this.setAllHandleTransform();
  }

  setAllHandleTransform() {
    const rect = this.resizeBoxElement.getBoundingClientRect();
    this.setHandleTransform(this.dragHandleCornerElement, rect, 'both');
    this.setHandleTransform(this.dragHandleRightElement, rect, 'x');
    this.setHandleTransform(this.dragHandleBottomElement, rect, 'y');
  }

  setHandleTransform(
    dragHandle: HTMLElement,
    targetRect: ClientRect | DOMRect,
    position: 'x' | 'y' | 'both'
  ) {
    const dragRect = dragHandle.getBoundingClientRect();
    const translateX = targetRect.width - dragRect.width;
    const translateY = targetRect.height - dragRect.height;

    if (position === 'x') {
      dragHandle.style.transform = `translate(${translateX}px, 0)`;
    }

    if (position === 'y') {
      dragHandle.style.transform = `translate(0, ${translateY}px)`;
    }

    if (position === 'both') {
      dragHandle.style.transform = `translate(${translateX}px, ${translateY}px)`;
    }
  }

  dragMove(dragHandle: HTMLElement, $event: CdkDragMove<any>) {
    this.ngZone.runOutsideAngular(() => {
      this.resize(dragHandle, this.resizeBoxElement);
    });
  }

  resize(dragHandle: HTMLElement, target: HTMLElement) {
    const dragRect = dragHandle.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();

    const width = dragRect.left - targetRect.left + dragRect.width;
    const height = dragRect.top - targetRect.top + dragRect.height;

    target.style.width = width + 'px';
    target.style.height = height + 'px';

    this.setAllHandleTransform();
  }

  ngOnDestroy() {
    if (this.audio) {
      this.audio.pause();
      this.audio.currentTime = 0;
    }
    var selectedChannel = 'get_message_' + this.code.toLowerCase();
    var hubChannel = 'receive_hub_data_' + this.code.toLowerCase();
    var refresh_channel = "refresh_hub_" + this.code.toLowerCase();
    var receivehub = "receive_hub_detail_" + this.code.toLowerCase();

    this.pubnub.unsubscribe({
      channels: [selectedChannel, hubChannel, refresh_channel, receivehub],
    });
  }

}





@Component({
  selector: 'camera',
  templateUrl: 'camera.html',
  styleUrls: ['camera.scss']
})

export class DialogOverviewExampleDialog {
  carddata;
  card_settingForm;
  card;
  urlPoints;
  message;
  token = sessionStorage.getItem("access_token")


  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>, private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.urlPoints = AppConfig.settings.EndPoints;
    console.log("data", data);
    this.message = data
    console.log("this.message", this.message);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  closepop() {
    this.dialogRef.close();
  }

}

export interface DialogData {
  cameradata: any;
}

export interface DialogAusculationData {
  account_email: any;
  patient_email: any;
}


export interface DialogDatapdf {
  mypdfdata: any
}

@Component({
  selector: 'logouts',
  templateUrl: 'logouts.html',
  styleUrls: ['logouts.scss']
})

export class DialogOverviewExamplelogout {

  urlPoints;


  token = sessionStorage.getItem("access_token");
  code = sessionStorage.getItem("code");

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExamplelogout>, private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDatapdf) {
    this.urlPoints = AppConfig.settings.EndPoints;

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  logouts(data) {
    if (data == 'yes') {
      this.dialogRef.close(data);
    } else {
      this.dialogRef.close(data);
    }
  }

  closepop() {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'confirm',
  templateUrl: 'confirm.html',
  styleUrls: ['confirm.scss']
})

export class DialogOverviewExampleconfirm {

  urlPoints;


  token = sessionStorage.getItem("access_token");
  code = sessionStorage.getItem("code");

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleconfirm>, private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDatapdf) {
    this.urlPoints = AppConfig.settings.EndPoints;

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  logouts(data) {
    if (data == 'yes') {
      this.dialogRef.close(data);
    } else {
      this.dialogRef.close(data);
    }
  }

  closepop() {
    this.dialogRef.close();
  }

}


export interface Dialog_Datapdf {
  mypdfdata: any
}

@Component({
  selector: 'uploadreport',
  templateUrl: 'uploadreport.html',
  styleUrls: ['uploadreport.scss']
})

export class DialogOverviewExampleuploadreport {

  urlPoints;
  url;
  files;
  uploaded;
  imgenvironment;
  imagepreview;
  auth = localStorage.getItem("authentication")
  progress;

  token = sessionStorage.getItem("access_token");
  code = sessionStorage.getItem("code");

  investigations: any = [];
  inv_copy: any = [];
  inv_copy1: any = [];
  inv_data: any = [];
  chars;

  inv_names: any = [];
  description;
  comments;
  fileList: any = [];


  inv_selected = [];
  inv_page = 1;
  inv_pages;
  matMenuTimer4: any;

  abnormal = false;

  UploadImages: any = [];
  imgURL = [];

  account_id = sessionStorage.getItem("acc_email");

  isFullListDisplayed: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleuploadreport>, private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: Dialog_Datapdf) {
    this.urlPoints = AppConfig.settings.EndPoints;

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  logouts(data) {

  }

  closereport() {
    this.dialogRef.close();
  }

  removeimg() {
    this.imagepreview = '';
  }

  clickedMe4() {
    if (!this.matMenuTimer4) return;
  }

  save() {
    var obj = {
      fileList: this.fileList,
      description: this.description,
      report: '',
      comments: this.comments,
      title: this.inv_names,
      abnormal: this.abnormal ? 1 : 0,
      tests: this.inv_names
    }
    this.dialogRef.close(obj);
  }


  public removePicture(item, index) {
    this.imgURL.splice(index, 1);
    this.files.splice(index, 1);
    this.UploadImages.splice(index, 1);
    let file = document.getElementById("files") as HTMLInputElement;
    file.value = "";
  }


  click4(i) {
    this.matMenuTimer4 = setTimeout(() => { this.clickedMe4(); }, 300);
  }

  remove_inv(index) {


    for (var i = 0; i < this.investigations.length; i++) {
      if (this.investigations[i].investigation_id == this.inv_selected[index].investigation_id) {

        if (this.inv_selected[index].question) {
          this.inv_selected[index].name = this.inv_selected[index].name.substring(1)
        }

        this.inv_copy1.push(this.inv_selected[index]);

        break;
      }
    }

    this.inv_selected.splice(index, 1);

    this.inv_copy1 = Object.assign([], this.investigations);
    var matchedArray = [];

    for (var i = 0; i < this.investigations.length; i++) {
      for (var k = 0; k < this.inv_selected.length; k++) {
        if (this.investigations[i].investigation_id == this.inv_selected[k].investigation_id) {
          matchedArray.push(i);
        }
      }
    }

    for (var i = matchedArray.length - 1; i >= 0; i--) {
      this.inv_copy.splice(matchedArray[i], 1);
      this.inv_copy1.splice(matchedArray[i], 1);
    }


    var inv_names = []

    for (var k = 0; k < this.inv_selected.length; k++) {
      inv_names.push(this.inv_selected[k].name)
    }

    this.inv_names = inv_names.join(',')
  }

  doubleClick4(i) {
    clearTimeout(this.matMenuTimer4);
    this.matMenuTimer4 = undefined;
    this.remove_inv(i);
  }


  update_inv(index, i) {

    var exists;

    for (var j = 0; j < this.inv_selected.length; j++) {
      if (this.inv_selected[j].name.trim().toLowerCase() == this.inv_copy1[index].name.trim().toLowerCase()) {
        exists = true
      }
    }

    if (!exists) {


      var index1 = this.investigations.indexOf(this.inv_copy1[index]);
      this.inv_selected.push(this.investigations[index1])
      this.inv_copy1.splice(index, 1);


      var inv_names = []

      for (var k = 0; k < this.inv_selected.length; k++) {
        inv_names.push(this.inv_selected[k].name)
      }

      this.inv_names = inv_names.join(',')

    }


  }


  onScroll4() {

    this.inv_page++;
    var params = {
      account_email: this.account_id,
      page_no: this.inv_page,
      alphabet: this.chars.toUpperCase()
    }


    var type: any = sessionStorage.getItem("is_live")

    if (type == 0) {

      this.appService.getecData(environment.epic + "/service/getconsultationinvestigation", params, this.auth).subscribe((response: any) => {
        if (response.success !== 0 && !response.message) {

          this.investigations = response.data;
          this.inv_pages = response.total_page;

          if (this.inv_page > 1)
            this.inv_data = this.inv_data.concat(response.data);
          else
            this.inv_data = response.data

          this.investigations = this.inv_data;

          this.inv_copy = Object.assign([], this.investigations);
          this.inv_copy1 = Object.assign([], this.investigations);
          var matchedArray = [];

          if (response.success == 1) {
            for (var i = 0; i < this.investigations.length; i++) {
              for (var k = 0; k < this.inv_selected.length; k++) {
                if (this.investigations[i].investigation_id == this.inv_selected[k].investigation_id) {
                  matchedArray.push(i);
                }
              }
            }

            for (var i = matchedArray.length - 1; i >= 0; i--) {
              this.inv_copy.splice(matchedArray[i], 1);
              this.inv_copy1.splice(matchedArray[i], 1);
            }
          }
        }
      })

    } else if (type == 1) {

      this.appService.getecData(environment.epic_dev + "/service/getconsultationinvestigation", params, this.auth).subscribe((response: any) => {
        if (response.success !== 0 && !response.message) {

          this.investigations = response.data;
          this.inv_pages = response.total_page;

          if (this.inv_page > 1)
            this.inv_data = this.inv_data.concat(response.data);
          else
            this.inv_data = response.data

          this.investigations = this.inv_data;

          this.inv_copy = Object.assign([], this.investigations);
          this.inv_copy1 = Object.assign([], this.investigations);
          var matchedArray = [];

          if (response.success == 1) {
            for (var i = 0; i < this.investigations.length; i++) {
              for (var k = 0; k < this.inv_selected.length; k++) {
                if (this.investigations[i].investigation_id == this.inv_selected[k].investigation_id) {
                  matchedArray.push(i);
                }
              }
            }

            for (var i = matchedArray.length - 1; i >= 0; i--) {
              this.inv_copy.splice(matchedArray[i], 1);
              this.inv_copy1.splice(matchedArray[i], 1);
            }
          }
        }
      })
    }

  }

  search(event) {

    this.investigations = [];
    this.inv_copy1 = [];
    this.inv_data = []
    this.chars = event.target.value
    var params = {
      account_email: this.account_id,
      page_no: 1,
      alphabet: this.chars.trim()
    }



    var type: any = sessionStorage.getItem("is_live")

    if (type == 0) {

      this.appService.getecData(environment.epic + "/service/getconsultationinvestigation", params, this.auth).subscribe((response: any) => {
        if (response.success !== 0 && !response.message) {

          this.investigations = response.data;
          this.inv_pages = response.total_page;

          if (this.inv_page > 1)
            this.inv_data = this.inv_data.concat(response.data);
          else
            this.inv_data = response.data

          this.investigations = this.inv_data;

          this.inv_copy = Object.assign([], this.investigations);
          this.inv_copy1 = Object.assign([], this.investigations);
          var matchedArray = [];

          if (response.success == 1) {
            for (var i = 0; i < this.investigations.length; i++) {
              for (var k = 0; k < this.inv_selected.length; k++) {
                if (this.investigations[i].investigation_id == this.inv_selected[k].investigation_id) {
                  matchedArray.push(i);
                }
              }
            }

            for (var i = matchedArray.length - 1; i >= 0; i--) {
              this.inv_copy.splice(matchedArray[i], 1);
              this.inv_copy1.splice(matchedArray[i], 1);
            }
          }
        }
      })

    } else if (type == 1) {

      this.appService.getecData(environment.epic_dev + "/service/getconsultationinvestigation", params, this.auth).subscribe((response: any) => {
        if (response.success !== 0 && !response.message) {

          this.investigations = response.data;
          this.inv_pages = response.total_page;

          if (this.inv_page > 1)
            this.inv_data = this.inv_data.concat(response.data);
          else
            this.inv_data = response.data

          this.investigations = this.inv_data;

          this.inv_copy = Object.assign([], this.investigations);
          this.inv_copy1 = Object.assign([], this.investigations);
          var matchedArray = [];

          if (response.success == 1) {
            for (var i = 0; i < this.investigations.length; i++) {
              for (var k = 0; k < this.inv_selected.length; k++) {
                if (this.investigations[i].investigation_id == this.inv_selected[k].investigation_id) {
                  matchedArray.push(i);
                }
              }
            }

            for (var i = matchedArray.length - 1; i >= 0; i--) {
              this.inv_copy.splice(matchedArray[i], 1);
              this.inv_copy1.splice(matchedArray[i], 1);
            }
          }
        }
      })
    }

  }


  // GetFile(event) {

  //   this.files = Array.from(event.target.files);
  //   if ((this.files[0].type === 'image/png')
  //     || (this.files[0].type === 'image/jpg')
  //     || (this.files[0].type === 'image/jpeg') || (this.files[0].type === 'application/pdf')
  //   ) {

  //   if(event.target.files){
  //     var reader = new FileReader()
  //     console.log("reader.result", reader.result);
  //     reader.readAsDataURL(event.target.files[0]);
  //     reader.onload = (event: any) =>{
  //        this.url = event.target.result;
  //     }
  //   }

  //   const file = (event.target as HTMLInputElement).files[0];
  //   var uploadFileName = file;
  //   console.log("upload file name", uploadFileName);
  //   var type:any = sessionStorage.getItem("is_live")
  //   if(type == 0){
  //     this.imgenvironment = 'https://test.epicare.net';
  //     this.appService.postimgData(this.imgenvironment , uploadFileName , this.token).subscribe((response: any) => {
  //       this.uploaded = response.body;

  //       if(this.uploaded != undefined){
  //           console.log(this.uploaded.image[0].filename);
  //           this.imagepreview = this.uploaded.image[0].filename;
  //       }

  //     });
  //   }else if(type == 1){
  //     this.imgenvironment = 'https://epicare.net'
  //     this.appService.postimgData(this.imgenvironment , uploadFileName , this.token).subscribe((response: any) => {
  //       this.uploaded = response.body;

  //       if(this.uploaded != undefined){
  //         console.log(this.uploaded.image[0].filename);
  //       this.imagepreview = this.uploaded.image[0].filename;

  //     }

  //     });
  //   }



  //   }
  // }

  GetFile(e) {


    this.files = Array.from(e.target.files);

    if ((this.files[0].type === 'image/png')
      || (this.files[0].type === 'image/jpg')
      || (this.files[0].type === 'image/jpeg')
      || (this.files[0].type === 'image/tif')
      || (this.files[0].type === 'application/pdf')) {
      if (this.files[0].size > 10000000) {

      } else {
        this.UploadImages.push(e.target.files)

        for (let i = 0; i < this.files.length; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {

            this.imgURL.push({
              url: event.target.result,
              name: this.files[i].name,
              type: this.files[i].name.split('.')[this.files[i].name.split('.').length - 1]
            })
          }
          reader.readAsDataURL(e.target.files[i]);
          const file = (e.target as HTMLInputElement).files[i];
          this.fileList.push(file)
          console.log('this.fileList', this.fileList);
        }
      }
    } else {

    }

  }


  closepop() {
    this.dialogRef.close();
  }

}



export interface Dialog__Datapdf {
  mypdfdata: any
}

@Component({
  selector: 'viewreport',
  templateUrl: 'viewreport.html',
  styleUrls: ['viewreport.scss']
})

export class DialogOverviewExampleviewreport {

  urlPoints;
  url;
  files: any = [];
  uploaded;

  auth = localStorage.getItem("authentication")
  token = sessionStorage.getItem("access_token");
  code = sessionStorage.getItem("code");
  account_id = sessionStorage.getItem("acc_email");

  abnormal = false;
  imgURL = [];

  Account_id;
  MR;

  uploadedimg;
  patientemail;

  filter_type = 1;

  actualdata: any = [];
  attachments: any = [];
  newAttachment: any = [];

  labtest: any = [];
  labReportList: any = [];


  dicomCount = 0;
  pacAttachments = []

  pacExists = false
  host;
  attachments1;
  newAttachment1;
  labArray = []
  type: any = sessionStorage.getItem("is_live")

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleviewreport>, private appService: AppService, private window: Window, pubnub: PubNubAngular, private ngZone: NgZone, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router, private toastr: ToastrService, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Dialog__Datapdf) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.host = this.type == 0 ? environment.epic1 : environment.epic_dev1

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  closereport() {
    this.dialogRef.close();
  }

  openAttachment2(data, id, type, title) {

    console.log(data)

    console.log(id)

    console.log(type)

    console.log(title)

    var files = []
    const dialogRef = this.dialog.open(Dialog_attachment, {
      width: '600px',
      data: { obj: data, title: type == 1 ? data.lab_tests.join(',') : title, type: type, mode: 'add' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result.fileList)

        for (var i = 0; i < result.fileList.length; i++) {
          this.appService.postimgData3(result.fileList[i].type.startsWith('image') ? this.urlPoints.imageUpload : this.urlPoints.pdfUpload, result.fileList[i], this.auth, result.fileList[i].type.startsWith('image') ? 1 : 2).subscribe((response: any) => {
            this.uploadedimg = response.body;

            if (this.uploadedimg) {
              console.log(this.uploadedimg)
              if (this.uploadedimg.image) {
                var params = {
                  account_email: this.Account_id,
                  patient_email: this.patientemail,
                  lab_id: id,
                  attachment: this.uploadedimg.image[0].filename,
                  file_type: 'image'
                }
              }
              else {
                var params = {
                  account_email: this.Account_id,
                  patient_email: this.patientemail,
                  lab_id: id,
                  attachment: this.uploadedimg.pdf[0].filename,
                  file_type: 'document'
                }
              }


              this.appService.postData1(this.urlPoints.createLabAttachment, params, this.auth).subscribe((response: any) => {
                console.log(response)
                this.refreshData()
              });


            }


          });
        }

      }
    });
  }


  labNotes() {

    const dialogRef = this.dialog.open(DialogOverviewExamplelabnotes, {
      width: '50%',
      data: {
        mypdfdata: ''
      }
    });

    dialogRef.afterClosed().subscribe(result => {


      if (result) {

      }

    })

  }

  delete(id) {
    var params = {
      account_email: this.Account_id,
      id: id
    }

    this.appService.postData1(this.urlPoints.deleteAttachment, params, this.auth).subscribe((response: any) => {
      console.log(response)

      this.toastr.success("File deleted!", '', {
        timeOut: 3000,
      });
      this.refreshData()
      this.getDICOMCount()
    });
  }

  getDICOMCount() {
    var params = {
      account_email: this.Account_id,
      patient_email: this.patientemail
    }

    console.log(this.auth)

    this.appService.postData1(this.urlPoints.getPacsAttachment, params, this.auth).subscribe((response: any) => {

      if (response.success == 1) {
        this.dicomCount = response.attachments.length
      }
    });
  }

  getPacsAttachment(data, lab_id, type, title) {
    var params = {
      account_email: this.Account_id,
      patient_email: this.patientemail
    }

    this.appService.postData1(this.urlPoints.getPacsAttachment, params, this.auth).subscribe((response: any) => {
      // console.log(response)

      if (response.success == 1) {
        this.pacAttachments = response.attachments
      }

      console.log(this.pacAttachments)

      const dialogRef = this.dialog.open(Dialog_pac_attachments, {
        width: '900px',
        data: { account_email: this.Account_id, token: this.auth, patient_email: this.patientemail, pacAttachments: this.pacAttachments, lab_id: lab_id, lab_data: data, type: type, title: title }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          console.log('here')
          // console.log(this.refreshData)
        }
        this.refreshData()
        this.getDICOMCount()

      });
    });

  }

  refreshData() {
    if (this.filter_type == 1) {
      var params_labreports = {
        account_email: this.Account_id,
        patient_email: this.patientemail,
      }

      this.labreports(this.urlPoints.getLabReports, params_labreports, this.auth)
    }

    if (this.filter_type == 2) {
      var params_labreports2 = {
        account_email: this.Account_id,
        patient_email: this.patientemail,
        category: 'Unseen'
      }

      this.labreports2(this.urlPoints.getLabReports, params_labreports2, this.auth)
    }

    if (this.filter_type == 3) {
      var params_labreports3 = {
        account_email: this.Account_id,
        patient_email: this.patientemail,
        category: 'abnormal'
      }

      this.labreports2(this.urlPoints.getLabReports, params_labreports3, this.auth)
    }
  }

  edit1(id, tests, date, type, labtest, lab_tests, category, description, comments, filename, test_taken_on) {

    console.log(id)
    console.log(tests)
    console.log(date)
    console.log(type)
    console.log(labtest)
    console.log(lab_tests)
    console.log(category)
    console.log(description)
    console.log(comments)

    console.log(filename)
    console.log(test_taken_on)

    var files = []

    var data = {
      id: id,
      lab_tests: tests,
      date: date
    }

    const dialogRef = this.dialog.open(Dialog_attachment_edit, {
      width: '600px',

      data: { obj: data, title: tests, edit: 1, category: category, description: description, comments: comments, filename: filename, test_taken_on: test_taken_on }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        var params = {
          account_email: this.Account_id,
          report: lab_tests,
          lab_test: lab_tests,
          description: result.description ? result.description : '',
          comments: result.comments ? result.comments : '',
          title: tests.join(','),
          category: result.abnormal ? 'abnormal' : "seen",
          patient_email: this.patientemail,
          lab_id: type == 2 ? data.id : '',
          file_name: result.filename,
          test_taken_on: result.test_taken_on
        }

        this.appService.postData1(this.urlPoints.createLabReport, params, this.auth).subscribe((response: any) => {

          // setTimeout(() => {
          //   //  this.loading = false;
          //   this._changeDetectorRef.markForCheck();
          // }, 500);

          this.getUnseen()
          if (this.filter_type == 1) {
            var params_labreports = {
              account_email: this.Account_id,
              patient_email: this.patientemail,
            }

            this.labreports(this.urlPoints.getLabReports, params_labreports, this.auth)
          }

          if (this.filter_type == 2) {
            var params_labreports2 = {
              account_email: this.Account_id,
              patient_email: this.patientemail,
              category: 'Unseen'
            }

            this.labreports2(this.urlPoints.getLabReports, params_labreports2, this.auth)
          }

          if (this.filter_type == 3) {
            var params_labreports3 = {
              account_email: this.Account_id,
              patient_email: this.patientemail,
              category: 'abnormal'
            }

            this.labreports2(this.urlPoints.getLabReports, params_labreports3, this.auth)
          }
        });

      }
    });
  }

  openUpload() {
    var files = []

    const dialogRef = this.dialog.open(Dialog_upload, {
      width: '600px',

      data: { dmail: this.Account_id, token: this.auth }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        for (var i = 0; i < result.fileList.length; i++) {

          this.appService.postimgData3(result.fileList[i].type.startsWith('image') ? this.urlPoints.imageUpload : this.urlPoints.pdfUpload, result.fileList[i], this.auth, result.fileList[i].type.startsWith('image') ? 1 : 2).subscribe((response: any) => {
            this.uploadedimg = response.body;

            if (this.uploadedimg) {
              if (this.uploadedimg.image)
                files.push(this.uploadedimg.image[0].filename)
              else
                files.push(this.uploadedimg.pdf[0].filename)
            }

            if (files.length == result.fileList.length) {

              var params = {
                report: files,
                description: result.description,
                comments: result.comments,
                lab_test: result.tests.split(','),
                account_email: this.Account_id,
                patient_email: this.patientemail,
                title: result.title,
                category: result.abnormal ? 'abnormal' : "seen",
                file_name: result.file_name,
                test_taken_on: result.test_taken_on
              }

              this.appService.postData1(this.urlPoints.createLabReport, params, this.auth).subscribe((response: any) => {

                // this.snackBar.open('Report Uploaded!', '', {
                //   duration: 3000,
                //   verticalPosition: 'bottom',
                //   horizontalPosition: 'right',
                // });

                // setTimeout(() => {
                //   //  this.loading = false;
                //   this._changeDetectorRef.markForCheck();
                // }, 500);

                if (this.filter_type == 1) {
                  var params_labreports = {
                    account_email: this.Account_id,
                    patient_email: this.patientemail,
                  }

                  this.labreports(this.urlPoints.getLabReports, params_labreports, this.auth)
                }

                if (this.filter_type == 2) {
                  var params_labreports2 = {
                    account_email: this.Account_id,
                    patient_email: this.patientemail,
                    category: 'Unseen'
                  }

                  this.labreports2(this.urlPoints.getLabReports, params_labreports2, this.auth)
                }

                if (this.filter_type == 3) {
                  var params_labreports3 = {
                    account_email: this.Account_id,
                    patient_email: this.patientemail,
                    category: 'abnormal'
                  }

                  this.labreports2(this.urlPoints.getLabReports, params_labreports3, this.auth)
                }
              });
            }

          });
        }

        if (result.fileList.length == 0) {
          var params = {
            report: [],
            description: result.description,
            comments: result.comments,
            lab_test: result.tests.split(','),
            account_email: this.Account_id,
            patient_email: this.patientemail,
            title: result.title,
            category: result.abnormal ? 'abnormal' : "seen",
            file_name: result.file_name,
            test_taken_on: result.test_taken_on
          }

          this.appService.postData1(this.urlPoints.createLabReport, params, this.auth).subscribe((response: any) => {
            this.refreshData()
          });
        }
      }
    });
  }

  getUnseen() {
    var params_labreports2 = {
      account_email: this.Account_id,
      patient_email: this.patientemail,
      category: 'Unseen'
    }
    this.labreports_seen(this.urlPoints.getLabReports, params_labreports2, this.auth)

  }

  labreports_seen(url, params, token) {

    var attachments1 = []

    var flag;

    var flag1;

    var obj;

    this.actualdata = []

    this.attachments1 = []

    this.newAttachment1 = []

    var inv_names = [];

    var data = []

    this.appService.getecData1(url, params, token).subscribe((response: any) => {

      for (var i = 0; i < response.length; i++) {
        obj = {}

        obj.lab_tests = []

        obj.image_attachments = []

        obj.data = []

        var labexists = false

        for (var k = 0; k < response[i].data.length; k++) {
          flag = 0
          flag1 = 0

          if (response[i].data[k].image_attachments) {
            if (response[i].data[k].image_attachments.length > 0) {
              flag1 = 1
            }
          }

          if (flag1 || flag == 1) {

            obj.date = response[i].date


            if (response[i].data[k].lab_test) {
              if (response[i].data[k].lab_test.length > 0) {
                //  obj.lab_tests = obj.lab_tests.concat(response[i].data[k].lab_test)
              }
            }

            if (response[i].data[k].image_attachments) {
              if (response[i].data[k].image_attachments.length > 0) {
                obj.image_attachments = obj.image_attachments.concat(response[i].data[k].lab_test)
              }
              if (response[i].data[k].lab_id) {
                labexists = true
                obj.image_attachments = obj.image_attachments.concat(response[i].data[k].lab_test)
              }
            }

            if (response[i].data[k].image_attachments) {

              obj.data.push(response[i].data[k])
              obj.lab_tests.push(response[i].data[k].title)
              obj.data.labexists = labexists
            }
          }
        }

        //  var day = '2021-10-05'

        if (obj.date && obj.data.labexists) {
          this.attachments1.push(obj)
        }
      }

      {
        for (var i = 0; i < response.length; i++) {
          for (var j = 0; j < response[i].data.length; j++) {
            this.actualdata.push(response[i].data[j]);
            this.labtest.push(response[i].data[j].lab_test);
          }
        }

        for (var i = 0; i < this.actualdata.length; i++) {
          if (this.actualdata[i].image_attachments != null) {
            for (var j = 0; j < this.actualdata[i].image_attachments.length; j++) {
              if (this.actualdata[i].image_attachments[j].startsWith("img_")) {
                this.newAttachment1.push({
                  name: 'View report ',
                  id: environment.HOST + '/helyxonapi/cloud/image/upload/' + this.actualdata[i].image_attachments[j],
                  replace: 'Replace Attachment',
                  overviewid: this.actualdata[i].overview_id,
                  labid: this.actualdata[i].lab_id,
                  description: this.actualdata[i].description ? this.actualdata[i].description : '',
                  comments: this.actualdata[i].comments ? this.actualdata[i].comments : '',
                  labtest: this.actualdata[i].lab_test,
                  date: this.actualdata[i].date,
                  type: 1,
                  title: this.actualdata[i].title
                })
              } else {
                this.newAttachment1.push({
                  name: 'View report ',
                  id: environment.HOST + '/helyxonapi/cloud/pdf/upload/' + this.actualdata[i].image_attachments[j],
                  replace: 'Replace Attachment',
                  overviewid: this.actualdata[i].overview_id,
                  labid: this.actualdata[i].lab_id,
                  description: this.actualdata[i].description ? this.actualdata[i].description : '',
                  comments: this.actualdata[i].comments ? this.actualdata[i].comments : '',
                  labtest: this.actualdata[i].lab_test,
                  date: this.actualdata[i].date,
                  type: 2,
                  title: this.actualdata[i].title

                })
              }
            }
          }
        }

        for (var i = 0; i < this.labtest.length; i++) {
          if (this.labtest[i] != null) {
            this.labReportList.push(this.labtest[i]);
          }
        }
      }

      for (var i = 0; i < this.attachments1.length; i++) {
        var exists = 0;

        for (var k = 0; k < this.newAttachment1.length; k++) {
          if (this.attachments1[i].date == this.newAttachment1[k].date) {
            exists = 1
          }
        }

        if (exists == 1) {
          this.attachments1[i].exists = 1
        }
        else {
          this.attachments1[i].exists = 0
        }
      }

      for (var i = 0; i < this.newAttachment1.length; i++) {
        if (this.newAttachment1[i].labid) {
        }
      }
    });

  }

  view(attachment, type, labid, category) {

    var type1: any = sessionStorage.getItem("is_live")

    console.log(type1)

    console.log(environment.epic1 + '/helyxonapi/cloud/image/upload/' + attachment)

    var path

    if (type == 'image') {
      path = (type1 == 0 ? environment.epic1 : environment.epic_dev1) + '/helyxonapi/cloud/image/upload/' + attachment

      window.open(path)

    }
    else if (type == 'document') {
      path = (type1 == 0 ? environment.epic1 : environment.epic_dev1) + '/helyxonapi/cloud/pdf/upload/' + attachment

      window.open(path)

    }

    else {
      path = (type1 == 0 ? environment.epic1 : environment.epic_dev1) + '/dicomviewer/?file=' + attachment

      window.open(path)

      // this.openDicomViewer(path)
    }

    console.log(type)
    console.log(labid)
    console.log(category)



    if (labid) {
      var params = {
        "account_email": this.Account_id,
        "patient_email": this.patientemail,
        "id": labid,
        "status": "seen"
      }

      if (category != 'abnormal') {
        this.appService.postData(this.urlPoints.updateReports, params, this.auth).subscribe((response: any) => {

          // setTimeout(() => {
          //   //  this.loading = false;
          //   this._changeDetectorRef.markForCheck();
          // }, 500);

          if (this.filter_type == 1) {
            var params_labreports = {
              account_email: this.account_id,
              patient_email: this.patientemail,
            }


            this.labreports(this.urlPoints.getLabReports, params_labreports, this.auth)
          }

          if (this.filter_type == 2) {
            var params_labreports2 = {
              account_email: this.Account_id,
              patient_email: this.patientemail,
              category: 'Unseen'
            }

            this.labreports2(this.urlPoints.getLabReports, params_labreports2, this.auth)
          }

          if (this.filter_type == 3) {
            var params_labreports3 = {
              account_email: this.Account_id,
              patient_email: this.patientemail,
              category: 'abnormal'
            }

            this.labreports2(this.urlPoints.getLabReports, params_labreports3, this.auth)
          }
        });
      }
      else {
        if (this.filter_type == 1) {
          var params_labreports = {
            account_email: this.Account_id,
            patient_email: this.patientemail,
          }


          this.labreports(this.urlPoints.getLabReports, params_labreports, this.auth)
        }

        if (this.filter_type == 2) {
          var params_labreports2 = {
            account_email: this.account_id,
            patient_email: this.patientemail,
            category: 'Unseen'
          }

          this.labreports2(this.urlPoints.getLabReports, params_labreports2, this.auth)
        }

        if (this.filter_type == 3) {
          var params_labreports3 = {
            account_email: this.account_id,
            patient_email: this.patientemail,
            category: 'abnormal'
          }

          this.labreports2(this.urlPoints.getLabReports, params_labreports3, this.auth)
        }
      }

    }
  }

  replace(id, tests, date, type, labtest, attachment, attachments, category, filename, test_taken_on, file_id) {

    console.log(id)
    console.log(tests)
    console.log(date)
    console.log(type)
    console.log(labtest)
    console.log(attachment)
    console.log(attachments)
    console.log(category)
    console.log(filename)
    console.log(test_taken_on)
    console.log(file_id)
    // console.log(test_taken_on)


    // this.onView.emit();

    // var filter = attachments.filter((res => res.labid == id) || (res => res.overviewid == id));

    // var total_attachments = []

    // for (var i = 0; i < filter.length; i++) {
    //   total_attachments.push(filter[i].id.split('/')[attachment.id.split('/').length - 1])
    // }

    // var index

    // index = total_attachments.indexOf(attachment.id.split('/')[attachment.id.split('/').length - 1])

    // total_attachments.splice(index, 1)

    var files = []

    var data = {
      id: id,
      lab_tests: tests,
      date: date
    }

    const dialogRef = this.dialog.open(Dialog_attachment2, {
      width: '600px',

      data: { obj: data, title: tests, category: category, type: type, mode: 'replace' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {


        for (var i = 0; i < result.fileList.length; i++) {

          this.appService.postimgData3(result.fileList[i].type.startsWith('image') ? this.urlPoints.imageUpload : this.urlPoints.pdfUpload, result.fileList[i], this.auth, result.fileList[i].type.startsWith('image') ? 1 : 2).subscribe((response: any) => {
            this.uploadedimg = response.body;

            if (this.uploadedimg) {
              if (this.uploadedimg.image) {
                var params = {
                  account_email: this.Account_id,
                  patient_email: this.patientemail,
                  id: file_id,
                  attachment: this.uploadedimg.image[0].filename,
                  file_type: 'image'
                }


              }
              // files.push(this.uploadedimg.image[0].filename)
              else {
                var params = {
                  account_email: this.Account_id,
                  patient_email: this.patientemail,
                  id: file_id,
                  attachment: this.uploadedimg.pdf[0].filename,
                  file_type: 'document'
                }


              }

              this.appService.postData1(this.urlPoints.createLabAttachment, params, this.auth).subscribe((response: any) => {
                console.log(response)

                this.toastr.success("File replaced!", '', {
                  timeOut: 3000,
                });
                this.refreshData()
              });
              // files.push(this.uploadedimg.pdf[0].filename)
            }

            console.log(files)





            // if (files.length == result.fileList.length) {

            //   var params = {
            //     lab_results: {
            //       attachments: total_attachments,
            //       overview_id: type == 1 ? data.id : '',
            //       lab_id: type == 2 ? data.id : '',
            //       comment: "",
            //       category: result.abnormal ? 'abnormal' : "seen"

            //     },
            //     account_email: this.account_id
            //   }

            //   this.appService.postData(this.urlPoints.labresult, params, this.auth).subscribe((response: any) => {

            //     setTimeout(() => {

            //       this._changeDetectorRef.markForCheck();
            //     }, 500);

            //     //  this.getUnseen()

            //     var params_labreports = {
            //       account_email: this.account_id,
            //       patient_email: this.patientemail,
            //     }

            //     if (this.filter_type == 1) {
            //       var params_labreports = {
            //         account_email: this.account_id,
            //         patient_email: this.patientemail,
            //       }

            //       this.labreports(this.urlPoints.getLabReports, params_labreports, this.auth)
            //     }

            //     if (this.filter_type == 2) {
            //       var params_labreports2 = {
            //         account_email: this.account_id,
            //         patient_email: this.patientemail,
            //         category: 'Unseen'
            //       }

            //       this.labreports2(this.urlPoints.getLabReports, params_labreports2, this.auth)
            //     }

            //     if (this.filter_type == 3) {
            //       var params_labreports3 = {
            //         account_email: this.account_id,
            //         patient_email: this.patientemail,
            //         category: 'abnormal'
            //       }

            //       this.labreports2(this.urlPoints.getLabReports, params_labreports3, this.auth)
            //     }
            //   });
            // }

          });
        }

      }
    });
  }

  checkPacs() {
    var params = {
      account_email: this.Account_id,
      patient_email: this.patientemail
    }

    this.appService.getecData1(this.urlPoints.checkPacs, params, this.auth).subscribe((response: any) => {
      console.log(response)
      if (response.success == 1) {
        this.pacExists = true

        // setTimeout(() => {
        //   //  this.loading = false;
        //   this._changeDetectorRef.markForCheck();
        // }, 500);
      }
    });
  }

  ngOnInit() {


    // this.setFilterType(1)


    this.Account_id = sessionStorage.getItem("acc_email");
    var type: any = sessionStorage.getItem("is_live")
    this.MR = sessionStorage.getItem("mr");



    var SearchDataparams = {
      'mr_number': this.MR.toUpperCase(),
      "account_email": this.Account_id
    };
    console.log("paarms", SearchDataparams);
    if (type == 0) {
      console.log(0)
      this.appService.postecData(environment.epic + "/service/search", SearchDataparams, this.auth).subscribe((response: any) => {
        if (response[0]) {
          this.patientemail = response[0].email
          this.account_id = response[0].account_id

          var params_labreports = {
            account_email: this.Account_id,
            patient_email: this.patientemail,
          }

          this.labreports(this.urlPoints.getLabReports, params_labreports, this.auth)

          this.checkPacs()
          this.getDICOMCount()
          console.log(this.auth)


        }
      });
    } else if (type == 1) {
      this.appService.postecData(environment.epic_dev + "/service/search", SearchDataparams, this.auth).subscribe((response: any) => {
        console.log("P2P", response);
        if (response[0]) {
          this.patientemail = response[0].email
          this.account_id = response[0].account_id

          var params_labreports = {
            account_email: this.Account_id,
            patient_email: this.patientemail,
          }

          console.log(this.auth)

          this.labreports(this.urlPoints.getLabReports, params_labreports, this.auth)

          this.checkPacs()
          this.getDICOMCount()

        }
      });
    }
  }

  labreports(url, params, token) {

    console.log(params)

    var attachments = []

    var flag;

    var flag1;

    var obj;

    this.actualdata = []

    this.attachments = []

    this.newAttachment = []

    var inv_names = [];

    var data = []

    console.log(environment.epic + url)

    this.appService.getecData1(url, params, token).subscribe((response1: any) => {
      this.labArray = []
      var response = response1.result

      for (var i = 0; i < response.length; i++) {
        obj = {}

        obj.lab_tests = []

        obj.image_attachments = []

        obj.data = []

        obj.impressions = []

        obj.comments = []

        obj.titles = []

        obj.file_names = []

        obj.test_taken_on = []

        obj.lab_attachments = []

        var labexists = false

        for (var k = 0; k < response[i].data.length; k++) {

          this.labArray.push(response[i].data[k])

          obj.impression = response[i].data[k].description

          obj.impressions.push(response[i].data[k].description)

          obj.comments.push(response[i].data[k].comments)

          obj.titles.push(response[i].data[k].title)

          obj.file_names.push(response[i].data[k].file_name)

          obj.test_taken_on.push(response[i].data[k].test_taken_on)

          obj.lab_attachments.push(response[i].data[k].lab_attachments)

          // obj.title = response[i].data[k].title

          // obj.file_name = response[i].data[k].file_name

          // obj.test_taken_on = response[i].data[k].test_taken_on

          flag = 0
          flag1 = 0

          if (response[i].data[k].lab_test) {
            if (response[i].data[k].lab_test.length > 0) {
              flag = 1
            }
          }

          if (response[i].data[k].image_attachments) {

            if (response[i].data[k].image_attachments.length > 0) {
              flag1 = 1
            }
            if (response[i].data[k].image_attachments.length == 0 && response[i].data[k].lab_id) {
              flag1 = 1
            }
          }

          if (flag1 || flag == 1) {

            obj.date = response[i].date


            if (response[i].data[k].lab_test) {
              if (response[i].data[k].lab_test.length > 0) {

                obj.lab_tests = obj.lab_tests.concat(response[i].data[k].lab_test)
              }
            }

            if (response[i].data[k].image_attachments) {
              if (response[i].data[k].image_attachments.length > 0) {
                obj.image_attachments = obj.image_attachments.concat(response[i].data[k].lab_test)
              }
              if (response[i].data[k].image_attachments.length == 0 && response[i].data[k].lab_id) {
                labexists = true
                obj.image_attachments = obj.image_attachments.concat(response[i].data[k].lab_test)
              }
            }


            if (response[i].data[k].image_attachments || response[i].data[k].lab_test) {
              obj.data.push(response[i].data[k])

              obj.data.labexists = labexists

            }
          }
        }

        if (obj.date) {
          this.attachments.push(obj)
        }
      }

      {
        for (var i = 0; i < response.length; i++) {
          for (var j = 0; j < response[i].data.length; j++) {
            this.actualdata.push(response[i].data[j]);
            this.labtest.push(response[i].data[j].lab_test);
          }
        }

        for (var i = 0; i < this.actualdata.length; i++) {

          var images = [];

          for (var j = 0; j < this.actualdata[i].image_attachments.length; j++) {
            images = images.concat(this.actualdata[i].image_attachments[j].split(','))
          }

          for (var j = 0; j < images.length; j++) {
            images[j] = images[j].trim()
          }

          this.actualdata[i].image_attachments = images
        }

        // console.log(this.actualdata)



        for (var i = 0; i < this.actualdata.length; i++) {
          if (this.actualdata[i].lab_attachments != null) {
            for (var j = 0; j < this.actualdata[i].lab_attachments.length; j++) {
              if (this.actualdata[i].lab_attachments[j].attachment.startsWith("img_")) {
                this.newAttachment.push({
                  name: 'View report ',
                  path: environment.HOST + '/helyxonapi/cloud/image/upload/' + this.actualdata[i].lab_attachments[j].attachment,
                  id: this.actualdata[i].lab_attachments[j].id,
                  file_type: this.actualdata[i].lab_attachments[j].file_type,
                  replace: 'Replace Attachment',
                  overviewid: this.actualdata[i].overview_id,
                  file_name: this.actualdata[i].file_name,
                  test_taken_on: this.actualdata[i].test_taken_on,
                  labid: this.actualdata[i].lab_id,
                  description: this.actualdata[i].description && this.actualdata[i].description != 'null' ? this.actualdata[i].description : '',
                  comments: this.actualdata[i].comments && this.actualdata[i].comments != 'null' ? this.actualdata[i].comments : '',
                  labtest: this.actualdata[i].lab_test,
                  date: this.actualdata[i].date,
                  type: 1,
                  title: this.actualdata[i].title,
                  category: this.actualdata[i].category
                })
              } else {
                this.newAttachment.push({
                  name: 'View report ',
                  path: environment.HOST + '/helyxonapi/cloud/pdf/upload/' + this.actualdata[i].lab_attachments[j].attachment,
                  id: this.actualdata[i].lab_attachments[j].id,
                  file_type: this.actualdata[i].lab_attachments[j].file_type,
                  replace: 'Replace Attachment',
                  overviewid: this.actualdata[i].overview_id,
                  file_name: this.actualdata[i].file_name,
                  test_taken_on: this.actualdata[i].test_taken_on,
                  labid: this.actualdata[i].lab_id,
                  description: this.actualdata[i].description && this.actualdata[i].description != 'null' ? this.actualdata[i].description : '',
                  comments: this.actualdata[i].comments && this.actualdata[i].comments != 'null' ? this.actualdata[i].comments : '',
                  labtest: this.actualdata[i].lab_test,
                  date: this.actualdata[i].date,
                  type: 2,
                  title: this.actualdata[i].title,
                  category: this.actualdata[i].category
                })
              }
            }
          }
        }


        for (var i = 0; i < this.labtest.length; i++) {
          if (this.labtest[i] != null) {
            this.labReportList.push(this.labtest[i]);
          }
        }
      }

      for (var i = 0; i < this.attachments.length; i++) {
        var exists = 0;

        for (var k = 0; k < this.newAttachment.length; k++) {
          if (this.attachments[i].date == this.newAttachment[k].date) {
            exists = 1
          }
        }

        if (exists == 1) {
          this.attachments[i].exists = 1
        }
        else {
          this.attachments[i].exists = 0
        }
      }

      for (var i = 0; i < this.newAttachment.length; i++) {
        if (this.newAttachment[i].labid) {
        }
      }

      for (var i = 0; i < response.length; i++) {
        var count = 0
        var test_names = []
        for (var j = 0; j < this.labArray.length; j++) {
          if (response[i].date == this.labArray[j].date) {

            // console.log(this.labArray[j].date)

            count++

            if (this.labArray[j].title && this.labArray[j].title != '')
              test_names.push(this.labArray[j].title)

            for (var k = 0; k < this.labArray.length; k++) {


              if (this.labArray[k].date == response[i].date) {
                this.labArray[k].count = count
                this.labArray[k].test_names = test_names

              }
            }

            // this.labArray[j].count=count

          }


        }

      }


      // setTimeout(() => {
      //   //  this.loading = false;
      //   this._changeDetectorRef.markForCheck();
      // }, 500);

    });

  }

  labreports2(url, params, token) {

    var attachments = []

    var flag;

    var flag1;

    var obj;

    this.actualdata = []

    this.attachments = []

    this.newAttachment = []

    var inv_names = [];

    var data = []

    this.appService.getecData1(url, params, token).subscribe((response1: any) => {
      this.labArray = []
      var response = response1.result

      for (var i = 0; i < response.length; i++) {
        obj = {}

        obj.lab_tests = []

        obj.image_attachments = []

        obj.data = []

        obj.impressions = []

        obj.comments = []

        obj.titles = []

        obj.file_names = []

        obj.test_taken_on = []

        obj.lab_attachments = []

        var labexists = false

        for (var k = 0; k < response[i].data.length; k++) {

          this.labArray.push(response[i].data[k])

          obj.impression = response[i].data[k].description

          obj.impressions.push(response[i].data[k].description)

          obj.comments.push(response[i].data[k].comments)

          obj.titles.push(response[i].data[k].title)

          obj.file_names.push(response[i].data[k].file_name)

          obj.test_taken_on.push(response[i].data[k].test_taken_on)

          obj.lab_attachments.push(response[i].data[k].lab_attachments)

          // obj.title = response[i].data[k].title

          // obj.file_name = response[i].data[k].file_name

          // obj.test_taken_on = response[i].data[k].test_taken_on

          flag = 0
          flag1 = 0

          if (response[i].data[k].lab_test) {
            if (response[i].data[k].lab_test.length > 0) {
              flag = 1
            }
          }

          if (response[i].data[k].image_attachments) {

            if (response[i].data[k].image_attachments.length > 0) {
              flag1 = 1
            }
            if (response[i].data[k].image_attachments.length == 0 && response[i].data[k].lab_id) {
              flag1 = 1
            }
          }

          if (flag1 || flag == 1) {

            obj.date = response[i].date


            if (response[i].data[k].lab_test) {
              if (response[i].data[k].lab_test.length > 0) {

                obj.lab_tests = obj.lab_tests.concat(response[i].data[k].lab_test)
              }
            }

            if (response[i].data[k].image_attachments) {
              if (response[i].data[k].image_attachments.length > 0) {
                obj.image_attachments = obj.image_attachments.concat(response[i].data[k].lab_test)
              }
              if (response[i].data[k].image_attachments.length == 0 && response[i].data[k].lab_id) {
                labexists = true
                obj.image_attachments = obj.image_attachments.concat(response[i].data[k].lab_test)
              }
            }


            if (response[i].data[k].image_attachments || response[i].data[k].lab_test) {
              obj.data.push(response[i].data[k])

              obj.data.labexists = labexists

            }
          }
        }

        if (obj.date) {
          this.attachments.push(obj)
        }
      }

      {
        for (var i = 0; i < response.length; i++) {
          for (var j = 0; j < response[i].data.length; j++) {
            this.actualdata.push(response[i].data[j]);
            this.labtest.push(response[i].data[j].lab_test);
          }
        }

        for (var i = 0; i < this.actualdata.length; i++) {

          var images = [];

          for (var j = 0; j < this.actualdata[i].image_attachments.length; j++) {
            images = images.concat(this.actualdata[i].image_attachments[j].split(','))
          }

          for (var j = 0; j < images.length; j++) {
            images[j] = images[j].trim()
          }

          this.actualdata[i].image_attachments = images
        }

        console.log(this.actualdata)



        for (var i = 0; i < this.actualdata.length; i++) {
          if (this.actualdata[i].lab_attachments != null) {
            for (var j = 0; j < this.actualdata[i].lab_attachments.length; j++) {
              if (this.actualdata[i].lab_attachments[j].attachment.startsWith("img_")) {
                this.newAttachment.push({
                  name: 'View report ',
                  path: environment.HOST + '/helyxonapi/cloud/image/upload/' + this.actualdata[i].lab_attachments[j].attachment,
                  id: this.actualdata[i].lab_attachments[j].id,
                  file_type: this.actualdata[i].lab_attachments[j].file_type,
                  replace: 'Replace Attachment',
                  overviewid: this.actualdata[i].overview_id,
                  file_name: this.actualdata[i].file_name,
                  test_taken_on: this.actualdata[i].test_taken_on,
                  labid: this.actualdata[i].lab_id,
                  description: this.actualdata[i].description && this.actualdata[i].description != 'null' ? this.actualdata[i].description : '',
                  comments: this.actualdata[i].comments && this.actualdata[i].comments != 'null' ? this.actualdata[i].comments : '',
                  labtest: this.actualdata[i].lab_test,
                  date: this.actualdata[i].date,
                  type: 1,
                  title: this.actualdata[i].title,
                  category: this.actualdata[i].category
                })
              } else {
                this.newAttachment.push({
                  name: 'View report ',
                  path: environment.HOST + '/helyxonapi/cloud/pdf/upload/' + this.actualdata[i].lab_attachments[j].attachment,
                  id: this.actualdata[i].lab_attachments[j].id,
                  file_type: this.actualdata[i].lab_attachments[j].file_type,
                  replace: 'Replace Attachment',
                  overviewid: this.actualdata[i].overview_id,
                  file_name: this.actualdata[i].file_name,
                  test_taken_on: this.actualdata[i].test_taken_on,
                  labid: this.actualdata[i].lab_id,
                  description: this.actualdata[i].description && this.actualdata[i].description != 'null' ? this.actualdata[i].description : '',
                  comments: this.actualdata[i].comments && this.actualdata[i].comments != 'null' ? this.actualdata[i].comments : '',
                  labtest: this.actualdata[i].lab_test,
                  date: this.actualdata[i].date,
                  type: 2,
                  title: this.actualdata[i].title,
                  category: this.actualdata[i].category
                })
              }
            }
          }
        }


        for (var i = 0; i < this.labtest.length; i++) {
          if (this.labtest[i] != null) {
            this.labReportList.push(this.labtest[i]);
          }
        }
      }

      for (var i = 0; i < this.attachments.length; i++) {
        var exists = 0;

        for (var k = 0; k < this.newAttachment.length; k++) {
          if (this.attachments[i].date == this.newAttachment[k].date) {
            exists = 1
          }
        }

        if (exists == 1) {
          this.attachments[i].exists = 1
        }
        else {
          this.attachments[i].exists = 0
        }
      }

      for (var i = 0; i < this.newAttachment.length; i++) {
        if (this.newAttachment[i].labid) {
        }
      }

      for (var i = 0; i < response.length; i++) {
        var count = 0
        var test_names = []
        for (var j = 0; j < this.labArray.length; j++) {
          if (response[i].date == this.labArray[j].date) {

            // console.log(this.labArray[j].date)

            count++

            if (this.labArray[j].title && this.labArray[j].title != '')
              test_names.push(this.labArray[j].title)

            for (var k = 0; k < this.labArray.length; k++) {


              if (this.labArray[k].date == response[i].date) {
                this.labArray[k].count = count
                this.labArray[k].test_names = test_names

              }
            }

            // this.labArray[j].count=count

          }


        }

      }


      if (params.category == "Unseen") {
        this.labArray = this.labArray.filter((res => res.lab_attachments.length > 0));

      }

      // setTimeout(() => {
      //   //  this.loading = false;
      //   this._changeDetectorRef.markForCheck();
      // }, 500);

    });

  }

  pacs() {
    // alert()

    const dialogRef = this.dialog.open(Dialog_addpacs, {
      width: '480px',
      data: { account_email: this.Account_id, token: this.auth, patient_email: this.patientemail }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result)
        console.log(result.ID)
        console.log(result.MainDicomTags.PatientID)

        var params = {
          account_email: this.Account_id,
          patient_email: this.patientemail,
          fl_number: result.MainDicomTags.PatientID,
          pac_server_id: result.ID
        }

        console.log(params)

        this.appService.postData1(this.urlPoints.createPac, params, this.auth).subscribe((response: any) => {
          this.checkPacs()
        });

      }
    });
  }

  setFilterType(i) {
    this.filter_type = i

    if (i == 1) {
      var params_labreports = {
        account_email: this.Account_id,
        patient_email: this.patientemail,
      }

      this.labreports(this.urlPoints.getLabReports, params_labreports, this.auth)
    }

    if (i == 2) {
      var params_labreports2 = {
        account_email: this.Account_id,
        patient_email: this.patientemail,
        category: 'Unseen'
      }

      this.labreports2(this.urlPoints.getLabReports, params_labreports2, this.auth)
    }

    if (i == 3) {
      var params_labreports3 = {
        account_email: this.Account_id,
        patient_email: this.patientemail,
        category: 'abnormal'
      }
      this.labreports2(this.urlPoints.getLabReports, params_labreports3, this.auth)
    }
  }


  uploadreport() {
    const dialogRef = this.dialog.open(DialogOverviewExampleuploadreport, {
      width: '50%',
      data: {
        mypdfdata: ''
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.Account_id = sessionStorage.getItem("acc_email");
      var type: any = sessionStorage.getItem("is_live")
      this.MR = sessionStorage.getItem("mr");

      if (result) {
        console.log("result 806", result)

        var SearchDataparams = {
          'mr_number': this.MR.toUpperCase(),
          "account_email": this.Account_id
        };
        console.log("paarms", SearchDataparams);
        if (type == 0) {
          this.appService.postecData(environment.epic + "/service/search", SearchDataparams, this.auth).subscribe((response: any) => {

            if (response[0]) {
              this.patientemail = response[0].email
              this.account_id = response[0].account_id

              for (var i = 0; i < result.fileList.length; i++) {
                console.log('starts with', result.fileList[i].type)

                this.appService.postimgData2('https://test.epicare.net', result.fileList[i].type.startsWith('image') ? this.urlPoints.imageUpload : this.urlPoints.pdfUpload, result.fileList[i], this.auth, result.fileList[i].type.startsWith('image') ? 1 : 2).subscribe((response: any) => {
                  this.uploadedimg = response.body;

                  if (this.uploadedimg) {
                    if (this.uploadedimg.image)
                      this.files.push(this.uploadedimg.image[0].filename)
                    else
                      this.files.push(this.uploadedimg.pdf[0].filename)
                  }

                  if (this.files.length == result.fileList.length) {

                    var params = {
                      report: this.files,
                      description: result.description,
                      comments: result.comments,
                      lab_test: result.tests.split(','),
                      account_email: this.Account_id,
                      patient_email: this.patientemail,
                      title: result.title,
                      category: result.abnormal ? 'abnormal' : "seen"

                    }
                    console.log("params 847", params);
                    this.appService.postecData(environment.epic + '/service/createlabreport', params, this.auth).subscribe((response: any) => {

                      // if (this.filter_type == 1) {
                      //   var params_labreports = {
                      //     account_email: this.account_id,
                      //     patient_email: this.patientemail,
                      //   }

                      //   this.labreports(this.urlPoints.labReports, params_labreports, this.auth)
                      // }

                      // if (this.filter_type == 2) {
                      //   var params_labreports2 = {
                      //     account_email: this.account_id,
                      //     patient_email: this.patientemail,
                      //     category: 'Unseen'
                      //   }

                      //   this.labreports2(this.urlPoints.labReports, params_labreports2, this.auth)
                      // }

                      // if (this.filter_type == 3) {
                      //   var params_labreports3 = {
                      //     account_email: this.account_id,
                      //     patient_email: this.patientemail,
                      //     category: 'abnormal'
                      //   }

                      //   this.labreports2(this.urlPoints.labReports, params_labreports3, this.auth)
                      // }
                    });
                  }

                });
              }


            }
          });
        } else if (type == 1) {
          this.appService.postecData(environment.epic_dev + "/service/search", SearchDataparams, this.auth).subscribe((response: any) => {
            console.log("P2P", response);
            if (response[0]) {
              this.patientemail = response[0].email
              this.account_id = response[0].account_id


            }
          });
        }

      }
    });
  }
}

@Component({
  selector: 'ausculation',
  templateUrl: 'ausculation.html',
  styleUrls: ['ausculation.scss']
})
export class DialogOverviewAusculation {

  urlPoints;
  getdata;
  patientemail;
  MR;
  auth = localStorage.getItem("authentication");
  getaudiosrc = "";
  getExamination;

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewAusculation>, private appService: AppService, private window: Window, pubnub: PubNubAngular, private ngZone: NgZone, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router, private toastr: ToastrService, public dialog: MatDialog, private spinner: NgxSpinnerService, private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogAusculationData) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.getdata = data;
  }

  ngOnInit(): void {

    this.MR = this.getdata.patient_email;
    var SearchDataparamss = {
      'mr_number': this.MR.toUpperCase(),
      "account_email": this.getdata.account_email
    };
    console.log("paarms", SearchDataparamss);

    this.appService.postecData(environment.epic + "/service/search", SearchDataparamss, this.auth).subscribe((response: any) => {
      if (response[0]) {
        this.patientemail = response[0].email;
        //this.account_id = response[0].account_id;
        this.getAusculation(this.patientemail);
      }
    })
  }

  getAusculation(getpatientemail) {
    var SearchDataparams = {
      "account_email": this.getdata.account_email,
      "patient_email": getpatientemail
    };
    this.appService.getecData(environment.epic + "/service/getvitallausculation", SearchDataparams, this.auth).subscribe((response: any) => {
      console.log(response);
      this.getaudiosrc = 'https://ivital.co/cloud/files/aus/' + response.ausculation[0].file_name;
      this.getExamination = response.ausculation[0].examination;
      console.log(this.getaudiosrc);
    })

  }

  closechart() {
    this.dialogRef.close();
  }
}


export interface Dialog__Datachart {
  mypdfdata: any
}

@Component({
  selector: 'viewchart',
  templateUrl: 'viewchart.html',
  styleUrls: ['viewchart.scss']
})

export class DialogOverviewExampleviewchart {

  urlPoints;
  url;

  form: FormGroup;

  maxDate = moment(new Date()).format('YYYY-MM-DD')
  from_Date;
  to_Date;

  fromdate;
  todate;

  auth = localStorage.getItem("authentication")
  token = sessionStorage.getItem("access_token");
  code = sessionStorage.getItem("code");
  account_id = sessionStorage.getItem("acc_email");

  imgURL = [];
  Account_id;
  MR;
  patientemail;

  glucoivitaldata;
  bpivitaldata;

  options: any;
  options1: any;

  date_picker;

  block = 1;
  bpdata;

  // Date1;
  // Date2;





  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleviewchart>, private appService: AppService, private window: Window, pubnub: PubNubAngular, private ngZone: NgZone, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router, private toastr: ToastrService, public dialog: MatDialog, private spinner: NgxSpinnerService, private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Dialog__Datachart) {
    this.urlPoints = AppConfig.settings.EndPoints;

    this.date_picker = this.fb.group({
      fromDate1: [''],
      toDate2: [''],
    })

  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }



  ngOnInit(): void {

    var type: any = sessionStorage.getItem("is_live")
    this.Account_id = sessionStorage.getItem("acc_email")
    this.MR = sessionStorage.getItem("mr");
    console.log("this.Account_id,this.MR", this.Account_id, this.MR);
    var SearchDataparams = {
      'mr_number': this.MR.toUpperCase(),
      "account_email": this.Account_id
    };
    console.log("paarms", SearchDataparams);
    if (type == 0) {
      this.appService.postecData(environment.epic + "/service/search", SearchDataparams, this.auth).subscribe((response: any) => {
        if (response[0]) {
          this.patientemail = response[0].email;
          this.account_id = response[0].account_id;

          this.call_bp();
          this.call_gluco(1);
        }
      })
    } else if (type == 1) {
      this.appService.postecData(environment.epic_dev + "/service/search", SearchDataparams, this.auth).subscribe((response: any) => {
        if (response[0]) {
          this.patientemail = response[0].email;
          this.account_id = response[0].account_id;

          this.call_bp();
          this.call_gluco(1);
          this.setBlock(1)
        }
      })
    }

  }

  // get_data(){


  // }
  closechart() {
    this.dialogRef.close();
  }

  setBlock(i) {
    this.block = i
    this.call_gluco(i)
  }

  call_bp() {
    var type: any = sessionStorage.getItem("is_live");
    var params = {
      "account_email": this.Account_id,
      "patient_email": this.patientemail,
      "from_date": moment(new Date()).format('YYYY-MM-DD'),
      "to_date": moment(new Date()).format('YYYY-MM-DD'),
      "is_live": parseInt(type),
      "vital": "bp"
    }


    this.appService.postData(this.urlPoints.bpivital, params, this.auth).subscribe((response: any) => {
      this.bpivitaldata = response;

      if (this.bpivitaldata != "") {
        this.bpdata = ""
        this.options = {
          chart: {
            type: 'scatter',
            zoomType: 'xy'
          },
          accessibility: {
            description: '',
          },
          title: {
            text: 'Blood Pressure'
          },

          xAxis: {
            title: {
              enabled: true,
              text: 'Time'
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true,
            type: 'datetime',
            labels: {
              rotation: -25,
              formatter: function () {
                return Highcharts.dateFormat("%H:%M", this.value);
              }
            }
          },
          yAxis: {
            title: {
              text: 'Values'
            }
          },
          legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            x: 0,
            y: 0,
            floating: true,
            borderWidth: 1
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            scatter: {
              marker: {
                radius: 5,
                states: {
                  hover: {
                    enabled: true,
                    lineColor: 'rgb(100,100,100)'
                  }
                }
              },
              states: {
                hover: {
                  marker: {
                    enabled: false
                  }
                }
              },
              tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormatter: function () {
                  return 'Time : ' + Highcharts.dateFormat('%H:%M', this.x) + ', Value : ' + this.y
                }
              }
            }
          },
          // 
          series: this.bpivitaldata
        }
        console.log('this.bpivitaldata', this.bpivitaldata);
        Highcharts.chart('bp', this.options);
      } else {
        this.bpdata = "No records Found for Blood Pressure"
      }

    });

  }

  call_gluco(data) {
    console.log("data", data);
    var type: any = sessionStorage.getItem("is_live")

    var unitvalue;

    if (data == 1) {
      var params2 = {
        "account_email": this.Account_id,
        "patient_email": this.patientemail,
        "from_date": moment(new Date()).format('YYYY-MM-DD'),
        "to_date": moment(new Date()).format('YYYY-MM-DD'),
        "is_live": parseInt(type),
        "vital": "glucometer",
        "unit_value": "mmol"
      }

      unitvalue = 'mmol'
    } else if (data == 2) {
      var params2 = {
        "account_email": this.Account_id,
        "patient_email": this.patientemail,
        "from_date": moment(new Date()).format('YYYY-MM-DD'),
        "to_date": moment(new Date()).format('YYYY-MM-DD'),
        "is_live": parseInt(type),
        "vital": "glucometer",
        "unit_value": "mgdl"
      }

      unitvalue = 'mgdl'
    }


    this.appService.postData(this.urlPoints.bpivital, params2, this.auth).subscribe((response: any) => {
      this.bpdata = ""
      this.glucoivitaldata = response;
      if (this.glucoivitaldata != "") {
        this.options1 = {
          chart: {
            type: 'scatter',
            zoomType: 'xy'
          },
          accessibility: {
            description: '',
          },
          title: {
            text: 'Glucometer'
          },

          xAxis: {
            title: {
              enabled: true,
              text: 'Time'
            },
            type: 'datetime',
            labels: {
              rotation: -25,
              formatter: function () {
                return Highcharts.dateFormat("%H:%M", this.value);
              }
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true
          },
          yAxis: {
            title: {
              text: unitvalue
            }
          },
          legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            x: 0,
            y: 20,
            floating: true,
            borderWidth: 1
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            scatter: {
              marker: {
                radius: 5,
                states: {
                  hover: {
                    enabled: true,
                    lineColor: 'rgb(100,100,100)'
                  }
                }
              },
              states: {
                hover: {
                  marker: {
                    enabled: false
                  }
                }
              },
              tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                // pointFormat: '{point.x} , {point.y} ',
                pointFormatter: function () {
                  return 'Time : ' + Highcharts.dateFormat('%H:%M', this.x) + ',' + unitvalue + ' : ' + this.y
                }
              }
            }
          },
          series: this.glucoivitaldata

        }

        Highcharts.chart('glucometer', this.options1)

      } else {
        this.bpdata = "No Records Found for Glucometer"
      }

    });
  }


}



export interface DialogData_labnotes {
  token: any
}


@Component({
  selector: 'labnotes',
  templateUrl: 'labnotes.html',
  styleUrls: ['labnotes.scss']
})

export class DialogOverviewExamplelabnotes {
  block = 1;
  newNote = '';
  disabled = false
  noteList;
  submitted = false
  urlPoints: any;

  auth = localStorage.getItem("authentication")
  token = sessionStorage.getItem("access_token");
  code = sessionStorage.getItem("code");
  account_id = sessionStorage.getItem("acc_email");
  doctor_id = sessionStorage.getItem("doc_id");

  Account_id;
  MR;
  patientemail;

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExamplelabnotes>, private appService: AppService, private window: Window, pubnub: PubNubAngular, private ngZone: NgZone, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router, private toastr: ToastrService, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData_labnotes) {
    this.urlPoints = AppConfig.settings.EndPoints;
  }

  ngOnInit(): void {
    var type: any = sessionStorage.getItem("is_live")
    this.Account_id = sessionStorage.getItem("acc_email")
    this.MR = sessionStorage.getItem("mr");
    console.log("this.Account_id,this.MR", this.Account_id, this.MR);
    var SearchDataparams = {
      'mr_number': this.MR.toUpperCase(),
      "account_email": this.Account_id
    };
    console.log("paarms", SearchDataparams);
    if (type == 0) {
      this.appService.postecData(environment.epic + "/service/search", SearchDataparams, this.token).subscribe((response: any) => {
        if (response[0]) {
          this.patientemail = response[0].email;
          this.account_id = response[0].account_id;
          this.getNotes()
        }
      })
    } else if (type == 1) {
      this.appService.postecData(environment.epic_dev + "/service/search", SearchDataparams, this.token).subscribe((response: any) => {
        if (response[0]) {
          this.patientemail = response[0].email;
          this.account_id = response[0].account_id;
          this.getNotes()
        }
      })
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  setBlock(i) {
    this.block = i
    this.newNote = ''
    this.submitted = false
  }

  save() {

    this.submitted = true;

    var params = {
      account_email: this.Account_id,
      lab_note: this.newNote,
      patient_email: this.patientemail,
      doctor_id: this.doctor_id
    }

    if (this.newNote != '') {
      this.disabled = true
      var type: any = sessionStorage.getItem("is_live");
      if (type == 0) {
        this.appService.postecData(environment.epic + "/service/saveicslab", params, this.auth).subscribe((response: any) => {
          this.toastr.success("Note added!")
          this.block = 1
          this.getNotes()
          this.disabled = false
        });
      } else if (type == 1) {
        this.appService.postecData(environment.epic_dev + "/service/saveicslab", params, this.auth).subscribe((response: any) => {
          this.toastr.success("Note added!")
          this.block = 1
          this.getNotes()
          this.disabled = false
        });
      }

    }
  }

  getNotes() {
    var params = {
      account_email: this.Account_id,
      patient_email: this.patientemail,
      doctor_id: this.doctor_id
    }

    var type: any = sessionStorage.getItem("is_live");

    if (type == 0) {
      this.appService.getecData(environment.epic + "/service/geticslab", params, this.auth).subscribe((response: any) => {
        this.noteList = [];
        this.noteList = response.details
        this.noteList.reverse()

      });
    } else if (type == 1) {
      this.appService.getecData(environment.epic_dev + "/service/geticslab", params, this.auth).subscribe((response: any) => {
        this.noteList = [];
        this.noteList = response.details
        this.noteList.reverse()

      });
    }


  }

  remove(id, notes) {
    var params = {
      account_email: this.Account_id,
      lab_note: notes,
      patient_email: this.patientemail,
      doctor_id: this.doctor_id,
      lab_id: id,
      status: 0

    }
    var type: any = sessionStorage.getItem("is_live");

    if (type == 0) {
      this.appService.postecData(environment.epic + "/service/saveicslab", params, this.auth).subscribe((response: any) => {
        this.toastr.success("Note deleted!")
        this.block = 1
        this.getNotes()
      });
    } else if (type == 1) {
      this.appService.postecData(environment.epic + "/service/saveicslab", params, this.auth).subscribe((response: any) => {
        this.toastr.success("Note deleted!")
        this.block = 1
        this.getNotes()
      });
    }


  }

}


export interface DialogData_attachment_edit {
  description: any;
  comments: any;
  category: string;
  overview_id: any,
  lab_tests: any,
  date: any,
  obj: any,
  title: any,
  edit: any
}


@Component({
  selector: 'attachment_edit',
  templateUrl: 'attachment_edit.html',
  styleUrls: ['attachment_edit.scss']
})

export class Dialog_attachment_edit {
  value
  urlPoints;
  description;
  comments;
  tests_str;
  files;
  UploadImages = [];
  imgURL = [];
  date;
  abnormal = false;
  fileList = [];
  fileName;
  test_taken_on;
  dateMax = moment(new Date()).format("YYYY-MM-DD");

  constructor(
    public dialogRef: MatDialogRef<Dialog_attachment_edit>,
    @Inject(MAT_DIALOG_DATA) public data: any, public router: Router, private fb: FormBuilder, public route: ActivatedRoute) {
  }
  ngOnInit(): void {
    this.tests_str = this.data.obj.lab_tests.join(',')
    this.date = this.data.obj.date

    if (this.data.category == 'abnormal') {
      this.abnormal = true
    }
    if (this.data.comments)
      this.comments = this.data.comments

    if (this.data.description)
      this.description = this.data.description

    if (this.data.filename)
      this.fileName = this.data.filename

    if (this.data.test_taken_on)
      this.test_taken_on = this.data.test_taken_on

  }

  GetFile(e) {

    this.files = Array.from(e.target.files);

    if ((this.files[0].type === 'image/png')
      || (this.files[0].type === 'image/jpg')
      || (this.files[0].type === 'image/jpeg')
      || (this.files[0].type === 'image/tif')
      || (this.files[0].type === 'application/pdf')) {
      if (this.files[0].size > 10000000) {

      } else {
        this.UploadImages.push(e.target.files)

        for (let i = 0; i < this.files.length; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {

            this.imgURL.push({
              url: event.target.result,
              name: this.files[i].name,
              type: this.files[i].name.split('.')[this.files[i].name.split('.').length - 1]
            })
          }
          reader.readAsDataURL(e.target.files[i]);
          const file = (e.target as HTMLInputElement).files[i];
          this.fileList.push(file)

        }
      }
    } else {

    }

  }

  public removePicture(item, index) {
    this.imgURL.splice(index, 1);
    this.files.splice(index, 1);
    this.UploadImages.splice(index, 1);
    this.fileList.splice(index, 1);

    let file = document.getElementById("files") as HTMLInputElement;
    file.value = "";
  }

  save() {
    var obj = {
      //  fileList: this.fileList,
      abnormal: this.abnormal ? 1 : 0,
      description: this.description,
      comments: this.comments,
      filename: this.fileName,
      test_taken_on: this.test_taken_on

    }
    this.dialogRef.close(obj);

  }
  cancel() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}




@Component({
  selector: 'multiline',
  templateUrl: 'multiline.html',
  styleUrls: ['multiline.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})

export class Dialog_multiline {

  code = sessionStorage.getItem("code");
  public activity;
  public xData;
  public label;
  newdata: any = [];
  urlPoints;
  highchartsParams;
  pname;
  dob;
  mr;
  minDate: Date;
  maxDate: Date;
  dateForm;
  timestamp1;
  timeParts2
  timeParts1
  timestamp2;

  finaltimestamp1;
  finaltimestamp2;
  finaltime1;
  finaltime2;
  fromdate
  todate
  t1;
  t2;

  token = localStorage.getItem("authentication");
  type: any = sessionStorage.getItem("is_live");
  Account_id = sessionStorage.getItem("acc_email");


  date_Form
  pdfdata: any = [];

  printdownload = false;

  multiline_response;

  temp_min = 30;
  temp_max = 42;
  temp_steps = 2;

  pulse_min = 20;
  pulse_max = 160;
  pulse_steps = 20;

  oxygen_min = 70;
  oxygen_max = 105;
  oxygen_steps = 10;

  resp_min = 5;
  resp_max = 90;
  resp_steps = 5;

  pulse_x;
  pulse_y;
  pulselength;

  temp_x;
  temp_y;
  templength;

  oxygen_x;
  oxygen_y;
  oxygenlength;

  resp_x;
  resp_y;
  resplength;

  temp_options;
  pulse_options;
  oxygen_options;
  resp_options;
  //   new Date().setHours( new Date().getHours() - 2 )


  format1 = 'MM/dd/yyyy HH:mm';
  value1: Date;

  format2 = 'MM/dd/yyyy HH:mm';
  value2: Date;

  Block = 1;

  patientemail;
  MR;

  bpivitaldata;
  glucoivitaldata;

  options1;
  options;

  auth = localStorage.getItem("authentication")

  bpdata;

  constructor(
    public dialogRef: MatDialogRef<Dialog_multiline>,
    @Inject(MAT_DIALOG_DATA) public data: any, public router: Router, private spinner: NgxSpinnerService, public dialog: MatDialog, private appService: AppService, private fb: FormBuilder, public route: ActivatedRoute) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.maxDate = new Date();

    this.pname = sessionStorage.getItem("pname");
    var timedob: any = sessionStorage.getItem("dob");
    this.dob = moment.unix(timedob).format("DD-MMM-YYYY");
    this.mr = sessionStorage.getItem("mr");
    this.t1 = moment().add(-1, 'hours').format('HH:mm');
    this.t2 = moment().add(0, 'hours').format('HH:mm')


    this.dateForm = this.fb.group({
      date1: [new Date(), [Validators.required]],
      date2: [new Date(), [Validators.required]],
      time1: [this.t1, [Validators.required]],
      time2: [this.t2, [Validators.required]],
    })

    this.date_Form = this.fb.group({
      Date1: [new Date(new Date().setHours(new Date().getHours() - 1)), [Validators.required]],
      Date2: [new Date(), [Validators.required]],
    })
  }
  previewmultiline() {
    var params = {
      "account_email": this.Account_id,
      "access_code": this.code
    }

    if (this.type == 0) {
      console.log("environment.epicenvironment.epic", environment.epic + "/service/getivitaldoctortemplate/", params)
      this.appService.getecData(environment.epic + "/service/getivitaldoctortemplate/", params, this.token).subscribe((response: any) => {

        if (response.status == 1) {
          const dialogRef = this.dialog.open(DialogOverviewExampleDialogpdf1, {
            width: '60%',
            data: {
              mypdfdata: response.data
            }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {
              this.spinner.show();
              this.previewmultilinecall(result)
            }
          });

        }
      })
    } else if (this.type == 1) {
      this.appService.getecData(environment.epic_dev + "/service/getivitaldoctortemplate/", params, this.token).subscribe((response: any) => {
        if (response.status == 1) {
          const dialogRef = this.dialog.open(DialogOverviewExampleDialogpdf1, {
            width: '60%',
            data: {
              mypdfdata: response.data
            }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {
              this.spinner.show();
              this.previewmultilinecall(result)
            }
          });
        }
      })
    }
  }

  previewmultilinecall(data) {
    var node = document.getElementById('container_chart');
    var options = { quality: 1 };
    domtoimage.toJpeg(node, options).then((dataUrl) => {
      console.log(dataUrl)
      var mydata = this.base64ImageToBlob(dataUrl);

      var dummyurl = "https://ivital.co/cloud/image/image.php";
      this.appService.postimgData(dummyurl, mydata, this.token).subscribe((response: any) => {
        console.log("response", response);
        if (response.type == 4) {
          if (response.body.image[0].success == 1) {
            var Time1 = Math.trunc(new Date(this.date_Form.value.Date1).getTime() / 1000);
            var Time2 = Math.trunc(new Date(this.date_Form.value.Date2).getTime() / 1000);

            var imgurl = response.body.image[0].filename;
            var params = {
              "account_email": this.Account_id,
              "template_id": data.template_id,
              "code": this.code,
              "did": "",
              "start_time": Time1,
              "end_time": Time2,
              "mac_id": "ABDFJFDKJGD",
              "vital_version": "1.2.5",
              "graph_image": imgurl,
              "patient_id": this.mr
            }

            if (this.type == 0) {
              this.appService.postecData(environment.epic + "/service/generateivitalreport", params, this.token).subscribe((response: any) => {
                if (response.success == 1) {
                  this.spinner.hide();
                  window.open("https://test.epicare.net/PDF/" + response.file_name);
                }
              });
            } else if (this.type == 1) {
              this.appService.postecData(environment.epic_dev + "/service/generateivitalreport", params, this.token).subscribe((response: any) => {
                if (response.success == 1) {
                  this.spinner.hide();
                  window.open("https://epicare.net/PDF/" + response.file_name);
                }
              });
            }
          }
        }

      });
    });

  }

  base64ImageToBlob(str) {
    var pos = str.indexOf(';base64,');
    var type = str.substring(5, pos);
    var b64 = str.substr(pos + 8);
    var imageContent = atob(b64);
    var buffer = new ArrayBuffer(imageContent.length);
    var view = new Uint8Array(buffer);
    for (var n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    var blob = new Blob([buffer], { type: type });
    return blob;
  }


  printmultiline() {

    var divToPrint = document.getElementById('container_chart');
    var divglu = document.getElementById('glucometer');
    var divbp = document.getElementById('bp');
    var popupWin = window.open('', '_blank', 'width=600,height=600');
    popupWin.document.open();
    popupWin.document.write('<html><body onload="window.print()">' + this.dob + " / " + this.pname + " / " + this.mr + divToPrint.innerHTML + divglu.innerHTML + divbp.innerHTML + '</html>');
    popupWin.document.close();


  }

  date__form() {
    if (this.date_Form.valid) {
      console.log("this.dateForm.value", this.date_Form.value);
      this.fromdate = moment(this.date_Form.value.Date1).format('DD-MM-YYYY')
      this.todate = moment(this.date_Form.value.Date2).format('DD-MM-YYYY')

      var Time1 = Math.trunc(new Date(this.date_Form.value.Date1).getTime() / 1000);
      var Time2 = Math.trunc(new Date(this.date_Form.value.Date2).getTime() / 1000);

      this.call_bp();
      this.call_gluco(1);
      this.set_Block(1)

      var params = {
        "code": this.code.toUpperCase(),
        "patientID": sessionStorage.getItem("mr"),
        "from_date": this.fromdate,
        "to_date": this.todate,
        "from_time": Time1,
        "to_time": Time2
      }

      console.log("params", params);
      this.callchart(this.urlPoints.multilinechart, params);
      //  this.calltempchart(this.urlPoints.multilinechart , params);
    }
  }

  set_Block(i) {
    this.Block = i
    this.call_gluco(parseInt(i));
  }


  callchart(url, params) {
    this.spinner.show();
    this.appService.getData(this.urlPoints.multilinechart, params).subscribe((response: any) => {

      document.getElementById('container_chart').innerHTML = " ";
      this.newdata = response;
      this.activity = this.newdata;
      this.spinner.hide();
      this.printdownload = true;
      ['mousemove', 'touchmove', 'touchstart'].forEach(function (eventType) {
        document.getElementById('container_chart').addEventListener(
          eventType,
          function (e) {
            var chart,
              point,
              i,
              event;

            for (i = 0; i < Highcharts.charts.length; i = i + 1) {
              chart = Highcharts.charts[i];
              // Find coordinates within the chart
              event = chart.pointer.normalize(e);
              // Get the hovered point
              point = chart.series[0].searchPoint(event, true);

              if (point) {
                point.highlight(e);
              }
            }
          }
        );
      });

      function syncExtremes(e) {
        var thisChart = this.chart;

        if (e.trigger !== 'syncExtremes') {
          Highcharts.each(Highcharts.charts, function (chart) {
            if (chart !== thisChart) {
              if (chart.xAxis[0].setExtremes) {
                chart.xAxis[0].setExtremes(
                  e.min,
                  e.max,
                  undefined,
                  false,
                  { trigger: 'syncExtremes' }
                );
              }
            }
          });
        }
      }
      Highcharts.Pointer.prototype.reset = function () {
        return undefined;
      };
      Highcharts.Point.prototype.select = function (event) {
        event = this.series.chart.pointer.normalize(event);
        this.onMouseOver(); // Show the hover marker
        this.series.chart.tooltip.refresh(this); // Show the tooltip
        this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
      };

      console.log("this.activity this.activity", this.activity);
      this.xData = this.activity.xData;
      let that = this;
      if (this.activity) {
        this.activity.datasets.forEach(function (dataset, i) {

          dataset.data = Highcharts.map(dataset.data, function (val, j) {
            return [that.xData[j], val];
          });


          var chartDiv = document.createElement('div');
          chartDiv.className = 'chart';
          document.getElementById('container_chart').appendChild(chartDiv);

          Highcharts.chart(chartDiv, {
            chart: {
              marginLeft: 50,
              backgroundColor: 'white',
              height: 305,
              borderColor: '#58b9d6',
              borderWidth: 5,
              shadow: true
            },

            title: {
              text: dataset.name,
              align: 'left',
              margin: 0,
              style: { "color": "#333333", "fontSize": "15px" },
              x: 30
            },
            credits: {
              enabled: false
            },
            legend: {
              enabled: false
            },
            xAxis: {
              crosshair: true,
              gridLineWidth: 0.80,
              events: {
                setExtremes: syncExtremes
              },

              type: 'datetime',
              labels: {
                rotation: -25,
                formatter: function () {
                  return Highcharts.dateFormat("%H:%M", this.value);
                }
              }
            },
            yAxis: {
              gridLineWidth: 0.80,
              title: {
                text: null
              },
              tickInterval: dataset.steps,
              min: dataset.lower_limit,
              max: dataset.thresh_high,
              plotLines: [
                {
                  value: dataset.thresh_high,
                  color: '#FB6183',
                  zIndex: 100,
                  width: 2,

                },
                {
                  value: dataset.thresh_low,
                  color: '#4DA5E1',
                  zIndex: 100,
                  width: 2,

                },
                {
                  value: dataset.median,
                  color: 'Black',
                  zIndex: 100,
                  width: 1,

                },
                {
                  value: dataset.lower_grid,
                  color: 'grey',
                  zIndex: 100,
                  width: 1,

                },
                {
                  value: dataset.lower_limit,
                  color: 'grey',
                  zIndex: 100,
                  width: 1,

                },
                {
                  value: dataset.upper_grid,
                  color: 'grey',
                  zIndex: 100,
                  width: 1,

                },
                {
                  value: dataset.upper_limit,
                  color: 'grey',
                  zIndex: 100,
                  width: 1,

                },

              ]
            },



            tooltip: {
              positioner: function () {
                return {
                  x: this.chart.chartWidth - 200,
                  y: 10 // align to title
                };
              },

              borderWidth: 0,
              backgroundColor: "white",
              pointFormat: '{point.y}',
              headerFormat: '',
              shadow: false,
              style: {
                fontSize: '18px'
              },
              valueDecimals: dataset.valueDecimals
            },

            series: [{
              data: dataset.data,
              name: dataset.name,
              type: dataset.type,
              color: Highcharts.getOptions().colors[i],
              fillOpacity: 0.3,
              tooltip: {
                valueSuffix: ' ' + dataset.unit
              }
            }]
          });
        });
      }

    });
  }

  close() {
    this.dialogRef.close()
  }


  ngOnInit() {
    var type: any = sessionStorage.getItem("is_live")
    this.Account_id = sessionStorage.getItem("acc_email")
    this.MR = sessionStorage.getItem("mr");
    console.log("this.Account_id,this.MR", this.Account_id, this.MR);
    var SearchDataparams = {
      'mr_number': this.MR.toUpperCase(),
      "account_email": this.Account_id
    };
    console.log("paarms", SearchDataparams);
    if (type == 0) {
      this.appService.postecData(environment.epic + "/service/search", SearchDataparams, this.auth).subscribe((response: any) => {
        if (response[0]) {
          this.patientemail = response[0].email;

        }
      })
    } else if (type == 1) {
      this.appService.postecData(environment.epic_dev + "/service/search", SearchDataparams, this.auth).subscribe((response: any) => {
        if (response[0]) {
          this.patientemail = response[0].email;
        }
      })
    }
  }


  call_bp() {
    console.log("from date", this.fromdate, this.todate)
    var type: any = sessionStorage.getItem("is_live");
    var params = {
      "account_email": this.Account_id,
      "patient_email": this.patientemail,
      "from_date": moment(this.date_Form.value.Date1).format('YYYY-MM-DD'),
      "to_date": moment(this.date_Form.value.Date2).format('YYYY-MM-DD'),
      "is_live": parseInt(type),
      "vital": "bp"
    }


    this.appService.postData(this.urlPoints.bpivital, params, this.auth).subscribe((response: any) => {
      this.bpivitaldata = response;

      if (this.bpivitaldata != "") {

        this.options = {
          chart: {
            type: 'scatter',
            zoomType: 'xy'
          },
          accessibility: {
            description: '',
          },
          title: {
            text: 'Blood Pressure'
          },

          xAxis: {
            title: {
              enabled: true,
              text: 'Time'
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true,
            type: 'datetime',
            labels: {
              rotation: -25,
              formatter: function () {
                return Highcharts.dateFormat("%d-%b-%y %H:%M", this.value);
              }
            }
          },
          yAxis: {
            title: {
              text: 'Values'
            }
          },
          legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            x: 0,
            y: 0,
            floating: true,
            borderWidth: 1
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            // scatter:{
            //     lineWidth:2
            // },
            // tooltip: {
            //     headerFormat: '<b>{series.name}</b><br>',
            //     pointFormatter: function() {
            //       return 'Time : '+ Highcharts.dateFormat('%d-%b-%y %H:%M', this.x ) +', Value : '+  this.y 
            //    }
            // }
            scatter: {
              marker: {
                radius: 5,
                states: {
                  hover: {
                    enabled: true,
                    lineColor: 'rgb(100,100,100)'
                  }
                }
              },
              states: {
                hover: {
                  marker: {
                    enabled: false
                  }
                }
              },
              tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormatter: function () {
                  return 'Time : ' + Highcharts.dateFormat('%d-%b-%y %H:%M', this.x) + ', Value : ' + this.y
                }
              }
            }
          },
          series: this.bpivitaldata
        }
        console.log('this.bpivitaldata', this.bpivitaldata);

        // this.options = {

        //     title: {
        //                 text: 'Blood Pressure'
        //             },

        //            xAxis: {
        //                 title: {
        //                     enabled: true,
        //                     text: 'Time'
        //                 },
        //                 startOnTick: true,
        //                 endOnTick: true,
        //                 showLastLabel: true,
        //                 type: 'datetime',
        //                 labels: {
        //                   rotation:-25, 
        //                   formatter: function() {
        //                     return Highcharts.dateFormat("%d-%b-%y %H:%M", this.value);
        //                   }  
        //                }
        //             },
        //             yAxis: {
        //                 title: {
        //                     text: 'Values'
        //                 }
        //             },

        //     legend: {
        //         layout: 'vertical',
        //         align: 'right',
        //         verticalAlign: 'middle'
        //     },

        //     plotOptions: {
        //         series: {
        //             label: {
        //                 connectorAllowed: false
        //             },
        //             pointStart: 2010
        //         }
        //     },

        //     series: this.bpivitaldata,

        //     responsive: {
        //         rules: [{
        //             condition: {
        //                 maxWidth: 500
        //             },
        //             chartOptions: {
        //                 legend: {
        //                     layout: 'horizontal',
        //                     align: 'center',
        //                     verticalAlign: 'bottom'
        //                 }
        //             }
        //         }]
        //     }
        // }


        Highcharts.chart('bp', this.options);

      } else {
        this.bpdata = "No Records Found For Blood Pressure"
      }

    });
  }

  call_gluco(data) {
    console.log("from date", this.fromdate, this.todate)
    console.log("data", data);
    var type: any = sessionStorage.getItem("is_live")

    var unitvalue;

    if (data == 1) {
      var params2 = {
        "account_email": this.Account_id,
        "patient_email": this.patientemail,
        "from_date": moment(this.date_Form.value.Date1).format('YYYY-MM-DD'),
        "to_date": moment(this.date_Form.value.Date2).format('YYYY-MM-DD'),
        "is_live": parseInt(type),
        "vital": "glucometer",
        "unit_value": "mmol"
      }

      unitvalue = 'mmol'
    } else if (data == 2) {
      var params2 = {
        "account_email": this.Account_id,
        "patient_email": this.patientemail,
        "from_date": moment(this.date_Form.value.Date1).format('YYYY-MM-DD'),
        "to_date": moment(this.date_Form.value.Date2).format('YYYY-MM-DD'),
        "is_live": parseInt(type),
        "vital": "glucometer",
        "unit_value": "mgdl"
      }

      unitvalue = 'mgdl'
    }


    this.appService.postData(this.urlPoints.bpivital, params2, this.auth).subscribe((response: any) => {
      this.glucoivitaldata = response;

      if (this.glucoivitaldata != '') {

        this.options1 = {
          chart: {
            type: 'scatter',
            zoomType: 'xy'
          },
          accessibility: {
            description: '',
          },
          title: {
            text: 'Glucometer',
            style: {
              marginLeft: 20
            }
          },

          xAxis: {
            title: {
              enabled: true,
              text: 'Time'
            },
            type: 'datetime',
            labels: {
              rotation: -25,
              formatter: function () {
                return Highcharts.dateFormat('%d-%b-%y %H:%M', this.value);
              }
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true
          },
          yAxis: {
            title: {
              text: unitvalue
            }
          },
          legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            x: 0,
            y: 20,
            floating: true,
            borderWidth: 1
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            scatter: {
              marker: {
                radius: 5,
                states: {
                  hover: {
                    enabled: true,
                    lineColor: 'rgb(100,100,100)'
                  }
                }
              },
              states: {
                hover: {
                  marker: {
                    enabled: false
                  }
                }
              },
              tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                // pointFormat: '{point.x} , {point.y} ',
                pointFormatter: function () {
                  return 'Time : ' + Highcharts.dateFormat('%d-%b-%y %H:%M', this.x) + ',' + unitvalue + ' : ' + this.y
                }
              }
            }
          },
          series: this.glucoivitaldata

        }

        Highcharts.chart('glucometer', this.options1)

      } else {
        this.bpdata = "No Records Found For Glucometer"
      }

    });
  }

  cancel() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'pdfpreview1',
  templateUrl: 'pdfpreview1.html',
  styleUrls: ['pdfpreview1.scss']
})

export class DialogOverviewExampleDialogpdf1 {

  urlPoints;
  mypdfdata: any = [];

  token = sessionStorage.getItem("access_token")

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialogpdf1>, private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDatapdf) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.mypdfdata.push(this.data.mypdfdata);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  callpreviewpdf(data) {
    this.dialogRef.close(data);
  }

  closepop() {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'data-table',
  templateUrl: 'data-table.html',
  styleUrls: ['data-table.scss']
})

export class DialogOverviewExampleDataTable {

  startDate = new Date()
  endDate = new Date()

  maxDate=new Date()
  urlPoints;
  dropDownArray=[]
  dropDate;
  tableData=[]
  // mypdfdata:any = [];

  // token = sessionStorage.getItem("access_token")

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDataTable>, private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.urlPoints = AppConfig.settings.EndPoints;
    console.log(this.urlPoints)
    // this.getData(this.startDate,this.endDate)
    this.getDropDown()

    // this.mypdfdata.push(this.data.mypdfdata);
  }

  getDropDown()
  {
    var params = {
      pid:this.data.mrn,
      code:this.data.code,
      dtype:0,
      ddate:0,
      mode:0
    }

    this.appService.getData(this.urlPoints.dropdowndates, params).subscribe((response: any) => {
      console.log(response)

      if(response.ivital[0].success == 1)
      {
        console.log(response.ivital[0].response)
        this.dropDownArray = response.ivital[0].response

        this.dropDate =this.dropDownArray[this.dropDownArray.length - 1].date

        console.log(this.dropDate)

        this.getData(this.dropDate,this.dropDate)

        var arr = this.dropDate.split('-')

        var date_str = arr[2] + ' ' + arr[1] + ' ' + arr[0]

        this.startDate = new Date(date_str)

        this.endDate = new Date(date_str)

        for(var i=0;i<this.dropDownArray.length;i++)
        {
          var date_arr = this.dropDownArray[i].date.split('-')

          var date_str = date_arr[2] + ' ' + date_arr[1] + ' ' + date_arr[0]

          this.dropDownArray[i].date1 = moment(date_str).format('DD-MMM-YYYY')
        }

        console.log(this.dropDownArray)

      }
      else
      {
        this.getData(moment(this.startDate).format('DD-MM-YYYY'),moment(this.endDate).format('DD-MM-YYYY'))
      }
    });
  }

  onSelectChange(e)
  {
      console.log(e.target.value)

      this.dropDate = e.target.value

      console.log(this.dropDate)

      this.getData(this.dropDate,this.dropDate)

      var arr = this.dropDate.split('-')

      var date_str = arr[2] + ' ' + arr[1] + ' ' + arr[0]

      this.startDate = new Date(date_str)

      this.endDate = new Date(date_str)

  }

  getData(start, end)
  {
    var params ={
      code:this.data.code,
      patientID:this.data.mrn,
      from_date:start,
      to_date:end,

    }

    this.appService.getData(this.urlPoints.getallvitalsdata, params).subscribe((response: any) => {
      console.log(response.data[0].data)

      this.tableData = []
      for(var i=0;i<response.data.length;i++)
      {
        this.tableData = this.tableData.concat(response.data[i].data)
      }

      console.log(this.tableData)
      this.tableData.reverse()
      // this.tableData = response.data[0].data.reverse()


    })

    console.log(params)
  }

  export()
  {
    var fileName = moment(this.startDate).format("DD MMM yyyy") + " to " + moment(this.endDate).format("DD MMM yyyy") +" Report.xlsx"
    let element = document.getElementById("dataTable");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, fileName);
  }

  onDateChange(e) {
    console.log(e.target.value)
    
    this.getData(moment(this.startDate).format('DD-MM-YYYY'),moment(this.endDate).format('DD-MM-YYYY'))

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  // callpreviewpdf(data){
  //     this.dialogRef.close(data);
  // }

  close() {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'pac_attachments',
  templateUrl: 'pac_attachments.html',
  styleUrls: ['pac_attachments.scss']

})

export class Dialog_pac_attachments {
  // hpi;
  urlPoints;
  file_no;
  list
  uploadedimg;
  imgURL = []
  files = []
  host
  type: any = sessionStorage.getItem("is_live")


  constructor(
    public dialogRef: MatDialogRef<Dialog_pac_attachments>, private appService: AppService, public dialog: MatDialog, private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any, public router: Router, private fb: FormBuilder) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.host = this.type == 0 ? environment.epic1 : environment.epic_dev1
  }
  ngOnInit(): void {
    console.log(this.data)
    // this.hpi = this.data.hpi

    var count = 0

    for (var i = 0; i < this.data.pacAttachments.length; i++) {
      this.imgURL[i] = 'assets/view.svg'
      this.files[i] = []
      var params = {
        account_email: this.data.account_email,
        attachment_id: this.data.pacAttachments[i]
      }

      this.appService.getecData1(this.urlPoints.getDICOMPreview, params, this.data.token).subscribe((response: any) => {
        console.log(response)

        this.imgURL[count] = (this.type == 0 ? environment.epic1 : environment.epic_dev1) + '/helyxonapi/cloud/dcm/' + response.file_name

        count++

        console.log(this.imgURL)
      });

    }

    // console.log(this.imgURL)
  }

  // save() {
  //   // this.dialogRef.close(this.hpi);

  // }

  // search()
  // {

  //   var params={
  //     account_email:this.data.account_email,
  //     patient_id:this.file_no
  //   }

  //   this.appService.postData(this.urlPoints.searchPacs, params, this.data.token).subscribe((response: any) => {
  //     if(response.success == 1)
  //     {
  //       this.list = response.pac_list
  //     }
  //     else
  //     {
  //       this.list = []
  //     }
  //   });
  // }

  // setData(data)
  // {
  //   this.dialogRef.close(data);

  // }

  view(id) {
    console.log(id)

    var params = {
      account_email: this.data.account_email,
      attachment_id: id
    }

    this.appService.getecData1(this.urlPoints.getDICOMPath, params, this.data.token).subscribe((response: any) => {
      console.log(response)

      console.log(response.file_name)

      var url = (this.type == 0 ? environment.epic1 : environment.epic_dev1) + '/dicomviewer/?file=' + response.file_name

      console.log(url)

      window.open(url)

      // this.openDicomViewer(url)

    });
  }

  openDicomViewer(path) {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_dicomviewer, {
      width: '90vw',
      height: '90vh',
      data: { path: path }
    });
  }

  add(id, index) {
    console.log(this.data.lab_id)

    console.log(id)

    var params = {
      account_email: this.data.account_email,
      attachment_id: id
    }

    this.appService.getecData1(this.urlPoints.getDICOMPath, params, this.data.token).subscribe((response: any) => {
      console.log(response)

      console.log(response.file_name)

      var file_name = response.file_name

      var url = (this.type == 0 ? environment.epic1 : environment.epic_dev1) + '/dicomviewer/?file=' + response.file_name

      console.log(url)

      var params_add = {
        account_email: this.data.account_email,
        patient_email: this.data.patient_email,
        lab_id: this.data.lab_id,
        attachment: response.file_name,
        file_type: 'dicom',
        rel_dicom_file: '',
        study_id: id
      }

      console.log(params)

      this.appService.postData1(this.urlPoints.createLabAttachment, params_add, this.data.token).subscribe((response1: any) => {

        console.log(response1)


        var count = 0;

        for (var i = 0; i < this.files[index].length; i++) {
          this.appService.postimgData3(this.files[index][i].type.startsWith('image') ? this.urlPoints.imageUpload : this.urlPoints.pdfUpload, this.files[index][i], this.data.token, this.files[index][i].type.startsWith('image') ? 1 : 2).subscribe((response: any) => {
            this.uploadedimg = response.body;

            if (this.uploadedimg) {
              console.log(this.uploadedimg)
              if (this.uploadedimg.image) {
                var params = {
                  account_email: this.data.account_email,
                  patient_email: this.data.patient_email,
                  lab_id: this.data.lab_id,
                  attachment: this.uploadedimg.image[0].filename,
                  file_type: 'image',
                  rel_dicom_file: file_name,
                  study_id: id
                }
              }
              else {
                var params = {
                  account_email: this.data.account_email,
                  patient_email: this.data.patient_email,
                  lab_id: this.data.lab_id,
                  attachment: this.uploadedimg.pdf[0].filename,
                  file_type: 'document',
                  rel_dicom_file: file_name,
                  study_id: id
                }
              }


              this.appService.postData1(this.urlPoints.createLabAttachment, params, this.data.token).subscribe((response: any) => {
                console.log(response)
                count++

                if (count == this.files[index].length) {
                  this.dialogRef.close()
                }
                // this.refreshData()
              });


            }


          });
        }

        if (this.files[index].length == 0) {
          this.dialogRef.close()
        }

        // var updateParams = {
        //   account_email: this.data.account_email,
        //   patient_email: this.data.patient_email,
        //   data: [
        //     {
        //       id: id,

        //     }
        //   ]
        // }

        // this.dialogRef.close();

      });

    });



    //createLabAttachment
  }

  addAttachment(id, index) {
    var files = []
    const dialogRef = this.dialog.open(Dialog_attachment, {
      width: '600px',
      data: { obj: this.data.lab_data, title: this.data.type == 1 ? this.data.data.lab_tests.join(',') : this.data.title, type: this.data.type, mode: 'add' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log(result.fileList)

        this.files[index] = result.fileList

        console.log(this.files[index])

        var params_path = {
          account_email: this.data.account_email,
          attachment_id: id
        }

        this.appService.getecData1(this.urlPoints.getDICOMPath, params_path, this.data.token).subscribe((response1: any) => {

          // var count = 0;

          // for (var i = 0; i < result.fileList.length; i++) {
          //   this.appService.postimgData2(result.fileList[i].type.startsWith('image') ? this.urlPoints.imageUpload : this.urlPoints.pdfUpload, result.fileList[i], this.data.token, result.fileList[i].type.startsWith('image') ? 1 : 2).subscribe((response: any) => {
          //     this.uploadedimg = response.body;

          //     if (this.uploadedimg) {
          //       console.log(this.uploadedimg)
          //       if (this.uploadedimg.image) {
          //         var params = {
          //           account_email: this.data.account_email,
          //           patient_email: this.data.patient_email,
          //           lab_id: this.data.lab_id,
          //           attachment: this.uploadedimg.image[0].filename,
          //           file_type: 'image',
          //           rel_dicom_file: response1.file_name,
          //           study_id: id
          //         }
          //       }
          //       else {
          //         var params = {
          //           account_email: this.data.account_email,
          //           patient_email: this.data.patient_email,
          //           lab_id: this.data.lab_id,
          //           attachment: this.uploadedimg.pdf[0].filename,
          //           file_type: 'document',
          //           rel_dicom_file: response1.file_name,
          //           study_id: id
          //         }
          //       }


          //       this.appService.postData(this.urlPoints.createLabAttachment, params, this.data.token).subscribe((response: any) => {
          //         console.log(response)
          //         count++

          //         if (count == result.fileList.length) {
          //           // this.dialogRef.close()
          //         }
          //         // this.refreshData()
          //       });


          //     }


          //   });
          // }

        });

      }
    });
  }

  close() {

    this.dialogRef.close();
  }

}

@Component({
  selector: 'attachment',
  templateUrl: 'attachment.html',
  styleUrls: ['attachment.scss']
})

export class Dialog_attachment {
  value
  urlPoints;
  description;
  comments;
  tests_str;
  files;
  UploadImages = [];
  imgURL = [];
  date;
  abnormal = false;
  fileList = [];

  constructor(
    public dialogRef: MatDialogRef<Dialog_attachment>,
    @Inject(MAT_DIALOG_DATA) public data: any, public router: Router, private fb: FormBuilder, public route: ActivatedRoute) {
  }
  ngOnInit(): void {

    console.log(this.data)

    if (this.data.obj.lab_tests)
      this.tests_str = this.data.obj.lab_tests.join(',')
    this.date = this.data.obj.date
  }

  GetFile(e) {

    this.files = Array.from(e.target.files);

    if ((this.files[0].type === 'image/png')
      || (this.files[0].type === 'image/jpg')
      || (this.files[0].type === 'image/jpeg')
      || (this.files[0].type === 'image/tif')
      || (this.files[0].type === 'application/pdf')) {
      if (this.files[0].size > 10000000) {

      } else {
        this.UploadImages.push(e.target.files)

        for (let i = 0; i < this.files.length; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {

            this.imgURL.push({
              url: event.target.result,
              name: this.files[i].name,
              type: this.files[i].name.split('.')[this.files[i].name.split('.').length - 1]
            })
          }
          reader.readAsDataURL(e.target.files[i]);
          const file = (e.target as HTMLInputElement).files[i];
          this.fileList.push(file)

        }
      }
    } else {

    }

  }

  public removePicture(item, index) {
    this.imgURL.splice(index, 1);
    this.files.splice(index, 1);
    this.UploadImages.splice(index, 1);
    this.fileList.splice(index, 1);

    let file = document.getElementById("files") as HTMLInputElement;
    file.value = "";
  }

  save() {
    var obj = {
      fileList: this.fileList,
      abnormal: this.abnormal ? 1 : 0

    }
    this.dialogRef.close(obj);

  }
  cancel() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'dicomviewer',
  templateUrl: 'dicomviewer.html',
  styleUrls: ['dicomviewer.scss']
})
export class DialogOverviewExampleDialog_dicomviewer {
  path;
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_dicomviewer>, private dom: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  ngOnInit(): void {
    this.path = this.dom.bypassSecurityTrustResourceUrl(this.data.path)

  }

  openTab() {
    window.open(this.data.path)
  }

  close() {
    this.dialogRef.close();

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'attachment2',
  templateUrl: 'attachment.html',
  styleUrls: ['attachment.scss']
})

export class Dialog_attachment2 {
  value
  urlPoints;
  description;
  comments;
  tests_str;
  files;
  UploadImages = [];
  imgURL = [];
  fileList = [];
  date;
  abnormal = false;

  constructor(
    public dialogRef: MatDialogRef<Dialog_attachment2>,
    @Inject(MAT_DIALOG_DATA) public data: any, public router: Router, private fb: FormBuilder, public route: ActivatedRoute) {
  }
  ngOnInit(): void {
    this.tests_str = this.data.obj.lab_tests.join(',')
    this.date = this.data.obj.date;
    if (this.data.category == 'abnormal') {
      this.abnormal = true
    }
  }

  GetFile(e) {
    this.files = Array.from(e.target.files);

    if ((this.files[0].type === 'image/png')
      || (this.files[0].type === 'image/jpg')
      || (this.files[0].type === 'image/jpeg')
      || (this.files[0].type === 'image/tif')
      || (this.files[0].type === 'application/pdf')) {
      if (this.files[0].size > 10000000) {

      } else {
        this.UploadImages.push(e.target.files)

        for (let i = 0; i < this.files.length; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {

            this.imgURL.push({
              url: event.target.result,
              name: this.files[i].name,
              type: this.files[i].name.split('.')[this.files[i].name.split('.').length - 1]
            })
          }
          reader.readAsDataURL(e.target.files[i]);
          const file = (e.target as HTMLInputElement).files[i];
          this.fileList.push(file)

        }
      }
    } else {
    }

  }

  public removePicture(item, index) {
    this.imgURL.splice(index, 1);
    this.files.splice(index, 1);
    this.UploadImages.splice(index, 1);
    this.fileList.splice(index, 1);

    let file = document.getElementById("files") as HTMLInputElement;
    file.value = "";
  }

  save() {
    var obj = {
      fileList: this.fileList,
      abnormal: this.abnormal ? 1 : 0
    }
    this.dialogRef.close(obj);
  }
  cancel() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'addpacs',
  templateUrl: 'addpacs.html',
  styleUrls: ['addpacs.scss']

})

export class Dialog_addpacs {
  // hpi;
  urlPoints;
  file_no;
  list

  constructor(
    public dialogRef: MatDialogRef<Dialog_addpacs>, private appService: AppService, public dialog: MatDialog, private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any, public router: Router, private fb: FormBuilder) {
    this.urlPoints = AppConfig.settings.EndPoints;

  }
  ngOnInit(): void {
    // this.hpi = this.data.hpi
  }

  save() {
    // this.dialogRef.close(this.hpi);

  }

  search() {

    var params = {
      account_email: this.data.account_email,
      patient_id: this.file_no.trim()
    }

    console.log(this.data.token)

    this.appService.postData1(this.urlPoints.searchPacs, params, this.data.token).subscribe((response: any) => {
      if (response.success == 1) {
        this.list = response.pac_list
      }
      else {
        this.list = []
      }
    });
  }

  setData(data) {
    this.dialogRef.close(data);

  }

  close() {

    this.dialogRef.close();
  }

}

@Component({
  selector: 'upload',
  templateUrl: 'upload.html',
  styleUrls: ['upload.scss']
})
export class Dialog_upload {
  value;
  urlPoints;
  description = '';
  comments = '';
  files;
  UploadImages = [];
  imgURL = [];
  fileList = [];
  abnormal = false;
  investigations = [];
  inv_pages;
  inv_page = 1;
  inv_data = [];
  inv_copy = [];
  inv_copy1 = [];
  inv_selected = [];
  inv_names;
  chars;
  isFullListDisplayed: boolean = false;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  matMenuTimer4: any;
  fileName = '';
  takenDate = moment(new Date()).format('YYYY-MM-DD');
  date = moment(new Date()).format('YYYY-MM-DD');


  constructor(
    public dialogRef: MatDialogRef<Dialog_upload>,
    @Inject(MAT_DIALOG_DATA) public data: any, public router: Router, private fb: FormBuilder, public route: ActivatedRoute, private appService: AppService, private toastr: ToastrService) {
    this.urlPoints = AppConfig.settings.EndPoints;
  }
  ngOnInit(): void {
  }
  search(event) {
    this.investigations = [];
    this.inv_copy1 = [];
    this.inv_data = []
    this.chars = event.target.value
    var params = {
      account_email: this.data.dmail,
      page_no: 1,
      alphabet: this.chars.trim()
    }
    this.getInv(this.urlPoints.getInv, params, this.data.token)
  }
  getInv(url, params, token) {
    this.appService.getecData1(url, params, token).subscribe((response: any) => {
      if (response.success !== 0 && !response.message) {
        this.investigations = response.data;
        this.inv_pages = response.total_page;
        if (this.inv_page > 1)
          this.inv_data = this.inv_data.concat(response.data);
        else
          this.inv_data = response.data
        this.investigations = this.inv_data;
        this.inv_copy = Object.assign([], this.investigations);
        this.inv_copy1 = Object.assign([], this.investigations);
        var matchedArray = [];
        if (response.success == 1) {
          for (var i = 0; i < this.investigations.length; i++) {
            for (var k = 0; k < this.inv_selected.length; k++) {
              if (this.investigations[i].investigation_id == this.inv_selected[k].investigation_id) {
                matchedArray.push(i);
              }
            }
          }
          for (var i = matchedArray.length - 1; i >= 0; i--) {
            this.inv_copy.splice(matchedArray[i], 1);
            this.inv_copy1.splice(matchedArray[i], 1);
          }
        }
      }
    })
  }
  update_inv(index, i) {
    var exists;
    for (var j = 0; j < this.inv_selected.length; j++) {
      if (this.inv_selected[j].name.trim().toLowerCase() == this.inv_copy1[index].name.trim().toLowerCase()) {
        exists = true
      }
    }
    if (!exists) {
      var index1 = this.investigations.indexOf(this.inv_copy1[index]);
      this.inv_selected.push(this.investigations[index1])
      this.inv_copy1.splice(index, 1);
      var inv_names = []
      for (var k = 0; k < this.inv_selected.length; k++) {
        inv_names.push(this.inv_selected[k].name)
      }
      this.inv_names = inv_names.join(',')
    }
    else {
      //  this.toastr.error(this.inv_copy1[index].name + " has been already added")
    }
  }
  onScroll4() {
    //  alert()
    var token = this.data.token;
    this.inv_page++;
    var params = {
      account_email: this.data.dmail,
      page_no: this.inv_page,
      alphabet: this.chars.toUpperCase()
    }
    this.getInv(this.urlPoints.getInv, params, token)
  }
  click4(i) {
    this.matMenuTimer4 = setTimeout(() => { this.clickedMe4(); }, 300);
  }
  doubleClick4(i) {
    clearTimeout(this.matMenuTimer4);
    this.matMenuTimer4 = undefined;
    this.remove_inv(i);
  }
  remove_inv(index) {
    for (var i = 0; i < this.investigations.length; i++) {
      if (this.investigations[i].investigation_id == this.inv_selected[index].investigation_id) {
        if (this.inv_selected[index].question) {
          this.inv_selected[index].name = this.inv_selected[index].name.substring(1)
        }
        this.inv_copy1.push(this.inv_selected[index]);
        break;
      }
    }
    this.inv_selected.splice(index, 1);
    this.inv_copy1 = Object.assign([], this.investigations);
    var matchedArray = [];
    for (var i = 0; i < this.investigations.length; i++) {
      for (var k = 0; k < this.inv_selected.length; k++) {
        if (this.investigations[i].investigation_id == this.inv_selected[k].investigation_id) {
          matchedArray.push(i);
        }
      }
    }
    for (var i = matchedArray.length - 1; i >= 0; i--) {
      this.inv_copy.splice(matchedArray[i], 1);
      this.inv_copy1.splice(matchedArray[i], 1);
    }
    var inv_names = []
    for (var k = 0; k < this.inv_selected.length; k++) {
      inv_names.push(this.inv_selected[k].name)
    }
    this.inv_names = inv_names.join(',')
  }
  clickedMe4() {
    if (!this.matMenuTimer4) return;
  }
  save() {

    var obj = {
      fileList: this.fileList,
      description: this.description,
      report: '',
      comments: this.comments,
      title: this.inv_names ? this.inv_names : '',
      abnormal: this.abnormal ? 1 : 0,
      tests: this.inv_names ? this.inv_names : '',
      test_taken_on: this.takenDate,
      file_name: this.fileName
    }
    this.dialogRef.close(obj);
  }
  cancel() {
    this.dialogRef.close();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  GetFile(e) {
    this.files = Array.from(e.target.files);
    if ((this.files[0].type === 'image/png')
      || (this.files[0].type === 'image/jpg')
      || (this.files[0].type === 'image/jpeg')
      || (this.files[0].type === 'image/tif')
      || (this.files[0].type === 'application/pdf')) {
      if (this.files[0].size > 10000000) {
      } else {
        this.UploadImages.push(e.target.files)
        for (let i = 0; i < this.files.length; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.imgURL.push({
              url: event.target.result,
              name: this.files[i].name,
              type: this.files[i].name.split('.')[this.files[i].name.split('.').length - 1]
            })
          }
          reader.readAsDataURL(e.target.files[i]);
          const file = (e.target as HTMLInputElement).files[i];
          this.fileList.push(file)
        }
      }
    } else {
    }
  }
  public removePicture(item, index) {
    this.imgURL.splice(index, 1);
    this.files.splice(index, 1);
    this.UploadImages.splice(index, 1);
    let file = document.getElementById("files") as HTMLInputElement;
    file.value = "";
  }
}