<app-navbar class="mb-2"></app-navbar>
<div class="text-center mt-4">
    <h2>Add Doctor</h2>
</div>
<div class="wrapper fadeInDown">
    <div id="formContent">
     <div class="row mt-2">
         <div class="col-lg-4 col-md-4 col-sm-4"></div>
         <div class="col-lg-4 col-md-4 col-sm-4">
            <form [formGroup]="adddoctorForm" (submit)="save_adddoctorForm()">
                <div class="row">
                    <div class="col-lg-12 mb-3">
                        <input type="text" id="login" class="form-control" name="login" placeholder="Doctor Name" formControlName="username" pattern="[a-zA-Z '']*" maxlength="30">
                    </div>
                    <div class="error" *ngIf="(adddoctorForm.controls.username.touched||adddoctorForm.controls.username.dirty )&&adddoctorForm.controls.username.errors?.required">
                        Name required
                    </div>
                    <div class="error" *ngIf="(adddoctorForm.controls.username.touched||adddoctorForm.controls.username.dirty )&&adddoctorForm.controls.username.errors?.minlength">
                        Minimum 3 characters required
                    </div>
                    <div class="error" *ngIf="(adddoctorForm.controls.username.touched||adddoctorForm.controls.username.dirty )&&adddoctorForm.controls.username.errors?.pattern">
                        Only letters are allowed
                    </div>
                    <div class="col-lg-12 mb-3">
                        <input type="email" class="form-control lastnamefilter" placeholder="Email Id" formControlName="Emailaddress">
                    </div>
                    <div class="error" *ngIf="(adddoctorForm.controls.Emailaddress.touched||adddoctorForm.controls.Emailaddress.dirty )&&adddoctorForm.controls.Emailaddress.errors?.required">
                        Email required
                    </div>
                    <div class="error" *ngIf="(adddoctorForm.controls.Emailaddress.touched||adddoctorForm.controls.Emailaddress.dirty )&&adddoctorForm.controls.Emailaddress.errors?.pattern">
                        Invalid Email
                    </div>
                    <div class="col-lg-12 mb-3">
                        <input class="form-control lastnamefilter phonenum" type="text" ng2TelInput
                        (countryChange)="onCountryChange($event)"  placeholder="phonenumber" [ng2TelInputOptions]="{initialCountry: 'in'}" formControlName="phonenumber" maxlength="10" pattern="[0-9]*" onKeyPress="if(this.value.length==10) return false;"/>        
                    </div>
                    <div class="error"
                    *ngIf="(adddoctorForm.controls.phonenumber.touched||adddoctorForm.controls.phonenumber.dirty)&&adddoctorForm.controls.phonenumber.errors?.required">
                    Phone Number required</div>
                    <div class="error"
                    *ngIf="(adddoctorForm.controls.phonenumber.touched||adddoctorForm.controls.phonenumber.dirty)&&adddoctorForm.controls.phonenumber.errors?.pattern">
                    Only numbers are allowed
                   </div>
                   <br>
                    <div><button mat-raised-button class="text-center ml-3 mt-2">Save</button><button mat-raised-button class="text-center ml-4" href="#" [routerLink]="['/settings']">Cancel</button></div>
                    <!-- <div class="mt-4">
                        <br>
                        <p>{{samedoctor}}</p>
                    </div> -->
                </div>
              </form>
         </div>
         <div class="col-lg-4 col-md-4 col-sm-4"></div>
     </div>
      
    </div>
</div>
