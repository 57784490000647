import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChartsModule } from './charts/charts.module';
import { SettingsComponent } from './settings/settings.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AddpatientComponent } from './addpatient/addpatient.component';
import { NotificationComponent } from './notification/notification.component';
import { SecurityComponent } from './security/security.component';
import { VitaltableComponent } from './vitaltable/vitaltable.component';
import { MultilinechartComponent } from './multilinechart/multilinechart.component';
import { CreateaccountComponent } from './createaccount/createaccount.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { AdddoctorComponent } from './adddoctor/adddoctor.component';
import { AlertsComponent } from './alerts/alerts.component';
import { HospitalsComponent } from './hospitals/hospitals.component';
import { FollowupComponent } from './followup/followup.component';
import { ReportsComponent } from './reports/reports.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { QuestionnaireChartsComponent } from './questionnaire-charts/questionnaire-charts.component';


const routes: Routes = [
  {
		path:'',
    component:LoginComponent
	},
  {
		path: 'home',
    component:HomeComponent 
	},
  {
		path: 'hospitals',
    component:HospitalsComponent
	},
  {
		path: 'alerts',
    component: AlertsComponent 
	},
  {
		path: 'reports',
    component: ReportsComponent 
	},
  {
		path: 'security',
    component:SecurityComponent
	},
  {
		path: 'adddoctor',
    component:AdddoctorComponent
	},
  {
		path: 'createaccount',
    component:CreateaccountComponent
	},
  {
		path: 'reset',
    component:ResetpasswordComponent
	},
  {
		path: 'multiline',
    component:MultilinechartComponent
	},
  {
		path: 'vitals',
    component:VitaltableComponent
	},
  {
		path: 'charts',
		loadChildren:() => ChartsModule
	},
  {
		path: 'notification',
    component:NotificationComponent 
	},
  {
		path: 'settings',
    component:SettingsComponent
	},
  {
		path: 'addpatient',
    component:AddpatientComponent 
	},
  
  {
		path: 'followup',
    component:FollowupComponent 
	},
  {
		path: 'questionnaire',
    component:QuestionnaireComponent 
	},
  {
		path: 'questionnairecharts',
    component:QuestionnaireChartsComponent 
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
