import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { AppConfig } from 'src/app/app.config';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-followup',
  templateUrl: './followup.component.html',
  styleUrls: ['./followup.component.scss']
})
export class FollowupComponent implements OnInit {
  followup_data;
  patient;
  y
  m
  urlPoints;
  auth = localStorage.getItem("authentication")
  code;
  mrn;
  follow_data;
  chathistoryList;
  chatArray=[];

  constructor(private appService: AppService, private router: Router) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.mrn = sessionStorage.getItem("mrn");

  }

  ngOnInit(): void {
    this.followup_data = JSON.parse(sessionStorage.getItem('followup_data'))
    this.patient = JSON.parse(sessionStorage.getItem('patient_data'))
    this.y = sessionStorage.getItem('year')
    this.m = sessionStorage.getItem('month')
    this.code = sessionStorage.getItem("code").toLowerCase();

    // console.log(this.followup_data)
    // console.log(this.patient) 

    var date_array = this.followup_data.discharge_date.split('-')

    var date_string = date_array[2] + ' ' + date_array[1] + ' ' + date_array[0]

    this.followup_data.discharge_date = moment(date_string).format('Do MMM , YYYY');

    this.getFollowDetails()
  }

  getFollowDetails() {
    //patient_id
    var params = {
      code: this.code,
      mrn: this.mrn
    }

    this.appService.postData(this.urlPoints.getQuestionnaire, params, this.auth).subscribe((response: any) => {
      this.follow_data = response.data
      // console.log(this.follow_data)
      this.getNotes()
    });
  }

  getNotes() {
    var params = {
      code: this.code,
      mrn: this.mrn
    }

    this.appService.postData(this.urlPoints.getNotes, params, this.auth).subscribe((response: any) => {
      // console.log(response)

      if (response.success == 1) {
        // console.log(response.data)

        this.chathistoryList = response.data;

        // console.log(this.chathistoryList)

        var dateArray = []

        for (var i = 0; i < this.chathistoryList.length; i++) {
          // console.log(this.chathistoryList[i].timestamp)
          if (this.chathistoryList[i].timestamp == '') {
            if (!dateArray.includes('')) {
              dateArray.push('')
            }
          }
          else {
            console.log(this.chathistoryList[i].timestamp.split(' ')[0])

            var date_array = this.chathistoryList[i].timestamp.split(' ')[0].split('-')

            var date_string = date_array[2] + ' ' + date_array[1] + ' ' + date_array[0]

            console.log(moment(date_string).format('Do MMM , YYYY'))

            if (!dateArray.includes(moment(date_string).format('Do MMM , YYYY')) )
            {
              dateArray.push(moment(date_string).format('Do MMM , YYYY'))
            }
          }

         


          // if(this.chathistoryList[i].timestamp=='')
          // {
          //   console.log('here')
          // }
        }

        console.log(dateArray)

        var newChat=[]

        for(var i=0;i<dateArray.length;i++)
        {

          console.log(dateArray[i])

          var texts=[]

          for(var j=0;j<this.chathistoryList.length;j++)
          {


            if(this.chathistoryList[j].timestamp!='')
            {
            var date_array = this.chathistoryList[j].timestamp.split(' ')[0].split('-')

            var date_string = date_array[2] + ' ' + date_array[1] + ' ' + date_array[0]

            console.log(moment(date_string).format('Do MMM , YYYY'))

            if(dateArray[i] == moment(date_string).format('Do MMM , YYYY'))
            {
              var time_arr;

              var time;

              time_arr = this.chathistoryList[j].timestamp.split(' ')[1].split(':')

              console.log(time_arr)

              if(time_arr[0]==0)
              {
                time='12:'+time_arr[1]+" am"
              }
              else if(time_arr[0]>12)
              {
                time=time_arr[0]-12+":"+time_arr[1]+" pm"
              }
              else{
                time=time_arr[0]+":"+time_arr[1]+" pm"

              }

              console.log(time)

              texts.push({
                notes:this.chathistoryList[j].notes,
                time:time,
                source:this.chathistoryList[j].source
              })
            }

            }
            else{
              if(dateArray[i] == '')
              {
                texts.push({
                  notes:this.chathistoryList[j].notes,
                  time:'',
                  source:this.chathistoryList[j].source
                })
              }
            }

          }

          // console.log(texts)

          newChat.push({
            date:dateArray[i],
            texts:texts
          })

        }

        console.log(newChat)

        this.chatArray = newChat

        console.log(this.chatArray)

        for (var i = 0; i < this.follow_data.length; i++) {
          for (var j = 0; j < response.data.length; j++) {
            if (this.follow_data[i].date == response.data[j].followup_date) {
              this.follow_data[i].notes = response.data[j].notes
            }
          }
        }

        // console.log(this.follow_data)
      }
    });
  }

  navigate(data, i) {

    console.log(data)

    data.index = i + 1

    sessionStorage.setItem('question_data', JSON.stringify(data))

    this.router.navigate(["/questionnaire"])
  }

  trends() {
    // console.log(this.follow_data)

    sessionStorage.setItem('questionnaire_data', JSON.stringify(this.follow_data))

    this.router.navigate(["/questionnairecharts"])

  }

}
