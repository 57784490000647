import { Component, OnInit , Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PubNubAngular } from 'pubnub-angular2';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { TransitionCheckState } from '@angular/material/checkbox';
import { heLocale } from 'ngx-bootstrap/chronos';
import { DomSanitizer } from '@angular/platform-browser';
import { numberFormat } from 'highcharts';
import * as Highcharts from 'highcharts';

import { NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import * as am4core from "@amcharts/amcharts4/core"
import * as am4maps from "@amcharts/amcharts4/maps"
import am4themes_animated from "@amcharts/amcharts4/themes/animated";



import { 
  connect,
  createLocalVideoTrack,
  RemoteAudioTrack, 
  RemoteParticipant, 
  RemoteTrack, 
  RemoteVideoTrack, 
  Room,
  
} from 'twilio-video';


export interface DialogData {
  carddata: any;
}



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  pubnub: PubNubAngular;
  pubnubep: PubNubAngular;
  urlPoints;
  addpatientParams;
  peertopeer_list;
  patient_List:any = [];
  options;
  vitals;
  data;
  rox;
  pubnubdata;
  pubnub_Epicare;
  bookappointments;
  bookalerts;
  appointmentcount = 0;
  alertcount = 0
  val = 0;
  finalcount;
  peercount = 0;
  peercounts = 0;
  peerlistCount:any = [];
  callfloatingVideosrc = false;
  src;
  epicarebook = true;
  alertsbook = false;
  epicarebooking:any =[];
  hiderightbar = false;
  gettemp;
  emergencyalerts;
  
  code;
  doctor_id;
  token;
  account_email;

  getdoc_id;
  gettoken;
  getdmail;
  // code =sessionStorage.getItem("code");
  // doctor_id = sessionStorage.getItem("doc_id")
  // token = localStorage.getItem("authentication");
  // account_email = sessionStorage.getItem("acc_email");
  href;
  showmarkermap;
  sub;
  getAccess_code;
  
 

  constructor(private dialogRef: MatDialog ,private appService: AppService, pubnub: PubNubAngular,private route: ActivatedRoute, private router: Router , private dom: DomSanitizer, private http: HttpClient , private spinner: NgxSpinnerService , private toastr: ToastrService , public dialog: MatDialog) { 
    this.urlPoints = AppConfig.settings.EndPoints;

     console.log("environment Host", environment.HOST);

     this.sub = this.route.queryParams
          .subscribe(params => {
          this.getAccess_code = params['pass'];
          this.getdoc_id = params['dr_Id'];
          this.gettoken = params['auth_token']
          this.getdmail = params['acc_email']
    });

     ///Status check where to get data from login or queryparam

      if(this.getAccess_code == undefined){
        console.log('****** get it from login data****** ');
        this.code =sessionStorage.getItem("code");
        this.doctor_id = sessionStorage.getItem("doc_id")
        this.token = localStorage.getItem("authentication");
        this.account_email = sessionStorage.getItem("acc_email");
      }else {
        console.log("****** get it from query param****** ");
        this.code = this.getAccess_code;
        this.doctor_id = this.getdoc_id;
        this.token = this.gettoken;
        this.account_email = this.getdmail;

        sessionStorage.setItem("code",this.code);
        console.log("***** Deployed build AS 1.2.01 *******")
        var loginParams = {
          "mode": 0,
          "code": this.code,
          "date": moment(new Date()).format("DD-MM-YYYY")
        }

        this.queryParamloginData(this.urlPoints.login , loginParams);
    }

    this.pubnub = pubnub;
     
    
    var gettempParams = {
      code : this.code.toUpperCase()
    } 

    this.appService.getData(this.urlPoints.gettempunit, gettempParams).subscribe((response: any) => {
      if(response.settings.success == 1){
        this.gettemp = response.settings.temp_unit;
      }
    });
}

  ngOnInit(): void {
    console.log("***** Deployed build 1.2.01 *******")
    this.href = this.router.url;
    this.pubnub1();
    this.pubnub2();
    this.epicareapi();
  }

 queryParamloginData(url , params){
  this.appService.getData(url , params).subscribe((response: any) => {
    //console.log("login rsponse" , response.login[0]);
      if(response.login[0].success == 1){
        let epicaredata = response.login[0].user;
        sessionStorage.setItem("access_token", epicaredata.access_token);
        localStorage.setItem("authentication", epicaredata.access_token)
        sessionStorage.setItem("acc_email", epicaredata.carenet_email);
        sessionStorage.setItem("doc_id",epicaredata.doctor_id);
        sessionStorage.setItem("is_live", epicaredata.is_live);
      }
  });
}

 pubnub1() {
    var that=this;
    var selectedChannel = 'get_message_' + this.code.toLowerCase();
    var hubChannel = 'receive_hub_data_' + this.code.toLowerCase();
    var refresh_channel = "refresh_hub_" + this.code.toLowerCase();
    var receivehub = "receive_hub_detail_"+this.code.toLowerCase();

    this.pubnub.init({
      publishKey: 'demo',
      subscribeKey: 'demo',
      ssl: false
    });


    this.pubnub.subscribe({
      channels: [selectedChannel, hubChannel, refresh_channel , receivehub],
      withPresence: true,
      triggerEvents: ['message', 'presence', 'status' , 'hub']
    });

    console.log("hubChannel", hubChannel);
    this.pubnub.getMessage([hubChannel], (msg) => {
      // console.log("msg", msg);
      for(var i=0;i< this.patient_List.length ; i++){
        if(this.patient_List[i].patient_id == msg.message.patientID ){
          if(msg.message.rr != ''){
            this.patient_List[i].rr = msg.message.rr +"rpm";
          }else{
            this.patient_List[i].rr = '';
          }
          if(msg.message.bpm != ''){
            this.patient_List[i].bpm = msg.message.bpm +"ppm";
          }else{
            this.patient_List[i].bpm = '';
          }
          if(msg.message.o2 != ''){
            this.patient_List[i].o2 = msg.message.o2 +"%";
          }else{
            this.patient_List[i].o2 = '';
          }
          this.patient_List[i].rox = msg.message.rox;
          this.patient_List[i].pi = msg.message.pi;
          this.patient_List[i].hub_name = msg.message.hub_name;
          this.patient_List[i].hub_color = '#'+msg.message.hub_color;
          this.patient_List[i].timeStamp = msg.message.timeStamp;
          if(this.gettemp == 'c' && msg.message.temp != ''){
            this.patient_List[i].temp = msg.message.temp +"°C";
          }else if(this.gettemp == 'f' && msg.message.tempf != ''){
            this.patient_List[i].temp = msg.message.tempf +"°F";
          }else{
            this.patient_List[i].temp = '';
          }
          
        }
      } 
    });

    this.pubnub.getStatus([selectedChannel, hubChannel, refresh_channel], (msg) => {
    //  console.log(msg);
    });

    this.pubnub.getPresence([selectedChannel, hubChannel, refresh_channel], (msg) => {
    //  console.log(msg);
    });


    this.pubnub.getMessage([refresh_channel], (msg) => {
      this.addpatient()
      this.epicareapi();    
      // console.log("msg refresh_channel", msg);
    });

    this.addpatientParams = {
      'mode': '1',
      'params': this.code.toUpperCase(),
      'dstate': '1'
    }
    this.addpatient();

    this.pubnub.unsubscribe({
      channels : ['receivehub']
    });

  }

  pubnub2() {
    var account_id = sessionStorage.getItem("acc_email")
    var channel = account_id.replace('@','_').replace('.org', '');
    var uuid = account_id
    var doctor_id = sessionStorage.getItem("doc_id")
    this.pubnub.init({
      publishKey: 'pub-c-c3cecd91-62bc-4365-b2d1-a6333576f17f',
      subscribeKey: 'sub-c-60c79014-12d7-11e8-b32f-5ea260837941',
      uuid: uuid
    });

    this.pubnub.subscribe({
      channels: [channel, 'get_banner_'+ channel, 'videocall_'+channel+'_'+doctor_id, 'vital_criticalityscore_'+channel ,'peer_room_'+account_id+'_'+doctor_id, 'peer_room_status_'+account_id+'_'+doctor_id, 'voice_' + uuid+'_'+doctor_id,'room_' + uuid+'_'+doctor_id],
      withPresence: true,
      triggerEvents: ['callback', 'message', 'presence']
    });

    this.pubnub.addListener({
      message: msg=> {
        if(msg){
          console.log("Get message from Channel****", msg);
          var split_Channel_Name = msg.channel.split("_");
          console.log(split_Channel_Name[1])
          if(split_Channel_Name[1] == 'room'){
            console.log("***Room channel")
            this.callpeertopeercount();
          }     
         }
        console.log("135", msg);
         if(msg.message.message.status == "in-progress"){
           this.epicareapi();
         }
         if(msg.message.source == "engine"){
          var audio_forallparams = {
            "code": this.code.toUpperCase()
          }
      
          this.appService.getData(this.urlPoints.getalert, audio_forallparams).subscribe((response: any) => {
             console.log("response 350", response);
             if(response.settings.alerts[4].alert_name == "Notification Alert"){
              var audiostring = response.settings.alerts[4].audio_url
              let audio = new Audio();
              audio.src = audiostring;
              audio.load();
              audio.play();
            }
            });
         }
         if(msg.message.message.status){
          this.toastr.success(msg.message.message.status);
          this.epicareapi();
         }else{
          this.toastr.success(msg.message.message);
          this.epicareapi();
          // this.router.navigate(["/home"],{ queryParams: { } })
         }
        }
     })

  
    this.pubnub.getMessage([channel, 'get_banner_'+ channel, 'videocall_'+channel+'_'+doctor_id, 'vital_criticalityscore_'+channel , 'voice_' + uuid+'_'+doctor_id,'room_' + uuid+'_'+doctor_id], (msg) => {
      console.log("message", msg); 
    });

    this.pubnub.getStatus([channel, 'get_banner_'+ channel, 'videocall_'+channel+'_'+doctor_id, 'vital_criticalityscore_'+channel , 'voice_' + uuid+'_'+doctor_id,'room_' + uuid+'_'+doctor_id], (msg) => {
    console.log("message", msg);
    });

    this.pubnub.getPresence([channel, 'get_banner_'+ channel, 'videocall_'+channel+'_'+doctor_id, 'vital_criticalityscore_'+channel , 'voice_' + uuid+'_'+doctor_id,'room_' + uuid+'_'+doctor_id], (msg) => {
      console.log("message", msg);
    });

    this.addpatientParams = {
      'mode': '1',
      'params': this.code.toUpperCase(),
      'dstate': '1'
    }
    this.addpatient();

  }
  
  addpatient(){
    this.appService.getData(this.urlPoints.ListData, this.addpatientParams).subscribe((response: any) => {
      this.patient_List = response.patient[0].response;
    });
  } 


  getsnoozes(url , params){
    this.appService.getData( url, params).subscribe((response: any) => {
      this.emergencyalerts = response.data; 

      if(response.data.length > 0){
  
        var audio_forallparams = {
          "code": this.code.toUpperCase()
        }

      this.appService.getData(this.urlPoints.getalert, audio_forallparams).subscribe((response: any) => {
        this.val += 1;
        if(this.val >= 1){
         console.log("response 350", response);
         if(response.settings.alerts[1].alert_name == "Snooze Alert"){
          var audiostring = response.settings.alerts[1].audio_url
          let audio = new Audio();
          audio.src = audiostring;
          audio.load();
          audio.play();
        }
        }
        });
      }
      this.alertcount = response.data.length;
    });
  }

  update_snooze(data){
    console.log("dataaaaa", data);
    var params = {
      "code": data.code,
      "patient_id":data.patientID
    }
    this.appService.getData(this.urlPoints.updatesnooze, params).subscribe((response: any) => {
      if(response.success == 1){
        console.log("removed from snooze list")
        this.toastr.success('removed from snooze list');
        this.emergencyalerts = ""
        this.alertcount = 0;
        var gettempParams = {
          code : this.code.toUpperCase()
        }
        this.getsnoozes(this.urlPoints.gettingsnoozelist , gettempParams);
     }

    });
  }

  temp(data){
    console.log("patient data", data);
    sessionStorage.setItem("pname", data.patient_name);
    sessionStorage.setItem("dob", data.dob);
    sessionStorage.setItem("code" , data.code);
    sessionStorage.setItem("mr", data.patient_id);
    sessionStorage.setItem("mrn", data.patient_id);
    this.router.navigate(["/charts"],{ queryParams: {
      code: data.code,
      mrn: data.patient_id
     } })
  }  

  hidden = false;
  hidden1 = false;

  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }

  toggleBadgeVisibility1() {
    this.hidden1 = !this.hidden1;
  }

  rightbarshow(){
    this.hiderightbar = !this.hiderightbar;
  }

  peertopeerlist(){
     this.peertopeer_list = !this.peertopeer_list;

     if(this.peertopeer_list){
      const dialogRef = this.dialog.open(DialogOverviewExamplepeertopeer , {
        width: '40%',
        data: {
          mypdfdata : ''
        }
      });
    
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
  
        }
      });
    }
    if(!this.peertopeer_list){
      this.dialogRef.closeAll();
    }
  }

  maptoggler(){
    this.showmarkermap = !this.showmarkermap;
    
    if(this.showmarkermap){
      const dialogRef = this.dialog.open(DialogOverviewExamplegeomap , {
        width: '100%',
        data: {
          mypdfdata : ''
        }
      });
    
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
  
        }
      });
    }
    if(!this.showmarkermap){
      this.dialogRef.closeAll();
    }
   
  }

  bookedappointment(){
    this.epicarebook = true;
     this.alertsbook = false;
    
  }

  bookedalerts(){
     this.epicarebook = false;
     this.alertsbook = true;
  }


  //videocall code starts here

  notify_Token(data) { 
    this.spinner.show();
    var type:any = sessionStorage.getItem("is_live")
    console.log("data", data);
    var notify_tokenparams = {
        "account_email": this.account_email,
        "patient_email": data.email,
         "a_type": data.type,
         "a_id": data.id,
         "doctor_id": this.doctor_id
    }
    if(type == 0){
      this.appService.postecData( environment.epic+"/service/notifybyvoice", notify_tokenparams , this.token).subscribe((response: any) => {
        this.spinner.hide(); 
        if(response.success == 1){
          // this.toastr.success(response.message);
          console.log("response", response);
        }
      });
    }else if (type == 1){
      this.appService.postecData( environment.epic_dev+"/service/notifybyvoice", notify_tokenparams , this.token).subscribe((response: any) => {
        this.spinner.hide();
        if(response.success == 1){
          // this.toastr.success(response.message);
          console.log("response", response);
        }
      });
    }
};

bookVoice(data){

}
endconsultation(data){
  console.log("data", data);

  var endconParam = {
    "account_email": this.account_email,
    "appointment_id": data.id,
    "patient_account": data.email
  }

  var params = {
    "account_email": this.account_email,
    "appointment_id": data.id,
    "category": "video_call_consultation",
    "description": " The Amount of Rs.0 is Debited for video_call_consultation",
    "doctor_charges": 0,
    "doctor_id": "4",
    "patient_email": data.email,
    "type": 3,
    "user_type": "doctor"
  }
  var type:any = sessionStorage.getItem("is_live")
  console.log("params raise invoice", params);
  if(type == 0){
    this.appService.postecData( environment.epic +"/service/endcon", endconParam , this.token).subscribe((response: any) => {
      if(response){
        console.log("EndConresponse", response);
        this.toastr.success("End Consultation");
      }
    });
    this.appService.postecData( environment.epic +"/service/raiseinvoice", params , this.token).subscribe((response: any) => {
      if(response){
        console.log("response", response);
        this.epicareapi();
      }
    });

  }else if(type == 1){
    this.appService.postecData( environment.epic_dev +"/service/endcon", endconParam , this.token).subscribe((response: any) => {
      if(response){
        console.log("EndConresponse", response);
        this.toastr.success("End Consultation");
      }
    });
    this.appService.postecData( environment.epic_dev +"/service/raiseinvoice", params , this.token).subscribe((response: any) => {
      if(response){
        console.log("response", response);
        this.epicareapi();
      }
    });
  }
}

doctorJoinOnClick(data){
  this.spinner.show();
  var senderId = data.email.replace('@','_').replace('.org', '')+'_'+ this.doctor_id; 
   this.callJoinRoom(senderId, data);
}

callJoinRoom(sender_id, data){
  var type:any = sessionStorage.getItem("is_live")
  var joinvideocallparams = {
    "a_id": data.id,
    "a_type": data.type,
    "account_email": this.account_email,
    "channel_name": sender_id,
    "doctor_id":  this.doctor_id,
    "patient_email": data.email,
   }

  if(type == 0){
    this.appService.postecData( environment.epic +"/service/createvideoroom", joinvideocallparams , this.token).subscribe((response: any) => {
      if(response){
        this.spinner.hide(); 
        console.log("rooom name 512 512", response.room_name , response.access_token);
        window.open('https://exp.epicare.net:8000/room/'+response.room_name+'/'+response.access_token,'_blank');
      }
    });

  }else if(type == 1){
    this.appService.postecData( environment.epic_dev +"/service/createvideoroom", joinvideocallparams , this.token).subscribe((response: any) => {
      if(response){
        this.spinner.hide(); 
        window.open('https://exp.epicare.net:8000/room/'+response.room_name+'/'+response.access_token,'_blank');
      }
    });
  }

}

epicareapi(){

  // this.src = "https://morioh.com/p/566c86f7f6bd";
  // this.src = this.dom.bypassSecurityTrustResourceUrl(this.src);

  let gettempParams = {
    code : this.code.toUpperCase()
  } 

  this.getsnoozes(this.urlPoints.gettingsnoozelist , gettempParams);

  let type:any = sessionStorage.getItem("is_live");
  console.log("@@@@@@@@@@ "+ type);
  let epicaretoken =  localStorage.getItem("authentication");
 

  var params = {
    "access_code": this.code.toUpperCase(),
    "doctor_id": this.doctor_id,
    "date": moment(new Date()).format("YYYY-MM-DD"),
    "account_email": this.account_email
  }
  
  if(type == 0){
    this.appService.getecData( environment.epic+"/service/getvitalappointment/", params , epicaretoken).subscribe((response: any) => {
      
      if(response.success == 1){
        console.log("enter type____0");
        this.epicarebooking = [];
        this.appointmentcount = 0
        this.epicarebooking = response.details;
        this.appointmentcount = this.epicarebooking.length;
        this.finalcount = this.appointmentcount; 
      }
    });
  }else if (type == 1){
    this.appService.getecData( environment.epic_dev+"/service/getvitalappointment/", params , epicaretoken).subscribe((response: any) => {
      console.log("epicare API", response);
      if(response.success == 1){
        this.epicarebooking = [];
        this.appointmentcount = 0;
        this.epicarebooking = response.details
        this.appointmentcount = this.epicarebooking.length
        this.finalcount = this.appointmentcount; 
      }
    });
  }

this.callpeertopeercount();

}

callpeertopeercount(){
  
  var type:any = sessionStorage.getItem("is_live");
  // var epicaretoken = "b2F1dGhfdG9rZW49aWJrOEpYUXJUbVgzbk9ra2tIb3gmb2F1dGhfdG9rZW5fc2VjcmV0PU1RQnFOenJ3MGNZQzFPZ0huWmNmJmFjY291bnRfaWQ9cng0JTQwcnhjYXJlbmV0Lm9yZw=="
   var epicaretoken =  localStorage.getItem("authentication");
  var peertopeerparams = {
    "account_email" : this.account_email,
    "code": this.code.toUpperCase(),
    "doctor_id": this.doctor_id
  }

  if(type == 0){
    this.appService.postecData( environment.epic+"/service/getpeerforaccesscode", peertopeerparams , epicaretoken).subscribe((response: any) => {
      if(response.success == 1){
        this.peerlistCount = [];
          sessionStorage.setItem("p2pcount",response.details.length);
          //this.peercount = parseInt(response.details.length);
          for(var i=0;i< response.details.length;i++){
            if(response.details[i].room_status!=1){
              this.peerlistCount.push(response.details[i]);
            }
          }
          this.peercount = this.peerlistCount.length;
      }
    });
  }else if (type == 1){
    this.appService.postecData( environment.epic_dev+"/service/getpeerforaccesscode", peertopeerparams , epicaretoken).subscribe((response: any) => {
      if(response.success == 1){
        this.peerlistCount = [];
        sessionStorage.setItem("p2pcount",response.details.length);
        //this.peercount = parseInt(response.details.length);
        for(var i=0;i< response.details.length;i++){
          if(response.details[i].room_status!=1){
            this.peerlistCount.push(response.details[i]);
          }
        }
        this.peercount = this.peerlistCount.length;
     }
    });
  }
  
}

//Individual card settings

cardsetting(data){
  const dialogRef = this.dialog.open(DialogOverviewExampleDialog_cardsettings, {
    width: '94%',
    data: {
      patientdata : data
    }
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
    // this.animal = result;
    console.log("result 373", result);
    if(result){
       console.log("resukt card", result);
       this.appService.postecData("https://ivital.co"+this.urlPoints.patientupdate , result , this.token).subscribe((response: any) => {
       console.log("response 328", response);
       if(response.patient[0].success == 1){
          this.toastr.success(response.patient[0].message);
          this.addpatient()
       }else{
         this.toastr.error(response.patient[0].message)
       }
     });
    }

  });
}

opencardsettings(data){
  console.log("data card settings", data);
}



ngOnDestroy() {
  var selectedChannel = 'get_message_' + this.code.toLowerCase();
  var hubChannel = 'receive_hub_data_' + this.code.toLowerCase();
  var refresh_channel = "refresh_hub_" + this.code.toLowerCase();
  var receivehub = "receive_hub_detail_"+this.code.toLowerCase();
  console.log("505 unsubscribe", selectedChannel, hubChannel, refresh_channel , receivehub);
  // selectedChannel, hubChannel, refresh_channel , receivehub
  this.pubnub.unsubscribe({
      channels: [hubChannel],
  });

}


}


@Component({
  selector: 'cardsettings',
  templateUrl:'cardsettings.html',
  styleUrls: ['cardsettings.scss']
})




export class DialogOverviewExampleDialog_cardsettings {
  carddata;
  card_settingForm;
  card;
  urlPoints;
  token =  localStorage.getItem("authentication");
  temperature:any = ["-1.67 °C","-1.61 °C","-1.56 °C","-1.50 °C","-1.44 °C","-1.39 °C","-1.33 °C","-1.28 °C","-1.22 °C","-1.17 °C","-1.11 °C",
  "-1.06 °C","-1.00 °C","-0.94 °C","-0.89 °C","-0.83 °C","-0.78 °C","-0.72 °C","-0.67 °C","-0.61 °C","-0.56 °C","-0.50 °C",
  "-0.44 °C","-0.39 °C","-0.33 °C","-0.28 °C","-0.22 °C","-0.17 °C","-0.11 °C","-0.06 °C","0.00 °C","0.06 °C","0.11 °C","0.17 °C"
  ,"0.22 °C","0.28 °C","0.33 °C","0.39 °C","0.44 °C","0.50 °C","0.56 °C","0.61 °C","0.67 °C","0.72 °C","0.78 °C",
  "0.83 °C","0.89 °C","0.94 °C","1.00 °C","1.06 °C","1.11 °C","1.17 °C","1.22 °C","1.28 °C","1.33 °C","1.39 °C","1.44 °C","1.50 °C","1.56 °C","1.61 °C","1.67 °C"]
  


  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_cardsettings>,private fb: FormBuilder,private appService: AppService,private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.urlPoints = AppConfig.settings.EndPoints;
  }

    ngOnInit():void {

    this.carddata = this.data;
    this.card = this.carddata.patientdata;
    
    console.log("this.cardthis.card", this.card);
    
    this.card_settingForm = this.fb.group({
      th_temp:[""],
      th_heart:[""],
      th_resp:[""],
      th_rox:[""],
      tl_temp:[""],
      tl_heart:[""],
      tl_spo2:[""],
      tl_resp:[""],
      tl_rox:[""],
      ae_temp:[this.card.alert_state == '1' ? true : false],
      ae_heart:[this.card.hr_escalate == '1' ? true : false],
      ae_spo2:[this.card.o2_escalate == '1' ? true : false],
      ae_resp:[this.card.rr_escalate == '1' ? true : false],
      ae_rox:[this.card.rox_escalate == '1' ? true : false],
      aes_temp:[""],
      aes_heart:[""],
      aes_spo2:[""],
      aes_resp:[""],
      aes_rox:[""],
      si_temp:[""],
      si_heart:[""],
      si_spo2:[""],
      si_resp:[""],
      si_rox:[""],
      s_temp:[this.card.temp_snooze == '1' ? true : false],
      s_heart:[this.card.ppm_snooze  == '1' ? true : false],
      s_spo2:[this.card.o2_snooze == '1' ? true : false],
      s_resp:[this.card.rpm_snooze == '1' ? true : false],
      s_rox:[this.card.rox_snooze == '1' ? true : false],
      tof_temp:[""],
      tof_rox:[""],
      selected_tempvalue:[""],
      ivrs:[this.card.is_ivrs == '1' ? true : false],
      sms:[this.card.is_sms == '1' ? true : false],
    });
    this.defaultFormData();
  }  

  defaultFormData(){
      this.card_settingForm.controls['th_temp'].setValue(this.carddata.patientdata.thresh_high)
      this.card_settingForm.controls['th_heart'].setValue(this.carddata.patientdata.hr_thresh_high)
      this.card_settingForm.controls['th_resp'].setValue(this.carddata.patientdata.rr_thresh_high)
      this.card_settingForm.controls['th_rox'].setValue(this.carddata.patientdata.rox_thresh_high)
      this.card_settingForm.controls['tl_temp'].setValue(this.carddata.patientdata.thresh_low)
      this.card_settingForm.controls['tl_heart'].setValue(this.carddata.patientdata.hr_thresh_low)
      this.card_settingForm.controls['tl_spo2'].setValue(this.carddata.patientdata.spo2_thresh_low)
      this.card_settingForm.controls['tl_resp'].setValue(this.carddata.patientdata.rr_thresh_low)
      this.card_settingForm.controls['tl_rox'].setValue(this.carddata.patientdata.rox_thresh_low);
      this.card_settingForm.controls['aes_temp'].setValue(this.carddata.patientdata.alert_interval)
      this.card_settingForm.controls['aes_heart'].setValue(this.carddata.patientdata.hr_escalate_interval)
      this.card_settingForm.controls['aes_spo2'].setValue(this.carddata.patientdata.spo2_escalate_interval)
      this.card_settingForm.controls['aes_resp'].setValue(this.carddata.patientdata.rr_escalate_interval)
      this.card_settingForm.controls['aes_rox'].setValue(this.carddata.patientdata.rox_escalate_interval)
      this.card_settingForm.controls['si_temp'].setValue(this.carddata.patientdata.temp_snooze_interval)
      this.card_settingForm.controls['si_heart'].setValue(this.carddata.patientdata.hr_snooze_interval)
      this.card_settingForm.controls['si_spo2'].setValue(this.carddata.patientdata.o2_snooze_interval)
      this.card_settingForm.controls['si_resp'].setValue(this.carddata.patientdata.rr_snooze_interval)
      this.card_settingForm.controls['si_rox'].setValue(this.carddata.patientdata.rox_snooze_interval);
      this.card_settingForm.controls['tof_rox'].setValue(this.carddata.patientdata.rox_value)
      this.card_settingForm.controls['tof_temp'].setValue(this.carddata.patientdata.offset);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }



  save_cardsettingForm(){
    console.log("411 this.carddata", this.carddata.patientdata);
     var patient = this.carddata.patientdata
    if(this.card_settingForm.valid){
      var data = this.card_settingForm.value;
      console.log("data form", data);

      console.log(data.th_temp ,  data.tl_temp , data.th_heart  , data.tl_heart)
     

      if( parseInt(data.th_temp) > parseInt(data.tl_temp)){
          if(parseInt(data.th_heart) > parseInt(data.tl_heart)){
            if(parseInt(data.th_resp) > parseInt(data.tl_resp)){
              if(parseInt(data.th_rox) > parseInt(data.tl_rox)){
                var cardsettingsparams = {
                  "patient_id": patient.patient_id,
                  "device_id": patient.device_id,
                  "code": patient.code.toUpperCase(),
                  "patient_name": patient.patient_name,
                  "dob": patient.dob,
                  "sex": patient.sex,
                  "uhid": patient.uhid,
                  "doctor_id": patient.doctor_id,
                  "status":patient.status,
                  "alert_state": data.ae_temp  == true ? "1" : "0",
                  "alert_interval": data.aes_temp,
                  "feverwatch": patient.feverwatch,
                  "date_created": patient.date_created,
                  "thresh_low": data.tl_temp,
                  "thresh_high": data.th_temp,
                  "doctor_mobile": patient.doctor_mobile,
                  "profile_id": patient.profile_id,
                  "spo2": patient.spo2,
                  "respiratory": patient.respiratory,
                  "o2_escalate": data.ae_spo2 == true ? "1" : "0",
                  "hr_escalate": data.ae_heart  == true ? "1" : "0",
                  "rr_escalate": data.ae_resp == true ? "1" : "0",
                  "spo2_thresh_low":data.tl_spo2,
                  "hr_thresh_low": data.tl_heart,
                  "hr_thresh_high": data.th_heart,
                  "rr_thresh_low": data.tl_resp,
                  "rr_thresh_high": data.th_resp,
                  "spo2_escalate_interval": data.aes_spo2,
                  "hr_escalate_interval": data.aes_heart,
                  "rr_escalate_interval": data.aes_resp,
                  "avatar_url": "new.png",
                  "dstate": patient.dstate,
                  "force_cloud": patient.force_cloud,
                  "mac_id": patient.mac_id,
                  "sensor_type": null,
                  "rox_thresh_low": data.tl_rox,
                  "rox_thresh_high": data.th_rox,
                  "rox_escalate": data.ae_rox  == true ? "1" : "0",
                  "rox_escalate_interval": data.aes_rox,
                  "rox_value": data.tof_rox,
                  "alert_user_state": "0",
                  "is_live": patient.is_live,
                  "is_ivrs": data.ivrs,
                  "is_sms": data.sms,
                  "temp_snooze_interval": data.si_temp,
                  "o2_snooze_interval": data.si_spo2,
                  "hr_snooze_interval": data.si_heart,
                  "rr_snooze_interval": data.si_resp,
                  "rox_snooze_interval": data.si_rox,
                  "temp_snooze": data.s_temp  == true ? "1" : "0",
                  "o2_snooze": data.s_spo2  == true ? "1" : "0",
                  "ppm_snooze": data.s_heart  == true ? "1" : "0",
                  "rpm_snooze": data.s_resp  == true ? "1" : "0",
                  "rox_snooze": data.s_rox  == true ? "1" : "0",
                  "mode": "2",
                  "offset": data.tof_temp,
                  // "offset":"0"
                }
                this.dialogRef.close(cardsettingsparams);
              }else{
                this.toastr.error("ROX Low should be below ROX High")
              }
            }else{
              this.toastr.error("Respiratory Rate Low should be below Respiratory Rate  High");
            }
          }else{
            this.toastr.error("Heart rate Low should be below Heart rate High");
          }

      }else{
        this.toastr.error("Temp Low should be below Temp High");
      }

     

    }
  }

  cardreset(){
      console.log("i am cardrest");
      var patient = this.carddata.patientdata
      var cardsettingsparams = {
        "patient_id": patient.patient_id,
        "device_id": patient.device_id,
        "code": patient.code.toUpperCase(),
        "patient_name": patient.patient_name,
        "dob": patient.dob,
        "sex": 0,
        "uhid": "",
        "doctor_id": "",
        "status": 0,
        "offset": "0",
        "alert_state": 0,
        "alert_interval": 0,
        "feverwatch": "",
        "date_created": "",
        "thresh_low": 50,
        "thresh_high": 200,
        "doctor_mobile": "",
        "profile_id": 123,
        "spo2": 4453,
        "respiratory": 4545,
        "o2_escalate": 0,
        "hr_escalate": 0,
        "rr_escalate": 0,
        "spo2_thresh_low": 70,
        "hr_thresh_low": 50,
        "hr_thresh_high": 200,
        "rr_thresh_low": 10,
        "rr_thresh_high": 20,
        "spo2_escalate_interval": 15,
        "hr_escalate_interval": 15,
        "rr_escalate_interval": 60,
        "avatar_url": "new.png",
        "dstate": 0,
        "force_cloud": 0,
        "mac_id": null,
        "sensor_type": null,
        "rox_thresh_low": 5,
        "rox_thresh_high": 10,
        "rox_escalate": 0,
        "rox_escalate_interval": 60,
        "rox_value": 21,
        "alert_user_state": 0,
        "is_live": 0,
        "is_ivrs": 0,
        "is_sms": 0,
        "temp_snooze_interval": 3600,
        "o2_snooze_interval": 3600,
        "hr_snooze_interval": 3600,
        "rr_snooze_interval": 3600,
        "rox_snooze_interval": 3600,
        "temp_snooze": 0,
        "o2_snooze": 0,
        "ppm_snooze": 0,
        "rpm_snooze": 0,
        "rox_snooze": 0,
        "mode": "2"
        
      }
      console.log("card rest", cardsettingsparams);
      this.dialogRef.close(cardsettingsparams);
  }
  cardfalse(){
    this.dialogRef.close();
  }

}

export interface DialogDatapdf {
  mypdfdata:any
}

@Component({
  selector: 'geomap',
  templateUrl:'geomap.html',
  styleUrls: ['geomap.scss']
})

export class DialogOverviewExamplegeomap {

  urlPoints;
  geolocations:any = []
  token = sessionStorage.getItem("access_token");
  code = sessionStorage.getItem("code");

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExamplegeomap>,private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDatapdf) {
      this.urlPoints = AppConfig.settings.EndPoints;
     
    }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.callmymarker();
  }

  callmymarker(){
    // Themes begin
    am4core.useTheme(am4themes_animated);
    am4core.addLicense("ch-custom-attribution");
    // Themes end
    
    // Create map instance
    let chart = am4core.create("chartdiv", am4maps.MapChart);
    
    // Set map definition
    chart.geodata = am4geodata_worldLow;
    
    // Set projection
    chart.projection = new am4maps.projections.Miller();
    
    // Create map polygon series
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    
    // Exclude Antartica
    polygonSeries.exclude = ["AQ"];
    
    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true;
    
    // Configure series
    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}";
    polygonTemplate.polygon.fillOpacity = 0.6;

  
    
    chart.events.on("ready", function(ev) {
      chart.zoomToMapObject(polygonSeries.getPolygonById("IN"));
    });

    chart.zoomToRectangle(
      chart.north,
      chart.east,
      chart.south,
      chart.west,
      1,
      true
    );

    // Create a zoom control
      var zoomControl = new am4maps.ZoomControl();
      chart.zoomControl = zoomControl;
      zoomControl.slider.height = 100;

    let home = chart.chartContainer.createChild(am4core.Button);
     home.label.text = "Reset Zoom";
     home.align = "right";
     home.events.on("hit", function(ev) {
       chart.goHome();
    });
    
    // Create hover state and set alternative fill color
    let hs = polygonTemplate.states.create("hover");
    hs.properties.fill = chart.colors.getIndex(0);
    
    // Add image series
    let imageSeries = chart.series.push(new am4maps.MapImageSeries());
    imageSeries.mapImages.template.propertyFields.longitude = "longitude";
    imageSeries.mapImages.template.propertyFields.latitude = "latitude";
    imageSeries.mapImages.template.tooltipText = "{title}";
    imageSeries.tooltip.label.interactionsEnabled = true;
    imageSeries.tooltip.keepTargetHover = true;
    console.log("environment.HOSTenvironment.HOST", environment.HOST);
    // href="{environment.HOST}/#/charts?code={code}&mrn={title}"
    // imageSeries.mapImages.template.tooltipHTML = '<b>{title}</b><br><a href="{environment.HOST}/#/charts?code={code}&mrn={title}">ICU info</a>';

    // polygonTemplate.tooltipHTML = '<b>{name}</b><br><a href="https://en.wikipedia.org/wiki/{name.urlEncode()}">More info</a>';
    imageSeries.mapImages.template.propertyFields.url = "url";
    
    let circle = imageSeries.mapImages.template.createChild(am4core.Circle);
    circle.radius = 3;
    circle.propertyFields.fill = "color";
    circle.nonScaling = true;
    
    let circle2 = imageSeries.mapImages.template.createChild(am4core.Circle);
    circle2.radius = 3;
    circle2.propertyFields.fill = "color";
    
    
    circle2.events.on("inited", function(event){
      animateBullet(event.target);
    })
    
    function animateBullet(circle) {
        let animation = circle.animate([{ property: "scale", from: 1 / chart.zoomLevel, to: 5 / chart.zoomLevel }, { property: "opacity", from: 1, to: 0 }], 1000, am4core.ease.circleOut);
        animation.events.on("animationended", function(event){
          animateBullet(event.target.object);
        })
    }
    
    let colorSet = new am4core.ColorSet();

    var getmapparams = {
      "code":this.code
    }
    this.appService.getData(this.urlPoints.getlocations, getmapparams).subscribe((response: any) => {
         console.log("params", response.ivital)
         var res = response.ivital
         if(res.success == 1){
            for(var i= 0;i<res.locations.length;i++){
             this.geolocations.push({
                "title": res.locations[i].title,
                "code":this.code.toUpperCase(),
                "latitude":  parseFloat(res.locations[i].latitude),
                "longitude": parseFloat(res.locations[i].longitude),
                "color":colorSet.next()
              })
            }

            imageSeries.data  = this.geolocations
         }
    });
    
    
        
    }
    closemap(){
      this.dialogRef.close();
    }    

  closepop(){
    this.dialogRef.close();
  }

}



export interface DialogDatapeertopeer {
  mypdfdata:any
}

@Component({
  selector: 'peertopeerlist',
  templateUrl:'peertopeerlist.html',
  styleUrls: ['peertopeerlist.scss']
})


export class DialogOverviewExamplepeertopeer {

  urlPoints;

  token = sessionStorage.getItem("access_token");
  // epicaretoken = "b2F1dGhfdG9rZW49aWJrOEpYUXJUbVgzbk9ra2tIb3gmb2F1dGhfdG9rZW5fc2VjcmV0PU1RQnFOenJ3MGNZQzFPZ0huWmNmJmFjY291bnRfaWQ9cng0JTQwcnhjYXJlbmV0Lm9yZw=="
  epicaretoken =  localStorage.getItem("authentication");
  code = sessionStorage.getItem("code");
  account_email = sessionStorage.getItem("acc_email");
  doctor_id = sessionStorage.getItem("doc_id");
  pubnub: PubNubAngular;
  peerlist:any = [];
  

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExamplepeertopeer>,private appService: AppService,private router: Router,public dialog: MatDialog,pubnub: PubNubAngular,
    @Inject(MAT_DIALOG_DATA) public data: DialogDatapeertopeer) {
      this.urlPoints = AppConfig.settings.EndPoints;
      this.pubnub = pubnub
      this.pubnub2();
    }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.callpeertopeerlist()
  }

  pubnub2() {
    var account_id = sessionStorage.getItem("acc_email")
    var channel = account_id.replace('@','_').replace('.org', '');
    var uuid = account_id
    var doctor_id = sessionStorage.getItem("doc_id")
    this.pubnub.init({
      publishKey: 'pub-c-c3cecd91-62bc-4365-b2d1-a6333576f17f',
      subscribeKey: 'sub-c-60c79014-12d7-11e8-b32f-5ea260837941',
      uuid: uuid
    });

    this.pubnub.subscribe({
      channels: [channel, 'get_banner_'+ channel, 'videocall_'+channel+'_'+doctor_id, 'vital_criticalityscore_'+channel ,'peer_room_'+account_id+'_'+doctor_id, 'peer_room_status_'+account_id+'_'+doctor_id, 'voice_' + uuid+'_'+doctor_id,'room_' + uuid+'_'+doctor_id],
      withPresence: true,
      triggerEvents: ['callback', 'message', 'presence']
    });

    this.pubnub.addListener({
      message: msg=> {
        if(msg){
          console.log("Get message from Channel****", msg);
          var split_Channel_Name = msg.channel.split("_");
          console.log(split_Channel_Name[1])
          if(split_Channel_Name[1] == 'room'){
            console.log("***Room channel")
            this.callpeertopeerlist();
          }     
         }
       
        
        }
     })

  
    this.pubnub.getMessage([channel, 'get_banner_'+ channel, 'videocall_'+channel+'_'+doctor_id, 'vital_criticalityscore_'+channel , 'voice_' + uuid+'_'+doctor_id,'room_' + uuid+'_'+doctor_id], (msg) => {
      console.log("message", msg); 
    });

    this.pubnub.getStatus([channel, 'get_banner_'+ channel, 'videocall_'+channel+'_'+doctor_id, 'vital_criticalityscore_'+channel , 'voice_' + uuid+'_'+doctor_id,'room_' + uuid+'_'+doctor_id], (msg) => {
    console.log("message", msg);
    });

    this.pubnub.getPresence([channel, 'get_banner_'+ channel, 'videocall_'+channel+'_'+doctor_id, 'vital_criticalityscore_'+channel , 'voice_' + uuid+'_'+doctor_id,'room_' + uuid+'_'+doctor_id], (msg) => {
      console.log("message", msg);
    });

  

  }

  callpeertopeerlist(){
    var type:any = sessionStorage.getItem("is_live")
    var peertopeerparams = {
      "account_email" : this.account_email,
      "code": this.code.toUpperCase(),
      "doctor_id": this.doctor_id
    }
    if(type == 0){
      this.appService.postecData( environment.epic+"/service/getpeerforaccesscode", peertopeerparams , this.epicaretoken).subscribe((response: any) => {
        this.peerlist = [];
        if(response.success == 1){
          for(var i=0;i< response.details.length;i++){
            if(response.details[i].room_status!=1){
              this.peerlist.push(response.details[i]);
            }
          }
        }
        console.log("resonse peer", this.peerlist);
      });
    }else if (type == 1){
      this.appService.postecData( environment.epic_dev+"/service/getpeerforaccesscode", peertopeerparams , this.epicaretoken).subscribe((response: any) => {
        this.peerlist = [];
        if(response.success == 1){
          for(var i=0;i< response.details.length;i++){
            if(response.details[i].room_status!=1){
              this.peerlist.push(response.details[i]);
            }
          }
       }
      });
    }

  }

  callroom(data , data1){
  
  var patient_email = data.patient_email;
  var a_type = data.a_type ;
  var room_name= data.room_name;
  var room_sid= data.room_sid;
  var patient_connect_status= data.patient_connect_status;
  var room_status = data.room_status;
  var doctor_connect_status= data.doctor_connect_status;
  var doctor_audio_track_status= data.doctor_audio_track_status;
  var doctor_video_track_status= data.doctor_video_track_status;
  var patient_video_track_status= data.patient_video_track_status;
  var patient_audio_track_status= data.patient_audio_track_status

  console.log("this.epicaretoken", data1.access_token , room_name)

  var Video = connect(data1.access_token , { name: room_name , audio: false, video: { height: 100, frameRate: 10, width: 100 }}).then(room => {
    console.log('Connected to Room "%s"', room.name);

    room.participants.forEach(participantConnected);
    room.on('participantConnected', participantConnected);

    room.on('participantDisconnected', participantDisconnected);

  });

  createLocalVideoTrack().then(track => {
    const localMediaContainer = document.getElementById('local-media');
    localMediaContainer.appendChild(track.attach());
  });


  function participantConnected(participant) {
    console.log('Participant "%s" connected', participant.identity);

    const div = document.createElement('div');
    div.id = participant.sid;
    div.innerText = participant.identity;

    participant.on('trackSubscribed', track => trackSubscribed(div, track));
    participant.on('trackUnsubscribed', trackUnsubscribed);

    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        trackSubscribed(div, publication.track);
      }
    });

    document.body.appendChild(div);
  }

  function participantDisconnected(participant) {
    console.log('Participant "%s" disconnected', participant.identity);
    document.getElementById(participant.sid).remove();
  }

  function trackSubscribed(div, track) {
    div.appendChild(track.attach());
  }

  function trackUnsubscribed(track) {
    track.detach().forEach(element => element.remove());
  }

  }

  callaccesstoken(data){
    
    var type:any = sessionStorage.getItem("is_live")
    var peertopeerparams = {
      "account_email" : this.account_email,
      "code": this.code,
      "doctor_id": this.doctor_id,
      "patient_email" : data.patient_email,
      "a_type": "instant",
      "a_id": "",
      "mode": "peer"
    }
    if(type == 0){
      this.appService.postecData( environment.epic+"/service/rejoinvitalpeercall", peertopeerparams , this.epicaretoken).subscribe((response: any) => {
        if(response.success == 1){
             //this.callroom(data , response)
            console.log("response", response);
            console.log("1240 data", data);
             this.router.navigate(["/charts"],{ queryParams: {
              code: this.code,
              mrn: data.mr,
              room: response.room_name,
              accesstoken: response.access_token,
              mode:'connect'
             } })
        }
      });
    }else if (type == 1){
      this.appService.postecData( environment.epic_dev+"/service/rejoinvitalpeercall", peertopeerparams , this.epicaretoken).subscribe((response: any) => {
        if(response.success == 1){
          console.log("response",response);
          this.router.navigate(["/charts"],{ queryParams: {
            code: this.code,
            mrn: data.mr,
            room: response.room_name,
            accesstoken: response.access_token,
            mode:'connect'
           } })
        }
      });
    }

  }

  jointopatientroom(data){
      console.log("data", data);
      var type:any = sessionStorage.getItem("is_live")
      var peertopeerparams = {
        "account_email" : this.account_email,
        "code": this.code.toUpperCase(),
        "doctor_id": this.doctor_id,
        "patient_email" : data.patient_email
      }
      if(type == 0){
        this.appService.postecData( environment.epic+"/service/getpeerforaccesscode", peertopeerparams , this.epicaretoken).subscribe((response: any) => {
          if(response.success == 1){
              console.log("response", response);
              for(var i=0;i<response.details.length;i++){
                   if(response.details[i].mr == data.mr){
                     console.log("that selected mr", data.mr)
                     this.callaccesstoken(data);
                     this.closepeertopeer();
                   }
              }
          }
        });
      }else if (type == 1){
        this.appService.postecData( environment.epic_dev+"/service/getpeerforaccesscode", peertopeerparams , this.epicaretoken).subscribe((response: any) => {
          if(response.success == 1){
            console.log("response", response);
            for(var i=0;i<response.details.length;i++){
                 if(response.details[i].mr == data.mr){
                   console.log("that selected mr", data.mr)
                   this.callaccesstoken(data);
                   this.closepeertopeer();
                 }
            }
        }
        });
      }
  
  }

 
 
  closepeertopeer(){
      this.dialogRef.close();
    }    

  closepop(){
    this.dialogRef.close();
  }

}

