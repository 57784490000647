<div>
    
    <div style="width: 100%; height: 560px;">
        <div class="text-center pt-2 pl-2 pr-2">
            <span><img src="/assets/p2plogo.svg" height="25"/><i style="font-size:18px;float:right;color:rgb(247, 62, 62)" class="fa" (click)="closepeertopeer()">&#xf00d;</i></span>
           <h4 style="color: #000;">Peers Joined list</h4>
        </div>
        <hr style="color: black;">
         
        <div class="row peerdiv mt-2" *ngFor="let data of peerlist; let i=index;">
            <div class="col-md-1" style="padding-top: 10px;"><strong style="padding-top: 4px;">{{i+1}}</strong></div>
            <div class="col-md-7 peerdiv_left">
                <h4>Name : <strong style="color: #405D6A;">{{data.patient_name |titlecase}}</strong></h4>
                <h4>MR Id : <strong style="color: #405D6A;">{{data.mr}}</strong></h4>
            </div>
            <div class="col-md-4 peerdiv_right pt-2">
                <h4 *ngIf="(data.room_status == 0 && data.patient_connect_status == 1)"><button class="btn btn-sm btn-success" style="padding: 1px 10px 1px 10px;border:none;" (click)="jointopatientroom(data)">Join</button></h4>
                <h4 *ngIf="(data.room_status == 0 && data.patient_connect_status == 0)"><strong style="color: rgba(248, 121, 71, 0.863);">Pending</strong></h4>
                <h4 *ngIf="(data.room_status == 1 && data.patient_connect_status == 1)"><strong style="color: rgba(248, 121, 71, 0.863);">Pending</strong></h4>
                <h4 *ngIf="(data.room_status == 1 && data.patient_connect_status == 0)"><strong style="color: rgba(248, 121, 71, 0.863);">Pending</strong></h4>
            
            </div>
        </div>
    </div>
</div>