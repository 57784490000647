import {  Input, OnInit , Type} from '@angular/core';
import { PubNubAngular } from 'pubnub-angular2';
import { AppConfig } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { computeDecimalDigest } from '@angular/compiler/src/i18n/digest';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { stringify } from 'querystring';
import { environment } from 'src/environments/environment';


export interface DialogData {
  patientdata: any;
}

export interface DialogDatadoctor {
  doctordata: any,
  doctorlist:any
}

export interface DialogDatacommunity {
  communitydata: any,
  eightdigit: any;
}


@Component({
  selector: 'app-addpatient',
  templateUrl: './addpatient.component.html',
  styleUrls: ['./addpatient.component.scss']
})
export class AddpatientComponent implements OnInit {

  minDate: Date;
  maxDate: Date;
  searchfilter: any;
  urlPoints;
  addpatientParams;
  doctorlistParams;
  addpatient_List;
  ageyear:any = [];
  is_live;
  cloud_monitoring;
  enable_doctor;
  doctor_List:any = [];
  totalpagebuttons:any = [];
  doctor_list;
  patientForm;
  count:number = 360;
  p = 0;
  pubnub;
  clearinter;
  mypage;

  d_state = 2;
  d_statetype;
  code = sessionStorage.getItem("code")

  token = localStorage.getItem("authentication");
  orderHeader: String = '';
  isDescOrder: boolean = false;
  countrycode;

  constructor(private fb: FormBuilder , private appService: AppService, private spinner: NgxSpinnerService , pubnub: PubNubAngular, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router , private toastr: ToastrService , public dialog: MatDialog) { 
    this.urlPoints = AppConfig.settings.EndPoints;
    this.pubnub = pubnub
    this.maxDate = new Date();
    

    this.patientForm = this.fb.group({
      patientName: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      DOB:["", [Validators.required]],
      gender:[" ", [Validators.required]],
      // doctor:["select", [Validators.required]],
      // patientid:["", [Validators.required]],
      // bedno:["", [Validators.required]],
      phonenumber:["",[Validators.required]]
    })
    
    

    // console.log("this.patientForm", this.patientForm);
    

    this.doctorlistParams = {
      'mode': '4',
      'code': this.code.toUpperCase(),
    }
    this.getdoctorlist()
    this.spinner.show();

    this.addpatient('2')
  }

  ngOnInit(): void {
    this. myFunction()
  }

  pageChanged(data){
    alert()
    console.log("data", data);
  }

  onCountryChange(event){
    console.log(event.dialCode);
    console.log(event.name);
    console.log(event.iso2);
    this.countrycode = event.dialCode;
}

  myFunction() {
    var that = this;
    that.clearinter = setInterval(function(){ 
     that.count -= 1
    if(that.count == 0){
      clearInterval(that.clearinter);
      that.router.navigate(["/home"],{ queryParams: { } })
    }
  }, 1000);
  }

  cancel_patientform(){
    this.patientForm.reset();
    var elems = document.querySelector(".active");
    elems.classList.remove("active");
  }

 
  sort(headerName: String){
    this.isDescOrder = !this.isDescOrder;
    this.orderHeader = headerName;
  }

 
  addpatient(type){
    this.d_statetype = parseInt(type)
    this.addpatientParams = {
      'mode': '1',
      'params': this.code.toUpperCase(),
      'dstate': parseInt(type),
      'page': 0
    }
    this.appService.getData(this.urlPoints.getpatientlist, this.addpatientParams).subscribe((response: any) => {
      this.addpatient_List = []
      this.totalpagebuttons = [];
      console.log(response, 'res');
     
      for(var i=0;i< parseInt(response.patient[0].totalpages)+1;i++){
        this.totalpagebuttons.push(i)
      }
      this.addpatient_List = response.patient[0].response;
      
    });
  }

  callpagination(page , k){
    
    this.mypage = k;
    this.addpatientParams = {
      'mode': '1',
      'params': this.code.toUpperCase(),
      'dstate': this.d_statetype,
      'page': parseInt(page)
    }
    this.appService.getData(this.urlPoints.getpatientlist, this.addpatientParams).subscribe((response: any) => {
      this.addpatient_List = []
      this.totalpagebuttons = [];
      console.log(response, 'res');
      
      for(var i=0;i< parseInt(response.patient[0].totalpages)+1;i++){
        this.totalpagebuttons.push(i)
      }

      this.addpatient_List = response.patient[0].response;
      
    });
  }
 
  getdoctorlist(){
    this.appService.getData(this.urlPoints.doctorlist, this.doctorlistParams).subscribe((response: any) => {
      this.spinner.hide();
      this.doctor_list = response.doctor[0].doctor
      // console.log("this.doctor_list", this.doctor_list);
      for(var i=0;i<this.doctor_list.length ; i++){
        this.doctor_List.push({
          "dr_name" : this.doctor_list[i].dr_name+" -> "+this.doctor_list[i].dr_mobile,
          "dr_id":this.doctor_list[i].dr_id 
        })
      }
      console.log('response', this.doctor_List);
    });
  }
  save_patient(){
  
  }

  addcommunity(data){
  console.log("addcommunity", data);
    var params={
      "code": data.code,
      "patient_id": data.patient_id
    }
 
    sessionStorage.setItem("community_pid", data.patient_id);
    this.appService.getData(this.urlPoints.getcommunity, this.addpatientParams).subscribe((response: any) => {
        console.log("137 res", response);
        if(response.settings.success == 1){
          console.log("community data", data);
          sessionStorage.setItem("eight", response.settings.code)
          if(data.is_community == 0){
            const dialogRef = this.dialog.open(DialogOverviewExampleDialog_community, {
              width: '950px',
              data: {
                communitydata : data,
                eightdigit: response.settings.code
              }
            });
        
            dialogRef.afterClosed().subscribe(result => {
              console.log('The dialog was closed');
              // this.animal = result;
              if(result){
                 console.log("result ", result);
              }
            });
          }
        }
        
    });
    
     
  }

  addenabledoctor(data){
    console.log("data", data.doctor_id);
   
    if((data.doctor_id == '') || (data.doctor_id == null)){
      const dialogRef = this.dialog.open(DialogOverviewExampleDialog_doctor, {
        width: '550px',
        data: {
          doctordata : data,
          doctorlist: this.doctor_list
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
        if(result){
           console.log("result check 205", result);
           console.log("res", result.data.doctordata.patient_id)
           var update_doctorparams = {
            "code": sessionStorage.getItem("code"),
            "mode":5,
            "patient_id": result.data.doctordata.patient_id,
            "doctor_id": result.id
          }
          this.update_doctor(this.urlPoints.patientupdate , update_doctorparams , this.token)
        }
      });
     } else {
      var params ={
        "mode":5,
        "patient_id": data.patient_id,
        "code": sessionStorage.getItem("code"),
        "doctor_id": ""
    } 
    this.update_doctor(this.urlPoints.patientupdate , params , this.token)
  }






    // if((data.doctor_id != null) || (data.doctor_id != '')){
    //   alert()
     

    // }
  }

  // enabledoctor(data){
  //   console.log("data enable doc", data);
  //   this.enable_doctor = !this.enable_doctor
  //   if(this.enable_doctor == true){
     
  //   }else{
      
  //   }
   
  // }

  update_doctor(url ,params , token){
    this.appService.postData(url ,params , token).subscribe((response: any) => {
      if(response.patient[0].success == 1 && response.patient[0].message == "updated successfully"){
        this.toastr.success(response.patient[0].message);
      }else{
        this.toastr.error(response.patient[0].message);
      }
    });
  }



  cloudmonitoring(data){
    console.log("t", data);
    
    if(data.force_cloud === '0'){
      const dialogRef = this.dialog.open(DialogOverviewExampleDialogcloud, {
        width: '550px',
        data: {
          patientdata : data
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
    
      var update_cloudmonitoringparams = {
        "dstate": 1,
        "mode": 4,
        "patient_id": data.patient_id,
        "code":sessionStorage.getItem("code").toLowerCase()
      }
      this.spinner.show();
      this.patientdata(data.patient_id , update_cloudmonitoringparams)
      this.update_cloudmonitoring(this.urlPoints.patientupdate , update_cloudmonitoringparams , this.token)
    });
    }else if(data.force_cloud === '1'){
      var update_cloudmonitoringparams = {
        "dstate": 0,
        "mode": 4,
        "patient_id": data.patient_id,
        "code":sessionStorage.getItem("code").toLowerCase()
  
      }
      this.spinner.show();
      this.patientdata(data.patient_id , update_cloudmonitoringparams)
      this.update_cloudmonitoring(this.urlPoints.patientupdate , update_cloudmonitoringparams , this.token)
    
    }

   
  }

  update_cloudmonitoring(url ,params , token){
    this.appService.postData(url ,params , token).subscribe((response: any) => {
      if(response.patient[0].success == 1 && response.patient[0].message == "updated successfully"){
        this.toastr.success(response.patient[0].message);
        this.spinner.hide();
        this.addpatient_List = [];
        this.searchfilter = ''
        this.addpatient('3');
      }else{
        this.toastr.error(response.patient[0].message);
        this.spinner.hide();
      }
    });
  }

  patientdata(patient_id,message){
    console.log('patient 306',patient_id, message);
    this.pubnub.init({
      publishKey: 'demo',
      subscribeKey: 'demo',
      ssl: false
    });

    var channel_name = message.code;
    console.log("channel_name",'get_settings_'+channel_name.toLowerCase()+'_'+patient_id.toLowerCase());
    this.pubnub.subscribe({
        channels: ['get_settings_'+channel_name.toLowerCase()+'_'+patient_id.toLowerCase()]
    });

    this.pubnub.publish({
        channel: 'get_settings_'+channel_name.toLowerCase()+'_'+patient_id.toLowerCase(),
        message:  message
    })

    this.pubnub.unsubscribe({
        channels: ['get_settings_'+channel_name.toLowerCase()+'_'+patient_id.toLowerCase()]
    })
}

  islive(data){
    console.log("data", data);
    console.log("dstate" , data.dstate == 0 ? 1:0)
    var boolean = data.dstate == 0 ? 1:0
    console.log("boolean", boolean);
    if(boolean == 1){
      var isliveparams = {
         "code": data.code,
         "dstate": 1,
         "mode": "3",
         "patient_id": data.patient_id
       }
    this.spinner.show();
    this.update_islive(this.urlPoints.patientupdate , isliveparams , this.token)
    }else if(boolean == 0){
      var isliveparams = {
          "code": data.code,
          "dstate": 0,
          "mode": "3",
          "patient_id": data.patient_id
      }
    this.spinner.show();
    this.update_islive(this.urlPoints.patientupdate , isliveparams , this.token)
    }

    
  }

  update_islive(url ,params , token){
    this.appService.postData(url ,params , token).subscribe((response: any) => {
      if(response.patient[0].success == 1 && response.patient[0].message == "updated successfully"){
        this.spinner.hide();
        this.toastr.success(response.patient[0].message);
      }else{
        this.spinner.hide();
        this.toastr.error(response.patient[0].message);
      }
    });
  }

  
  
  save_patientForm(){
     if(this.patientForm.valid){
      console.log("this.patientForm", this.patientForm.value);

      var data = this.patientForm.value;
 
      var params = {
        "mode": "1",
        "demail": sessionStorage.getItem("acc_email"),
        "fname": data.patientName,
        "lname": "",
        "gender": data.gender,
        "dob": moment(new Date(data.DOB)).format('YYYY-MM-DD'),
        "weight": "",
        "bgroup": "",
        "address": "",
        "father": "",
        "mother": "",
        "phone1": data.phonenumber,
        "phone2": "",
        "email": "",
        "edate": moment(new Date(data.DOB)).format('DD'),
        "emonth": moment(new Date(data.DOB)).format('MM'),
        "eyear": moment(new Date(data.DOB)).format('YYYY'),
        "uhid": "",
        "country_code": "91"
    }

    console.log("456 params", params)

    var type:any = sessionStorage.getItem("is_live");
    var token = localStorage.getItem("authentication");

    if(type == 0){
      // var responses = {"mh":{"success":1,"mr_number":"MR62297","patient_email":"rx9432@rxcarenet.org"}}
      //     console.log("responses", responses)
            
         
      this.appService.postecData( "https://test.epicare.net/helyxonapi/mh/api/v3.1/ivital_register.php", params , token).subscribe((response: any) => { 
          if(response.mh.success == 1){
                  this.checkpatient(response.mh.mr_number);
          }
       });
      }else if (type == 1){
      this.appService.postecData( "https://epicare.net/helyxonapi/mh/api/v3.1/ivital_register.php", params , token).subscribe((response: any) => {
        if(response.mh.success == 1){
          this.checkpatient(response.mh.mr_number);
      }
      });
    }

    }
  }

  checkpatient(data){
    // https://ivital.co/ivital/api/v1.3/patient_id.php?pid=patient_id&code=access_code
    this.code = sessionStorage.getItem("code").toLowerCase();

    var mr = data

    var Params = {
      pid:data,
      code:this.code
    }

    this.appService.getData(this.urlPoints.patient_id, Params).subscribe((response: any) => {
          console.log("res", response);
          var data = response.patient[0]

          function epoch (date) {
            return Date.parse(date)/1000
          }
          
          const dateToday = this.patientForm.value.DOB // Mon Jun 08 2020 16:47:55 GMT+0800 (China Standard Time)
          const timestamp = epoch(dateToday)
          
          console.log(timestamp)
          if(data.success == 1){
            
            var paramsexists = {
                pid: mr,
                did: "ivital_web",
                dcode: sessionStorage.getItem("code").toLowerCase(),
                pname: this.patientForm.value.patientName,
                pdob: timestamp,
                psex: this.patientForm.value.gender === 'male' ? '0' : '1',
                puhid:"",
                pdrid:1,
                palert:0,
                palertesc:0,
                fwatch:"",
                tlow:36,
                thigh:38,
                offset:0,
                prid:123,
                spo2_id:4453,
                rr_id:4545,
                ox_escalate:0,
                hr_escalate:0,
                rr_escalate:0,
                spo2_low:70,
                hr_low:50,
                hr_high:200,
                rr_low:10,
                rr_high:20,
                spo2_interval:15,
                hr_interval:15,
                rr_interval:60,
                rox_low:5,
                rox_high:10,
                rox_escalate:0,
                rox_escalate_interval:60,
                avatar:"",
                mode:0,
                caretaker:""
              }

              console.log("paramsexists 549", paramsexists )
              this.patient_doesnotexist(paramsexists)

            }else if(data.success == 0){
              
              var param = {
                "patient_id": mr,
                "device_id": "ivital_web",
                "code": sessionStorage.getItem("code").toLowerCase(),
                "patient_name": this.patientForm.value.patientName,
                "dob": timestamp,
                "sex": this.patientForm.value.gender === 'male' ? '0' : '1',
                "uhid": "",
                "status": 0,
                "alert_state": 0,
                "alert_interval": 0,
                "feverwatch": "",
                "date_created": "",
                "offset": 0,
                "thresh_low": 35,
                "thresh_high": 38,
                "profile_id": 123,
                "spo2": 4453,
                "respiratory": 4545,
                "o2_escalate": 0,
                "hr_escalate": 0,
                "rr_escalate": 0,
                "spo2_thresh_low": 89,
                "hr_thresh_low": 80,
                "hr_thresh_high": 120,
                "rr_thresh_low": 66,
                "rr_thresh_high": 88,
                "spo2_escalate_interval": 0,
                "hr_escalate_interval": 0,
                "rr_escalate_interval": 0,
                "avatar_url": "",
                "dstate": 1,
                "force_cloud": 0,
                "rox_thresh_low": 3,
                "rox_thresh_high": 5,
                "rox_escalate": 0,
                "rox_escalate_interval": 60,
                "rox_value": 21,
                "alert_user_state": 0,
                "is_live": 0,
                "mode": 2
              }

              this.patient_doesexist(param)

          }
    });
  }

  patient_doesnotexist(param){
    this.appService.getData(this.urlPoints.mpatient_update, param).subscribe((response: any) => {
         console.log("response 572", response);
         this.toastr.success(response.patient[0].message);
         
    this.patientForm = this.fb.group({
      patientName: [""],
      DOB:[""],
      gender: "",
      phonenumber:[""]
    })

    });

  }

  patient_doesexist(data){
    this.appService.postData(this.urlPoints.patient_update, data, this.token).subscribe((response: any) => {
      console.log("response 624", response);
      this.toastr.success(response.patient[0].message);

      this.patientForm = this.fb.group({
        patientName: [""],
        DOB:[""],
        gender: "",
        phonenumber:[""]
      })

 });
  }


  search_filter(){
       var data = this.searchfilter.replace(' ','%')
      var Params = {
        'mode': '1',
        'params': this.code.toUpperCase(),
        'dstate': '4',
        'search': data
      }
     
      this.appService.getData(this.urlPoints.getpatientlist, Params).subscribe((response: any) => {
        console.log("search response", response);
        this.addpatient_List = []
        this.totalpagebuttons = [];
        console.log(response, 'res');
       
        for(var i=0;i< parseInt(response.patient[0].totalpages)+1;i++){
          this.totalpagebuttons.push(i)
        }
        this.addpatient_List = response.patient[0].response;
      });
  }

  
  deletepatient(data){
      const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
        width: '550px',
        data: {
          patientdata : data
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
        if(result){
           console.log("resukt ", result);
           this.opendeletemodal(result);
        }
      });
    
  }

  opendeletemodal(data){
    console.log('data 47', data);
    var delete_params = {
     "patient_name": data.patientdata.patient_name,
     "patient_id":data.patientdata.patient_id,
     "code":data.patientdata.code,
     "dob":data.patientdata.dob,
     "profile_id":data.patientdata.profile_id,
     "mode":'1'
   }
   this.delete(this.urlPoints.patientupdate , delete_params , this.token)
 }

 delete(url ,params , token){
   console.log("url ,params , tokenurl ,params , token", url ,params , token);
   this.appService.postData(url ,params , token).subscribe((response: any) => {
     if(response.patient[0].success == 1){
       this.toastr.success(response.patient[0].message);
     }else{
       this.toastr.error(response.patient[0].message);
     }
   });
 }


 ngOnDestroy() {
  clearInterval(this.clearinter);
 }
  

}


@Component({
  selector: 'cloudmessagepopup',
  templateUrl: 'cloudmessage.html',
  styleUrls: ['cloudmessage.scss']
})

export class DialogOverviewExampleDialogcloud {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialogcloud>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

      console.log("dtttta", this.data);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
 
  messagecloud(){
    this.dialogRef.close();
  }

}



@Component({
  selector: 'deletepopup',
  templateUrl: 'deletepopup.html',
  styleUrls: ['deletepopup.scss']
})

export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

      console.log("dtttta", this.data);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deletetrue(){
    this.dialogRef.close(this.data);
    console.log("I am true" , this.data);
    
  }
  deletefalse(){
    console.log("i am false");
    this.dialogRef.close();
  }

}


@Component({
  selector: 'adddoctor',
  templateUrl: 'adddoctor.html',
  styleUrls: ['adddoctor.scss']
})

export class DialogOverviewExampleDialog_doctor {
  doctor_list;
  selectdoctor;
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDatadoctor ) {

      console.log("docotr data", this.data);
      
    }

    ngOnInit(): void {
      this.doctor_list = this.data.doctorlist;
      console.log("this.data.doctorlist", this.data.doctorlist);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  adddoctrue(){
    var Data = {
      data : this.data,
      id: this.selectdoctor
    }
    this.dialogRef.close(Data);
    console.log("I am true" , Data);
    
  }
  adddocfalse(){
    console.log("i am false");
    this.dialogRef.close();
  }

}


@Component({
  selector: 'community',
  templateUrl: 'community.html',
  styleUrls: ['community.scss']
})

export class DialogOverviewExampleDialog_community {
  communityForm;
  countrycode;
  eightdigit;
  token = localStorage.getItem("authentication");
  realcode = sessionStorage.getItem("eight");
  urlPoints

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,private fb: FormBuilder,private appService: AppService,private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDatacommunity ) {
      this.urlPoints = AppConfig.settings.EndPoints;

      this.eightdigit = this.data.eightdigit
      console.log("docotr data", this.data);
      this.communityForm = this.fb.group({
        community_code: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
        phonenumber: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(15)]],
        Emailaddress: ["", [Validators.email]],
        // password: ["", [Validators.required, Validators.maxLength(15)]],
        islive:[""]
      })
      
    }

    ngOnInit(): void {
      
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  adddoctrue(){
    var Data = {
      data : this.data,
    }
    this.dialogRef.close(Data);
    console.log("I am true" , Data);
    
  }
  adddocfalse(){
    console.log("i am false");
    this.dialogRef.close();
  }
  onCountryChange(event){
    console.log(event.dialCode);
    console.log(event.name);
    console.log(event.iso2);
    this.countrycode = event.dialCode;
}

  community_form(){
    if(this.communityForm.valid){
      console.log("this.communityForm.valid", this.communityForm.value);
      var data = this.communityForm.value
      var communitypostparams = {
        "code": sessionStorage.getItem("code").toUpperCase(),
        "community_code": data.community_code,
        "patient_id" : sessionStorage.getItem("community_pid"),
        "email" : data.Emailaddress,
        "mobile" : data.phonenumber,
        "country_code" : this.countrycode,
        "is_notify" : data.islive == true ? "1" : "0"
      }

      this.communitypost( this.urlPoints.updatecommunity , communitypostparams , this.token)
    }
  }

  communitypost(url , params , token){
    this.appService.postData(url ,params , token).subscribe((response: any) => {
      if(response.settings.success == 1){
        this.toastr.success(response.settings.message);
        this.dialogRef.close();
      }
    });

}



}


