import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AppConfig } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {Md5} from 'ts-md5/dist/md5';

@Component({
  selector: 'app-createaccount',
  templateUrl: './createaccount.component.html',
  styleUrls: ['./createaccount.component.scss']
})
export class CreateaccountComponent implements OnInit {

  urlPoints;
  createForm;
  countrycode;
  password = "password";
  show;

  code = sessionStorage.getItem("code");
  token = localStorage.getItem("authentication");

  constructor(private fb: FormBuilder , private appService: AppService,private route: ActivatedRoute, private router: Router , private toastr: ToastrService) { 
    this.urlPoints = AppConfig.settings.EndPoints;

    this.createForm = this.fb.group({
      username: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      phonenumber: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      Emailaddress: ["", [Validators.required,Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      password: ["", [Validators.required,Validators.minLength(8), Validators.maxLength(15)]],
    })
    
  }
 
  ngOnInit(): void {

  }

  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

  onCountryChange(event){
      console.log(event.dialCode);
      console.log(event.name);
      console.log(event.iso2);
      this.countrycode = event.dialCode;
  }

  create_form(){
    console.log("this.createForm", this.createForm);
    if(this.createForm.valid){
      alert('')
      var data = this.createForm.value;
      console.log("data data", data);
      const md5 = new Md5();
      var data1 = md5.appendStr(data.password).end()
      var createusernamepassparams = {
        "mode": 2,
        "mobile" : data.phonenumber,
        "country_code" : this.countrycode,
        "uname" : data.username,
        "upswd" : data1,
        "email" : data.Emailaddress,
        "code" : this.code
      }
  
      this.createusernamepassword(this.urlPoints.verifyadminpassword , createusernamepassparams , this.token)
    }
  }

  createusernamepassword(url ,params , token){
    this.appService.postData(url ,params , token).subscribe((response: any) => {
      if(response.settings.success == 1 && response.settings.message == "created successfully"){
        console.log("response.settings.success",response.settings.message)
        this.toastr.success("Account " +response.settings.message);
        this.router.navigate(["/security"],{ queryParams: {}})
      }else{
        this.toastr.error(response.settings.message)
      }
    });
  }
  
}