import { Component, OnInit , Type} from '@angular/core';
import { PubNubAngular } from 'pubnub-angular2';
import { AppConfig } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { computeDecimalDigest } from '@angular/compiler/src/i18n/digest';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm;
  urlPoints;
  constructor(private fb: FormBuilder , private appService: AppService, pubnub: PubNubAngular, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router) { 
    this.urlPoints = AppConfig.settings.EndPoints;
    this.loginForm = this.fb.group({
      code: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
    })
  }

  ngOnInit(): void {
  }

  save_loginForm(){
    if(this.loginForm.valid){
      var formdata = this.loginForm.value
      sessionStorage.setItem("code",formdata.code)
      var loginParams = {
        "mode": 0,
        "code": formdata.code,
        "date": moment(new Date()).format("DD-MM-YYYY")
      }
      console.log(" loginParams loginParams",  loginParams);
      this.loginData(this.urlPoints.login , loginParams)
     }
  }

  loginData(url , params){
    this.appService.getData(url , params).subscribe((response: any) => {
      console.log("login rsponse" , response.login[0]);
        if(response.login[0].success == 1){
          var epicaredata = response.login[0].user;
          sessionStorage.setItem("access_token", epicaredata.access_token);
          localStorage.setItem("authentication", epicaredata.access_token)
          sessionStorage.setItem("acc_email", epicaredata.carenet_email);
          sessionStorage.setItem("doc_id",epicaredata.doctor_id);
          sessionStorage.setItem("is_live", epicaredata.is_live);
         
          // sessionStorage.setItem("profile_id", epicaredata.profile_id);
          this.router.navigate(["/home"],{ queryParams: {}})
        }
    });
  }

  


}
