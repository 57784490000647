import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Graph';
  name = 'Get Current Url Route Demo';
  currentRoute: string;
  hidenav = false;
  sub;
 route: string;



  constructor(location: Location,private router: Router){

    router.events.subscribe(val => {
      if (location.path() != "") {
        this.route = location.path();
        this.hidenav = true;
      } else {
        this.hidenav = false;
      }
    });

  }
    
}
