<div class="row">
    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
        <div class="bracket"></div>
        <figure class="highcharts-figure">
            <div id="container3"></div>
        </figure>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
        <div class="texts">
            <span class="spo2">SpO2</span>
            <span id="pi">PI</span>
        </div>
        
        <div style="display: flex;flex-direction: row;height: 75%;padding-bottom: 10px;">
            <div class="numbers_texts">
                <div style="height: 100%;" class="row1">
                    <div class="numbers">
                        <span class="x">&nbsp;{{number}}%</span>
                    </div>
                    <div class="equalizer">
                        <div id="e1" class="e e1"></div>
                        <div id="e2" class="e e2"></div>
                        <div id="e3" class="e e3"></div>
                        <div id="e4" class="e e4"></div>
                        <div id="e5" class="e e5"></div>
                        <div id="e6" class="e e6"></div>
                        <div id="e7" class="e e7"></div>
                        <div id="e8" class="e e8"></div>
                        <div id="e9" class="e e9"></div>
                        <div id="e10" class="e e10"></div>
                        <div id="e11" class="e e11"></div>
                        <div id="e12" class="e e12"></div>
                        <div id="e13" class="e e13"></div>
                        <div id="e14" class="e e14"></div>
                        <div id="e15" class="e e15"></div>
        
                    </div>
                </div>

                
            </div>
            <div class="brac">
        
            </div>
            <div class="row1">
                <div style="display: flex;flex-direction: column;">
                    <div class="row1 maxmin">
                        <p>&nbsp;100&nbsp;</p>
                    </div>

                    <div class="row1 maxmin">
                        <p style="align-self: flex-end;">&nbsp;{{low}}&nbsp;</p>
                    </div>
                </div>
            </div>

            <!-- <div class="equalizer">
                <div id="e1" class="e e1"></div>
                <div id="e2" class="e e2"></div>
                <div id="e3" class="e e3"></div>
                <div id="e4" class="e e4"></div>
                <div id="e5" class="e e5"></div>
                <div id="e6" class="e e6"></div>
                <div id="e7" class="e e7"></div>
                <div id="e8" class="e e8"></div>
                <div id="e9" class="e e9"></div>
                <div id="e10" class="e e10"></div>
                <div id="e11" class="e e11"></div>
                <div id="e12" class="e e12"></div>
                <div id="e13" class="e e13"></div>
                <div id="e14" class="e e14"></div>
                <div id="e15" class="e e15"></div>

            </div> -->
        </div>
    </div>
</div>