import { Component, VERSION ,OnInit } from '@angular/core';
import { PubNubAngular } from 'pubnub-angular2';
import { AppConfig } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { computeDecimalDigest } from '@angular/compiler/src/i18n/digest';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'src/environments/environment';
import domtoimage from 'dom-to-image';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject} from '@angular/core';





declare var require: any;
const More = require('highcharts/highcharts-more');
More(Highcharts);


export const MY_FORMATS = {
	parse: {
		dateInput: "LL"
	},
	display: {
		dateInput: "DD-MMM-YY",
		monthYearLabel: "YYYY",
		dateA11yLabel: "LL",
		monthYearA11yLabel: "YYYY"
	}
}

// const Exporting = require('highcharts/modules/exporting');
// Exporting(Highcharts);

// const ExportData = require('highcharts/modules/export-data');
// ExportData(Highcharts);

const Accessibility = require('highcharts/modules/accessibility');
Accessibility(Highcharts);


@Component({
  selector: 'app-multilinechart',
  templateUrl: './multilinechart.component.html',
  styleUrls: ['./multilinechart.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
],

})
export class MultilinechartComponent implements OnInit {

  code= sessionStorage.getItem("code");
  public activity;
  public xData;
  public label;
  newdata:any = [];
  urlPoints;
  highchartsParams;
  pname;
  dob;
  mr;
  minDate: Date;
  maxDate: Date;
  dateForm;
  timestamp1;
  timeParts2
  timeParts1
  timestamp2;

  finaltimestamp1;
  finaltimestamp2;
  finaltime1;
  finaltime2;
  fromdate 
  todate 
  t1;
  t2;

  token = localStorage.getItem("authentication");
  type:any = sessionStorage.getItem("is_live");
  Account_id  = sessionStorage.getItem("acc_email");


  date_Form
  pdfdata:any = [];

  printdownload = false;

  multiline_response;

  temp_min = 30;
  temp_max = 42;
  temp_steps = 2;

  pulse_min = 20;
  pulse_max = 160;
  pulse_steps = 20;

  oxygen_min = 70;
  oxygen_max = 105;
  oxygen_steps = 10;

  resp_min = 5;
  resp_max = 90;
  resp_steps = 5;

  pulse_x;
  pulse_y;
  pulselength;

  temp_x;
  temp_y;
  templength;

  oxygen_x;
  oxygen_y;
  oxygenlength;

  resp_x;
  resp_y;
  resplength;

  temp_options;
  pulse_options;
  oxygen_options;
  resp_options;
//   new Date().setHours( new Date().getHours() - 2 )


  format1 = 'MM/dd/yyyy HH:mm';
  value1: Date ;

  format2 = 'MM/dd/yyyy HH:mm';
  value2: Date ;
 
  Block = 1;
  
  patientemail;
  MR;

  bpivitaldata;
  glucoivitaldata;

  options1;
  options;

  auth = localStorage.getItem("authentication")

  bpdata;
  
  constructor(private spinner: NgxSpinnerService  , private fb: FormBuilder , private appService: AppService, pubnub: PubNubAngular, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router , public dialog: MatDialog) { 
    this.urlPoints = AppConfig.settings.EndPoints;
    this.maxDate = new Date();
   
    this.pname = sessionStorage.getItem("pname");
    var timedob:any = sessionStorage.getItem("dob"); 
    this.dob =  moment.unix( timedob).format("DD-MMM-YYYY");
    this.mr = sessionStorage.getItem("mr");
    this.t1 = moment().add(-1, 'hours').format('HH:mm');
    this.t2 = moment().add(0, 'hours').format('HH:mm')
    

    this.dateForm = this.fb.group({
        date1: [new Date(), [Validators.required]],
        date2: [new Date(), [Validators.required]],
        time1: [this.t1, [Validators.required]],
        time2: [this.t2, [Validators.required]], 
      })

      this.date_Form = this.fb.group({
        Date1: [new Date(new Date().setHours(new Date().getHours() - 1)), [Validators.required]],
        Date2: [new Date(), [Validators.required]],
      })
}

previewmultiline(){
    var params={
        "account_email": this.Account_id ,
        "access_code": this.code
    }

    if(this.type == 0){
       console.log("environment.epicenvironment.epic",environment.epic+"/service/getivitaldoctortemplate/", params)
        this.appService.getecData(environment.epic+"/service/getivitaldoctortemplate/", params , this.token).subscribe((response: any) => {
            
            if(response.status == 1){
                const dialogRef = this.dialog.open(DialogOverviewExampleDialogpdf, {
                    width: '60%',
                    data: {
                      mypdfdata : response.data
                    }
                  });
                
                  dialogRef.afterClosed().subscribe(result => {
                    if(result != undefined){
                        this.spinner.show();
                        this.previewmultilinecall(result)
                    }
                  });
                
            }
        })
    }else if(this.type == 1){
        this.appService.getecData(environment.epic_dev+"/service/getivitaldoctortemplate/", params , this.token).subscribe((response: any) => {
            if(response.status == 1){
                const dialogRef = this.dialog.open(DialogOverviewExampleDialogpdf, {
                    width: '60%',
                    data: {
                      mypdfdata : response.data
                    }
                  });
                
                  dialogRef.afterClosed().subscribe(result => {
                      if(result != undefined){
                        this.spinner.show();
                        this.previewmultilinecall(result)
                      }
                  });
            }
        })
    }
}

previewmultilinecall(data){
    var node = document.getElementById('container');
    var options = {quality: 1};
    domtoimage.toJpeg(node, options).then((dataUrl) => {
      console.log(dataUrl) 
      var mydata =  this.base64ImageToBlob(dataUrl);
       
      var dummyurl = "https://ivital.co/cloud/image/image.php";
      this.appService.postimgData(dummyurl, mydata , this.token).subscribe((response: any) => {
            console.log("response",response);
            if(response.type == 4){
                if(response.body.image[0].success == 1){
                    var Time1 = Math.trunc( new Date(this.date_Form.value.Date1).getTime() / 1000 );
                    var Time2 = Math.trunc( new Date(this.date_Form.value.Date2).getTime() / 1000 );
                   
                    var imgurl = response.body.image[0].filename;
                    var params = {
                        "account_email" : this.Account_id,
                        "template_id" : data.template_id,
                        "code" : this.code,
                        "did" : "",
                        "start_time" : Time1,
                        "end_time" : Time2,
                        "mac_id" : "ABDFJFDKJGD",
                        "vital_version" : "1.2.5",
                        "graph_image" : imgurl,
                        "patient_id" : this.mr
                    }
                   
                    if(this.type == 0){
                        this.appService.postecData(environment.epic+"/service/generateivitalreport" ,params , this.token).subscribe((response: any) => {
                         if(response.success == 1){
                            this.spinner.hide();
                            window.open("https://test.epicare.net/PDF/"+response.file_name);
                         }
                      });
                     }else if(this.type == 1){
                        this.appService.postecData(environment.epic_dev+"/service/generateivitalreport" ,params , this.token).subscribe((response: any) => {
                            if(response.success == 1){
                                this.spinner.hide();
                                window.open("https://epicare.net/PDF/"+response.file_name);
                             }
                        });
                     }
                }
            }
            
      });
    });

}

base64ImageToBlob(str) {
    var pos = str.indexOf(';base64,');
    var type = str.substring(5, pos);
    var b64 = str.substr(pos + 8);
    var imageContent = atob(b64);
    var buffer = new ArrayBuffer(imageContent.length);
    var view = new Uint8Array(buffer);
    for(var n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    var blob = new Blob([buffer], { type: type });
    return blob;
}


printmultiline(){
    
    var divToPrint = document.getElementById('container');
    var divglu = document.getElementById('glucometer');
    var divbp = document.getElementById('bp');
    var popupWin = window.open('', '_blank', 'width=600,height=600');
    popupWin.document.open();
    popupWin.document.write('<html><body onload="window.print()">'+this.dob+" / "+this.pname+" / "+this.mr + divToPrint.innerHTML + divglu.innerHTML + divbp.innerHTML + '</html>');
     popupWin.document.close();


}

date__form(){
    if(this.date_Form.valid){
         console.log("this.dateForm.value",this.date_Form.value);
         this.fromdate = moment(this.date_Form.value.Date1).format('DD-MM-YYYY')
         this.todate = moment(this.date_Form.value.Date2).format('DD-MM-YYYY')
        
         var Time1 = Math.trunc( new Date(this.date_Form.value.Date1).getTime() / 1000 );
         var Time2 = Math.trunc( new Date(this.date_Form.value.Date2).getTime() / 1000 );

         this.call_bp();
         this.call_gluco(1);
         this.set_Block(1)
       
         var params = {
            "code": this.code.toUpperCase(),
            "patientID": sessionStorage.getItem("mr"),
            "from_date": this.fromdate ,
            "to_date": this.todate,
            "from_time": Time1,
            "to_time": Time2
         }

         console.log("params", params);
         this.callchart(this.urlPoints.multilinechart , params);
        //  this.calltempchart(this.urlPoints.multilinechart , params);
    }
}

  set_Block(i) {
    this.Block = i
    this.call_gluco(parseInt(i));
  }


callchart(url , params){
    this.spinner.show();
    this.appService.getData(this.urlPoints.multilinechart , params).subscribe((response: any) => {

        document.getElementById('container').innerHTML = " ";
        this.newdata = response;
        this.activity = this.newdata;
        this.spinner.hide();
        this.printdownload = true;
        ['mousemove', 'touchmove', 'touchstart'].forEach(function (eventType) {
        document.getElementById('container').addEventListener(
            eventType,
            function (e) {
                var chart,
                    point,
                    i,
                    event;
    
                for (i = 0; i < Highcharts.charts.length; i = i + 1) {
                    chart = Highcharts.charts[i];
                    // Find coordinates within the chart
                    event = chart.pointer.normalize(e);
                    // Get the hovered point
                    point = chart.series[0].searchPoint(event, true);
                    
                    if (point) {
                        point.highlight(e);
                    }
                }
            }
        );
    });
    
        function syncExtremes(e) {
        var thisChart = this.chart;
    
        if (e.trigger !== 'syncExtremes') { 
            Highcharts.each(Highcharts.charts, function (chart) {
                if (chart !== thisChart) {
                    if (chart.xAxis[0].setExtremes) { 
                        chart.xAxis[0].setExtremes(
                            e.min,
                            e.max,
                            undefined,
                            false,
                            { trigger: 'syncExtremes' }
                        );
                    }
                }
            });
        }
    }
    Highcharts.Pointer.prototype.reset = function () {
        return undefined;
    };
    Highcharts.Point.prototype.select  = function (event) {
        event = this.series.chart.pointer.normalize(event);
        this.onMouseOver(); // Show the hover marker
        this.series.chart.tooltip.refresh(this); // Show the tooltip
        this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };
        
    console.log("this.activity this.activity", this.activity);
        this.xData = this.activity.xData;
        let that = this;
        if( this.activity){
        this.activity.datasets.forEach(function (dataset, i) {
    
           dataset.data = Highcharts.map(dataset.data, function (val, j) {
                    return [that.xData[j], val];
                });
           
             
                var chartDiv = document.createElement('div');
                chartDiv.className = 'chart';
                document.getElementById('container').appendChild(chartDiv);
    
                Highcharts.chart(chartDiv, {
                    chart: {
                        marginLeft: 50,
                        backgroundColor: 'white',
                        height:305,
                        borderColor: '#58b9d6',
                        borderWidth: 5,
                        shadow: true
                    },
                    
                    title: {
                        text: dataset.name,
                        align: 'left',
                        margin: 0,
                        style: { "color": "#333333", "fontSize": "15px" },
                        x: 30
                    },
                    credits: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    xAxis: {
                        crosshair: true,
                        gridLineWidth: 0.80,
                        events: {
                          setExtremes: syncExtremes
                        },
                
                        type: 'datetime',
                        labels: {
                           rotation:-25, 
                           formatter: function() {
                            return Highcharts.dateFormat("%H:%M", this.value);
                          }   
                        }
                    },
                    yAxis: {
                        gridLineWidth: 0.80,
                        title: {
                            text: null
                        },
                        tickInterval: dataset.steps,
                        min: dataset.lower_limit,
                        max: dataset.thresh_high,
                        plotLines: [
                            {
                                value: dataset.thresh_high,
                                color: '#FB6183',
                                zIndex: 100,
                                width: 2,
                               
                            },
                            {
                                value: dataset.thresh_low,
                                color: '#4DA5E1',
                                zIndex: 100,
                                width: 2,
                               
                            },
                            {
                                value: dataset.median ,
                                color: 'Black',
                                zIndex: 100,
                                width: 1,
                               
                            },
                            {
                                value: dataset.lower_grid,
                                color: 'grey',
                                zIndex: 100,
                                width: 1,
                               
                            },
                            {
                                value: dataset.lower_limit,
                                color: 'grey',
                                zIndex: 100,
                                width: 1,
                               
                            },
                            {
                                value: dataset.upper_grid,
                                color: 'grey',
                                zIndex: 100,
                                width: 1,
                                
                            },
                            {
                                value: dataset.upper_limit,
                                color: 'grey',
                                zIndex: 100,
                                width: 1,
                                
                            },
                        
                        ]
                    },
                    
                    
                    
                    tooltip: {
                        positioner: function () {
                            return {
                                x: this.chart.chartWidth-200,
                                y: 10 // align to title
                            };
                        },
                        
                        borderWidth: 0,
                        backgroundColor: "white",
                        pointFormat: '{point.y}',
                        headerFormat: '',
                        shadow: false,
                        style: {
                            fontSize: '18px'
                        },
                        valueDecimals: dataset.valueDecimals
                    },
                    
                    series: [{
                        data: dataset.data,
                        name: dataset.name,
                        type: dataset.type,
                        color: Highcharts.getOptions().colors[i],
                        fillOpacity: 0.3,
                        tooltip: {
                            valueSuffix: ' ' + dataset.unit
                        }
                    }]
                });
            });
      }

      });
}


  ngOnInit(){
    var type:any = sessionStorage.getItem("is_live")
    this.Account_id  = sessionStorage.getItem("acc_email")
    this.MR = sessionStorage.getItem("mr");
    console.log("this.Account_id,this.MR", this.Account_id,this.MR);
    var SearchDataparams = {
          'mr_number': this.MR.toUpperCase(),
          "account_email": this.Account_id  
    };
    console.log("paarms", SearchDataparams);
    if(type == 0){
      this.appService.postecData( environment.epic+"/service/search", SearchDataparams , this.auth).subscribe((response: any) => {
        if(response[0]){
          this.patientemail = response[0].email;

        }
      })
    }else if(type == 1){
      this.appService.postecData( environment.epic_dev+"/service/search", SearchDataparams , this.auth).subscribe((response: any) => {
        if(response[0]){
          this.patientemail = response[0].email;
        }
      })
    }
  }

  
  call_bp(){
      console.log("from date", this.fromdate , this.todate)
    var type:any = sessionStorage.getItem("is_live");
    var params = {
      "account_email" : this.Account_id,
      "patient_email": this.patientemail,
      "from_date": moment(this.date_Form.value.Date1).format('YYYY-MM-DD'),
      "to_date": moment(this.date_Form.value.Date2).format('YYYY-MM-DD'),
      "is_live" : parseInt(type),
      "vital": "bp"
  }
  
  
    this.appService.postData(this.urlPoints.bpivital ,params , this.auth).subscribe((response: any) => {
      this.bpivitaldata = response;

      if(this.bpivitaldata != ""){
 
    this.options = {
        chart: {
            type: 'scatter',
            zoomType: 'xy'
        },
        accessibility: {
            description: '',
        },
        title: {
            text: 'Blood Pressure'
        },
      
       xAxis: {
            title: {
                enabled: true,
                text: 'Time'
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true,
            type: 'datetime',
            labels: {
              rotation:-25, 
              formatter: function() {
                return Highcharts.dateFormat("%d-%b-%y %H:%M", this.value);
              }  
           }
        },
        yAxis: {
            title: {
                text: 'Values'
            }
        },
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            x: 0,
            y: 0,
            floating: true,
            borderWidth: 1
        },
        credits: {
          enabled: false
        },
        plotOptions: {
            // scatter:{
            //     lineWidth:2
            // },
            // tooltip: {
            //     headerFormat: '<b>{series.name}</b><br>',
            //     pointFormatter: function() {
            //       return 'Time : '+ Highcharts.dateFormat('%d-%b-%y %H:%M', this.x ) +', Value : '+  this.y 
            //    }
            // }
            scatter: {
                marker: {
                    radius: 5,
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    headerFormat: '<b>{series.name}</b><br>',
                    pointFormatter: function() {
                      return 'Time : '+ Highcharts.dateFormat('%d-%b-%y %H:%M', this.x ) +', Value : '+  this.y 
                   }
                }
            }
        },
        series: this.bpivitaldata
      }
      console.log('this.bpivitaldata', this.bpivitaldata);

    // this.options = {
        
    //     title: {
    //                 text: 'Blood Pressure'
    //             },
              
    //            xAxis: {
    //                 title: {
    //                     enabled: true,
    //                     text: 'Time'
    //                 },
    //                 startOnTick: true,
    //                 endOnTick: true,
    //                 showLastLabel: true,
    //                 type: 'datetime',
    //                 labels: {
    //                   rotation:-25, 
    //                   formatter: function() {
    //                     return Highcharts.dateFormat("%d-%b-%y %H:%M", this.value);
    //                   }  
    //                }
    //             },
    //             yAxis: {
    //                 title: {
    //                     text: 'Values'
    //                 }
    //             },
    
    //     legend: {
    //         layout: 'vertical',
    //         align: 'right',
    //         verticalAlign: 'middle'
    //     },
    
    //     plotOptions: {
    //         series: {
    //             label: {
    //                 connectorAllowed: false
    //             },
    //             pointStart: 2010
    //         }
    //     },
    
    //     series: this.bpivitaldata,
    
    //     responsive: {
    //         rules: [{
    //             condition: {
    //                 maxWidth: 500
    //             },
    //             chartOptions: {
    //                 legend: {
    //                     layout: 'horizontal',
    //                     align: 'center',
    //                     verticalAlign: 'bottom'
    //                 }
    //             }
    //         }]
    //     }
    // }


      Highcharts.chart('bp', this.options);

    }else{
        this.bpdata = "No Records Found For Blood Pressure"
    }
   
    });
  }
  
  call_gluco(data){
    console.log("from date", this.fromdate , this.todate)
    console.log("data", data);
    var type:any = sessionStorage.getItem("is_live")
  
    var unitvalue;
  
  if(data == 1){
    var params2 = {
      "account_email": this.Account_id,
      "patient_email": this.patientemail,
      "from_date": moment(this.date_Form.value.Date1).format('YYYY-MM-DD'),
      "to_date": moment(this.date_Form.value.Date2).format('YYYY-MM-DD'),
      "is_live":  parseInt(type),
      "vital": "glucometer",
      "unit_value" : "mmol"
  }
  
  unitvalue = 'mmol'
  }else if(data == 2){
    var params2 = {
      "account_email": this.Account_id,
      "patient_email": this.patientemail,
      "from_date": moment(this.date_Form.value.Date1).format('YYYY-MM-DD'),
      "to_date": moment(this.date_Form.value.Date2).format('YYYY-MM-DD'),
      "is_live":  parseInt(type),
      "vital": "glucometer",
      "unit_value" : "mgdl"
  }
  
  unitvalue = 'mgdl'
  }
  
  
    this.appService.postData(this.urlPoints.bpivital ,params2 , this.auth).subscribe((response: any) => {
      this.glucoivitaldata = response;

      if(this.glucoivitaldata != ''){

     this.options1 = {
  chart: {
      type: 'scatter',
      zoomType: 'xy'
  },
  accessibility: {
      description: '',
  },
  title: {
      text: 'Glucometer',
      style: {
        marginLeft: 20
    }
  },
  
  xAxis: {
      title: {
          enabled: true,
          text: 'Time'
      },
      type: 'datetime',
      labels: {
        rotation:-25, 
        formatter: function() {
          return Highcharts.dateFormat('%d-%b-%y %H:%M', this.value);
        }  
     },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true
  },
  yAxis: {
      title: {
          text: unitvalue 
      }
  },
  legend: {
      layout: 'horizontal',
      align: 'right',
      verticalAlign: 'top',
      x: 0,
      y: 20,
      floating: true,
      borderWidth: 1
  },
  credits: {
    enabled: false
  },
  plotOptions: {
      scatter: {
          marker: {
              radius: 5,
              states: {
                  hover: {
                      enabled: true,
                      lineColor: 'rgb(100,100,100)'
                  }
              }
          },
          states: {
              hover: {
                  marker: {
                      enabled: false
                  }
              }
          },
          tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              // pointFormat: '{point.x} , {point.y} ',
              pointFormatter: function() {
                return 'Time : '+ Highcharts.dateFormat('%d-%b-%y %H:%M', this.x ) +','+ unitvalue +' : '+  this.y 
             }
          }
      }
     },
    series: this.glucoivitaldata
  
    }
  
    Highcharts.chart('glucometer', this.options1)

}else{
    this.bpdata = "No Records Found For Glucometer"
}
    
    });
  }


}





  export interface DialogDatapdf {
    mypdfdata:any
  }

@Component({
    selector: 'pdfpreview',
    templateUrl:'pdfpreview.html',
    styleUrls: ['pdfpreview.scss']
  })
  
  export class DialogOverviewExampleDialogpdf {
 
    urlPoints;
    mypdfdata:any = [];

    token = sessionStorage.getItem("access_token")
  
    constructor(
      public dialogRef: MatDialogRef<DialogOverviewExampleDialogpdf>,private appService: AppService,
      @Inject(MAT_DIALOG_DATA) public data: DialogDatapdf) {
        this.urlPoints = AppConfig.settings.EndPoints;
        this.mypdfdata.push(this.data.mypdfdata);
      }
    onNoClick(): void {
      this.dialogRef.close();
    }

    callpreviewpdf(data){
        this.dialogRef.close(data);
    }
  
    closepop(){
      this.dialogRef.close();
    }
  
  }
  
