<div style="height:80vh; overflow-x: hidden;">
<div class="row">
    <div class="col-lg-8 col-md-8">
        <p class="header">Add Lab Reports</p>
    </div>

    <div class="col-lg-2 col-md-2">
        <p class="header" (click)="cancel() " style="cursor: pointer;">Cancel</p>

    </div>

    <div class="col-lg-2 col-md-2">
        <p class="header" (click)="save() " [class.disabled]="inv_selected.length==0" style="cursor: pointer;">Save</p>

    </div>
</div>

<div class="row" style="display: flex;justify-content: space-around;">

    <div class="col-lg-5 col-md-5 bg">

        <label style="width: 100%;">
            <div style="display: flex;justify-content: center;">
                <img src="assets/cloud-computing.svg">
            </div>
            <p class="text-center">Upload Image</p>
            <input type="file" id="files" multiple accept="image/*" style="display: none;" (change)="GetFile($event)">
        </label>

    </div>



    <div class="col-lg-5 col-md-5 bg">
        <label style="width: 100%;">
            <div style="display: flex;justify-content: center;">
                <img src="assets/surface1.svg">
            </div>

            <p class="text-center">Upload Document</p>
            <input type="file" id="files" multiple accept="application/pdf,application/vnd.ms-excel"
                style="display: none;" (change)="GetFile($event)">

        </label>

    </div>
</div>

<div style="margin-top: 1rem;margin-bottom: 1rem;">
    <div class="row image_size" *ngFor="let item of imgURL; let i= index;" style="margin-bottom:0.5rem">
        <div style="width: 30%;">
            <div class="text-center" style="position: relative;">
                <img *ngIf="item?.type != 'pdf'" src="" imgPreview [src]="item.url" class="image">
                <img *ngIf="item?.type === 'pdf'" imgPreview src="assets/pdf.png" class="image">
            </div>
        </div>
        <div style="width: 50%;">
            {{ item?.name }}
        </div>
        <div style="width: 20%;">
            <!-- <i class="fa fa-times-circle" (click)="removePicture(item, i);"></i> -->
            <img src="assets/delete_lab.svg" (click)="removePicture(item, i);" style="cursor: pointer;width: 1.5rem;">
        </div>
    </div>

</div>

<div [class.disabled]="inv_selected.length==1">
    <input (input)="search($event)">
    <div class="cc_items2" style="height: 5rem;max-height: 5rem;" infiniteScroll [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50" (scrolled)="onScroll4()" [infiniteScrollDisabled]="isFullListDisplayed"
        [scrollWindow]="false">
        <div #inv_item style="font-size: 0.75rem;height: fit-content;"
            class="cc_item chip chip-md success-color white-text  example z-depth-2 mr-1"
            *ngFor="let inv of inv_copy1;let indexOfelement=index;let i as index" tabindex="1"
            (click)="update_inv(indexOfelement,i)" (keydown.enter)="update_inv(indexOfelement,i)">

            {{inv.name}}
        </div>
        <!-- <p *ngIf="investigations.length==0">No investigations found.</p> -->
    </div>
</div>

<div>
    <p style="font-weight: 600;">Title</p>
    <p style="cursor: pointer;"><span *ngFor="let inv_s of inv_selected; let i=index;" (click)='click4(i)'
            (dblclick)='doubleClick4(i)'>{{inv_s.name}}  <i class="fa fa-remove" (click)='remove_inv(i)'></i><span *ngIf="inv_selected.length!=(i+1)">, </span></span></p>
</div>

<div   style="margin-top: 0.5rem;margin-bottom: 0.5rem;">
    <input style="cursor:pointer;" type="checkbox" id="abnormal" name="abnormal" [(ngModel)]="abnormal">
    <label for="abnormal">Abnormal</label>
</div>

<div style="display: flex;margin-top: 0.5rem;margin-bottom: 0.5rem;">
    <p>Test taken date:</p>
    <input type="date" style="margin-left: 0.5rem;" [(ngModel)]="takenDate" value="date" max="{{date}}">
</div>

<div>
    <p style="font-weight: 600;">File Name</p>

    <textarea maxlength="200" name="fileName" [(ngModel)]="fileName"></textarea>
</div>

<div>
    <p style="font-weight: 600;">Impression</p>

    <textarea maxlength="200" name="description" [(ngModel)]="description"></textarea>
</div>

<div>
    <p style="font-weight: 600;">Comments</p>
    <textarea [(ngModel)]="comments"></textarea>
</div>
</div>