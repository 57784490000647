<div style="display: flex;margin-bottom: 00.5rem;">
    <p style="color: #1A73FF;font-weight:600;font-size:1rem">DICOM files</p>
    <img (click)="close()" style="margin-left: auto;cursor: pointer;width: 1rem;" src="assets/close-copy.svg">
</div>

<div>
    <p *ngIf="data.pacAttachments.length==0">No studies available for the patient</p>

    <div *ngIf="data.pacAttachments.length>0" style="display:flex;flex-wrap:wrap">
        <div *ngFor="let d of data.pacAttachments;let i = index"
            style="width: 30%;border: 1px solid #D9D9D9;padding:0.5rem;margin-right: 3%;margin-bottom: 0.5rem;">
            <div style="display: flex;margin-bottom: 00.5rem;justify-content:space-between;">
                <p style="align-self: center;font-size: 1rem;font-weight:600">Study {{i+1}}</p>


            </div>

            <div style="display: flex;justify-content:space-between">

                <div style="margin-right: 0.5rem;display: flex;cursor: pointer;padding:5px" (click)="view(d)"
                    class="btn-primary1">
                    <img style="cursor:pointer;width: 2rem;" [src]="imgURL[i]">
                    <p style="align-self: center;margin-left: 0.5rem;">View</p>

                </div>

                <div style="display:flex;cursor: pointer;padding:5px" (click)="add(d,i)" class="btn-primary1">
                    <img style="cursor:pointer;width: 2rem;" src="assets/add_lab.svg">
                    <p style="align-self: center;margin-left: 0.5rem;">Add</p>
                </div>

            </div>

            <div style="display: flex;cursor: pointer;padding:5px;margin-top: 0.5rem;" (click)="addAttachment(d,i)"
                class="btn-primary1">
                <img style="cursor:pointer;width: 2rem;" src="assets/add_icon1.svg">

                <p *ngIf="files[i].length==0" style="
                align-self: center;
                margin-left:00.5rem">Add Attachments</p>

                <p *ngIf="files[i].length>0" style="align-self: center;margin-left:00.5rem">{{files[i].length}} Attachment(s) added</p>
            </div>
        </div>
    </div>
</div>