import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questionnaire-charts',
  templateUrl: './questionnaire-charts.component.html',
  styleUrls: ['./questionnaire-charts.component.scss']
})
export class QuestionnaireChartsComponent implements OnInit {
  questionnaire_data;
  questions_array;

  constructor() { }

  ngOnInit(): void {
    this.questionnaire_data=JSON.parse(sessionStorage.getItem('questionnaire_data'))

    console.log(this.questionnaire_data)

    console.log(this.questionnaire_data[0].data)

    this.questions_array = this.questionnaire_data[0].data

  }

}
