import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PubNubAngular } from 'pubnub-angular2';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-hospitals',
  templateUrl: './hospitals.component.html',
  styleUrls: ['./hospitals.component.scss']
})
export class HospitalsComponent implements OnInit {

  token = localStorage.getItem("authentication");
  code = sessionStorage.getItem("code")
  urlPoints;
  hospital;
  hospital_create = true;
  countrycode;
  hospitalForm;
  updatehospitalForm;

  constructor(private fb: FormBuilder ,private appService: AppService, pubnub: PubNubAngular, private router: Router , private http: HttpClient , private spinner: NgxSpinnerService , private toastr: ToastrService) { 
    this.urlPoints = AppConfig.settings.EndPoints;
    this.hospitalForm = this.fb.group({
      hospitalname: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      phonenumber: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(15)]],
      address1: ["", [Validators.required]],
      address2: ["", [Validators.required]],
      city: ["", [Validators.required]],
      state: ["", [Validators.required]],
      country: ["", [Validators.required]],
      pincode: ["", [Validators.required,Validators.minLength(6), Validators.maxLength(6)]],
    })

    this.updatehospitalForm = this.fb.group({
      hospitalname: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      phonenumber: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(15)]],
      address1: ["", [Validators.required]],
      address2: ["", [Validators.required]],
      city: ["", [Validators.required]],
      state: ["", [Validators.required]],
      country: ["", [Validators.required]],
      pincode: ["", [Validators.required,Validators.minLength(6), Validators.maxLength(6)]],
    })

    var hospitalparams = {
      code : this.code
    }

    this.gethospital(this.urlPoints.gethospitals ,  hospitalparams)
  }

  ngOnInit(): void {

  }

  onCountryChange(event){
    console.log(event.dialCode);
    console.log(event.name);
    console.log(event.iso2);
    this.countrycode = event.dialCode;
}

  gethospital(url , params){
    this.appService.getData( url ,params).subscribe((response: any) => {
      console.log("hospitals response", response.settings.success);
      if( response.settings.success == 1){
         this.hospital =  response.settings.profile
      }else if(response.settings.success == 0){
        this.hospital_create =  false;
      }
    });
  }

  save_hospitalForm(){
    console.log(" hospitalForm" , this.hospitalForm)
    if(this.hospitalForm.valid){
      var data = this.hospitalForm.value;
      console.log("data hosparams", data);
      var hosparms={
        "mode": 0,
        "code": sessionStorage.getItem("code"),
        "hospital_name": data.hospitalname,
        "address1" : data.address1,
        "address2" : data.address2,
        "city": data.city,
        "state": data.state,
        "country":data.country,
        "pincode" : data.pincode,
        "country_code" : this.countrycode,
        "mobile" : data.phonenumber,
        "avatar" : ""
       }
      this.createhospital(this.urlPoints.gethospitals ,  hosparms , this.token)
    }
  }

  update_hospitalForm(){
    if(this.updatehospitalForm.valid){
      var data = this.updatehospitalForm.value;
      console.log("data hosparams", data);
      var hosparms={
        "mode": 1,
        "code": sessionStorage.getItem("code"),
        "hospital_name": data.hospitalname,
        "address1" : data.address1,
        "address2" : data.address2,
        "city": data.city,
        "state": data.state,
        "country":data.country,
        "pincode" : data.pincode,
        "country_code" : this.countrycode,
        "mobile" : data.phonenumber,
        "avatar" : ""
       }
      this.createhospital(this.urlPoints.gethospitals ,  hosparms , this.token)
    }
  }


  createhospital(url ,params , token){
  
    this.appService.postData(url ,params , token).subscribe((response: any) => {
      this.spinner.hide()
     if(response.settings.success == 1){
      this.toastr.success(response.settings.message)
      this.router.navigate(["/settings"],{ queryParams: { } })
     }else{
      this.toastr.error(response.settings.message);
     }
   });
  }

  

}
