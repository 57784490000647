import { Component, OnInit , Type} from '@angular/core';
import { PubNubAngular } from 'pubnub-angular2';
import { AppConfig } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { computeDecimalDigest } from '@angular/compiler/src/i18n/digest';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClient  } from '@angular/common/http';
import {Md5} from 'ts-md5/dist/md5';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {
  securityForm;
  urlPoints;
  password;
  show = false;

  ip = "255.255.255.255"

  code = sessionStorage.getItem("code");
  token = localStorage.getItem("authentication");
  constructor(private fb: FormBuilder , private appService: AppService, pubnub: PubNubAngular, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router , private toastr: ToastrService , private http:HttpClient) { 
    this.urlPoints = AppConfig.settings.EndPoints;
    this.securityForm = this.fb.group({
      username: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      pass_word: ["", [Validators.required]],
    })
    
  }

  ngOnInit(): void {
    this.password = 'password';
    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
        this.ip = res.ip;
    })
  // admin
  // 21232f297a57a5a743894a0e4a801fc3
}



  save_securityForm(){
  

    if(this.securityForm.valid){
      var data = this.securityForm.value;
      const md5 = new Md5();
      var data1 = md5.appendStr(data.pass_word).end()
      var verifyusernamepassParams = {
        "mode": 1,
        "uname" : data.username,
        "upswd" : data1,
        "ip_address" : this.ip,
        "code" : this.code
       }
  
      this.verrifyusernamepasswordParams( this.urlPoints.verifyadminpassword , verifyusernamepassParams , this.token);

     }
  }

  verrifyusernamepasswordParams(url ,params , token){
    console.log('response first' , url ,params , token)
    this.appService.postData(url ,params , token).subscribe((response: any) => {
       if(response.settings.success == 1 && response.settings.message == "correct username and password"){
          this.toastr.success(response.settings.message);
          this.router.navigate(["/addpatient"],{ queryParams: {}})
       }else{
          this.toastr.error(response.settings.message);
       }
    });
  }

 
  
 

  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }


}
