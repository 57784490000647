<app-navbar class="mb-2"></app-navbar>


<div class="row">
    <div class="col-sm-1 col-md-1 col-lg-1"></div>
    <div class="col-sm-10 col-md-10 col-lg-10">

       
        <div style="display:flex">
        <span style="margin-top: 3px;color:#FFF">Date :</span>
        <input style="margin-left: 10px;" type="text"
        ngxDaterangepickerMd
        [locale]="{format: 'DD-MM-YYYY'}"
        [(ngModel)]="selected"
        [showCustomRangeLabel]="true"
        [alwaysShowCalendars]="true"
        [ranges]="ranges"
        [linkedCalendars]="true"
        [isInvalidDate] = "isInvalidDate"
        [showClearButton]="true"
        (rangeClicked)="rangeClicked($event)"
        (datesUpdated)="datesUpdated($event)"
        placeholder="Select Date"/>
    </div>   
    <div>
      <div style="display:flex;float: right;margin-right:10px;">
        <div style="margin-top: 2px;color:#FFF"><h4>Download : </h4></div>
        <div style="margin-left:10px;" class="addDr" (click)="downloadXLS()"><div class="addDrTxt">XLS</div></div>
        <div style="margin-left:10px;" class="addDr" (click)="downloadPDF()"><div class="addDrTxt">PDF</div></div>
    </div>
    </div>
    

      <!-- <div style="text-align: center !important;justify-content:center !important">
        <nav aria-label="...">
           <ul class="pagination pagination-sm" >
             <span style="color: #fff;margin-top:3px;"> Pages &nbsp;&nbsp;</span> <li class="page-item" [class.pagess]="mypage == i"  id="pages{{i}}" style="border: 1px solid white;cursor:pointer" *ngFor="let num of totalpagebuttons ;let i = index"  (click)="callpagination(num , i)"><a class="page-link" id="pagenum">{{num}}</a></li>
           </ul>
        </nav>
      </div> -->
      <div id="mytable">
        <table class="table tablefix" style="margin-top: 20px;">
        <thead>
            <tr>
                <th>Date & Time</th>
                <th>Patient Id</th>
                <th>Vital</th>
                <th>Message</th>
                
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of getreportData">
                <td>{{item.time}}</td>
                <td>{{item.patient_id}}</td>
                <td style="width:100px">{{item.vital}}</td>
                <td>{{item.message}}</td>
              </tr>
            
        </tbody>
        </table>
        <div *ngIf="getreportData == ''">
         <div style="text-align:center;color:#fff"> No Data</div> 
        </div>
      </div>
      <!-- <div style="float: right;margin-top: 10px;margin-right: 20px;">
        <app-paginate style="margin-top: 1rem;" [items]="pagelist" [pages]="count" (changePage)="onChangePage($event)"></app-paginate>
      </div> -->
    </div>
    <div class="col-sm-1 col-md-1 col-lg-1"></div>
</div>

  
  

