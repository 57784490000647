<!-- <app-navbar class="mb-2"></app-navbar> -->
<form [formGroup]="communityForm" (submit)="community_form()">
<div class="row mt-1">
    <div class="col-lg-4"></div>
    <div class="col-lg-4">
        <div class="text-center" ><h2>Community Code : <span style="color:red">{{realcode}}</span> </h2></div>
    <div class="mt-3"> 
        <input type="text" class="form-control" placeholder="community code" formControlName="community_code">
    </div>
    <div class="mt-3">
    <input class="form-control lastnamefilter" type="text" ng2TelInput
    (countryChange)="onCountryChange($event)"  placeholder="phonenumber" [ng2TelInputOptions]="{initialCountry: 'in'}" formControlName="phonenumber" style="width: 116% !important;"/>
    </div>

    <div  class="mt-3">
        <input type="email" class="form-control lastnamefilter" placeholder="Email Id" formControlName="Emailaddress">
    </div>
    <!-- <div  class="mt-3">
        <input type="password" class="form-control" placeholder="Password" formControlName="password">
    </div> -->
    <div  class="mt-3">
        <img src="assets/ivital/islive.png"  class="mr-2" alt="ivital" height="25" width="25"><input type="checkbox" value="" formControlName="islive">
    </div>
    
    <div  class="mt-4">
        <button mat-flat-button color="primary">Assign code</button>
    </div>
    <div class="col-lg-4"></div>
    </div>
</div>
</form>