import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppService {
//  settings: any;

  constructor(private http: HttpClient) {
    // tslint:disable-next-line: deprecation
    // this.settings = this.injector.get('config').settings;
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  getData(url: string, params): Observable<any> 
  {
    return this.http.get(environment.HOST + url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      params,
    });
  }

  getpaginationData(url: string, params, pagecount): Observable<any> 
  {
    return this.http.get(environment.HOST + url+ '/' + pagecount, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      params,
    });
  }

  postData(url: string, body: any, token?: string): Observable<any>
  {
    // console.log("respose token", token)
    return this.http.post(environment.HOST + url, JSON.stringify(body), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token
      })
    });
  }

  postData1(url: string, body: any, token?: string): Observable<any>
  {

    var type:any = sessionStorage.getItem("is_live")

    console.log(url)

    console.log(token)


    // console.log("respose token", token)
    return this.http.post((type==0?environment.epic:environment.epic_dev) + url, JSON.stringify(body), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token
      })
    });
  }

  getecData(url: string, params , token?: string): Observable<any> 
  {
    // console.log("url url", url);
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token
      }),
      params,
    });
  }

  getecData1(url: string, params , token?: string): Observable<any> 
  {
    // console.log("url url", url);
    var type:any = sessionStorage.getItem("is_live")

    return this.http.get((type==0?environment.epic:environment.epic_dev) + url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token
      }),
      params,
    });
  }

  postecData(url: string, body: any, token?: string): Observable<any>
  {
    console.log(token)
    return this.http.post( url, JSON.stringify(body), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token
      })
    });
  }


  postimgData(url: string, body: any, token?: string): Observable<any>
  {
    
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });

     var data = new FormData();
    data.append('photo', body);
    // data.append('param', body);
   return this.http.post(url+environment.imgurl, data, {
     reportProgress: true,
     observe: 'events',
   });
   
  }


  
  
  postimgData2(Url: string,url: string, body: any, token: string,type): Observable<any>
  {
    var data = new FormData();
    data.append(type==1?'photo':'pdf', body);
    console.log('url+environment.imgurl', Url + url);

    return this.http.post(Url + url, data, {
     reportProgress: true,
     observe: 'events',
   });
   
  }


  postimgData3(url: string, body: any, token: string, type): Observable<any> {
    var data = new FormData();
    console.log(type)
    var type2:any = sessionStorage.getItem("is_live")

    data.append(type == 1 ? 'photo' : 'pdf', body);

    return this.http.post((type2==0?environment.epic1:environment.epic_dev1) + url, data, {
      reportProgress: true,
      observe: 'events',
    });

  }


}