
import { Component, Input, OnInit , Type} from '@angular/core';
import { PubNubAngular } from 'pubnub-angular2';
import { AppConfig } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { computeDecimalDigest } from '@angular/compiler/src/i18n/digest';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Moment } from 'moment';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import * as lib from 'countries-and-timezones';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit { 
  
  urlPoints;
  audios;
  vitalbreach;
  snoozealert;
  snoozeaudios;
  IVC;
  ivcs;
  oc;
  ocs;
  notification;
  notificationalert;
  code = sessionStorage.getItem("code");
  favoritetone: any;
  allalerts;
  panelOpenState = false;

  token = localStorage.getItem("authentication");

  constructor(private fb: FormBuilder , private appService: AppService, private spinner: NgxSpinnerService ,  pubnub: PubNubAngular, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router , private toastr: ToastrService) { 
    this.urlPoints = AppConfig.settings.EndPoints;
    
    var audio_forallparams = {
      "code": this.code.toUpperCase()
    }

    this.appService.getData(this.urlPoints.getalert, audio_forallparams).subscribe((response: any) => {
       console.log("response 47", response);
        if(response.settings.alerts[0].alert_name == "Vitals Breach"){
          var audiostring = response.settings.alerts[0].audio_url
          var getaudio = audiostring.charAt(audiostring.length - 5);
          console.log("getaudio", getaudio);
          this.vitalbreach = getaudio+response.settings.alerts[0].audio_url;
        }
        if(response.settings.alerts[1].alert_name == "Snooze Alert"){
          var audiostring = response.settings.alerts[1].audio_url
          var getaudio = audiostring.charAt(audiostring.length - 5);
          var get_audio = parseInt(getaudio) + 4;
          this.snoozealert = get_audio+response.settings.alerts[1].audio_url;
        }
        if(response.settings.alerts[2].alert_name == "Incoming Video Calls"){
          var audiostring = response.settings.alerts[2].audio_url
          var getaudio = audiostring.charAt(audiostring.length - 5);
          var get_audio = parseInt(getaudio) + 6;
          this.IVC = get_audio+response.settings.alerts[2].audio_url;
        }
        if(response.settings.alerts[3].alert_name == "Outgoing Calls"){
          var audiostring = response.settings.alerts[3].audio_url
          var getaudio = audiostring.charAt(audiostring.length - 5);
          var get_audio = parseInt(getaudio) + 9;
          this.oc = get_audio+response.settings.alerts[3].audio_url;
        }
        if(response.settings.alerts[4].alert_name == "Notification Alert"){
          var audiostring = response.settings.alerts[4].audio_url
          var getaudio = audiostring.charAt(audiostring.length - 5);
          console.log("getaudiogetaudiogetaudio", getaudio);
          var get_audio = parseInt(getaudio) + 10
          this.notification = get_audio+response.settings.alerts[4].audio_url;
          console.log("this.notification",this.notification );
        }
        
    });

    var audioparams = {
      "alert_id": 1
    }
    this.appService.getData(this.urlPoints.getaudioalerrt, audioparams).subscribe((response: any) => {
      if(response.settings.success == 1){
        this.audios = response.settings.alerts
      }
    });

    var audiosnoozeparams = {
      "alert_id": 2
    }
    this.appService.getData(this.urlPoints.getaudioalerrt, audiosnoozeparams).subscribe((response: any) => {
      if(response.settings.success == 1){
        this.snoozeaudios = response.settings.alerts
      }
    });

    var audiosnoozeparams = {
      "alert_id": 3
    }
    this.appService.getData(this.urlPoints.getaudioalerrt, audiosnoozeparams).subscribe((response: any) => {
      if(response.settings.success == 1){
        this.ivcs = response.settings.alerts
      }
    });

    var audiosnooze_params = {
      "alert_id": 4
    }
    this.appService.getData(this.urlPoints.getaudioalerrt, audiosnooze_params).subscribe((response: any) => {
      if(response.settings.success == 1){
        this.ocs = response.settings.alerts
      }
    });

    var audionotificationparams = {
      "alert_id": 5
    }
    this.appService.getData(this.urlPoints.getaudioalerrt, audionotificationparams).subscribe((response: any) => {
      if(response.settings.success == 1){
        this.notificationalert = response.settings.alerts
        console.log("this.audios 102", this.notificationalert);
      }
    });

    var audio_params ={
      
    }
    this.getaudio(this.urlPoints.getmasteralerts, audio_params);

  }

  ngOnInit(): void {
  }


  getaudio(url ,params){
    this.appService.getData(url, params).subscribe((response: any) => {
      if(response.settings.success == 1){
        console.log("response audio", response.settings.alerts);
        this.allalerts =  response.settings.alerts
      }
    });
  }

selectedtone(e){
  this.spinner.show()
  var audioparms={
   "code" : this.code,
   "mode" : "1",
   "alert_id": "1",
   "audio_id" : e.value
  }
 this.updateaudio(this.urlPoints.getalert ,  audioparms , this.token)   
}

audiochangencalls(e){
  console.log("e", e.target.value);
  var myaudio = e.target.value;
  var firstletter = myaudio.charAt(0);
  var audiostring = myaudio.substring(2)
  var getaudio = audiostring.charAt(audiostring.length - 5);
  console.log("getaudio", getaudio);
  console.log("audiostring 183",audiostring);
  let audio = new Audio();
  audio.src = audiostring;
  audio.load();
  audio.play();

         

  var audioupdateparams={
    "code" : this.code,
    "mode" : "1",
    "alert_id": "5",
    "audio_id" : parseInt(getaudio) + 10
  }
  this.updateaudio(this.urlPoints.getalert ,audioupdateparams , this.token)
}

audiochangeoutcalls(e){
  console.log("e", e.target.value);
  var myaudio = e.target.value;
  var firstletter = myaudio.charAt(0);
  var audiostring = myaudio.substring(2)
  let audio = new Audio();
  audio.src = audiostring;
  audio.load();
  audio.play();

  var audioupdateparams={
    "code" : this.code,
    "mode" : "1",
    "alert_id": "4",
    "audio_id" : firstletter
  }
  this.updateaudio(this.urlPoints.getalert ,audioupdateparams , this.token)
}

audiochangeivc(e){
  console.log("e", e.target.value);
  var myaudio = e.target.value;
  var firstletter = myaudio.charAt(0);
  var audiostring = myaudio.substring(1)
  let audio = new Audio();
  audio.src = audiostring;
  audio.load();
  audio.play();

  var audioupdateparams={
    "code" : this.code,
    "mode" : "1",
    "alert_id": "3",
    "audio_id" : firstletter
  }
  this.updateaudio(this.urlPoints.getalert ,audioupdateparams , this.token)
}

audiochangesnooze(e){
  console.log("e", e.target.value);
  var myaudio = e.target.value;
  var firstletter = myaudio.charAt(0);
  var audiostring = myaudio.substring(1)
  let audio = new Audio();
  audio.src = audiostring;
  audio.load();
  audio.play();

  var audioupdateparams={
    "code" : this.code,
    "mode" : "1",
    "alert_id": "2",
    "audio_id" : firstletter
  }
  this.updateaudio(this.urlPoints.getalert ,audioupdateparams , this.token)
}

audiochange(e){

  var myaudio = e.target.value;
  var firstletter = myaudio.charAt(0);
  var audiostring = myaudio.substring(1)
  let audio = new Audio();
  audio.src = audiostring;
  audio.load();
  audio.play();

  var audioupdateparams={
    "code" : this.code,
    "mode" : "1",
    "alert_id": "1",
    "audio_id" : firstletter
  }
  this.updateaudio(this.urlPoints.getalert ,audioupdateparams , this.token)
}

updateaudio(url ,params , token){
  this.appService.postData(url ,params , token).subscribe((response: any) => {
    this.spinner.hide()
   if(response.settings.success == 1){
    this.toastr.success(response.settings.message)
   }else{
    this.toastr.error(response.settings.message);
   }
 });
}
 

}
