<app-navbar class="mb-2"></app-navbar>
<div class="wrapper fadeInDown">
    <div id="formContent">
      <div class="fadeIn first">
        <img src="assets/ivital/LOGO.svg" id="icon" alt="ivital logo" />
      </div>
      <form [formGroup]="securityForm" (submit)="save_securityForm()">
        <input type="text" id="login" class="fadeIn second" name="login" placeholder="username" formControlName="username">
        <span>
          <input [type]="password" id="password" class="fadeIn third" name="login" placeholder="password" formControlName="pass_word" onpaste="return false;" ondrop="return false;" autocomplete="off">
          <span class="hideshow"><i class="fa fa-eye" aria-hidden="true" *ngIf="!show" (click)="onClick()"></i><i class="fa fa-eye-slash" aria-hidden="true" *ngIf="show" (click)="onClick()"></i></span>
        </span>
        <input type="submit" class="fadeIn fourth" value="Log In">
        <!-- <div id="formFooter">
          <a class="underlineHover"  href="#" [routerLink]="['/createaccount']">Create account</a> or <a class="underlineHover" href="#" [routerLink]="['/reset']">Reset password</a>
        </div>  -->
      </form>
    </div>
</div>
